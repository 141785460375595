import styled from 'styled-components';

export const CustomizedTableWrapper = styled.div`
  border-radius: 8px;
  margin-top: 2rem;

  div.rdt_TableBody {
    margin: 15px;
  }

  div.rdt_TableCol_Sortable:hover {
    color: #cfcfcf;
  }

  div.rdt_TableHeadRow {
    align-items: center;
  }

  div.rdt_TableCell {
    width: 100%;
  }

  div.rdt_TableRow:hover div.rdt_TableCell {
    color: #0d65ff;
  }

  div.rdt_TableRow {
    border-bottom: 1px solid #e4e5e6;
  }

  div.rdt_tableCell {
    color: '#6E6969';
    font-weight: 600;
  }

  div.rdt_TableCol_Sortable:focus {
    color: #cfcfcf;
  }
`;

export const MaterialButton = styled.button.attrs({
  type: 'button'
})`
  padding: 10px;
  border-radius: 50%;
  transition: all 0.2s;

  &:hover {
    background-color: rgb(13, 101, 255, 0.1);

    svg {
      fill: #0d65ff;
    }
  }
`;
