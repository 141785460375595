/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * Os comentarios foram adicionados para simular ambientes de teste
 * Remve-los apos o servidor estiver online
 */

import React, { useState, useContext } from 'react';
import { CheckoutContext } from './Context';
import { AuthContext } from '../Auth';
// eslint-disable-next-line
import { userSubscriptions, UserPaymentMethod, PriceObj, CouponObj, InvoiceObj, CustomerObj } from '../../interfaces';
import { withAuth, Axios } from '../../utils/axios';
import { getActiveProduct } from '../../utils/checkoutHelpers';

interface Props {
  children: React.ReactNode;
}
const localSelectedPrice = localStorage.getItem('selected-price');

export const CheckoutProvider: React.FC<Props> = ({ children }) => {
  const [customer, setCustomer] = useState<CustomerObj | undefined>(undefined);
  const [isChangingPaymentMethod, setIsChangingPaymentMethod] = useState(false);
  const [isChangingPlan, setIsChangingPlan] = useState<boolean>(false);
  const [isChangedPlan, setIsChangedPaln] = useState<boolean>(false);
  const [errorOnConfirmSubscription, setErrorOnConfirmSubscription] = useState<string | undefined>(undefined);

  const [coupon, setCoupon] = useState<CouponObj>();
  const [isAtachingPaymentMethod, setIsAtachingPaymentMethod] = useState<boolean>(false);
  const [atachPaymentError, setAtachPaymentError] = useState<string | undefined>(undefined);
  const [atachedPaymentMethod, setAtachedPaymentMethod] = useState<boolean>(false);
  const [isGettingPrices, setIsGettingPrices] = useState<boolean>(false);
  const [gettedPrices, setGettedPrices] = useState<boolean | undefined>(undefined);
  const [prices, setPrices] = useState<PriceObj[]>();

  const [userSubscriptions, setUserSubscriptions] = useState<userSubscriptions[] | undefined>();
  const [isGettingUserSubscriptions, setisGettingUserSubscriptions] = useState<boolean>(false);
  const [gettedUserSubscriptions, setgettedUserSubscriptions] = useState<boolean>(false);

  const [userPaymentMethod, setUserPaymentMethod] = useState<UserPaymentMethod | undefined>();
  const [isGettingUserPayemntMethod, setIsGettingUserPaymentMethod] = useState<boolean>(false);
  const [gettedUserPayemntMethod, setGettedUserPaymentMethod] = useState<boolean>(false);

  const [isValidatingCoupon, setIsValidatingCoupon] = useState<boolean>(false);
  const [isValidCoupon, setIsValidCoupon] = useState<number>(0);

  const [discountedPrice, setDiscountedPrice] = useState<number>(0);

  const [selectedPrice, setSelectedPrice] = useState<PriceObj | undefined>(
    localSelectedPrice && localSelectedPrice !== 'undefined' ? JSON.parse(localSelectedPrice) : undefined
  );

  const [isCancelingSubscription, setIsCancelingSubscription] = useState<boolean>(false);
  const [canceledSubscription, setCanceledSubscription] = useState<number>(0);

  const [userPrice, setUserPrice] = useState<PriceObj | undefined>();
  const [isGettingUserPrice, setIsGettingUserPrice] = useState<boolean>(false);
  const [gettedUserPrice, setGettedUserPrice] = useState<boolean>(false);

  const [isGettingUserInvoices, setIsGettingUserInvoices] = useState<boolean>(false);
  const [gettedUserInvoices, setGettedUserInvoices] = useState<boolean>(false);
  const [userInvoices, setUserInvoices] = useState<InvoiceObj[] | undefined>(undefined);

  const [isGettingUserUpcomingInvoice, setIsGettingUserUpcomingInvoice] = useState<boolean>(false);
  const [gettedUserUpcomingInvoice, setGettedUserUpcomingInvoice] = useState<boolean>(false);
  const [userUpcomingInvoice, setUserUpcomingInvoice] = useState<InvoiceObj | undefined | any>(undefined);

  const [isReactivatingSubscription, setIsReactivatingSubscription] = useState<boolean>(false);
  const [reactivatedSubscription, setReactivatedSubscription] = useState<boolean>(false);

  const [userActivePlan, setActivePlan] = useState<PriceObj>();
  const [isGettingPlan, setIsGettingPlan] = useState(false);

  const { token } = useContext(AuthContext);

  // const setIsChangingPaymentMethod = (setChanging: boolean) => setIsChangingPaymentMethod(setChanging);

  const getUserSubscriptions = async (defaultToken?: string) => {
    // setisGettingUserSubscriptions(true);

    // try {
    //   const response = await Axios(process.env.REACT_APP_NODE_ENV).get('/v2/subscriptions/me', withAuth(defaultToken || token));
    //   setgettedUserSubscriptions(true);

    //   setCustomer(response.data.data.customer);
    //   setUserSubscriptions(response.data.data.subscriptions);

    //   setisGettingUserSubscriptions(false);
    //   setReactivatedSubscription(false);
    //   return response.data.data.subscriptions;
    // } catch (error) {
    //   setisGettingUserSubscriptions(false);
    //   setgettedUserSubscriptions(false);
    //   console.error(error);
    //   return [];
    // }
    return []
  };

  const validateCoupon = async (couponId?: string) => {
    // consider status 200 to good request or 400 to bad
    setIsValidatingCoupon(true);
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v2/subscriptions/coupons/${couponId}`, {
        headers: {
          Authorization: `Bearer sk_test_IVX50N9MfZEX74VeN2SJd7Z100JHjriXZm`
        }
      });
      setCoupon(response.data.data);
      if (response.data.data.valid) {
        setIsValidCoupon(1);
        setIsValidatingCoupon(false);
      } else {
        setIsValidCoupon(-1);
        setIsValidatingCoupon(false);
      }
    } catch (error) {
      setIsValidCoupon(-1);
      setIsValidatingCoupon(false);
      console.error(error.response);
    }
  };

  const atachPaymentMethod = async (paymentMethod?: UserPaymentMethod) => {
    setIsAtachingPaymentMethod(true);
    try {
      await Axios(process.env.REACT_APP_NODE_ENV).post(
        'v2/subscriptions/payment-methods/attach/',
        {
          id_stripe_payment_method: paymentMethod?.id
        },
        withAuth(token)
      );
      setAtachedPaymentMethod(true);
      setUserPaymentMethod(paymentMethod);
      setIsAtachingPaymentMethod(false);
    } catch (error) {
      console.error(error.response);
      if (error.response.data.message === 'Error: Your card was declined.') {
        setAtachPaymentError('Erro: Seu cartão está bloqueado.');
      } else if (error.response.data.message === 'Error: Your card number is incorrect.') {
        setAtachPaymentError('Erro: Verifique as informações do cartão e tente novamente.');
      } else if (error.response.data.message === 'Error: Your card has insufficient funds.') {
        setAtachPaymentError('Erro: Seu cartão não possui saldo suficiente para realizar a assinatura.');
      } else if (error.response.data.message === 'This payment method is already attached to another customer.') {
        setAtachPaymentError('Esse cartão já está sendo usado em outra assinatura.');
      } else {
        setAtachPaymentError(error.response.data.message);
      }

      setAtachedPaymentMethod(false);
      setIsAtachingPaymentMethod(false);
    }
  };

  const getUserPaymentMethod = async (defaultToken?: string) => {
    setIsGettingUserPaymentMethod(true);
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(
        'v2/subscriptions/payment-methods/',
        withAuth(defaultToken || (token as string))
      );

      if (Array.isArray(response.data.data)) {
        if (!response.data.data.length) {
          setUserPaymentMethod(undefined);
          setGettedUserPaymentMethod(false);
        }
      } else if (response.data.data.data.length) {
        const lastPayment = response.data.data.data.length - 1;
        setUserPaymentMethod(response.data.data.data[lastPayment]);
        setGettedUserPaymentMethod(true);
      }

      setIsGettingUserPaymentMethod(false);
    } catch (error) {
      console.error(error);
      setIsGettingUserPaymentMethod(false);
      setGettedUserPaymentMethod(false);
    }
  };

  const noAvailablePriceIds = [
    '5f64dba5cc18cb2f53ec1e72',
    '5f64dc3acc18cb2f53ec1e73',
    '5f7baf76d9bc7db5f28ea35f',
    '5f889310be69e4e822a23f90',
    '5fbeb894fd7fb42688fb7d69'
  ];

  const getPrices = async () => {
    setIsGettingPrices(true);
    const prices: React.SetStateAction<PriceObj[] | undefined> = [];
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get('v2/subscriptions/prices');

      response.data.data.forEach((item: PriceObj) => {
        if (process.env.REACT_APP_NODE_ENV === 'prod') {
          if (noAvailablePriceIds?.indexOf(item._id as string) < 0) {
            prices.push(item);
            setPrices(prices);
          }
        } else if (noAvailablePriceIds?.indexOf(item._id as string) < 0) {
          prices.push(item);
          setPrices(prices);
        }
      });

      setGettedPrices(true);
      setIsGettingPrices(false);

      return response.data.data;
    } catch (error) {
      console.error(error);
      setGettedPrices(false);
      setIsGettingPrices(false);
      return [];
    }
  };
  const handleSelectPrice = (priceId: string) => {
    const selectedPrice = prices?.find((item: PriceObj) => item._id === priceId);
    setSelectedPrice(selectedPrice);
    localStorage.setItem('selected-price', JSON.stringify(selectedPrice));
  };

  // irá criar a subscrição no sistema (atribuindo as roles corretas para que o user tenha acesso aos beneficios do plano escolhido)
  // irá também atrelar o user a um price no stripe, que contem os valores e prazos de acordo com o selectedPrice
  const handleConfirmSubscription = async (priceId?: string | null, coupon?: string) => {
    setIsChangingPlan(true);

    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).post(
        `v2/subscriptions/prices/${priceId}/purchase/`,
        {
          coupon
        },
        withAuth(token)
      );

      setUserSubscriptions(response.data.data);
      setIsChangingPlan(false);
      setIsChangedPaln(true);
    } catch (error) {
      console.error('Error on make sub:', error.response);
      if (error.response) {
        if (error.response.status === 409) {
          setErrorOnConfirmSubscription('Você já está assinando esse plano!');
        } else if (error.response.data.message === 'Stripe subscription status is incomplete. Something went wrong!') {
          setErrorOnConfirmSubscription('Verifique o saldo do seu cartão e tente novamente.');
        } else {
          setErrorOnConfirmSubscription(error.response.data.message);
        }
      }

      setIsChangedPaln(false);
      setIsChangingPlan(false);
    }
  };

  const handleCancelSubscription = async (subscriptionId?: string) => {
    setIsCancelingSubscription(true);
    setCanceledSubscription(0);
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).delete(`v2/subscriptions/${subscriptionId}`, withAuth(token));
      // essa resposta é diferente, caso seja 204 quer dizer uma resposta bem sucedida a solicitação de cancelamento
      setIsCancelingSubscription(false);
      setCanceledSubscription(1);
    } catch (error) {
      setIsCancelingSubscription(false);
      setCanceledSubscription(-1);
      console.error(error.response);
    }
  };

  const getPriceById = async (priceId: string) => {
    setIsGettingUserPrice(true);
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`v2/subscriptions/prices/${priceId}`, withAuth(token));
      setUserPrice(response.data.data);
      setIsGettingUserPrice(false);
      setGettedUserPrice(true);
    } catch (error) {
      setIsGettingUserPrice(false);
      setGettedUserPrice(false);
      console.error(error.response);
    }
  };

  const getUserInvoices = async (defaultToken?: string) => {
    setIsGettingUserInvoices(true);
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`v2/subscriptions/invoices/`, withAuth(defaultToken || token));

      setUserInvoices(response.data.data.data);
      setIsGettingUserInvoices(false);
      setGettedUserInvoices(true);
    } catch (error) {
      setIsGettingUserInvoices(false);
      setGettedUserInvoices(false);
      console.error(error.response);
    }
  };

  const getUserUpcomingInvoice = async (defaultToken?: string) => {
    setIsGettingUserUpcomingInvoice(true);
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get('v2/subscriptions/invoices/upcoming', withAuth(defaultToken || token));
      if (!response.data.length) {
        const userSubscriptions: userSubscriptions[] = await getUserSubscriptions(defaultToken || token);
        if (userSubscriptions.length) {
          const lastSubscriptionIndex = userSubscriptions.length - 1;

          setUserUpcomingInvoice({
            period_end: userSubscriptions[lastSubscriptionIndex]?.stripe_subscription?.current_period_end as number,
            period_start: userSubscriptions[lastSubscriptionIndex].stripe_subscription?.current_period_start as number,
            total: userSubscriptions[lastSubscriptionIndex].stripe_subscription?.items.data[lastSubscriptionIndex].price.unit_amount as number
          });
        }
      }
      setUserUpcomingInvoice(response.data.data);

      setIsGettingUserUpcomingInvoice(false);
      setGettedUserUpcomingInvoice(true);
    } catch (error) {
      setIsGettingUserUpcomingInvoice(false);
      setGettedUserUpcomingInvoice(false);
      console.error(error);
    }
  };

  // Quando o usuário cancela o cancelamento da subscription
  const handleReactivateSubscription = async (subscriptionId: string, defaultToken?: string) => {
    setIsReactivatingSubscription(true);
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).post(
        `v2/subscriptions/${subscriptionId}/revive`,
        {},
        withAuth(defaultToken || token)
      );
      setIsReactivatingSubscription(false);
      setReactivatedSubscription(true);
    } catch (error) {
      setIsReactivatingSubscription(false);
      setReactivatedSubscription(false);
      console.error(error.response);
    }
  };

  const getActualPlan = async (defaultToken?: string) => {
    if (!userSubscriptions?.length || !prices?.length) {
      setIsGettingPlan(true);
      const prices: PriceObj[] = [];//await getPrices();
      const userSubscriptions = await getUserSubscriptions(defaultToken || token);
      const activePrice = getActiveProduct(userSubscriptions, prices);
      setActivePlan(activePrice);
      setIsGettingPlan(false);
    }

    return null;
  };

  return (
    <CheckoutContext.Provider
      value={{
        customer,
        isChangingPlan,
        isChangedPlan,
        isChangingPaymentMethod,
        userSubscriptions,
        coupon,
        isValidCoupon,
        isAtachingPaymentMethod,
        atachedPaymentMethod,
        discountedPrice,
        selectedPrice,
        userActivePlan,
        isGettingPlan,
        userPaymentMethod,
        prices,
        isGettingPrices,
        gettedPrices,
        isGettingUserSubscriptions,
        gettedUserSubscriptions,
        isValidatingCoupon,
        userPrice,
        isGettingUserPrice,
        gettedUserPrice,
        isGettingUserPayemntMethod,
        gettedUserPayemntMethod,
        isCancelingSubscription,
        canceledSubscription,
        userInvoices,
        isGettingUserInvoices,
        gettedUserInvoices,
        errorOnConfirmSubscription,
        userUpcomingInvoice,
        isGettingUserUpcomingInvoice,
        gettedUserUpcomingInvoice,
        isReactivatingSubscription,
        reactivatedSubscription,
        atachPaymentError,
        setAtachPaymentError,
        setErrorOnConfirmSubscription,
        setAtachedPaymentMethod,
        setCanceledSubscription,
        handleReactivateSubscription,
        setIsAtachingPaymentMethod,
        getUserUpcomingInvoice,
        getUserInvoices,
        getActualPlan,
        handleCancelSubscription,
        getUserPaymentMethod,
        getPriceById,
        handleConfirmSubscription,
        setSelectedPrice,
        getPrices,
        handleSelectPrice,
        setDiscountedPrice,
        atachPaymentMethod,
        setIsValidCoupon,
        validateCoupon,
        getUserSubscriptions,
        setIsChangingPlan,
        setIsChangedPaln,
        setIsChangingPaymentMethod
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
