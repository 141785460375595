import { Axios, withAuth } from '../../../../utils/axios';

interface Artist {
  _id: string;
  name: string;
  rank: number;
  picture_profile: {
    _id: string;
    url: string;
    type: string;
    datetime_created: string;
    datetime_updated: string;
    processed: boolean;
    resources: string[];
  };
}

interface Tag {
  _id: string | null;
  label: string;
}

export const searchTags = async (label: string, defaultToken?: string) => {
  const token = localStorage.getItem('LOCAL_TOKEN');
  try {
    const res = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v2/auditions/tags/search?label=${label}`, withAuth(token || ''));

    return res.data as Tag[];
  } catch (error) {
    console.error('Error on creating tags: ', error.response);
  }
};

const createTags = async (tagsArray: Tag[] | undefined, defaultToken?: string) => {
  const labels: string[] = [];
  tagsArray?.map((tag: Tag) => {
    labels.push(tag.label);
  });

  try {
    const res = await Axios(process.env.REACT_APP_NODE_ENV).post(`/v2/auditions/tags`, { labels }, withAuth(defaultToken));
    return res.data.data as Tag[];
  } catch (error) {
    console.error('Error on creating tags: ', error.response);
  }
};

export const createAudition = async (
  idProject: string,
  title: string,
  briefing: string,
  related_artists: Artist[],
  target_artist: string,
  video_url?: string,
  tags?: Tag[],
  defaultToken?: string
) => {
  try {
    const createTagsResponse: Tag[] | undefined = await createTags(tags, defaultToken);
    const id_audition_tags = createTagsResponse?.map((tag: Tag) => tag._id);
    const idRelatedArtists = related_artists.map((item) => item._id);

    const payload = video_url
      ? { title, briefing, target_artist, video_url, related_artists: idRelatedArtists || [''], id_audition_tags }
      : { title, briefing, target_artist, related_artists: idRelatedArtists || [''], id_audition_tags };

    const { data }: any = await Axios(process.env.REACT_APP_NODE_ENV).post(
      `/v1/producer/project/${idProject}/auditions`,
      payload,
      withAuth(defaultToken)
    );

    return data;
  } catch (error) {
    console.error('Error on creating new audition: ', error.response);
    if (error.response.data.error.code === 'audition/body-validation-error') {
      if (error.response.data.error.messages[0].field === 'title') {
        return {
          error: 'Por favor, preencha o campo "Título da audição" antes de salvar'
        };
      }
      if (error.response.data.error.messages[0].field === 'briefing') {
        return {
          error: 'Por favor, preencha o campo "Briefing da Audição" antes de salvar'
        };
      }
      if (error.response.data.error.messages[0].field === 'target_artists') {
        return {
          error: 'Por favor, preencha o campo "Artista principal" antes de salvar'
        };
      }
      if (error.response.data.error.messages[0].field === 'target_artists[0]') {
        return {
          error: 'Por favor, preencha o campo "Artista principal" antes de salvar'
        };
      }
    }
  }
};
