/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { RightColumn } from './styles';
import 'moment/locale/pt-br';
import DashboardContainer from '../../DashboardContainer';
import DashCard from '../../../components/DashCard/DashCard';
import SliderCards from '../../../components/SliderCards/SliderCards';
import InfoCard from '../../../components/InfoCard/InfoCard';
import DashCardList from '../../../components/DashCardList/DashCardList';
import { CompositionPlay, PriceObj } from '../../../interfaces';
import PublishedPostsSearch from '../../../components/PublishedPostsSearch/PublishedPostsSearch';
import MPClock from '../../../images/mpf-clock.svg';
import { ArtistDashContext, AuthContext, ComposerDashContext, WhoMatchesContext } from '../../../context';
import { DashContext } from '../../../context/dash/Context';
import { formatTimestamp } from '../../../utils/firebase';

type Props = {
  activePlan?: PriceObj;
};

const Compositions: React.FC<Props> = ({ activePlan }) => {
  const [inputValue, setInputValue] = useState('');
  const [composersName, setComposerNames] = useState<string[] | undefined>(undefined);

  // const { width } = useWindowDimensions();

  const { user, token, checkUserIsAuth, refreshMe, getCurrentUserId } = useContext(AuthContext);

  const { setActiveArtistTrack } = useContext(ArtistDashContext);
  const { setActivePollsTrack } = useContext(DashContext);
  const { setActiveWhoMatchesTrack } = useContext(WhoMatchesContext);

  // useEffect(() => {
  //   setActiveArtistTrack(undefined);
  //   setActivePollsTrack(undefined);
  //   setActiveWhoMatchesTrack(undefined);
  // }, []);

  const {
    compositions,
    // totalTopShares,
    // totalTopArtists,
    totalPlaysByDay,
    totalIndicationsByDay,
    // isGettingPosts,
    isGettingPlays,
    isGettingIndications,
    isGettingTopArtists,
    isGettingCompositions,
    isGettingTotals,
    activeComposition,
    playsByDay,
    indicationsByDay,
    topArtists,
    // topShares,
    // indicationLocations,
    // indicationTimes,
    composersNameList,
    showTotalsPostsInfos,
    // showTotalCompositionInfos,
    // auditionPosts,
    // handlePostSelect,
    // setTotalPlaysByDay,
    handleCompositionData,
    fetchCompositionsByUserProfileId
  } = useContext(ComposerDashContext);

  const [showResults, setShowResults] = useState<boolean>(false);

  useEffect(() => {
    setShowResults(false);
    (async () => {
      if (getCurrentUserId()) {
        if (compositions.length === 0) {
          fetchCompositionsByUserProfileId();
        }
      }

      if (activeComposition) {
        setComposerNames(composersNameList?.map((item) => item?.name));
      }
      setShowResults(true);
    })();
  }, [activeComposition]);

  // const mostIndicated = topArtists?.length && topArtists[0];
  // const totalIndications = compositions?.reduce((acc: any, item: any) => acc + item.indications, 0);

  // const ordenatedCompositions = compositions?.sort((a: any, b: any) => {
  //   const x = a.createdAt.toLowerCase();
  //   const y = b.createdAt.toLowerCase();
  //   if (x > y) {
  //     return -1;
  //   }
  //   if (x < y) {
  //     return 1;
  //   }
  //   return 0;
  // });

  useEffect(() => {
    (async () => {
      if (checkUserIsAuth()) {
        refreshMe();
      }
    })();
  }, []);

  return (
    <DashboardContainer activePlan={activePlan}>
      <div className="w-full flex flex-col" id="page-top-div">
        <div className="w-full hidden sm:block">
          <span className="font-semibold text-2xl">
            {/* <FormattedMessage id="dashboard.composer.page-main-title" defaultMessage="Composições" /> */}
            <FormattedMessage id="dashboard.composer.page-main-title" defaultMessage="Guias" />
          </span>
          {compositions.length > 0 ? (
            <span className="text-gray-700 flex items-center">
              <img src={MPClock} alt="icon-clock" />
              <span className="ml-1">
                <FormattedMessage
                  id="dashboard.composer.last-published-post"
                  values={{
                    date: moment(formatTimestamp(compositions[0].createdAt)).format('DD/MM/YYYY'),
                    hourDate: moment(formatTimestamp(compositions[0].createdAt)).format('LT')
                  }}
                  defaultMessage="Última guia publicada em: ???"
                />
              </span>
            </span>
          ) : null}
        </div>
        <div className="flex flex-col lg:flex-row mt-0 sm:mt-8">
          <div className="flex h-full justify-center ">
            <PublishedPostsSearch
              onPostSelect={(e: any) => {
                handleCompositionData(e);
              }}
              changeInput={setInputValue}
              inputValue={inputValue}
              compositionData={compositions}
              isLoading={!compositions?.length && (isGettingTotals || isGettingCompositions) && !activeComposition}
              isComposer
              className="rounded-none sm:rounded-lg"
              style={{ width: '100%' }}
            />
          </div>
          <hr className="block sm:hidden" />
          <RightColumn className="flex flex-col pt-0 sm:pt-8 md:pt-8 md:pl-0 lg:pt-0 lg:pl-8 w-full calc-width">
            {isGettingTotals || isGettingCompositions ? (
              showTotalsPostsInfos ? (
                <div className="pb-8 hidden sm:block">
                  <InfoCard isLoading={isGettingTotals || isGettingCompositions} />
                </div>
              ) : (
                <div className="pb-8 hidden sm:block">
                  <InfoCard
                    guideName={activeComposition?.title || ''}
                    composersName={composersName}
                    isLoading={isGettingTotals || isGettingCompositions}
                  />
                </div>
              )
            ) : compositions?.length ? (
              <div className="pb-8 hidden sm:block">
                <InfoCard
                  guideName={activeComposition?.title || ''}
                  composersName={composersName}
                  isLoading={isGettingTotals || isGettingCompositions}
                />
              </div>
            ) : null}
            {/* {isGettingTotals || isGettingCompositions ? (
              showTotalsPostsInfos ? (
                <div className="pb-8 hidden sm:block">
                  <InfoCard isLoading={isGettingTotals || isGettingCompositions} />
                </div>
              ) : (
                <div className="pb-8 hidden sm:block">
                  <InfoCard
                    guideName={activeComposition?.name || ''}
                    composersName={composersName}
                    isLoading={isGettingTotals || isGettingCompositions}
                  />
                </div>
              )
            ) : compositions?.length ? (
              <div className="pb-8 hidden sm:block">
                <InfoCard
                  guideName={activeComposition?.name || ''}
                  composersName={composersName}
                  isLoading={isGettingTotals || isGettingCompositions}
                />
              </div>
            ) : null} */}

            <SliderCards>
              <DashCard
                title="Plays"
                width="w-full"
                isPlays
                isCompositions
                showResults={showResults}
                isLoading={isGettingTotals || isGettingPlays}
                totalValue={activeComposition ? activeComposition?.playsCount : totalPlaysByDay}
                playsByDay={playsByDay || ([] as CompositionPlay[])}
              />
              <DashCard
                title="Indicações"
                width="w-full"
                isIndications
                isCompositions
                showResults={showResults}
                isLoading={isGettingTotals || isGettingIndications}
                totalValue={activeComposition ? activeComposition?.indicationsCount : totalIndicationsByDay}
                indicationsByDay={indicationsByDay || []}
              />
              {/* <DashCard
                title="O mais indicado"
                width="w-full"
                isMostIndicated
                showResults={showResults}
                isLoading={isGettingTotals || isGettingPosts}
                mostIndicated={
                  showTotalsPostsInfos
                    ? totalIndications && totalIndications > 0
                      ? totalTopArtists[0]
                      : undefined
                    : mostIndicated
                    ? (mostIndicated as TopArtist)
                    : undefined
                }
              /> */}
              <DashCardList
                showResults={showResults}
                isTopIndications
                isLoading={isGettingTotals || isGettingTopArtists}
                topArtists={topArtists || []}
              />
            </SliderCards>
            {/* <div style={{ overflow: 'auto', whiteSpace: 'nowrap', width: '100%' }} className={`w-full mt-8 ${width < 768 && 'mb-8'}`}>
              <AuditionCard data={auditionPosts} isLoading={isGettingTotals || isGettingPosts} />
            </div>
            {!showTotalsPostsInfos ? (
              <>
                <IndicationTimeGraph
                  data={showTotalsPostsInfos ? undefined : activeComposition?.indications > 0 ? indicationTimes : undefined}
                  isLoading={isGettingTotals || isGettingPosts || isGettingPosts}
                />
                <hr className="block sm:hidden" />
              </>
            ) : (
              <div className="w-full" />
            )} */}

            {/* <Media>
              {({ breakpoints, currentBreakpoint }: MediaOptions) =>
                breakpoints[currentBreakpoint] > breakpoints.sm ? (
                  <>
                    {!showTotalsPostsInfos && <MapComponent data={showTotalsPostsInfos ? undefined : activeComposition ? indicationLocations : undefined} />}
                    <div className={`w-full grid ${width > 1390 && 'grid-cols-2'} gap-8 mt-8 grid-cols-1 md:col-gap-8`}>
                      <DashCardList
                        showResults={showResults}
                        isTopIndications
                        isLoading={isGettingTotals || isGettingPosts}
                        topArtists={
                          showTotalsPostsInfos
                            ? totalIndications && totalIndications > 0
                              ? totalTopArtists
                              : []
                            : topArtists?.length
                            ? topArtists
                            : undefined
                        }
                      />
                      <DashCardList
                        showResults={showResults}
                        isSocialMedia
                        isLoading={isGettingTotals || isGettingPosts}
                        topShares={showTotalsPostsInfos ? totalTopShares : topShares?.length ? topShares : undefined}
                      />
                    </div>
                  </>
                ) : (
                  <div className={`w-full grid ${width > 1390 && 'grid-cols-2'} gap-0 sm:gap-8 grid-cols-1 md:col-gap-8`}>
                    <DashCardList
                      showResults={showResults}
                      isTopIndications
                      isLoading={isGettingTotals || isGettingPosts}
                      topArtists={showTotalsPostsInfos ? totalTopArtists : topArtists?.length ? topArtists : undefined}
                    />
                    <hr className="block sm:hidden" />
                    <DashCardList
                      showResults={showResults}
                      isSocialMedia
                      isLoading={isGettingTotals || isGettingPosts}
                      topShares={showTotalsPostsInfos ? totalTopShares : topShares?.length ? topShares : undefined}
                    />
                  </div>
                )
              }
            </Media> */}
          </RightColumn>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Compositions;
