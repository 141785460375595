import styled from 'styled-components';

export const Container = styled.div`
 
  .max-w-72 {
    max-width: 18rem;
  }

  .max-w-76 {
    max-width: 22rem;
  }

  .h-14 {
    height: 3.75rem;
  }

  .border-b-1 {
    border-bottom: 1px solid #cfcfcf;
  }

  span.register--now {
    text-align: center;
    color: #202020;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
  }

  a.link--terms {
    color: #ef233c;
  }

  div.container--test--app {
    display: flex;
    flex-direction: column;
    margin-bottom: 10.15vh;
  }

  @media (min-width: 640px) {
    .terms {
      max-width: 350px;
    }
  }
`;
