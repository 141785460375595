/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AuthRouter from './auth/AuthRouter';
import CheckoutRouter from './checkout/CheckoutRouter';
import { ProtectedRoute } from './auth/ProtectedRoute';
import DashboardRouter from './dashboard/DashboardRouter';
import AccountManagementRouter from './accountManagement/AccountManagementRouter';
import RedirectToDash from './components/Redirect/Redirect';
import Logout from './auth/logout/Logout';
import { AuthContext, CheckoutContext } from './context';
import { News } from './news';
import { GraveOnline } from './graveOnline';

function Router() {
  const { getActualPlan, userActivePlan } = useContext(CheckoutContext);
  const { token } = useContext(AuthContext);

  const checkIfHasPlan = async (defaultToken?: string) => {
    if (!userActivePlan && getActualPlan) {
      if (userActivePlan) return;

      await getActualPlan(defaultToken || token);
    }
  };

  useEffect(() => {
    if (token) checkIfHasPlan(token);
  }, [token]);
  
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path="/" component={RedirectToDash} />

        <Route path="/auth">
          <AuthRouter />
        </Route>

        <Route path="/logout">
          <Logout />
        </Route>

        <Route path="/news">
          <News />
        </Route>

        <Route path="/grave-online">
          <GraveOnline />
        </Route>

        <ProtectedRoute path="/dash" component={DashboardRouter} />

        <ProtectedRoute path="/checkout" component={CheckoutRouter} />

        <ProtectedRoute path="/account-management" component={AccountManagementRouter} />
      </Switch>
    </BrowserRouter>
  );
}

export default React.memo(Router);
