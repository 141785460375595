import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import { Container } from './styles';

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
};

const EngagementModal: React.FC<Props> = ({ isOpen, toggleModal, children }) => (
  <>
    {isOpen && (
      <Container>
        <div className="content-container text-center">
          <button type="button" onClick={toggleModal} className="close">
            <span className="close-text">
              <FormattedMessage id="dashboard.my-projects.modal.close-text" defaultMessage="FECHAR" />
            </span>
            <AiOutlineCloseCircle color="#CFCFCF" size={18} />
          </button>
          <span className="text-3xl leading-8 font-semibold mb-4">Engaje sua audiência a ouvir e indicar ainda mais suas músias dentro do App</span>
          <span className="text-base leading-6 mb-12">
            Quanto mais plays e indicações, maior o engajamento do público e maior será a relevância delas dentro das Audições Digitais
          </span>
          <div style={{ maxWidth: '440px' }} className="w-full flex items-center text-center justify-center">
            {children}
          </div>
        </div>
      </Container>
    )}
  </>
);

export default EngagementModal;
