/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useRouteMatch, Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import SecondCheckoutContainer from '../secondCheckoutContainer/SecondCheckoutContainer';
import { Container } from './styles';
import Button from '../../components/Button/Button';
import { CheckoutConsumer, CheckoutContext } from '../../context/checkout';
import useUserData from '../../utils/customHooks/useUserData';
import { Match } from '../../interfaces';
import { getActiveProduct, getCurrentSubscription } from '../../utils/checkoutHelpers';
import Spinner from '../../components/Spinner/Spinner';
import { AuthContext } from '../../context';

const ChangedPlan: React.FC = () => {
  const user = useUserData();
  const {
    prices,
    userSubscriptions,
    userInvoices,
    setCanceledSubscription,
    getPrices,
    getUserSubscriptions,
    getUserInvoices
  } = useContext(CheckoutContext);

  const { token } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (!prices) await getPrices();
      if (token) {
        await getUserSubscriptions(token);
        await getUserInvoices(token);
      }
      setCanceledSubscription(0);
    })();
  }, [token]);

  const { params }: Match = useRouteMatch();
  const { priceId } = params;

  const activePrice = getActiveProduct(userSubscriptions, prices);

  const nameFormated = user?.name;

  const priceName = activePrice?.name;

  const currentSubscription = getCurrentSubscription(userSubscriptions);

  const cancelAt = moment((currentSubscription?.stripe_subscription?.cancel_at as number) * 1000).format('LL');

  const beginnerProduct = prices?.find((item) =>
    process.env.REACT_APP_NODE_ENV === 'prod'
      ? item.id_product === '5f6c23949d3957ac67ae9633'
      : item.id_product === '5f3db81dbd9e96ba6c4cabd4'
  );

  return (
    <CheckoutConsumer>
      {({ isGettingUserInvoices, isGettingPrices, isGettingUserSubscriptions }) => (
        <SecondCheckoutContainer hasGoBack goBackRoute="/checkout/change-plan" title="changed-plan">
          <Container className="max-w-md xl:max-w-none w-full pb-4 flex flex-col h-full">
            {isGettingPrices || isGettingUserSubscriptions || isGettingUserInvoices ? (
              <div className="w-full mt-8 flex justify-center items-center">
                <Spinner isLoading={isGettingPrices || isGettingUserSubscriptions || isGettingUserInvoices} />
              </div>
            ) : prices?.length &&
              userSubscriptions?.length &&
              userInvoices?.length &&
              currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
              <>
                <div style={{ maxWidth: '600px' }} className="w-full flex flex-col mb-8">
                  <div className="w-auto text-white mb-4">
                    {/* <span
                        style={{
                          padding: '5px 10px',
                          background: '#1DB954',
                          borderRadius: '4px'
                        }}
                        className="w-auto text-xs"
                      >
                        <FormattedMessage id="checkout.changed-plan.active" />
                      </span> */}
                  </div>
                  <span className="font-normal text-xl lg:leading-10 lg:text-3xl " style={{ color: '#202020' }}>
                    <FormattedMessage
                      id="checkout.changed-plan.plan-name"
                      values={{
                        priceName: beginnerProduct?.name
                      }}
                    />
                  </span>
                  <div className="w-full flex flex-col justify-between mb-8 mt-2">
                    <span style={{ color: '#202020' }} className="text-xl font-semibold leading-7">
                      <FormattedMessage
                        id="checkout.changed-plan.plan-price"
                        values={{
                          planPrice: beginnerProduct?.price
                        }}
                      />
                    </span>
                    <p style={{ color: '#817B7B' }} className="text-sm leading-5 w-full lg:text-base lg:leading-6">
                      <FormattedMessage
                        id="checkout.changed-plan.description-invoicing"
                        values={{
                          cancelAt,
                          currentPlanName: <b>{priceName}</b>
                        }}
                      />
                    </p>
                  </div>
                  <div className="w-full max-w-md lg:max-w-none flex items-center justify-between mb-5">
                    <Link to={`/checkout/reactivate-subscription/${currentSubscription._id}`} className="w-full mr-5">
                      <Button
                        style={{
                          margin: '0 20px 0 0',
                          borderRadius: '4px',
                          padding: '17px 10px',
                          maxWidth: '285px'
                        }}
                        className="w-full flex items-center font-semibold justify-center text-xs lg:text-base py-4"
                        theme="secondary"
                      >
                        <FormattedMessage id="checkout.changed-plan.reactivate-subscription" />
                      </Button>
                    </Link>
                    <Link to="/checkout" className="w-full">
                      <Button
                        style={{
                          margin: '0',
                          borderRadius: '4px',
                          padding: '17px 10px',
                          border: '1px solid #202020',
                          maxWidth: '285px'
                        }}
                        className="w-full text-xs lg:text-base py-4 font-semibold"
                        theme="default"
                      >
                        <FormattedMessage id="checkout.changed-plan.see-others-plans" />
                      </Button>
                    </Link>
                  </div>
                  <p style={{ color: '#817B7B' }} className="w-full font-normal text-xs leading-5">
                    <FormattedMessage id="checkout.changed-plan.terms-initial" />
                    <a href="https://static.musicplayce.com/assets/terms_of_use.pdf" className="text-blue-600">
                      <FormattedMessage id="checkout.changed-plan.terms.initial-2" />
                    </a>
                    <FormattedMessage id="checkout.changed-plan.terms.initial-3" />
                    <a className="text-blue-600" href="https://musicplayce.com" target="blank">
                      <FormattedMessage id="checkout.changed-plan.terms.initial-4" />
                    </a>
                    <FormattedMessage id="checkout.changed-plan.terms.initial-5" />
                  </p>
                </div>

                <div style={{ background: '#cfcfcf' }} className="mb-14 w-full h-px" />

                <div style={{ maxWidth: '600px' }} className="w-full flex flex-col items-start">
                  <span className="font-normal text-xl lg:leading-10 lg:text-2xl mb-2" style={{ color: '#202020' }}>
                    <FormattedMessage
                      id="checkout.changed-plan.user-name"
                      values={{
                        userName: nameFormated
                      }}
                    />
                    ,
                  </span>
                  <p style={{ color: '#817B7B' }} className="font-normal text-xl leading-7 mb-2">
                    <FormattedMessage id="checkout.changed-plan.user-interation" />
                  </p>
                  <p style={{ color: '#817B7B' }} className="text-sm leading-5 w-full lg:text-base lg:leading-7">
                    <FormattedMessage id="checkout.changed-plan.user-interation-2" />
                  </p>
                  <a
                    className="typeform-share button text-sm leading-5 w-full lg:text-base lg:leading-7"
                    href="https://form.typeform.com/to/sopDn8lf"
                    data-mode="drawer_right"
                    target="blank"
                  >
                    <FormattedMessage
                      id="checkout.changed-plan.submit-feedback"
                      defaultMessage="checkout.changed-plan.submit-feedback"
                    />
                  </a>
                </div>
              </>
            ) : (
              <Redirect to={`/checkout/change-plan/${priceId}`} />
            )}
          </Container>
        </SecondCheckoutContainer>
      )}
    </CheckoutConsumer>
  );
};

export default ChangedPlan;
