import { Axios } from '../../../../utils/axios';

interface successApiResponse {
  status: number;
  data: any;
}

interface errorApiResponse {
  response: {
    status: number;
    data: {
      error?: {
        code: string;
        message: string;
      };
    };
  };
}

export default async function getProject(projectId: string) {
  try {
    const token = localStorage.getItem('LOCAL_TOKEN');
    const res: successApiResponse = await Axios(process.env.REACT_APP_NODE_ENV).get(
      '/v1/producer/project/' + projectId + '?resolve=["tags","target_artists","related_artists"]',
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return res;
  } catch (error) {
    console.error('Error on creating new project: ', error);
  }
}
