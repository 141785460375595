import React, { useEffect, useState } from 'react';
import { TagsContainer, TagItem } from './styles';

type Props = {
  clickable?: boolean;
  data: string[];
} & React.HTMLAttributes<HTMLDivElement>;

const Tags: React.FC<Props> = ({ clickable, data }) => {
  const [tags, setTags] = useState<string[]>([]);
  const [tagsSelected, setTagsSelected] = useState<string[]>([]);

  function checkTag(tagName: string) {
    if (!clickable) {
      return false;
    }

    const arr = [...tagsSelected];

    if (arr.filter((item) => item === tagName).length > 0) {
      arr.splice(arr.indexOf(tagName), 1);
    } else {
      arr.push(tagName);
    }

    setTagsSelected(arr);
  }

  useEffect(() => {
    setTags(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <TagsContainer>
      {!!tags &&
        tags.map((tag, index) => {
          return (
            <TagItem key={index} className={`${tagsSelected.indexOf(tag) > -1 ? 'selected' : 'not-selected'}`} onClick={() => checkTag(tag)}>
              {tag}
            </TagItem>
          );
        })}
    </TagsContainer>
  );
};

export default Tags;
