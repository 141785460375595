import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { NavLink, useHistory } from 'react-router-dom';
import { PriceObj } from '../../interfaces';
import useUserData from '../../utils/customHooks/useUserData';

import MPlogo from '../../images/logo-musicplayce-white.svg';
import MPlogoMini from '../../images/mpf-logo-mini.svg';
import MPInsta from '../../images/mpf-instagram.svg';
import MPFacebook from '../../images/mpf-facebook.svg';
import MPWhatsapp from '../../images/mpf-whatsapp.svg';
// import dollar from '../../images/mpf-dollar.svg';getUserProfileByUserId
import Spinner from '../Spinner/Spinner';
import defaultUser from '../../images/default_avatar.png';

import useOutsideIdentifier from '../../utils/customHooks/useOutsideIdentifier';

import { DropdownContainer } from './styles';

type Props = {
  activePrice?: PriceObj;
  isGettingInfos?: boolean;
  dashRoute?: string;
  isCheckout?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Header: React.FC<Props> = ({ activePrice, isGettingInfos, dashRoute, isCheckout }) => {
  const history = useHistory();
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const [showDropdown, setShowDropDown] = useState(false);
  const user = useUserData();

  const setFalse = () => {
    setShowDropDown(false);
  };

  useOutsideIdentifier(dropdownRef, setFalse);

  const userAvatar = user?.avatarUrl;

  return (
    <div className="flex w-full justify-between items-center px-10 relative" style={{ backgroundColor: '#202020', height: 88 }}>
      {isCheckout ? (
        <>
          <button className={`block lg:hidden ${!isCheckout && 'ml-8'}`} type="button" onClick={() => history.push(dashRoute || '')}>
            <img src={MPlogoMini} alt="logo-musicplayce" />
          </button>
          <button className={`header-logo ${!isCheckout && 'ml-8'} hidden lg:block`} type="button" onClick={() => history.push(dashRoute || '')}>
            <img src={MPlogo} alt="logo-musicplayce" />
          </button>
        </>
      ) : (
        <>
          <img
            src={MPlogoMini}
            onClick={() => history.push(dashRoute || '')}
            alt="logo-musicplayce"
            className="block lg:hidden ml-8 cursor-pointer"
          />
          <img
            src={MPlogo}
            onClick={() => history.push(dashRoute || '')}
            alt="logo-musicplayce"
            className="header-logo ml-8 hidden lg:block cursor-pointer"
          />
        </>
      )}
      <div className="flex justify-between items-center select-none">
        <div className="hidden md:flex items-center">
          <span className="text-white mr-4">
            <FormattedMessage id="dashboard.header-dashboard.follow-networks" defaultMessage="Siga nas redes" />
          </span>
          <a href="https://www.instagram.com/musicplayce/" target="_blank" rel="noopener noreferrer">
            <img src={MPInsta} alt="logo-musicplayce-insta" />
          </a>
          <a href="https://www.facebook.com/musicplayce/" className="mx-4" target="_blank" rel="noopener noreferrer">
            <img src={MPFacebook} alt="logo-musicplayce-facebook" />
          </a>
          <a href="https://api.whatsapp.com/send?1=pt_BR&phone=556240186600" target="_blank" rel="noopener noreferrer">
            <img src={MPWhatsapp} alt="logo-musicplayce-whatsapp" />
          </a>

          <div className="h-6 mx-4" style={{ width: 1, backgroundColor: '#8F8F8F' }} />
        </div>
        <button
          type="button"
          ref={dropdownRef}
          className="flex items-center justify-between"
          onClick={() => setShowDropDown((prevState) => !prevState)}
        >
          {isGettingInfos ? (
            <div className="w-full flex items-center justify-around p-8">
              <Spinner isLoading={isGettingInfos} />
            </div>
          ) : (
            <div className="w-full py-0 sm:py-6 flex items-center">
              <img
                style={{ width: '3rem', height: '3rem', marginRight: '0.625rem' }}
                src={userAvatar || defaultUser}
                className="rounded-full"
                alt="user-avatar"
              />
              {showDropdown ? (
                <IoIosArrowUp size={20} className="ml-2 flex sm:hidden" color="#fff" />
              ) : (
                <IoIosArrowDown size={20} className="ml-2 flex sm:hidden" color="#fff" />
              )}
              <div className="w-full flex-col hidden sm:flex">
                <span style={{ color: '#fff' }} className="text-left hidden sm:flex items-center text-base font-normal leading-6">
                  <FormattedMessage id="sidebar.username" values={{ userName: user?.name }} defaultMessage="sidebar.username" />
                  {showDropdown ? <IoIosArrowUp size={18} className="ml-2" /> : <IoIosArrowDown size={18} className="ml-2" />}
                </span>
                {/* @TODO uncomment <span style={{ color: '#817B7B' }} className="text-left hidden sm:flex text-xs leading-5">
                  <img src={dollar} className="mr-2" alt="money-icon" />
                  <FormattedMessage
                    id="sidebar.pricename"
                    values={{ priceName: activePrice?.name.split(' - ')[0] }}
                    defaultMessage="sidebar.pricename"
                  />
                </span> */}
              </div>
              {showDropdown && (
                <DropdownContainer isCheckout={isCheckout} className="shadow-lg">
                  <div className="w-full flex-col flex sm:hidden px-4">
                    <span style={{ color: '#222' }} className="text-left flex sm:hidden items-center text-base font-normal leading-6">
                      <FormattedMessage id="sidebar.username" values={{ userName: user?.name }} defaultMessage="sidebar.username" />
                    </span>
                    {/* @TODO uncomment <span style={{ color: '#817B7B' }} className="text-left flex sm:hidden text-xs leading-5">
                      <img src={dollar} className="mr-2" alt="money-icon" />
                      <FormattedMessage id="sidebar.pricename" values={{ priceName: activePrice?.name }} defaultMessage="sidebar.pricename" />
                    </span> */}
                  </div>
                  <div className="w-full bg-gray-500 flex sm:hidden my-2" style={{ height: 1 }} />
                  {isCheckout ? (
                    <>
                      <NavLink to="/checkout" isActive={(_, { pathname }) => pathname.includes('/checkout')}>
                        Planos
                      </NavLink>
                      <NavLink to="/account-management">Gestão de Conta</NavLink>
                      <NavLink to="/account-management/signature">Minha Assinatura</NavLink>
                      <NavLink to="/account-management/receipts">Recibos</NavLink>
                      <NavLink to="/logout">Sair</NavLink>
                    </>
                  ) : (
                    <>
                      <NavLink to="/logout" className="leave">
                        Sair
                      </NavLink>
                    </>
                  )}
                </DropdownContainer>
              )}
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default React.memo(Header);
