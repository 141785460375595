import styled from 'styled-components';

type Props = {
  hasPlayed?: boolean;
};

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  background-color: ${(props: Props) => (!props.hasPlayed ? '#f3f7ff' : '#FFF')};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e4e5e6;
  margin-bottom: 5px;
  padding: 14px;

  div.player-box {
    max-width: 96px;
    flex: 1;
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;

    .stars {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

  .icon {
    width: 13px;
  }

  div.text-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 20px;
    white-space: normal;
    padding: 5px 0 0 0;

    div.plays-indications-container {
      display: flex;
      width: 70%;
      justify-content: space-between;
    }

    div.title {
      display: flex;
      flex-direction: row;
      img {
        margin: 10px;
      }
    }

    span.plays-indications {
      /* font-size: 0.8rem;
      color: #8f8f8f; */
      /* margin: 5px 0px; */
    }

    /* div.updated-at {
      font-size: 0.6rem;
      color: #8f8f8f;
    } */
  }

  .card-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    position: relative;

    .add-tags {
      width: 100px;
      font-size: 0.6rem;
      font-weight: bold;
      /* padding: 20px 0 0; */
    }

    .tag-items-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .tag-item {
        border: 1px solid #7b7b7b;
        border-radius: 3px;
        padding: 2px 5px;
        text-align: center;
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 10px;
        width: fit-content;
        display: flex;

        img {
          margin-left: 5px;
        }
      }
    }
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .reload-icon {
    animation: spinner infinite 1s linear;
  }
`;
