import { Axios, withAuth } from '../../../../utils/axios';

export const handleAddPostToAudition = async (postId: string, idAudition: string, defaultToken?: string) => {
  try {
    const response = await Axios(process.env.REACT_APP_NODE_ENV).put(
      `/v2/auditions/link-post/${idAudition}`,
      { id_posts: [`${postId}`] },
      withAuth(defaultToken)
    );

    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    console.log('Error on adding post do audition: ', error);
    return {
      success: false,
      error: error
    };
  }
};

export const getAuditionsPosts = async (idAudition: string, defaultToken?: string) => {
  try {
    const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v2/auditions/${idAudition}/my-posts/`, withAuth(defaultToken));

    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    console.log('Error on getting auditions posts: ', error);
    return {
      success: false,
      error: error
    };
  }
};

export const getAuditionDetails = async (idAudition: string, defaultToken?: string) => {
  try {
    const response = await Axios(process.env.REACT_APP_NODE_ENV).get(
      `/v2/auditions/${idAudition}?resolve=["target_artist","related_artists","id_audition_tags"]`,
      withAuth(defaultToken)
    );

    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    console.log('Error on getting audition details: ', error);
    return {
      success: false,
      error: error
    };
  }
};
