/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-shadow */
import React, { useEffect, useContext } from 'react';
import SecondCheckoutContainer from '../secondCheckoutContainer/SecondCheckoutContainer';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Container } from './styles';
import { FormattedMessage } from 'react-intl';
import Button from '../../components/Button/Button';
import visaCard from '../../images/visa-card.svg';
import masterCard from '../../images/mc-card.svg';
import unknownCard from '../../images/unknown-card.svg';
import editIcon from '../../images/mpf-edit.svg';
import { CheckoutConsumer, CheckoutContext } from '../../context/checkout';
import { Link, useRouteMatch } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import { Match } from '../../interfaces';
import reaisConverter from '../../utils/simpleReaisConverter/reaisConverter';
import useUserData from '../../utils/customHooks/useUserData';
import { getCurrentInvoice, getCurrentSubscription, getActiveProduct, canTrial } from '../../utils/checkoutHelpers';
import { AuthContext } from '../../context';

const Invoicing: React.FC = () => {
  const user = useUserData();
  const {
    prices,
    userSubscriptions,
    userInvoices,
    customer,
    getPrices,
    getUserUpcomingInvoice,
    getUserSubscriptions,
    getUserPaymentMethod,
    getUserInvoices
  } = useContext(CheckoutContext);

  const { token } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (token) {
        await getUserSubscriptions(token);
        await getUserPaymentMethod(token);
        await getUserInvoices(token);
        await getUserUpcomingInvoice(token);
      }
      if (!prices) await getPrices();
    })();
  }, [token]);

  const { params }: Match = useRouteMatch();
  const { priceId } = params;

  const activePrice = getActiveProduct(userSubscriptions, prices);

  const nameFormated = user?.name;

  const priceName = activePrice?.name;

  const currentSubscription = userSubscriptions && getCurrentSubscription(userSubscriptions);

  const currentInvoice = userInvoices && getCurrentInvoice(userInvoices);

  const trialEndDate = moment((currentSubscription?.stripe_subscription?.trial_end as number) * 1000).format('LL');

  const userCanTrial = canTrial(customer, activePrice);

  return (
    <CheckoutConsumer>
      {({
        isGettingUserSubscriptions,
        isGettingUserPayemntMethod,
        userPaymentMethod,
        userInvoices,
        isGettingPrices,
        userUpcomingInvoice,
        isGettingUserUpcomingInvoice,
        isGettingUserInvoices
      }) => (
        <SecondCheckoutContainer hasGoBack goBackRoute="/account-management" title="invoicing">
          <Container className="max-w-md xl:max-w-none w-full pb-4 flex flex-col h-full">
            {isGettingUserSubscriptions ||
            isGettingUserPayemntMethod ||
            isGettingUserInvoices ||
            isGettingUserUpcomingInvoice ||
            isGettingPrices ? (
              <div className="w-full mt-8 flex justify-center items-center">
                <Spinner
                  isLoading={
                    isGettingUserSubscriptions ||
                    isGettingUserPayemntMethod ||
                    isGettingUserInvoices ||
                    isGettingUserUpcomingInvoice ||
                    isGettingPrices
                  }
                />
              </div>
            ) : userSubscriptions?.length &&
              userPaymentMethod &&
              userInvoices?.length &&
              userUpcomingInvoice &&
              prices?.length ? (
              <>
                <div style={{ maxWidth: '600px' }} className="w-full flex flex-col">
                  <span className="font-normal text-xl lg:leading-10 lg:text-3xl " style={{ color: '#202020' }}>
                    <FormattedMessage
                      id="checkout.invoicing.plan-type"
                      values={{
                        productName: priceName
                      }}
                    />
                  </span>
                  <div className="w-full flex flex-col justify-between mb-8 mt-2">
                    <span style={{ color: '#202020' }} className="text-xl font-semibold leading-7">
                      {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                        <FormattedMessage
                          id="checkout.invoicing.plan-price"
                          values={{
                            productPrice: reaisConverter((currentInvoice?.total as number) / 100)
                          }}
                        />
                      ) : (
                        // <FormattedMessage
                        //   id="checkout.invoicing.plan-price"
                        //   values={{
                        //     productPrice: reaisConverter(((userCanTrial ? currentInvoice?.total : userUpcomingInvoice?.total) as number) / 100)
                        //   }}
                        // />
                        <FormattedMessage
                          id="checkout.invoicing.plan-price"
                          values={{
                            productPrice: reaisConverter((currentInvoice?.total as number) / 100)
                          }}
                        />
                      )}
                    </span>
                    <p style={{ color: '#817B7B' }} className="text-sm leading-5 w-full lg:text-base lg:leading-6">
                      {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                        <>
                          <FormattedMessage
                            id="checkout.invoicing.description-invoicing-requested-cancellation"
                            values={{
                              userName: nameFormated,
                              cancelAtDate: moment(
                                (currentSubscription.stripe_subscription.cancel_at as number) * 1000
                              ).format('LL')
                            }}
                          />
                          <Link
                            className="text-blue-500 underline ml-2"
                            to={`/checkout/reactivate-subscription/${currentSubscription._id}`}
                          >
                            <FormattedMessage id="checkout.invoicing.reactivate-subscription" />
                          </Link>
                        </>
                      ) : currentSubscription?.stripe_subscription?.status === 'trialing' ? (
                        <FormattedMessage
                          id="checkout.invoicing.description-invoicing-trial"
                          values={{
                            userName: nameFormated,
                            trialEndDate,
                            productPrice: reaisConverter((userUpcomingInvoice.total as number) / 100)
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id="checkout.invoicing.description-invoicing"
                          values={{
                            userName: nameFormated,
                            upcomingInvoiceDate: moment(
                              (userCanTrial
                                ? (currentInvoice?.period_end as number)
                                : (userUpcomingInvoice.period_end as number)) * 1000
                            ).format('LL'),
                            upcomingInvoicePrice: reaisConverter((userUpcomingInvoice.total as number) / 100)
                          }}
                        />
                      )}
                    </p>
                  </div>
                  <div className="w-full max-w-md lg:max-w-none flex items-center justify-between mb-14">
                    <Link to="/checkout" className="w-full mr-5">
                      <Button
                        style={{
                          margin: '0 20px 0 0',
                          borderRadius: '4px',
                          padding: '13px 0',
                          maxWidth: '285px'
                        }}
                        className="w-full flex items-center font-semibold justify-center text-base py-4"
                        theme="tertiary"
                      >
                        <FormattedMessage id="checkout.invoicing.change-plan" />
                      </Button>
                    </Link>
                    <Link to={`/checkout/change-plan/${priceId}`} className="w-full">
                      <Button
                        style={{
                          margin: '0',
                          borderRadius: '4px',
                          padding: '13px 0',
                          border: '1px solid #202020',
                          maxWidth: '285px'
                        }}
                        className="w-full text-base py-4 font-semibold"
                        theme="default"
                      >
                        <FormattedMessage id="checkout.invoicing.cancel-plan" />
                      </Button>
                    </Link>
                  </div>
                  <span style={{ color: '#202020' }} className="font-semibold text-xl leading-7">
                    <FormattedMessage id="checkout.invoicing.payment-type" />
                  </span>
                </div>

                <div style={{ background: '#cfcfcf' }} className="mb-8 mt-4 w-full h-px"></div>

                <div style={{ maxWidth: '600px' }} className="w-full flex flex-col">
                  {userPaymentMethod ? (
                    <div className="w-full flex items-center justify-between mb-8">
                      <div className="w-full text-sm sm:text-base leading-6 font-semibold h-8 flex items-center sm:items-start">
                        {userPaymentMethod?.card.brand === 'visa' && (
                          <img className="mr-4" src={visaCard} alt="brand-card" />
                        )}

                        {userPaymentMethod?.card.brand === 'mastercard' && (
                          <img className="mr-4" src={masterCard} alt="brand-card" />
                        )}

                        {!userPaymentMethod?.card.brand && <img className="mr-4" src={unknownCard} alt="brand-card" />}
                        <span className="w-full">
                          <FormattedMessage
                            id="checkout.invoicing.card-numbers"
                            values={{
                              last4CardNumbers: userPaymentMethod?.card.last4
                            }}
                          />
                        </span>
                      </div>
                      <span
                        style={{ color: '#817B7B' }}
                        className="sm:w-full text-right text-sm sm:text-base font-normal leading-6"
                      >
                        <FormattedMessage
                          id="checkout.invoicing.expiration-card-date"
                          values={{
                            expirationCardDate: `${
                              userPaymentMethod?.card.exp_month < 10
                                ? `0${userPaymentMethod?.card.exp_month}`
                                : userPaymentMethod.card.exp_month
                            }/${userPaymentMethod.card.exp_year}`
                          }}
                        />
                      </span>
                    </div>
                  ) : (
                    <span className="mb-8 w-full">
                      <FormattedMessage id="checkout.invoicing.payment-method-not-found" />{' '}
                    </span>
                  )}

                  <Link to="/account-management/signature">
                    <div className="w-full flex mb-15">
                      <img src={editIcon} alt="edit-icon" />
                      <span className="ml-2">
                        <FormattedMessage id="checkout.invoicing.update-payment-types" />
                      </span>
                    </div>
                  </Link>
                  <span className="font-semibold text-xl leading-7">
                    <FormattedMessage id="checkout.invoicing.payment-details" />
                  </span>
                </div>
                <div style={{ background: '#cfcfcf' }} className="mb-8 mt-4 w-full h-px"></div>
                <div style={{ maxWidth: '600px' }} className="sm:w-full text-right flex items-center justify-between">
                  <span style={{ color: '#0D65FF' }} className="text-left font-semibold text-sm sm:text-base leading-7">
                    {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                      <FormattedMessage
                        id="checkout.invoicing.date-next-invoicing"
                        values={{
                          currentInvoiceDate: moment((currentInvoice?.period_start as number) * 1000).format('LL')
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="checkout.invoicing.date-next-invoicing"
                        values={{
                          currentInvoiceDate: moment(
                            (userCanTrial
                              ? (currentInvoice?.period_start as number)
                              : (userUpcomingInvoice.period_start as number)) * 1000
                          ).format('LL')
                        }}
                      />
                    )}
                  </span>
                  <span
                    style={{ color: '#817B7B' }}
                    className="text-left font-normal text-sm sm:text-base leading-6 mx-2"
                  >
                    {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                      <FormattedMessage
                        id="checkout.invoicing.actual-plan-price"
                        values={{
                          productPrice: reaisConverter((currentInvoice?.total as number) / 100)
                        }}
                      />
                    ) : (
                      // <FormattedMessage
                      //   id="checkout.invoicing.actual-plan-price"
                      //   values={{
                      //     productPrice: reaisConverter(((userCanTrial ? currentInvoice?.total : userUpcomingInvoice.total) as number) / 100)
                      //   }}
                      // />
                      <FormattedMessage
                        id="checkout.invoicing.actual-plan-price"
                        values={{
                          productPrice: reaisConverter((currentInvoice?.total as number) / 100)
                        }}
                      />
                    )}
                  </span>
                  <span
                    style={{ color: '#817B7B' }}
                    className="w-full max-w-plan-type font-normal text-sm sm:text-base leading-6"
                  >
                    <FormattedMessage
                      id="checkout.invoicing.actual-plan-type"
                      values={{
                        productName: priceName
                      }}
                    />
                  </span>
                </div>
              </>
            ) : (
              !userSubscriptions?.length &&
              !isGettingUserSubscriptions && (
                <>
                  <span className="font-normal text-xl lg:leading-10 lg:text-3xl " style={{ color: '#202020' }}>
                    <FormattedMessage
                      id="checkout.invoicing.plan-type"
                      values={{
                        productName: priceName
                      }}
                    />
                  </span>
                  <span>
                    <FormattedMessage id="checkout.invoicing.subscription-not-found" />
                  </span>
                </>
              )
            )}
          </Container>
        </SecondCheckoutContainer>
      )}
    </CheckoutConsumer>
  );
};

export default Invoicing;
