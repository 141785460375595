import styled, { css, keyframes } from "styled-components";

type DotsProp = {
  active?: boolean;
};

const increaseWidth = keyframes`
  0% {
      width: 6px;
  background: rgba(94,90,90,0.34);
      }

  100% {
      width: 18px;
  background: rgba(94,90,90,1);
      }
`;

export const Container = styled.div`
  min-height: 100vh;
  padding: 5%;
  min-width: 100%;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Spacer = styled.div`
  height: 20px;
`;

export const Dot = styled.button`
  width: 6px;
  height: 6px;
  margin: 0 5px;
  border: none;
  padding: 0;
  cursor: pointer;
  animation: ${(props: DotsProp) =>
    props.active &&
    css`
      ${increaseWidth} 0.3s
    `};
  animation-fill-mode: forwards;
  background: rgba(94, 90, 90, 0.34);
  border-radius: 100px;
  z-index: 999;
`;

export const DotsGroup = styled.div`
  display: flex;
`;

export const CustomButton = styled.button`
  width: 132px;
  height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #0d65ff;
  color: #0d65ff;
  box-sizing: border-box;
  border-radius: 100px;

  &:focus {
    border: 1px solid #0d65ff !important;
  }
`;

export const TextButton = styled.button`
  border: none;
  background: none;
  font-style: normal;
  font-weight: 500;
  color: #5e5a5a;
`;

export const SliderGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const randomizeShape = keyframes`
  0%  {
    border-radius: 30% 70% 70% 30% / 30% 41% 59% 70%;
  }
  25%  {
    border-radius: 30% 70% 61% 39% / 30% 63% 37% 70%;
  }
  50%  {
    border-radius: 58% 42% 61% 39% / 65% 63% 37% 35%;
  }
  75%  { 
    border-radius: 58% 42% 61% 39% / 40% 80% 20% 60%;
  }
  100% {  
    border-radius: 30% 70% 70% 30% / 30% 41% 59% 70%;
  }
`;

const randomizeShape2 = keyframes`
  0%  {
    border-radius: 56% 44% 89% 11% / 78% 32% 68% 22%;
  }
  25%  {
    border-radius: 56% 44% 64% 36% / 61% 32% 68% 39%;
  }
  50%  {
    border-radius: 56% 44% 64% 36% / 61% 78% 22% 39%;
  }
  75%  { 
    border-radius: 23% 77% 64% 36% / 28% 78% 22% 72%;
  }
  100% {  
    border-radius: 56% 44% 89% 11% / 78% 32% 68% 22%;
  }
`;

export const RandomShape = styled.div`
  width: 80%;
  height: 100%;
  max-width: 313px;
  position: absolute;
  z-index: 1;
  max-height: 313px;
  animation: ${randomizeShape} 5s linear infinite;
  border-radius: 30% 70% 70% 30% / 30% 41% 59% 70%;
  background-color: RGBA(26, 0, 0, 0.03);
`;

export const SecondRandomShape = styled.div`
  width: 80%;
  height: 100%;
  max-width: 313px;
  z-index: 1;
  position: absolute;
  animation: ${randomizeShape2} 10s linear infinite;
  max-height: 313px;
  border-radius: 56% 44% 89% 11% / 78% 32% 68% 22%;
  background-color: RGBA(26, 0, 0, 0.02);
`;