import { Audition } from '../../../../interfaces';
import { Axios } from '../../../../utils/axios';
import { removeDiacritics } from '../../../../utils/removeDiacritics/removeDiacritics';


export const getAuditions = async (query?: any) => {
  const userToken = localStorage.getItem('LOCAL_TOKEN');

  try {
    const res = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v2/auditions?resolve=["related_artists"]${query ? '&' + query : ''}`, {
      headers: { Authorization: `Bearer ${userToken}`, 'Content-Type': 'application/json' }
    });

    return { success: true, data: res.data };
  } catch (err) {
    console.log('Success: false', err.response);
    return {
      success: false,
      code: err?.response?.data?.error?.code,
      message: err?.response?.data?.error?.message
    };
  }
};

export const search = async (title: string, status: string) => {
  const userToken = localStorage.getItem('LOCAL_TOKEN');

  try {
    const res = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v2/auditions/search?resolve=["target_artist", "related_artists", "id_audition_tags"]&status=${status}&title=${title}`, {
      headers: { Authorization: `Bearer ${userToken}`, 'Content-Type': 'application/json' }
    });

    return { success: true, data: res.data };
  } catch (err) {
    console.log('Success: false', err.response);
    return {
      success: false,
      code: err?.response?.data?.error?.code,
      message: err?.response?.data?.error?.message
    };
  }
};

export const filter = (collection: Audition[], filterText: string) => {
  return collection.filter((item) => {
    return removeDiacritics(item.title).toLowerCase().indexOf(filterText.toLowerCase()) > -1;
  });
};
