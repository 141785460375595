import React, { Dispatch } from 'react';
import { Board, PostProd } from '../../../../interfaces';

interface KanbanState {
  boardData?: Board;
  boardTags?: any[];
  currentTrack?: PostProd;
  // loadBoardTags: (idBoard: string, defaultToken?: string) => Promise<any>;
  reloadBoard: (idProject: string) => Promise<void>;
  handleUploadFile: (projectId: string, file: any, defaultToken?: string) => Promise<any>;
  handleMoveLane: (newPosition: number, laneId: string, defaultToken?: string) => Promise<void>;
  handleMoveCard: (cardId: string, targetLaneId: string, position: number, defaultToken?: string) => Promise<void>;
  deleteCard: (cardId: string, defaultToken?: string) => Promise<void>;
  editPostName: (newName: string, postId: string, defaultToken?: string) => Promise<void>;
  setCurrentTrack: (currentTrack?: PostProd) => void;
  createLane: (
    title: string,
    id_board: string
  ) => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
  loadInitialBoard: (idProject: string, defaultToken?: string) => Promise<{ success: boolean; data: any } | undefined>;
  setBoardData: Dispatch<any>;
  handleClassify: (
    idProject: string,
    idProjectPost: string,
    classification: number,
    defaultToken?: string
  ) => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
  handleView: (
    idProject: string,
    idProjectPost: string,
    defaultToken?: string
  ) => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
  handleAddTag: (
    idProject: string,
    idCard: string,
    label: string,
    defaultToken?: string
  ) => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
  handleRemoveTag: (
    idProject: string,
    idCard: string,
    label: string,
    defaultToken?: string
  ) => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
  handleShutDownAudition: (
    idAudition: string,
    idProject: string,
    defaultToken?: string
  ) => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
}

const initialState: KanbanState = {
  boardData: undefined,
  boardTags: undefined,
  // loadBoardTags: async () => {},
  handleUploadFile: async () => {},
  reloadBoard: async () => {},
  handleMoveLane: async () => {},
  handleMoveCard: async () => {},
  deleteCard: async () => {},
  editPostName: async () => {},
  setCurrentTrack: () => {},
  loadInitialBoard: async () => undefined,
  createLane: async () => undefined,
  setBoardData: () => {},
  handleClassify: async () => undefined,
  handleView: async () => undefined,
  handleAddTag: async () => undefined,
  handleRemoveTag: async () => undefined,
  handleShutDownAudition: async () => undefined
};

const KanbanContext = React.createContext<KanbanState>(initialState);

const KanbanConsumer = KanbanContext.Consumer;

export { KanbanContext, KanbanConsumer };

export type { KanbanState };
