import React, { useState, useEffect, SyntheticEvent, useContext } from 'react';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Spinner from '../Spinner/Spinner';
import RectButton from '../RectButton/RectButton';
import { PriceObj } from '../../interfaces';
import visaCard from '../../images/visa-card.svg';
import masterCard from '../../images/mc-card.svg';
import { CheckoutContext } from '../../context/checkout';
import useUserData from '../../utils/customHooks/useUserData';
import reloadIcon from '../../images/mpf-reload-white.svg';
import { Container } from './styles';

const optionsObj = {
  style: {
    base: {
      iconColor: '#333',
      color: '#222',
      '::placeholder': {
        color: '#718096'
      }
    },
    invalid: {
      color: '#ef233c'
    }
  }
};

type Props = {
  isMySignature?: boolean;
  product?: PriceObj | null;
  stripe?: any;
  elements?: any;
};

const BenefitsFormComponent: React.FC<Props> = ({ isMySignature, product, stripe, elements }) => {
  const [cardHolderName, setCardholderName] = useState('');
  const [hasTouched, setTouched] = useState(false);
  const [hasErrors, setHasErrors] = useState({
    cardNumber: '',
    cardholderName: '',
    cardExpiry: '',
    cardCvc: ''
  });

  const { setIsAtachingPaymentMethod, atachPaymentMethod, isAtachingPaymentMethod, atachedPaymentMethod } = useContext(
    CheckoutContext
  );

  const user = useUserData();

  const [promiseError, setPromiseError] = useState<string | undefined>('');

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    setIsAtachingPaymentMethod(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      // @ts-ignore
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: user?.name,
        email: user?.email
      }
    });

    if (payload.error) {
      setPromiseError(payload.error.message);
      setIsAtachingPaymentMethod(false);
    } else if (payload.paymentMethod) {
      await atachPaymentMethod(payload.paymentMethod as any);
    }
  };

  const handleBlur = () => {
    setTouched(true);
  };

  const verifyValidation = () => {
    if (!cardHolderName.length && hasTouched) {
      return setHasErrors({ ...hasErrors, cardholderName: 'Insira um nome.' });
    }

    if (cardHolderName.length > 50) {
      return setHasErrors({
        ...hasErrors,
        cardholderName: 'Insira um nome válido!'
      });
    }

    return setHasErrors({ ...hasErrors, cardholderName: '' });
  };

  useEffect(() => {
    verifyValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardHolderName, hasTouched]);

  return (
    <div className="flex flex-col lg:flex-row w-full h-full justify-center items-center lg:justify-start lg:items-stretch">
      <div className={`bg-white w-full md:w-3/4 lg:w-1/2 xl:w-1/2 p-8 flex flex-col  ${!isMySignature && 'shadow-sm'}`}>
        <span className="text-xl mb-4">O que está incluso no meu plano?</span>
        <div>
          {product?.benefits.map((planBenefit, index) => (
            <span className="flex items-center my-2" key={index}>
              <AiOutlineCheckCircle size={18} className="text-blue-500" />{' '}
              <span className="ml-2 text-gray-600 text-lg">{planBenefit}</span>
            </span>
          ))}
        </div>
      </div>
      <div
        className={`bg-white w-full md:w-3/4 lg:w-1/2 xl:w-1/2 p-8 flex flex-col justify-around ${
          !isMySignature ? 'border-t-2 lg:border-l-2 lg:border-t-0 shadow-sm' : ''
        } border-gray-300`}
      >
        <span className="text-xl">Insira os detalhes do pagamento</span>
        <span className="pb-3 pt-2 text-sm flex w-full justify-center items-center text-gray-500">
          *Bandeiras aceitas:
          <img className="mr-4 ml-4" src={visaCard} alt="brand-card" />
          <img src={masterCard} alt="brand-card" />
        </span>
        {!stripe || !elements ? (
          <div className="flex items-center justify-center">
            <Spinner isLoading />
          </div>
        ) : (
          <>
            <label htmlFor="card--number" className="text-sm">
              Número do cartão
              <CardNumberElement
                id="card--number"
                className="px-0 py-3 border-b-2 border-gray-300"
                options={{
                  ...optionsObj,
                  showIcon: true,
                  placeholder: 'DIGITE OS 16 NÚMEROS DO CARTÃO'
                }}
                onChange={(event) => {
                  setHasErrors({
                    ...hasErrors,
                    cardNumber: event.error?.message || ''
                  });
                }}
              />
              {hasErrors.cardNumber && <span className="text-sm text-red-500">{hasErrors.cardNumber}</span>}
            </label>
            <label htmlFor="card--name" className="text-sm flex flex-col py-2">
              Nome do titular
              <input
                id="card--name"
                placeholder="NOME IMPRESSO NO CARTÃO"
                maxLength={50}
                className="px-0 py-3 border-b-2 border-gray-300 placeholder-gray-600 tracking-normal"
                value={cardHolderName}
                onBlur={handleBlur}
                onChange={(e) => setCardholderName(e.target.value)}
              />
              {
                // Todo: correct this validation below, adding hasTouched tag.
              }
              {hasErrors.cardholderName && <span className="text-sm text-red-500">{hasErrors.cardholderName}</span>}
            </label>
            <div className="flex flex-col sm:flex-row items-end sm:flex-end justify-between py-2">
              <label className="w-full sm:w-1/2 text-sm">
                Data de validade
                <CardExpiryElement
                  options={optionsObj}
                  className="px-0 py-3 border-b-2 border-gray-300"
                  onChange={(event) => {
                    setHasErrors({
                      ...hasErrors,
                      cardExpiry: event.error?.message || ''
                    });
                  }}
                />
              </label>
              <label className="w-full sm:w-1/2 ml-0 sm:ml-4  text-sm">
                Cód. de segurança
                <CardCvcElement
                  options={{
                    ...optionsObj,
                    placeholder: 'CVC atrás do cartão'
                  }}
                  className="px-0 py-3 border-b-2 border-gray-300"
                  onChange={(event) => {
                    setHasErrors({
                      ...hasErrors,
                      cardCvc: event.error?.message || ''
                    });
                  }}
                />
              </label>
            </div>
          </>
        )}
        {!isMySignature ? (
          <p className="pt-2 text-sm text-gray-500">
            Ao submeter as suas informações de pagamento e clicar em &quot;
            <span className="text-black">Continuar</span>&quot;, você concorda com os{' '}
            <a href="https://musicplayce.com.br" target="blank" className="no-underline text-blue-500">
              Termos de Uso do MusicPlayce
            </a>
            .
          </p>
        ) : (
          <RectButton
            onClick={(event) => handleSubmit(event)}
            theme="primary"
            className="flex items-center justify-center mt-8"
          >
            {!isAtachingPaymentMethod && !atachedPaymentMethod && 'Salvar'}
            {isAtachingPaymentMethod && !atachedPaymentMethod && (
              <>
                Salvando...
                <img className="spin" src={reloadIcon} alt="spinner-icon" />
              </>
            )}
            {!isAtachingPaymentMethod && atachedPaymentMethod && 'Cartão salvo!'}
          </RectButton>
        )}
        {promiseError !== '' && <span className="text-red-500 text-xs mt-1">{promiseError}</span>}
      </div>
    </div>
  );
};

// @ts-ignore
const BenefitsForm = ({ product, stripe, elements, isMySignature }: Props) => {
  return (
    <Container>
      <BenefitsFormComponent product={product} stripe={stripe} elements={elements} isMySignature={isMySignature} />{' '}
    </Container>
  );
};

export default BenefitsForm;
