import { Axios, withAuth } from '../../../../utils/axios';

interface Tag {
  _id: string;
  label: string;
  id_producer_project: string;
  archived: string;
  datetime_created: string;
  datetime_updated: string;
}

interface editProjectResponse {
  data: {
    _id: string;
    name: string;
    description: string;
    archived: boolean;
    id_board: string;
    target_artists: [string];
    related_artists: [string];
    user_created: string;
    datetime_created: string;
    datetime_updated: string;
    tags: Tag[];
  };
}

export default async function editProject(
  projectId: string,
  name: string,
  description: string,
  target_artists_ids: string[],
  related_artists_ids: string[]
) {
  try {
    const token = localStorage.getItem('LOCAL_TOKEN');
    const data: editProjectResponse = await Axios(process.env.REACT_APP_NODE_ENV).put(
      `/v1/producer/project/${projectId}`,
      {
        name,
        description,
        target_artists: target_artists_ids,
        related_artists: related_artists_ids
      },
      withAuth(token || '')
    );

    return data;
  } catch (error) {
    if (error.response.data.error.code === 'producer/body-validation-error') {
      if (error.response.data.error.messages[0].field === 'name') {
        return {
          error: 'Por favor, preencha o campo "Nome do projeto" do projeto antes de salvar'
        };
      }
      if (error.response.data.error.messages[0].field === 'description') {
        return {
          error: 'Por favor, preencha o campo "Descrição do projeto" antes de salvar'
        };
      }
      if (error.response.data.error.messages[0].field === 'target_artists') {
        return {
          error: 'Por favor, preencha o campo "Artista principal" antes de salvar'
        };
      }
      if (error.response.data.error.messages[0].field === 'related_artists[0]') {
        return {
          error: 'Por favor, preencha o campo "Artista principal" antes de salvar'
        };
      }
    }
  }
}
