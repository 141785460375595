import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  background-color: #222;

  display: flex;
  flex-direction: column;
  align-items: center;
`


export const Logo = styled.img`
  height: 80vh;

  object-fit: contain;
`

export const CallContainer = styled.div`
  padding: 32px 0;

  max-width: 650px;

  h1{
    color: #FB0016;

    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
    letter-spacing: 2px;
    
    padding-bottom: 16px;
  }

  @media(max-width: 720px){
    padding: 32px 16px;
  }
`

export const Call = styled.p`
  color: #fafafa;

  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 33px;

  text-align: left;
`
