/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react';
import { FeatureCollection } from 'geojson';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import { FormattedMessage } from 'react-intl';

type Props = {
  isPoll?: boolean;
  isWhoMatches?: boolean;
  data?: FeatureCollection;
};

/**
 * Conta do Matheus aparentemente está fora do ar. utilizaremos a chave da conta tecnologia@musicplayce.com no lugar.
 *
 */
// chave antiga => 'pk.eyJ1IjoibXRmZXJyYXoiLCJhIjoiY2s1dTg2N3d1MHFiYzNqcDNtemZwY3NpaiJ9.4PmVXlOOHGW4Dib8Ya9yvA'
mapboxgl.accessToken = 'pk.eyJ1IjoibXVzaWNwbGF5Y2UiLCJhIjoiY2tlbzU3b2QxMGVsZTJ5bW9lbmV6Y2s4eSJ9.PgJZ6ypo-A1trQPgw8ZDqQ';

const MapComponent: React.FC<Props> = ({ isPoll, isWhoMatches, data }) => {
  const [mapState, setMap] = useState<mapboxgl.Map | null>(null);
  const mapDiv = useRef<HTMLDivElement>(null);

  const isNormal = !isPoll && !isWhoMatches;

  useEffect(() => {
    if (!mapDiv.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapDiv.current || '', // Fallback for null useref.
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 3,
      center: [-61.9510124, -14.0805276]
    });

    map.on('load', () => {
      map.resize();
      map.addSource('votes-source', {
        type: 'geojson',
        data: data || {
          type: 'FeatureCollection',
          features: []
        },
        cluster: true
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'votes-source',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'votes-source',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'votes-source',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 10
        }
      });

      // inspect a cluster on click
      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });

        const clusterId = features[0].properties?.cluster_id;
        (map.getSource('votes-source') as GeoJSONSource).getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: (features[0].geometry as any).coordinates,
            zoom
          });
        });
      });

      map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = '';
      });
    });

    setMap(map);

    if (mapState === null) {
      setMap(map);
    }

    return () => map.remove();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mapState) {
      _reloadMapSouce();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _reloadMapSouce = () => {
    (mapState?.getSource('votes-source') as GeoJSONSource).setData(data ? (data as any) : null);
  };

  return (
    <div
      className="w-full bg-white p-6 flex flex-col"
      style={{ boxShadow: '0px 30px 40px -20px #E4E5E6', borderRadius: 8 }}
    >
      <span className="text-2xl" style={{ color: '#202020' }}>
        {isPoll && (
          <FormattedMessage id="dashboard.composer.maps-poll-main-title" defaultMessage="Localização dos votos" />
        )}
        {isWhoMatches && (
          <FormattedMessage
            id="dashboard.composer.maps-whomatches-main-title"
            defaultMessage="Localização das indicações"
          />
        )}
        {isNormal && (
          <FormattedMessage id="dashboard.composer.maps-main-title" defaultMessage="Localização das indicações" />
        )}
      </span>
      <span className="text-sm" style={{ color: '#817B7B' }}>
        {isPoll && (
          <FormattedMessage
            id="dashboard.composer.maps-poll-brief-description"
            defaultMessage="Local em que a votação ocorreu"
          />
        )}
        {isWhoMatches && (
          <FormattedMessage
            id="dashboard.composer.maps-whomatches-brief-description"
            defaultMessage="Local em que a indicação ocorreu"
          />
        )}
        {isNormal && (
          <FormattedMessage
            id="dashboard.composer.maps-brief-description"
            defaultMessage="Local em que a indicação ocorreu"
          />
        )}
      </span>

      <hr className="my-4" />
      <div className="Map" ref={mapDiv} style={{ width: '100%', height: 550, borderRadius: 8 }} />
    </div>
  );
};

export default MapComponent;
