import { showToast } from '../../../../components/Toast/Toast';
import { Axios } from '../../../../utils/axios';

interface successApiResponse {
  status: number;
  data: any;
}

interface errorApiResponse {
  response: {
    status: number;
    data: {
      error?: {
        code: string;
        message: string;
      };
    };
  };
}

export default async function deleteProjectTags(tags: any[], project_id: string) {
  try {
    const token = localStorage.getItem('LOCAL_TOKEN');
    const res: successApiResponse = await Axios(process.env.REACT_APP_NODE_ENV).delete('/v1/producer/project/' + project_id + '/tags', {
      data: tags,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (res.status !== 200) {
      return showToast({ type: 'error', message: 'Ocorreu um erro ao deletar as tags do projeto. Tente novamente' });
    }

    return res;
  } catch (e) {
    showToast({ type: 'error', message: e });
  }
}
