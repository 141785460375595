/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import { FeatureCollection } from 'geojson';
import React, { useContext, useEffect, useState } from 'react';
import { HistogramData } from '../../../components/IndicationTimeGraph/IndicationTimeGraph';
import {
  AuditionPost,
  Composer,
  Composition,
  CompositionIndication,
  CompositionPlay,
  CompositionTopArtists,
  TopShare,
  Track
} from '../../../interfaces';
// import { Axios, withAuth } from '../../../utils/axios';
import { AuthContext } from '../../Auth';
import { ArtistDashContext } from '../artist';
import { DashContext } from '../Context';
import { WhoMatchesContext } from '../whomatches';
import { ComposerDashContext } from './Context';
import * as compositionsRepo from '../../../repositories/compositions';

interface Props {
  children: React.ReactNode;
}

export const ComposerDashProvider: React.FC<Props> = ({ children }) => {
  const [compositions, setCompositions] = useState<Composition[]>([]);
  const [isGettingPosts] = useState<boolean>(false);
  const [isGettingCompositions, setIsGettingCompositions] = useState<boolean>(false);
  const [isGettingPlays, setIsGettingPlays] = useState<boolean>(false);
  const [isGettingIndications, setIsGettingIndications] = useState<boolean>(false);
  const [isGettingTopArtists, setIsGettingTopArtists] = useState<boolean>(false);

  const [totalPlaysByDay, setTotalPlaysByDay] = useState<number>(0);
  const [totalIndicationsByDay, setTotalIndicationsByDay] = useState<number>(0);
  const [totalTopArtists, setTotalTopArtists] = useState<number>(0);
  const [totalTopShares] = useState<TopShare[]>([]);
  const [isGettingTotals, setIsGettingTotals] = useState<boolean>(false);
  const [activeComposition, setActiveComposition] = useState<Composition | undefined>(undefined);
  // const [gettedPosts, setGettedPosts] = useState<boolean>(false);
  const [composersNameList, setComposersNameList] = useState<Composer[] | undefined>(undefined);
  const [playsByDay, setPlaysByDay] = useState<CompositionPlay[] | undefined>(undefined);
  const [indicationsByDay, setIndicationsByDay] = useState<CompositionIndication[] | undefined>(undefined);
  const [topArtists, setTopArtists] = useState<CompositionTopArtists[] | undefined>(undefined);
  const [topShares] = useState<TopShare[] | undefined>(undefined);
  const [indicationTimes] = useState<HistogramData | undefined>(undefined);
  const [indicationLocations] = useState<FeatureCollection>();
  // const [showTotalsPostsInfos, setShowTotalsPostsInfos] = useState<boolean>(false);
  // const [showTotalCompositionInfos, setShowTotalCompositionInfos] = useState<boolean>(false);
  const [activeCompositionsTrack, setActiveCompositionsTrack] = useState<undefined | Track>(undefined);
  const [compositionsPlayList] = useState<Track[]>([]);
  const [isGettingCompPlayList] = useState<boolean>(false);
  const [auditionPosts] = useState<AuditionPost[]>([]);

  const { getCurrentUserId } = useContext(AuthContext);

  const { setActiveArtistTrack } = useContext(ArtistDashContext);
  const { setActivePollsTrack } = useContext(DashContext);
  const { setActiveWhoMatchesTrack } = useContext(WhoMatchesContext);

  useEffect(() => {
    setActiveArtistTrack(undefined);
    setActivePollsTrack(undefined);
    setActiveWhoMatchesTrack(undefined);
  }, []);

  useEffect(() => {
    (async () => {
      await handleCompositionData();
    })();
  }, []);

  // useEffect(() => {
  //   let tPlays = 0;
  //       compositions?.map((item: Composition) => {
  //         console.log("TOTAL_PLAYS", tPlays)
  //         tPlays += item.plays
  //       });
  //       setTotalPlaysByDay(tPlays)
  // }, [activeComposition])

  // const _getCompTrackById = async (id: string, defaultToken?: string) => {
  //   try {
  //     const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/posts/${id}`, withAuth(defaultToken || token));
  //     return response.data.data;
  //   } catch (error) {
  //     console.error('Error on getting compositions play list: ', error);
  //     return undefined;
  //   }
  // };

  // const getCompPlayList = async (defaultToken?: string) => {
  //   setIsGettingCompPlayList(true);
  //   compositions?.forEach(async (item, idx) => {
  //     const track = await _getCompTrackById(item.id, defaultToken);
  //     setCompositionsPlayList((prev) => [...prev, track]);
  //     if (idx === compositions.length - 1) {
  //       setIsGettingCompPlayList(false);
  //     }
  //   });
  // };

  // const _getTotalPlaysByDay = async (defaultToken?: string) => {
  //   try {
  //     const response = await Axios(process.env.REACT_APP_NODE_ENV).get(
  //       `/v1/data/dashboard/posts/plays_by_day_total/`,
  //       withAuth(defaultToken || token)
  //     );

  //     setTotalPlaysByDay(response.data);
  //   } catch (error) {
  //     console.error('Error on getting totals plays by day in composer provider: ', error);
  //   }
  // };

  // const _getTotalIndicationsByDay = async (defaultToken?: string) => {
  //   try {
  //     const response = await Axios(process.env.REACT_APP_NODE_ENV).get(
  //       `/v1/data/dashboard/posts/indications_by_day_total/`,
  //       withAuth(defaultToken || token)
  //     );

  //     setTotalIndicationsByDay(response.data);
  //   } catch (error) {
  //     console.error('Error on getting total indications by day in composer provider: ', error);
  //   }
  // };

  // const _getTotalTopArtists = async (defaultToken?: string) => {
  //   try {
  //     // const response = await Axios(process.env.REACT_APP_NODE_ENV).get(
  //     //   `/v1/data/dashboard/posts/top_artists_total/`,
  //     //   withAuth(defaultToken || token)
  //     // );

  //     // setTotalTopArtists(response.data);
  //   } catch (error) {
  //     console.error('Error on getting total top artists in composer provider: ', error);
  //   }
  // };

  // const _getTotalTopShares = async (defaultToken?: string) => {
  //   try {
  //     // const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/dashboard/posts/top_shares_total/`, withAuth(defaultToken || token));

  //     // setTotalTopShares(response.data);
  //   } catch (error) {
  //     console.error('Error on getting total top shares in composer provider: ', error);
  //   }
  // };

  // const _getPostAuditionDetails = async (postId: string, defaultToken?: string) => {
  //   try {
  //     // const response = await Axios(process.env.REACT_APP_NODE_ENV).get(
  //     //   `/v1/data/dashboard/posts/${postId}/audition_submitted/`,
  //     //   withAuth(defaultToken || token)
  //     // );
  //     // setAuditionPosts(response.data);
  //     return {
  //       success: true,
  //       data: '' //response.data
  //     };
  //   } catch (error) {
  //     console.log('Error on getting post audition details: ', error);
  //     return {
  //       success: false,
  //       error: error
  //     };
  //   }
  // };

  // const fetchPostsByProfileToken = async (defaultToken?: string) => {
  //   setIsGettingTotals(true);
  //   setActiveComposition(undefined);
  //   try {
  //     const response = await Axios(process.env.REACT_APP_NODE_ENV).get(
  //       '/v1/data/dashboard/search/indications/songwriter/',
  //       withAuth(defaultToken || token)
  //     );

  //     const sorted: PostData[] = response.data;

  //     setCompositions(sorted?.sort((a, b) => b.plays - a.plays));

  //     await _getTotalPlaysByDay(defaultToken);
  //     await _getTotalIndicationsByDay(defaultToken);
  //     await _getTotalTopArtists(defaultToken);
  //     await _getTotalTopShares(defaultToken);

  //     setShowTotalsPostsInfos(true);
  //     setIsGettingTotals(false);
  //     return response.data;
  //   } catch (error) {
  //     setShowTotalsPostsInfos(false);
  //     setIsGettingTotals(false);
  //     setGettedPosts(false);
  //     console.error(error);
  //     return undefined;
  //   }
  // };

  const fetchCompositionsByUserProfileId = async () => {
    setIsGettingCompositions(true);
    setActiveComposition(undefined);
    try {
      const response: Composition[] = await compositionsRepo.getCompositionsByUserProfileId(getCurrentUserId());
      setCompositions(response);

      if (response.length > 0) {
        setActiveComposition(response[0]);
        handleCompositionData(response[0]._id);
      }

      setIsGettingCompositions(false);
    } catch (error) {
      setIsGettingCompositions(false);
      console.error(error);
    }
  };

  // const _getComposersNameList = async (id: string, defaultToken?: string) => {
  //   try {
  //     const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/posts/${id}`, withAuth(defaultToken || token));
  //     setComposersNameList(response.data.data.composers);
  //   } catch (error) {
  //     console.error(error);
  //     setActiveCompositionsTrack(undefined);
  //     setComposersNameList([]);
  //   }
  // };

  const _getPlaysByDay = async (compositionId: string) => {
    try {
      setIsGettingPlays(true);
      const response = await compositionsRepo.getCompositionPlaysByCompositionId(compositionId);
      setPlaysByDay(response);
      setIsGettingTotals(false);
      setIsGettingPlays(false);
    } catch (error) {
      console.error(error);
      setPlaysByDay(undefined);
      setIsGettingTotals(false);
      setIsGettingPlays(false);
    }
  };

  const _getIndicationsByDay = async (compositionId: string) => {
    try {
      setIsGettingIndications(true);
      const response = await compositionsRepo.getCompositionIndicationsByCompositionId(compositionId);
      setIndicationsByDay(response);
      setIsGettingTotals(false);
      setIsGettingIndications(false);
    } catch (error) {
      console.error(error);
      setIndicationsByDay(undefined);
      setIsGettingTotals(false);
      setIsGettingIndications(false);
    }
  };

  const _getTopArtists = async (compositionId: string) => {
    try {
      setIsGettingTopArtists(true);
      const response = await compositionsRepo.getCompositionTopIndicationsByCompositionId(compositionId);
      setTopArtists(response);
      setIsGettingTotals(false);
      setIsGettingTopArtists(false);
    } catch (error) {
      console.error(error);
      setTopArtists(undefined);
      setIsGettingTotals(false);
      setIsGettingTopArtists(false);
    }
  };

  // const _getShares = async (id: string, defaultToken?: string) => {
  //   try {
  //     // const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/dashboard/posts/${id}/top_shares/`, withAuth(defaultToken || token));

  //     // setTopShares(response.data);
  //   } catch (error) {
  //     console.error(error);
  //     setTopShares(undefined);
  //   }
  // };

  // const _getIndicationsMoment = async (id: string, defaultToken?: string) => {
  //   try {
  //     // const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/indications/${id}/moment/`, withAuth(defaultToken || token));

  //     // setIndicationTimes(response.data);
  //   } catch (error) {
  //     console.error(error);
  //     setIndicationTimes(undefined);
  //   }
  // };

  // const _getIndicationsLocation = async (id: string, defaultToken?: string) => {
  //   // try {
  //   //   const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/indications/${id}/location/`, withAuth(defaultToken || token));

  //   //   setIndicationLocations(response.data);
  //   // } catch (error) {
  //   //   console.error(error);
  //   //   setIndicationLocations(undefined);
  //   // }
  // };

  const handlePostSelect = async (id: string, defaultToken?: string, isPlay?: boolean) => {
    // setIsGettingCompositions(true)
    // if (!id) {
    //   setActiveComposition(undefined);
    //   setShowTotalsPostsInfos(true);
    //   setActiveCompositionsTrack(undefined);
    //   if (!compositions?.length) {
    //     await fetchPostsByProfileToken(defaultToken);
    //   }
    // }
    // if (id) {
    //   setIsGettingPosts(true);
    //   setShowTotalsPostsInfos(false);
    //   try {
    //     const post = compositions?.find((item) => item.id === id);
    //     setActiveComposition(post);
    //     if (isPlay) {
    //       const track = compositionsPlayList?.find((item) => item._id === id);
    //       setActiveCompositionsTrack(track);
    //     }
    //     await _getComposersNameList(id, defaultToken);
    //     // await _getPlaysByDay(id, defaultToken);
    //     // await _getIndicationsByDay(id, defaultToken);
    //     // await _getTopArtists(id, defaultToken);
    //     await _getShares(id, defaultToken);
    //     await _getIndicationsMoment(id, defaultToken);
    //     await _getIndicationsLocation(id, defaultToken);
    //     await _getPostAuditionDetails(id, defaultToken);
    //     // setIsGettingPosts(false);
    //   } catch (error) {
    //     console.error('Error on selecting post in composer provider: ', error);
    //     setActiveComposition(undefined);
    //     setIsGettingPosts(false);
    //   }
    // }
  };

  const handleCompositionData = async (compositionId?: string) => {
    setIsGettingCompositions(true);

    if (compositionId) {
      _getPlaysByDay(compositionId);
      _getIndicationsByDay(compositionId);
      _getTopArtists(compositionId);
      
      if(activeComposition){
        setActiveComposition(compositions?.find((c: Composition) => c._id === compositionId));
      }
    }

    setIsGettingCompositions(false);
    setIsGettingTotals(false);
    // if (!id) {
    //   setActiveComposition(undefined);
    //   setShowTotalsPostsInfos(true);
    //   setActiveCompositionsTrack(undefined);

    //   if (!compositions?.length) {
    //     await fetchPostsByProfileToken(defaultToken);
    //   }
    // }

    // if (id) {
    //   setIsGettingPosts(true);
    //   setShowTotalsPostsInfos(false);
    //   try {
    //     const post = compositions?.find((item) => item.id === id);
    //     setActiveComposition(post);

    //     if (isPlay) {
    //       const track = compositionsPlayList?.find((item) => item._id === id);
    //       setActiveCompositionsTrack(track);
    //     }

    //     await _getComposersNameList(id, defaultToken);
    //     await _getPlaysByDay(id, defaultToken);
    //     await _getIndicationsByDay(id, defaultToken);
    //     await _getTopArtists(id, defaultToken);
    //     await _getShares(id, defaultToken);
    //     await _getIndicationsMoment(id, defaultToken);
    //     await _getIndicationsLocation(id, defaultToken);
    //     await _getPostAuditionDetails(id, defaultToken);

    //     setIsGettingPosts(false);
    //   } catch (error) {
    //     console.error('Error on selecting post in composer provider: ', error);
    //     setActiveComposition(undefined);
    //     setIsGettingPosts(false);
    //   }
    // }
  };

  return (
    <ComposerDashContext.Provider
      value={{
        compositions,
        isGettingPosts,
        isGettingCompositions,
        isGettingPlays,
        isGettingIndications,
        isGettingTopArtists,
        // gettedPosts,
        isGettingTotals,
        totalPlaysByDay,
        totalIndicationsByDay,
        totalTopArtists,
        totalTopShares,
        activeComposition,
        playsByDay,
        composersNameList,
        indicationsByDay,
        topArtists,
        topShares,
        indicationTimes,
        indicationLocations,
        activeCompositionsTrack,
        compositionsPlayList,
        isGettingCompPlayList,
        auditionPosts,
        setActiveComposition,
        setActiveCompositionsTrack,
        handleCompositionData,
        handlePostSelect,
        fetchCompositionsByUserProfileId,
        setComposersNameList,
        setTotalPlaysByDay,
        setTotalIndicationsByDay,
        setTotalTopArtists
      }}
    >
      {children}
    </ComposerDashContext.Provider>
  );
};
