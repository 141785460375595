import styled from 'styled-components';

export const Stars = styled.div`
  width: 100%;

  .stars {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;
