import React from 'react';
import { User, CreatePortalResponse } from '../../interfaces';

interface AuthState {
  avatar?: string;
  token?: string;
  refreshToken?: string;
  errorMessage?: string;
  user?: User;
  username: string;
  isAuth: boolean;
  isLogginIn: boolean;
  isSending: boolean;
  isSended: boolean;
  isResetingPassword: boolean;
  resetedPassword: boolean;
  isGettingUser: boolean;
  isCreatingPortal: boolean;
  createCustomerPortal: (id: string, defaultToken?: string) => Promise<CreatePortalResponse | undefined>;
  handleResetPassword: (username: string, code: string, newPassword: string) => Promise<any>;
  login: (email: string, password: string) => Promise<void>;
  forgotPassword: (email: string) => Promise<any>;
  logout: () => void;
  refreshMe: () => void;
  setErrorMessage: (errorMessage: string) => void;
  setUsername: (username: string) => void;
  checkUserIsAuth: () => Promise<boolean>;
  getCurrentUserId: () => string;
}

const initialState: AuthState = {
  token: undefined,
  refreshToken: undefined,
  errorMessage: '',
  avatar: '',
  user: undefined,
  username: '',
  isAuth: true,
  isLogginIn: false,
  isSending: false,
  isSended: false,
  isResetingPassword: false,
  resetedPassword: false,
  isGettingUser: false,
  isCreatingPortal: false,
  createCustomerPortal: async () => undefined,
  handleResetPassword: async () => false,
  login: async () => {},
  forgotPassword: async () => {},
  refreshMe: () => {},
  logout: async () => {},
  setErrorMessage: () => {},
  setUsername: () => {},
  checkUserIsAuth: async () => false,
  getCurrentUserId: () => ''
};

const AuthContext = React.createContext<AuthState>(initialState);

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthConsumer };

export type { AuthState };
