import { FeatureCollection } from 'geojson';
import React from 'react';
import { HistogramData } from '../../../components/IndicationTimeGraph/IndicationTimeGraph';
import { Composer, CompositionIndication, CompositionPlay, CompositionTopArtists, PollObj, Quiz, Track } from '../../../interfaces';

interface WhoMatches {
  quizes?: Quiz[];
  selectedQuizTopArtists?: CompositionTopArtists[] | undefined;
  isGettingQuizInfos?: boolean;
  activeQuiz?: Quiz;
  activeQuizPost?: PollObj;
  quizPlaysByDay?: CompositionPlay[];
  composers?: Composer[];
  quizIndicationsByDay?: CompositionIndication[];
  quizIndicationByMoment?: HistogramData | any;
  quizLocationIndications?: FeatureCollection;
  activeWhoMatchesTrack?: Track;
  whoMatchesPlayList: Track[];
  isGettingWhoMatchesPlayList: boolean;
  setActiveWhoMatchesTrack: (activeWhoMatchesTrack?: Track) => void;
  fetchQuizesByToken: (defaultToken?: string) => Promise<Quiz[] | undefined>;
  handleQuizSelect: (id: string, isPlay?: boolean) => Promise<any>;
}

const initialState: WhoMatches = {
  quizes: undefined,
  activeQuiz: undefined,
  isGettingWhoMatchesPlayList: false,
  whoMatchesPlayList: [],
  setActiveWhoMatchesTrack: () => {},
  fetchQuizesByToken: async () => undefined,
  handleQuizSelect: async () => {}
};

const WhoMatchesContext = React.createContext<WhoMatches>(initialState);

const WhoMatchesConsumer = WhoMatchesContext.Consumer;

export { WhoMatchesContext, WhoMatchesConsumer };

export type { WhoMatches };
