/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { MainContainer, Container } from './styles';
import DesktopSidebar from '../components/DesktopSidebar/DesktopSidebar';
import { CheckoutConsumer, CheckoutContext } from '../context/checkout';
import FooterWithButton from '../components/FooterWithButton/FooterWithButton';
import MobileSidebar from '../components/MobileSidebar/MobileSidebar';

import { PriceObj } from '../interfaces';
import Header from '../components/Header/Header';
import { AuthContext } from '../context';
import { getActiveProduct } from '../utils/checkoutHelpers';

type Props = {
  children: React.ReactNode;
  activePlan?: PriceObj;
  hideButton?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const DashboardContainer = ({ children, hideButton }: Props) => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const handleMenuButtonClick = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const { userSubscriptions, prices, getUserSubscriptions } = useContext(CheckoutContext);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const isFirstLogin = localStorage.getItem('IS_FIRST_LOGIN');
    if (!isFirstLogin) {
      localStorage.setItem('IS_FIRST_LOGIN', 'true');
    }
  }, []);

  useEffect(() => {
    (async () => {
      // if (!prices?.length) await getPrices();
      if (token) {
        if (!userSubscriptions?.length) {
          await getUserSubscriptions(token);
        }
      }
    })();
  }, [token]);

  const activePrice = getActiveProduct(userSubscriptions, prices);

  return (
    <CheckoutConsumer>
      {({ isGettingUserSubscriptions, isGettingPlan }) => (
        <MainContainer>
          <div id="page-wrap">
            <Container className="w-full flex flex-col h-screen overflow-hidden">
              <div className="relative">
                <MobileSidebar isMenuOpen={isMenuOpen} handleMenuButtonClick={handleMenuButtonClick} isDash activePrice={activePrice} />
                <Header activePrice={activePrice} isGettingInfos={isGettingPlan || isGettingUserSubscriptions} />
              </div>

              <div className="w-full overflow-hidden flex flex-1">
                <DesktopSidebar isDash activePrice={activePrice} />
                <section style={{ background: '#E5E5E5', position: 'relative' }} className="w-full overflow-auto px-0 pt-0 sm:pt-8 sm:px-8 pb-5">
                  {children}
                  <FooterWithButton hideButton={hideButton} />
                </section>
              </div>
            </Container>
          </div>
        </MainContainer>
      )}
    </CheckoutConsumer>
  );
};

export default DashboardContainer;
