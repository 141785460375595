import styled from 'styled-components';

export const Container = styled.div``;

export const RightColumn = styled.div`
  @media screen and (min-width: 1070px) {
    width: calc(100vw - 565px);
  }

  @media screen and (min-width: 1650px) {
    width: calc(100vw - 820px);
  }
`;
