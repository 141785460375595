/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext } from '../../context';

const Logout: React.FC = () => {
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    logout();
  }, []);

  return <Redirect to="/auth" />;
};

export default Logout;
