import { Axios, withAuth } from '../../../../utils/axios';

export const handleShutDownAudition = async (idAudition: string, defaultToken?: string) => {
  try {
    const response = await Axios(process.env.REACT_APP_NODE_ENV).put(`/v2/auditions/close/${idAudition}`, {}, withAuth(defaultToken));

    return { success: true, data: response };
  } catch (error) {
    console.error('Error on closing audition: ', error);
    return { success: false, data: error };
  }
};

export const getProjectData = async (idProject: string, defaultToken?: string) => {
  try {
    const response = await Axios(process.env.REACT_APP_NODE_ENV).get(
      `/v1/producer/project/${idProject}?resolve=["tags", "target_artists","lanes", "related_artists"]`,
      withAuth((localStorage.getItem('LOCAL_TOKEN') as string) || defaultToken)
    );

    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error on getting audition data: ', error);
    return { success: false, data: error };
  }
};
