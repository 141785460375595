/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Compositions from './Songwriter/Compositions/Compositions';
import BuildingPage from './Others/BuildingPage/BuildingPage';
import Artist from './Artist/Indications/Indications';
import Poll from './Poll/Poll';
import { CheckoutContext } from '../context/checkout';

import { AuthContext } from '../context';
import WhoMatches from './WhoMatches/WhoMatches';
import useQuery from '../utils/customHooks/useQuery';
import { MyProjects } from './Producer/MyProjects';
import { EditProject } from './Producer/EditProject';
import { NewProject } from './Producer/NewProject';
import { Kanban } from './Producer/Kanban';
import { NewAudition } from './Audition/NewAudition';
import { AuditionDetails } from './Audition/AuditionDetails';
import { AuditionList } from './Audition/AuditionList';

import ToastAnimated from '../components/Toast/Toast';

const DashboardRouter = React.memo(() => {
  const { getActualPlan, userActivePlan } = useContext(CheckoutContext);

  const { token } = useContext(AuthContext);

  const query = useQuery();

  const redirectToCustomerPortal = query.get('customer_portal');

  useEffect(() => {
    (async () => {
      if (getActualPlan && !redirectToCustomerPortal && token) await getActualPlan(token);
    })();
  }, [token]);

  return (
    <>
      <ToastAnimated />
      <Switch>
      <Route exact path="/dash/compositions">
          <Compositions activePlan={userActivePlan} />
        </Route>
        <Route exact path="/dash/building">
          <BuildingPage />
        </Route>
        <Route path="/dash/artist/">
          <Artist activePlan={userActivePlan} />
        </Route>
        <Route path="/dash/polls">
          <Poll activePlan={userActivePlan} />
        </Route>
        <Route path="/dash/who-matches">
          <WhoMatches activePlan={userActivePlan} />
        </Route>
        <Route path="/dash/producer/my-projects">
          <MyProjects activePlan={userActivePlan} />
        </Route>
        <Route path="/dash/producer/edit-project/:id">
          <EditProject activePlan={userActivePlan} />
        </Route>
        <Route path="/dash/producer/new-project">
          <NewProject activePlan={userActivePlan} />
        </Route>
        <Route path="/dash/producer/project/:id">
          <Kanban activePlan={userActivePlan} />
        </Route>

        <Route path="/dash/audition/new-audition">
          <NewAudition activePlan={userActivePlan} />
        </Route>
        <Route path="/dash/auditions/:id">
          <AuditionDetails activePlan={userActivePlan} />
        </Route>
        <Route path="/dash/auditions">
          <AuditionList activePlan={userActivePlan} />
        </Route>
        <Route path="/dash/auditions-closed">
          <AuditionList activePlan={userActivePlan} closed />
        </Route>
      </Switch>
    </>
  );
});

export default DashboardRouter;
