import styled from 'styled-components';

export const MainContainer = styled.div`
  a {
    position: relative;
  }

  a.active {
    color: #222;
  }

  a.active-nested {
    color: #ef233c;
  }

  a.active::before {
    content: '';
    left: 0;
    position: absolute;
    width: 2%;
    border-radius: 0 8px 8px 0;
    margin-top: -5px;
    height: 35px;
    background: #ef233c;
  }
`;

export const Container = styled.div`
  .bm-burger-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    padding: 0;
    top: 26px;
    left: 20px;
    color: #fff;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #fff;
    /* padding: 2.5em 1.5em 0; */
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .bm-item-list {
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    display: none;
  }

  @media (min-width: 1650px) {
    .header-logo {
      margin-left: 0;
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  background: #fff;
  height: fit-content;
  box-shadow: 0px 30px 40px -20px #e4e5e6;
  border-radius: 8px;
  display: flex;
  padding: 1.5rem;

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  button {
    img {
      animation: spinner infinite 1s linear;
      text-align: center;
    }
  }
`;

export const Collapse = styled.div`
  width: 100%;
  background: #fff;
  height: fit-content;
  box-shadow: 0px 30px 40px -20px #e4e5e6;
  display: flex;
  border-radius: 0px 0px 8px 8px;
  padding: 0 1.5rem 1.5rem 1.5rem;

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  button {
    img {
      animation: spinner infinite 1s linear;
      text-align: center;
    }
  }
`;
