/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import React, { useState, SyntheticEvent, useContext, useEffect } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import moment from 'moment';
import 'moment/locale/pt-br';
import CheckoutContainer from '../CheckoutContainer';
import BenefitsForm from '../../components/BenefitsForm/BenefitsForm';
import reaisConverter from '../../utils/simpleReaisConverter/reaisConverter';
import RectButton from '../../components/RectButton/RectButton';
import checkedIcon from '../../images/checked-green.svg';
import reloadIcon from '../../images/mpf-reload-white.svg';
import reloadIconBlack from '../../images/mpf-reload-black.svg';
import { CheckoutConsumer, CheckoutContext } from '../../context/checkout';
import { Container } from './styles';
import { Match } from '../../interfaces';
import Spinner from '../../components/Spinner/Spinner';
import { canTrial } from '../../utils/checkoutHelpers';
import useUserData from '../../utils/customHooks/useUserData';
import ToastAnimated, { showToast } from '../../components/Toast/Toast';
import { AuthContext } from '../../context/Auth';

const MakePayment: React.FC = () => {
  const user = useUserData();
  const [isAgree, setIsAgree] = useState(false);
  const [insertedCoupon, setInsertedCoupon] = useState<string>('');
  const [promiseError, setPromiseError] = useState<string | undefined>('');

  const { token } = useContext(AuthContext);

  const {
    selectedPrice,
    isValidCoupon,
    coupon,
    prices,
    userSubscriptions,
    customer,
    atachPaymentError,
    setAtachPaymentError,
    setAtachedPaymentMethod,
    getPrices,
    handleSelectPrice,
    setDiscountedPrice,
    atachPaymentMethod,
    setIsAtachingPaymentMethod,
    getUserSubscriptions,
    setIsChangingPaymentMethod
  } = useContext(CheckoutContext);

  const { params }: Match = useRouteMatch();
  const { priceId } = params;

  useEffect(() => {
    (async () => {
      if (token) {
        if (!selectedPrice) {
          if (!prices?.length) await getPrices();
        }
        if (!userSubscriptions?.length) await getUserSubscriptions(token);
      }
    })();
    if (prices?.length) {
      handleSelectPrice(priceId);
    }
  }, [prices, token]);

  useEffect(() => {
    if (isValidCoupon === 1) {
      if (selectedPrice?.price && coupon?.percent_off) {
        const discountedPrice = selectedPrice?.price * ((100 - coupon?.percent_off) / 100);
        setDiscountedPrice(discountedPrice);
      }
    }
    // eslint-disable-next-line
  }, [isValidCoupon]);

  useEffect(() => {
    setAtachedPaymentMethod(false);
    setIsAtachingPaymentMethod(false);
    setAtachPaymentError('');
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory();

  if (!priceId) {
    return <Redirect to="/checkout" />;
  }

  const handleGoBack = () => {
    history.push('/checkout');
  };

  const nameFormated = user?.name;

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    setPromiseError('');

    setIsChangingPaymentMethod(false);
    setIsAtachingPaymentMethod(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      // @ts-ignore
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: user?.name,
        email: user?.email
      }
    });

    if (payload.error) {
      console.error('payloadErro: ', payload.error);
      setPromiseError(payload.error.message);
      setIsAtachingPaymentMethod(false);
      console.error('payload erorr');
      showToast({
        type: 'error',
        message: 'Erro! revise os dados e tente novamente.'
      });
    } else if (payload.paymentMethod) {
      await atachPaymentMethod(payload.paymentMethod as any);
    }
  };

  // é true quando o id do produto selecionado não existe no vetor de id's de produtos testados do customer
  const userCanTrial = canTrial(customer, selectedPrice);

  const trialStart = moment().format('LL');

  const trialEnd = moment()
    .add((selectedPrice?.trial_days as number) / 30, 'M')
    .format('LL');

  return (
    <CheckoutConsumer>
      {({
        validateCoupon,
        setIsValidCoupon,
        isGettingUserSubscriptions,
        isGettingPrices,
        isValidCoupon,
        isAtachingPaymentMethod,
        atachedPaymentMethod,
        discountedPrice,
        isValidatingCoupon,
        isChangingPaymentMethod
      }) => {
        return (
          <CheckoutContainer hasGoBack goBackRoute="/checkout">
            <ToastAnimated />
            <Container>
              <div className="flex flex-col pb-4 px-5 sm:px-4 md:px-2 lg:px-2 xl:px-0">
                <div className="flex flex-col">
                  <span className="text-2xl pt-10">Realizar pagamento</span>
                  {selectedPrice && user && !isGettingUserSubscriptions ? (
                    <>
                      {!userCanTrial ? (
                        <span className="text-sm text-gray-600">
                          Insira as informações de pagamento e clique em continuar.
                        </span>
                      ) : selectedPrice.has_trial ? (
                        <span className="text-sm text-gray-600">
                          {`${nameFormated || null}`}
                          {nameFormated ? ', a' : 'A'}
                          tivando esse plano, você tem {selectedPrice.trial_days} dias de teste grátis. Não gostou?
                          Cancele a qualquer momento.
                        </span>
                      ) : (
                        <span className="text-sm text-gray-600">
                          Insira as informações de pagamento e clique em continuar.
                        </span>
                      )}

                      <div className="mt-8 flex flex-col lg:flex-row">
                        <div className="flex flex-col w-full items-center">
                          <BenefitsForm stripe={stripe} elements={elements} product={selectedPrice} />
                          {selectedPrice.has_trial && userCanTrial && (
                            <div className="bg-white w-full md:w-3/4 lg:w-full xl:w-full mt-6 shadow-xs rounded-sm p-8 flex flex-col justify-center">
                              <span className="text-base">
                                Por que preciso fornecer meus dados para aproveitar a demonstração gratuita?
                              </span>
                              <span className="text-sm text-gray-600 mt-4">
                                O seu período de teste gratuito começa hoje{' '}
                                <span className="text-black">{trialStart}</span> e termina em{' '}
                                {selectedPrice.trial_days && <span className="text-black">{trialEnd}</span>}. Cancele
                                quando quiser antes do dia{' '}
                                {selectedPrice.trial_days && <span className="text-black">{trialEnd}</span>} para evitar
                                cobrança. Nós enviaremos um lembrete por e-mail{' '}
                                <span className="text-black">7 dias antes</span> do término da sua demonstração
                                gratuita.Esta transação será cobrada em R$ (Reais).
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col mx-auto bg-white w-full md:w-3/4 lg:w-1/2 xl:w-1/2 lg:ml-6 mt-6 lg:mt-0 shadow-xs px-8 py-6 border-t-8 border-red-500">
                          <span className="text-2xl pb-4">Plano {selectedPrice.name}</span>
                          <p className="text-sm text-gray-600">
                            Será cobrado o valor total de{' '}
                            {isValidCoupon === 1 && discountedPrice !== undefined ? (
                              reaisConverter(discountedPrice)
                            ) : (
                              <span className="text-black">{selectedPrice && reaisConverter(selectedPrice.price)}</span>
                            )}{' '}
                            mensal até você cancelar sua assinatura. Pagamentos realizados anteriormente não serão
                            reembolsados quando você cancelar, a menos que haja obrigatoriedade legal.
                          </p>
                          <div className="flex justify-between border-gray-300 border-b-2 py-3 mt-6">
                            <span className="text-gray-600 text-sm">Período de cobrança</span>
                            <span className="text-sm">Mensal</span>
                          </div>
                          {selectedPrice.has_trial && userCanTrial && (
                            <>
                              <div className="flex justify-between border-gray-300 border-b-2 py-3">
                                <span className="text-gray-600 text-sm">Demonstração gratuita</span>
                                {selectedPrice.trial_days && (
                                  <span className="text-sm">
                                    {selectedPrice.trial_days <= 30
                                      ? `${selectedPrice?.trial_days / 30} mês`
                                      : `${selectedPrice?.trial_days / 30} meses`}
                                  </span>
                                )}
                              </div>
                              <div className="flex justify-between border-gray-300 border-b-2 py-3">
                                <span className="text-gray-600 text-sm">Após {selectedPrice.trial_days} dias</span>
                                {selectedPrice.has_trial ? (
                                  isValidCoupon === 1 && discountedPrice !== undefined ? (
                                    <span className="text-sm">{reaisConverter(discountedPrice)}</span>
                                  ) : (
                                    <span className="text-sm">
                                      {selectedPrice && reaisConverter(selectedPrice.price)}
                                    </span>
                                  )
                                ) : (
                                  <span className="text-sm">
                                    {selectedPrice && reaisConverter(selectedPrice.price)}
                                  </span>
                                )}
                              </div>
                            </>
                          )}
                          <div className="flex flex-col sm:flex-row justify-between items-center border-gray-300 border-b-2 py-3">
                            <span className="text-gray-600 text-sm">Tem cupom?</span>
                            <div className="flex">
                              {isValidCoupon === 0 && (
                                <>
                                  <input
                                    value={insertedCoupon}
                                    onChange={(e) => setInsertedCoupon(e.target.value)}
                                    type="text"
                                    className="p-2 text-sm border-gray-400 border-2 h-8 w-32 rounded-md"
                                    placeholder="digite o cupom"
                                  />
                                  <RectButton
                                    className="w-10 rounded-md ml-2 flex items-center justify-center text-center"
                                    theme={insertedCoupon.length ? 'outline' : 'disabled'}
                                    style={{
                                      border: insertedCoupon.length ? '1px solid #202020' : 'none',
                                      padding: '0'
                                    }}
                                    onClick={() => validateCoupon(insertedCoupon)}
                                  >
                                    {!isValidatingCoupon ? (
                                      <>OK</>
                                    ) : (
                                      <img
                                        style={{ margin: 0 }}
                                        className="spin"
                                        src={reloadIconBlack}
                                        alt="reload-icon"
                                      />
                                    )}
                                  </RectButton>
                                </>
                              )}
                              {insertedCoupon.length !== 0 && isValidCoupon === -1 && (
                                <span className="text-xs text-red-500">
                                  Cupom inválido,{' '}
                                  <span className="cursor-pointer underline" onClick={() => setIsValidCoupon(0)}>
                                    tentar novamente
                                  </span>
                                </span>
                              )}
                              {insertedCoupon.length !== 0 && isValidCoupon === 1 && discountedPrice !== undefined && (
                                <div className="flex items-center">
                                  <img className="w-5 h-5" src={checkedIcon} alt="checked-icon" />
                                  <span style={{ color: '#817B7B' }} className="ml-2 mr-2 sm:mr-4 text-xs">
                                    Cupom aplicado
                                  </span>
                                  <span
                                    style={{ color: '#202020' }}
                                    className="text-right text-base font-semibold leading-6"
                                  >
                                    -{reaisConverter(selectedPrice.price - discountedPrice)}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="flex justify-between py-3">
                            <span className="text-base">Total para hoje</span>
                            {selectedPrice.has_trial && userCanTrial ? (
                              <span className="font-bold text-base">{reaisConverter(selectedPrice.trial_price)}</span>
                            ) : isValidCoupon === 1 && discountedPrice !== undefined ? (
                              <span className="font-bold text-base">{reaisConverter(discountedPrice)}</span>
                            ) : (
                              <span className="font-bold text-base">
                                {selectedPrice && reaisConverter(selectedPrice.price)}
                              </span>
                            )}
                          </div>
                          <div className="flex items-start mt-auto">
                            <input
                              type="checkbox"
                              checked={isAgree}
                              onChange={() => setIsAgree((prevState) => !prevState)}
                            />
                            <span className="text-sm text-gray-600 ml-2" style={{ marginTop: '-5px' }}>
                              Eu concordo com os{' '}
                              <a
                                href="https://static.musicplayce.com/assets/terms_of_use.pdf"
                                target="blank"
                                className="no-underline text-blue-500"
                              >
                                Termos da MusicPlayce
                              </a>{' '}
                              e com os{' '}
                              <a
                                href="https://musicplayce.com.br"
                                target="blank"
                                className="no-underline text-blue-500"
                              >
                                Termos de renovação automática
                              </a>{' '}
                              acima.
                            </span>
                          </div>
                          <div className="flex justify-between items-center mt-8">
                            <RectButton
                              theme={isAgree ? 'success' : 'disabled'}
                              className="w-1/2 flex items-center justify-center"
                              onClick={(event) => handleSubmit(event)}
                            >
                              {!isAtachingPaymentMethod && !atachedPaymentMethod && 'Continuar'}
                              {isAtachingPaymentMethod && !atachedPaymentMethod && (
                                <>
                                  Carregando...
                                  <img className="spin" src={reloadIcon} alt="spinner-icon" />
                                </>
                              )}
                              {!isAtachingPaymentMethod && atachedPaymentMethod && !isChangingPaymentMethod && (
                                <Redirect to={`/checkout/confirm-plan/${priceId}`} />
                              )}
                            </RectButton>

                            <RectButton
                              theme="outline"
                              style={{ border: '1px solid #202020' }}
                              className="w-1/2 ml-2"
                              onClick={handleGoBack}
                            >
                              Voltar
                            </RectButton>
                          </div>
                          {promiseError === '' &&
                            (atachPaymentError !== '' ? (
                              <span className="text-red-500 text-xs mt-1">{atachPaymentError}</span>
                            ) : null)}
                          {promiseError !== '' && <span className="text-red-500 text-xs mt-1">{promiseError}</span>}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex mt-8 items-center justify-center">
                      <Spinner isLoading={isGettingPrices || !user || isGettingUserSubscriptions} />
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </CheckoutContainer>
        );
      }}
    </CheckoutConsumer>
  );
};

export default MakePayment;
