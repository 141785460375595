import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import RectButton from '../../../../../components/RectButton/RectButton';

export const NewLaneForm: React.FC = (props: any) => {
  const intl = useIntl();

  const [inputText, setInputText] = useState('');

  const { onAdd, onCancel } = props;

  return (
    <div className="flex flex-col p-4 rounded-md bg-white shadow-lg" style={{ marginTop: 5, marginLeft: 5 }}>
      <input
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputText(e.target.value)}
        value={inputText}
        className="bg-gray-200 rounded p-2 text-sm mb-6"
        placeholder={intl.formatMessage({ id: 'dashboard.my-projects.new-lane-form.column-name', defaultMessage: 'Digite o nome da coluna' })}
      />
      <RectButton theme="success" onClick={() => onAdd({ title: inputText || 'Nova Coluna' })} style={{ padding: 6, backgroundColor: 'black' }}>
        <FormattedMessage id="dashboard.my-projects.new-lane-form.create" defaultMessage="Criar" />
      </RectButton>
      <RectButton theme="outline" onClick={onCancel} style={{ padding: 6, marginTop: 5, border: '1px solid black', height: 33 }}>
        <FormattedMessage id="dashboard.my-projects.new-lane-form.cancel" defaultMessage="Cancelar" />
      </RectButton>
    </div>
  );
};
