import styled from 'styled-components';

type Props = {
  isAuditionMenuActive?: boolean;
};

export const Container = styled.div`
  .wrapper {
    display: none;
  }

  .coming-soon {
    border: 1px solid #1db954;
    box-sizing: border-box;
    border-radius: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 10px;
    text-align: center;
    text-transform: uppercase;
    color: #1db954;
    padding: 3px 8px;
    margin-left: 10px;
  }

  @media (min-width: 1650px) {
    .wrapper {
      display: flex;
    }
  }

  .options {
    display: ${(props: Props) => (props.isAuditionMenuActive ? 'flex' : 'none')};
    flex-direction: column;
    margin-left: 60px;
    font-size: 14px;
    line-height: 26px;

    a:first-child {
      margin: 10px 0;
    }

    a.active {
      color: #ef233c;
    }

    a.active::before {
      content: '';
      background: none;
    }
  }
`;
