import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { IoIosSearch } from 'react-icons/io';
import { FormattedMessage, useIntl } from 'react-intl';
import { Audition } from '../../../../interfaces';
import { DataTableComponent } from '../components/DataTable';

import { SearchInput } from '../styles';

import { getAuditions, filter, search } from '../services';
import { showToast } from '../../../../components/Toast/Toast';

const loadAuditions = async (
  setIsLoading: (param: boolean) => void,
  setAuditions: (param: any) => void,
  pagination: any,
  setPagination: (param: any) => void,
  page: number,
  limit: number,
  all?: boolean
) => {
  setIsLoading(true);

  let query: string | any = {
    page: page || pagination.page,
    limit: limit || pagination.limit,
    sortField: 'datetime_created',
    sortOrder: 'DESC'
  };

  query = qs.stringify(query);

  const res = await getAuditions(query);

  if (!res.success) {
    showToast({ type: 'error', message: 'Erro ao carregar a lista de audições. Recarregue a página para tentar novamente.' });
    setIsLoading(false);
    return;
  }

  setAuditions(res?.data?.data?.docs?.filter((item: any) => item.status === 'closed'));
  setPagination({
    ...pagination,
    totalDocs: res.data.data.totalDocs,
    page: res.data.data.page
  });
  setIsLoading(false);
};

const searchAuditions = async (
  title: string,
  setIsLoading: (param: boolean) => void,
  setAuditions: (param: any) => void
) => {
  setIsLoading(true);

  const res = await search(title, 'CLOSED');

  if (!res.success) {
    showToast({ type: 'error', message: 'Erro ao carregar a lista de audições. Recarregue a página para tentar novamente.' });
    setIsLoading(false);
    return;
  }

  setAuditions(res?.data?.data);
  setIsLoading(false);
};

const AuditionListClosed: React.FC = () => {
  const [auditions, setAuditions] = useState<Audition[] | any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputText, setInputText] = useState('');

  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    totalDocs: 0,
    limit: 10
  });

  const intl = useIntl();

  const [startSearching, setStartSearching] = useState<any>(null);

  const filteredItems = filter(auditions, inputText);

  useEffect(() => {
    loadAuditions(setIsLoading, setAuditions, pagination, setPagination, 1, 99999);
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (page: number, _: number) => {
    loadAuditions(setIsLoading, setAuditions, pagination, setPagination, page, pagination.limit);
  };

  const handleChangeRowsPerPage = (currentRowsPerPage: number, currentPage: number) => {
    setPagination({ ...pagination, limit: currentRowsPerPage });
    loadAuditions(setIsLoading, setAuditions, pagination, setPagination, currentPage, currentRowsPerPage);
  };

  async function loadData() {
    if (inputText.length) {
      await searchAuditions(inputText ,setIsLoading, setAuditions);
      return true;
    }
    await loadAuditions(setIsLoading, setAuditions, pagination, setPagination, 1, 10);
  }

  return (
    <div className="w-full p-4 sm:p-0 flex flex-col">
      <span className="font-semibold text-2xl">
        <FormattedMessage id="dashboard.audition.list-closed.main-title" defaultMessage="Audições Encerradas" />
      </span>
      <div
        className="flex flex-col sm:flex-row rounded-md shadow-lg w-full justify-between items-center bg-white mt-8"
        style={{ padding: '20px 25px' }}
      >
        <div className="relative flex items-center w-full sm:w-auto">
          <SearchInput
            type="text"
            placeholder={intl.formatMessage({
              id: 'dashboard.audition.list-open.search-input-placeholder',
              defaultMessage: 'Buscar audição'
            })}
            onKeyUp={(e) => {
              setAuditions([]);
              clearTimeout(startSearching);
              setStartSearching(
                setTimeout(() => {
                  loadData();
                }, 1000)
              );
            }}
            className="w-full sm:w-auto"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputText(e.target.value)}
            value={inputText}
          />
          <IoIosSearch size={20} color="#ACACAC" className="absolute ml-2" />
        </div>
      </div>
      <DataTableComponent
        isLoading={isLoading}
        data={filteredItems}
        isListingActives={false}
        paginationTotalRows={pagination.totalDocs}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default AuditionListClosed;
