import styled from 'styled-components';

export const SearchInput = styled.input`
  /* Surface / 200 */

  background: #f5f5f5;
  /* Surface / 300 */

  border: 1px solid #e4e5e6;
  border-radius: 4px;
  padding: 6.5px 6.5px 6.5px 35px;
`;
