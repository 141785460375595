import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import songIcon from '../../../images/song.svg';
import { Audition, PriceObj } from '../../../interfaces';
import DashboardContainer from '../../DashboardContainer';
import { UploadModal } from './components';
import { Card } from '../../styles';
import RectButton from '../../../components/RectButton/RectButton';
import Tags from '../../../components/Tags/Tags';
import { SongsSection, VideoContent, SpinnerContainer, PaginateContainer } from './styles';
import { KanbanContext } from '../../../context/dash/producer/kanban';
import { AuthContext } from '../../../context';
import Toast, { showToast } from '../../../components/Toast/Toast';
import blocked from '../../../images/blocked.svg';
import blockedWhite from '../../../images/blocked-white.svg';
import crown from '../../../images/crown.svg';
import personListening from '../../../images/person-listening.svg';
import iconArrowDown from '../../../images/icon-down-arrow.svg';
import noMusicImg from '../../../images/no-music.svg';
import MusicOutsideAudition from './components/MusicOutsideAudition/MusicOutsideAudition';
import MusicInsideAudition from './components/MusicInsideAudition/MusicInsideAudition';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';
import reloadIcon from '../../../images/mpf-reload-white.svg';
import { handleAddPostToAudition, getAuditionsPosts, getAuditionDetails } from './services';
import EngagementModal from './components/EngagementModal/EngagementModal';
import Spinner from '../../../components/Spinner/Spinner';
import Message from '../../../components/Message/Message';
import YouTube from 'react-youtube';
import ReactPaginate from 'react-paginate';

// import AuditionDetailsTour from '../components/AuditionDetailsTour/AuditionDetailsTour';

const isFirstLogin = localStorage.getItem('IS_FIRST_LOGIN');

type Props = {
  activePlan?: PriceObj;
};

interface PostSubmitted {
  _id: string;
  name: string;
  plays: number;
  indications: number;
  shares: number;
  classification: number;
  datetime_submitted: string;
}

interface PostNotSubmitted {
  _id: string;
  name: string;
  plays: number;
  indications: number;
  shares: number;
}

type Params = {
  id: string;
};

export const AuditionDetails: React.FC<Props> = ({ activePlan }: any) => {
  const history = useHistory();
  const { id: audition_id } = useParams<Params>();
  const { handleUploadFile, reloadBoard } = useContext(KanbanContext);
  const [isUploadModalOpen, setOpenUploadModal] = useState(false);
  const [auditionData, setAuditionData] = useState<Audition | undefined>(undefined);
  const [auditionTags, setAuditionTags] = useState<string[]>([]);
  const [isGettingAuditionDetails, setIsGettingAuditionDetails] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isSendingToAudition, setIsSendingToAudition] = useState<boolean>(false);
  const [postToSend, setPostToSend] = useState<any | undefined>(undefined);
  const [engagementModal, setShowEngagementModal] = useState<boolean>(isFirstLogin === 'true' ? true : false);
  const [submittedPosts, setSubmittedPosts] = useState<PostSubmitted[]>([]);
  const [notSubmittedPosts, setNotSubmittedPosts] = useState<PostNotSubmitted[]>([]);
  const [isGettingAuditionPosts, setIsGettingAuditionPosts] = useState<boolean>(false);
  const [reloadPosts, setReloadPosts] = useState<boolean>(false);

  const { token } = useContext(AuthContext);

  const BETA_BEGGINER_PRICE_ID = '5f3db5ed0bbae19bb46f573e';
  const PROD_BEGGINER_PRICE_ID = '5f6c29669d3957ac67ae9637';

  useEffect(() => {
    if (activePlan) {
      if (activePlan?._id === (process.env.REACT_APP_NODE_ENV === 'prod' ? PROD_BEGGINER_PRICE_ID : BETA_BEGGINER_PRICE_ID)) {
        setIsBlocked(true);
      }
    }
  }, [activePlan]);

  useEffect(() => {
    (async () => {
      if (token && audition_id) {
        setIsGettingAuditionDetails(true);
        const audition = await getAuditionDetails(audition_id, token);
        setIsGettingAuditionDetails(false);

        if (audition.success) {
          setAuditionData(audition?.data?.data);

          const tags: string[] = [];
          audition?.data?.data?.id_audition_tags?.map((tag: any) => {
            return tags.push(tag as string);
          });
          setAuditionTags(tags);
        }
      }
    })();
    // eslint-disable-next-line
  }, [token, audition_id]);

  useEffect(() => {
    (async () => {
      if (token && audition_id) {
        setReloadPosts(false);
        setIsGettingAuditionPosts(true);
        const { success, data } = await getAuditionsPosts(audition_id, token);
        setIsGettingAuditionPosts(false);

        if (success) {
          setSubmittedPosts(data.data.submitted);
          setNotSubmittedPosts(data.data.notSubmitted);
        }
      }
    })();
    // eslint-disable-next-line
  }, [token, reloadPosts, audition_id]);

  const formatedValue = (value: number) => {
    let newValue: string;

    if (value >= 1000) {
      newValue = `${(value / 1000).toFixed(1).replace('.', ',')}K`;
      return newValue;
    } else {
      return value;
    }
  };

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const handleSelectPost = (postId: string) => {
    toggleModal();
    const selectedPost = notSubmittedPosts?.find((item: any) => item._id === postId);
    setPostToSend(selectedPost);
  };

  const handleSendPost = async (postId: string) => {
    setIsSendingToAudition(true);
    if (auditionData && token) {
      const resp = await handleAddPostToAudition(postId, auditionData?._id, token);

      if (resp.success) {
        setReloadPosts(true);
        toggleModal();
        showToast({
          type: 'success',
          message: 'Guia enviada para a audição com sucesso.'
        });
        setIsSendingToAudition(false);
      } else {
        toggleModal();
        showToast({
          type: 'error',
          message: 'Falha ao enviar a guia para a audição. Tente novamente.'
        });
        setIsSendingToAudition(false);
      }
    }
  };

  const toggleEngagementModal = () => setShowEngagementModal(!engagementModal);

  const handleEngagementMsgOff = () => {
    localStorage.setItem('IS_FIRST_LOGIN', 'false');
    toggleEngagementModal();
  };

  const onReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const url = auditionData?.video_url;
  let videoId = url?.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
  let validVideoId;
  if (videoId != null) {
    validVideoId = videoId[1];
  } else {
    validVideoId = undefined;
  }

  const [currentPageSubmittedPosts, setCurrentPageSubmittedPosts] = useState(0);
  const [currentPageNotSubmittedPosts, setCurrentPageNotSubmittedPosts] = useState(0);
  const PER_PAGE = 10;
  const offsetSubmittedPosts = currentPageSubmittedPosts * PER_PAGE;
  const offsetNotSubmittedPosts = currentPageNotSubmittedPosts * PER_PAGE;

  const currentPageDataSubmittedPosts = submittedPosts?.slice(offsetSubmittedPosts, offsetSubmittedPosts + PER_PAGE);
  const currentPageDataNotSubmittedPosts = notSubmittedPosts?.slice(offsetNotSubmittedPosts, offsetNotSubmittedPosts + PER_PAGE);
  const pageCountSubmittedPosts = Math.ceil(submittedPosts?.length / PER_PAGE);
  const pageCountNotSubmittedPosts = Math.ceil(notSubmittedPosts?.length / PER_PAGE);

  const handlePageClickSubmitted = ({ selected: selectedPage }: any) => {
    setCurrentPageSubmittedPosts(selectedPage);
  };

  const handlePageClickNotSubmitted = ({ selected: selectedPage }: any) => {
    setCurrentPageNotSubmittedPosts(selectedPage);
  };

  return activePlan ? (
    <>
      {/* <AuditionDetailsTour /> */}
      <EngagementModal isOpen={engagementModal} toggleModal={toggleEngagementModal}>
        <RectButton onClick={handleEngagementMsgOff} theme="dark">
          Ok, entendi!
        </RectButton>
      </EngagementModal>
      <SpinnerContainer>
        <ConfirmationModal isOpen={modalIsOpen} post={postToSend} audition={auditionData} toggleModal={toggleModal}>
          <RectButton
            onClick={() => handleSendPost(postToSend?._id)}
            style={{ maxWidth: '210px', height: '45px' }}
            className="w-full order-2 mb-5 md:mb-0 flex items-center justify-center"
            theme="success"
          >
            {isSendingToAudition ? <img className="spinner" src={reloadIcon} alt="reload-icon" /> : 'Confirmar envio'}
          </RectButton>
          <RectButton
            onClick={toggleModal}
            style={{ maxWidth: '210px', height: 45, border: '1px solid black' }}
            className="w-full order-1"
            theme="outline"
          >
            Cancelar
          </RectButton>
        </ConfirmationModal>
      </SpinnerContainer>
      <Toast />
      <UploadModal
        uploadFunction={handleUploadFile}
        reloadBoardFunction={() => reloadBoard(auditionData?._id as string)}
        projectId={auditionData?._id as string}
        closeFunction={() => setOpenUploadModal(false)}
        isOpen={isUploadModalOpen}
      />
      <DashboardContainer activePlan={activePlan} hideButton>
        {isGettingAuditionDetails ? (
          <div className="w-full h-full flex flex-1 justify-center items-center">
            <Spinner isLoading={isGettingAuditionDetails} />
          </div>
        ) : (
          <div className="w-full p-4 sm:p-0">
            <div id="title" className="w-full flex flex-row justify-between">
              <span className="font-semibold text-2xl flex items-center">
                <img className="mr-3" src={songIcon} alt="song icon" />
                {auditionData?.title}
              </span>
            </div>
            <div className="flex flex-col">
              <Card id="details" className="flex flex-col mt-5 py-8 px-6">
                <div className="flex flex-row border-b-2 border-gray-200 mb-4  pb-6">
                  <div className="flex flex-col mr-16">
                    <span className="text-gray-500 text-sm flex flex-row">
                      {isBlocked && <img className="mr-2" src={blocked} alt="Blocked" />}
                      <FormattedMessage id="sidebar.audition.details.main-artist" defaultMessage="Artista principal" />
                    </span>
                    <div className="mt-4 font-semibold">
                      {isBlocked
                        ? auditionData?.target_artist?.name
                          ? auditionData?.target_artist?.name.replace(/[\w]/g, '*')
                          : '***********'
                        : auditionData?.target_artist
                        ? auditionData?.target_artist?.name
                        : 'Nenhum artista principal'}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-500 text-sm">
                      <FormattedMessage id="sidebar.audition.details.related-artists" defaultMessage="Artistas relacionados" />
                    </span>
                    <div className="flex flex-row">
                      {auditionData && auditionData?.related_artists.length > 0 ? (
                        auditionData?.related_artists.map((artist: any, idx: any) => {
                          return (
                            <div key={idx} className="mt-4 font-semibold mr-6">
                              {artist.name}
                            </div>
                          );
                        })
                      ) : (
                        <span className="mt-4 font-semibold mr-6">
                          <FormattedMessage id="sidebar.audition.details.related-artists.none" defaultMessage="Nenhum artista relacionado" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col text-sm my-2">
                  <div className="flex flex-col lg:flex-row">
                    <div className="flex flex-col mr-8 md:mr-20 lg:mr-20 w-full lg:w-2/3 ">
                      <span className="text-gray-500 text-sm">
                        <FormattedMessage id="sidebar.audition.details.briefing" defaultMessage="Briefing da audição" />
                      </span>
                      <div className=" mt-4 text-sm text-justify">{auditionData?.briefing}</div>
                    </div>

                    <VideoContent
                      style={{ height: '300px' }}
                      className="flex flex-col justify-center w-full lg:w-1/3 items-start my-4 lg:my-0 rounded"
                    >
                      <span className="text-gray-500 text-sm flex flex-row mb-4">
                        {isBlocked && <img className="mr-2" src={blocked} alt="Blocked" />}
                        <FormattedMessage id="sidebar.audition.details.video" defaultMessage="Vídeo" />
                      </span>
                      {auditionData?.video_url ? (
                        <div className={`w-full lg:minWidth-400 relative`} style={{ height: '300px' }}>
                          {isBlocked && (
                            <div style={{ position: 'absolute', zIndex: 9999 }} className="w-full h-full flex flex-1">
                              <img
                                style={{ position: 'absolute', zIndex: 9999, left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
                                className="mr-2"
                                src={blockedWhite}
                                alt="BlockedWhite"
                              />
                            </div>
                          )}
                          <YouTube
                            videoId={validVideoId}
                            className={`border-radius ${isBlocked ? 'blur' : ''}`}
                            containerClassName="w-full h-full flex flex-1"
                            opts={{
                              width: '100%',
                              height: '100%',
                              playerVars: {
                                autoplay: 0
                              }
                            }}
                            onReady={onReady}
                          />
                        </div>
                      ) : isBlocked ? (
                        <div style={{ position: 'relative', height: '300px' }} className="w-full h-full flex flex-1 bg-black">
                          <img
                            style={{ position: 'absolute', zIndex: 9999, left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
                            className="mr-2"
                            src={blockedWhite}
                            alt="BlockedWhite"
                          />
                        </div>
                      ) : (
                        <div style={{ border: '1px solid #CFCFCF' }} className="w-full rounded p-3">
                          <Message type="notFound" message="Nenhum vídeo encontrado" isKanban />
                        </div>
                      )}
                    </VideoContent>
                  </div>
                  <div className="flex flex-col mr-16 w-56 sm:w-1/2">
                    <span className="text-gray-500 text-sm flex flex-row">
                      {isBlocked && <img className="mr-2" src={blocked} alt="Blocked" />}
                      <FormattedMessage id="sidebar.audition.details.tags" defaultMessage="Tags da audição" />
                    </span>
                    <div className="mt-4 text-sm">
                      <Tags
                        clickable={false}
                        data={
                          auditionTags?.length
                            ? auditionTags.map((t: string) => {
                                return isBlocked ? t.replace(/[\w]/g, '*') : t;
                              })
                            : isBlocked
                            ? ['*******']
                            : ['Nenhuma tag encontrada']
                        }
                      />
                    </div>
                  </div>
                </div>
              </Card>
              <SongsSection>
                {isBlocked ? (
                  <Card id="is-blocked" className="flex flex-col mt-5 pt-8 px-6 items-center">
                    <div className="blocked-content">
                      <div className="flex flex-row">
                        <span className="text-lg flex flex-row">
                          <img className="mr-2" src={crown} alt="Crown" />
                          <FormattedMessage id="sidebar.audition.blocked.title" defaultMessage="Ative o plano Compositor Profissional" />
                        </span>
                      </div>
                      <div className="flex flex-row mb-4">
                        <span className="text-gray-500 text-sm text-center flex flex-row">
                          <FormattedMessage
                            id="sidebar.audition.blocked.subtitle"
                            defaultMessage="para visualizar todas as informações e enviar guias para esta Audição Digital"
                          />
                        </span>
                      </div>
                      <div className="mb-4">
                        <RectButton theme="success" onClick={() => history.push('/checkout')}>
                          <FormattedMessage id="dashboard.audition.blocked.create.button" defaultMessage="Criar conta compositor profissional" />
                        </RectButton>
                      </div>
                      <div>
                        <img className="mr-2" src={personListening} alt="person-listening" />
                      </div>
                    </div>
                  </Card>
                ) : (
                  <div
                    style={{ boxShadow: 'box-shadow: 0px 30px 40px -20px #E4E5E6', color: '#817B7B' }}
                    className="w-full mt-8 bg-white rounded-lg flex flex-col p-8"
                  >
                    <div id="audition-tracks-in">
                      <div className="flex mb-8">
                        <img src={iconArrowDown} alt="icon-arrow-down" className="mr-2" />
                        <span style={{ color: '#ACACAC' }} className="text-xl leading-7">
                          Suas guias nesta Audição
                        </span>
                      </div>
                      {currentPageDataSubmittedPosts?.length ? (
                        <>
                          <div className="w-full whitespace-no-wrap overflow-y-auto">
                            {currentPageDataSubmittedPosts?.map(({ name, plays, _id, shares, classification, datetime_submitted, indications }) => (
                              <MusicInsideAudition
                                key={_id}
                                name={name}
                                plays={plays}
                                shares={shares}
                                formatedValue={formatedValue}
                                indications={indications}
                                datetime_submitted={datetime_submitted}
                                classification={classification}
                              />
                            ))}
                          </div>
                          {currentPageDataSubmittedPosts.length > 10 && (
                            <PaginateContainer className="flex items-center justify-center my-8 w-full">
                              <ReactPaginate
                                previousLabel={'←'}
                                nextLabel={'→'}
                                containerClassName={'pagination flex space-x-8'}
                                previousLinkClassName={'pagination__link'}
                                nextLinkClassName={'pagination__link'}
                                disabledClassName={'pagination__link--disabled'}
                                activeClassName={'pagination__link--active'}
                                pageCount={pageCountSubmittedPosts}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageClickSubmitted}
                              />
                            </PaginateContainer>
                          )}
                        </>
                      ) : (
                        <div
                          style={{ border: '1px solid #E4E5E6', color: '#ACACAC' }}
                          className="w-full flex flex-col items-center justify-center mb-3 px-8 pt-12 pb-10 rounded text-center"
                        >
                          <img src={noMusicImg} alt="no-music-img" className="mb-6" />
                          <p style={{ fontSize: '28px' }} className="leading-9 font-semibold">
                            Você ainda não enviou guias para esta Audição Digital
                          </p>
                          <p style={{ maxWidth: '670px', fontSize: 16 }} className="text-base leading-5 mt-2">
                            Na sua lista de guias já publicadas, logo abaixo, clique no botão ”Enviar para audição” na guia que gostaria de enviar
                            para esta Audição Digital
                          </p>
                        </div>
                      )}
                    </div>
                    <div id="audition-tracks-out" style={{ height: isGettingAuditionPosts ? '300px' : 'auto' }} className="w-full flex flex-col">
                      <div className="flex mb-8 mt-5">
                        <img src={iconArrowDown} alt="icon-arrow-down" className="mr-2" />
                        <span style={{ color: '#ACACAC' }} className="text-xl leading-7">
                          Suas guias que estão fora desta Audição
                        </span>
                      </div>
                      {!isGettingAuditionPosts ? (
                        <>
                          <div className={`w-full ${notSubmittedPosts.length && 'whitespace-no-wrap'} overflow-x-auto`}>
                            {currentPageDataNotSubmittedPosts.length ? (
                              currentPageDataNotSubmittedPosts?.map(({ name, plays, indications, shares, _id }) => (
                                <MusicOutsideAudition
                                  key={_id}
                                  postId={_id}
                                  formatedValue={formatedValue}
                                  name={name}
                                  plays={plays}
                                  indications={indications}
                                  shares={shares}
                                  handleSelectPost={handleSelectPost}
                                />
                              ))
                            ) : (
                              <div className="w-full rounded p-8" style={{ border: '1px solid #E4E5E6' }}>
                                <Message type="notFound" message="Nenhuma guia encontrada" isKanban />
                              </div>
                            )}
                          </div>
                          <div>
                            {currentPageDataNotSubmittedPosts.length > 10 && (
                              <PaginateContainer className="flex items-center justify-center my-8 w-full">
                                <ReactPaginate
                                  previousLabel={'←'}
                                  nextLabel={'→'}
                                  containerClassName={'pagination flex space-x-8'}
                                  previousLinkClassName={'pagination__link'}
                                  nextLinkClassName={'pagination__link'}
                                  disabledClassName={'pagination__link--disabled'}
                                  activeClassName={'pagination__link--active'}
                                  pageCount={pageCountNotSubmittedPosts}
                                  pageRangeDisplayed={2}
                                  marginPagesDisplayed={2}
                                  onPageChange={handlePageClickNotSubmitted}
                                />
                              </PaginateContainer>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="w-full h-full flex flex-1 justify-center items-center">
                          <Spinner isLoading={isGettingAuditionPosts} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </SongsSection>
            </div>
          </div>
        )}
      </DashboardContainer>
    </>
  ) : null;
};
