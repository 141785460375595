import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  onClick?: () => void;
};

export const DashedCard: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      type="button"
      className="flex items-center justify-center"
      onClick={onClick}
      style={{ border: '2px dashed #0D65FF', borderRadius: 8, height: 109, marginTop: 18, width: 250, marginLeft: 5 }}
    >
      <span className="font-semibold text-xl text-blue-500">
        <FormattedMessage id="dashboard.my-projects.dashed-card.new-column" defaultMessage="Adicionar coluna" />
      </span>
    </button>
  );
};
