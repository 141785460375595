import React from 'react';
import CheckoutContainer from '../CheckoutContainer';

import PlansChoiceCards from '../../components/PlansChoiceCards/PlansChoiceCards';

const PlanChoice = () => {
  return (
    <CheckoutContainer goBackRoute="/" hasGoBack>
      <PlansChoiceCards />
    </CheckoutContainer>
  );
};

export default PlanChoice;
