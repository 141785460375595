import styled from 'styled-components';

export const ContainerGraph = styled.div`
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  div.recharts-wrapper {
    width: 300px !important;

    @media (min-width: 768px) {
      & {
        width: 800px;
      }
    }
  }
`;
