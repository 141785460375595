import styled from 'styled-components';


export const Container = styled.div`

.w-68 {
        max-width: 250px;
} 

.max-w-plan-type {
  max-width: 80px;
}

.mb-14 {
margin-bottom: 1rem;
}

.mb-15 {
  margin-bottom: 3.75rem;
}

.typeform-share.button {
  display:flex;
  align-items: center;
  justify-content: center;
  text-decoration:none;
  background-color:#EF233C;
  color:white;
  cursor:pointer;
  text-align:center;
  margin: 32px 0 16px;
  height:50px;
  padding:17px 33px;
  border-radius:4px;
  width: 100%;
  font-weight:bold;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;

}

@media (min-width: 425px) {
 
 .max-w-plan-type {
   max-width: 150px;
 }

}

@media (min-width: 640px) {
 
  .mr-23 {
  margin-right: 5.625rem; 
  }
  .mb-14 {
  margin-bottom: 3.625rem;

}

}

@media (min-width: 1020px) {
    .w-68 {
        max-width: 286px;
    }
}

`;
