/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IoIosSearch } from 'react-icons/io';
import moment from 'moment';
import { Container, PostContainer } from './styles';
import { PollData, Quiz, Poll, Composition } from '../../interfaces';
import MPlayButton from '../../images/mpf-play-button.svg';
import MPlayButtonWhite from '../../images/mpf-play-button-white.svg';
import MPlaylist from '../../images/mpf-playlist.svg';
import MPEye from '../../images/mpf-eye.svg';
import { ArtistPost } from './ArtistPost';
import { AuthContext, ComposerDashContext, WhoMatchesContext } from '../../context';
import { DashContext } from '../../context/dash/Context';
import Spinner from '../Spinner/Spinner';
import Message from '../Message/Message';
import { formatTimestamp } from '../../utils/firebase';

// TODO: foto azul quando estiver tocando no player.

type Props = {
  className?: string;
  style?: React.CSSProperties;
  isPoll?: boolean;
  isWhoMatches?: boolean;
  restTime?: string;
  isFinished?: boolean;
  inputValue: string;
  compositionData?: Composition[];
  pollData?: Poll[];
  isArtist?: boolean;
  isComposer?: boolean;
  quizData?: Quiz[];
  isLoading?: boolean;
  showTotalsPostsInfos?: boolean;
  changeInput: (e: string) => void;
  onPostSelect?: (...args: any[]) => any;
};

type PostInfoProps = {
  postInfo?: Composition;
  compositionData?: Composition[];
  isActive?: boolean;
  isSearching?: boolean;
  isAllGuides?: boolean;
  showTotalsPostsInfos?: boolean;
  handleSelect: (type?: 'allguides') => void;
  handleSelectPlay?: () => void;
};

type PostPollProps = {
  pollInfo?: PollData | Quiz;
  isSearching?: boolean;
  isActive?: boolean;
  hasFinished?: boolean;
  handleSelect: () => void;
  handleSelectPlay?: () => void;
};

const Post: React.FC<PostInfoProps> = React.memo(({ postInfo, isSearching, isAllGuides, compositionData, handleSelect, handleSelectPlay }) => {
  const intl = useIntl();

  const defaultCoverUrl = 'https://firebasestorage.googleapis.com/v0/b/musicplayce-dev-65d73.appspot.com/o/images%2Fdefault%2Fimage-cover.jpg?alt=media&token=c9ef5388-0670-4673-99a4-2ffabeae2938';
  let coverUrl = '';
  let name = '';
  let datetime_uploaded: any = '';
  let plays = 0;
  let indications = 0;

  if (postInfo) {
    datetime_uploaded = postInfo.createdAt || String(new Date());
    plays = postInfo.playsCount || 0;
    indications = postInfo.indicationsCount || 0;
    name = postInfo.title || '';
    coverUrl = postInfo.coverUrl;
  }

  const dateParsed = moment(formatTimestamp(datetime_uploaded)).format('L LT');

  const dateFormated = dateParsed.split(' ')[0];
  const timeFormated = dateParsed.split(' ')[1];

  const { activeQuiz } = useContext(WhoMatchesContext);

  const { activeComposition } = useContext(ComposerDashContext);

  const { activePoll } = useContext(DashContext);

  let isPostActive = false;

  if (activeQuiz || activeComposition || activePoll) {
    isPostActive = activeQuiz?._id === postInfo?._id || activeComposition?._id === postInfo?._id || activePoll?._id === postInfo?._id;
  }

  const totalPostsPlays = compositionData?.reduce((acc: any, item: any) => acc + item?.playsCount, 0);
  const totalPostsIndications = compositionData?.reduce((acc: any, item: any) => acc + item?.indicationsCount, 0);

  return (
    <PostContainer isAllGuides={isAllGuides} isAllGuidesActive={isAllGuides && !activeComposition} isActive={isAllGuides ? false : isPostActive}>
      <div style={{ width: '40%', minWidth: 80, maxWidth: 96 }}>
        <button type="button" className={`flex h-full w-full justify-center items-center ${isAllGuides ? 'bg-blue-500' : 'bg-transparent'}`}>
          {isAllGuides ? (
            <img src={MPlaylist} alt="icon-playlist" />
          ) : (
            <div
              className="composition-cover"
              onClick={handleSelectPlay}
              style={{ backgroundImage: 'url(' + coverUrl + '), url(' + defaultCoverUrl + ')' }}
            >
              <img className="cursor-pointer" src={MPlayButtonWhite} alt="icon-play" />
            </div>
          )}
        </button>
      </div>
      <div
        className="flex justify-around items-center w-full"
        onClick={() => (isAllGuides ? handleSelect('allguides') : handleSelect && handleSelect())}
        role="button"
        tabIndex={0}
        style={{ borderBottom: '1px solid #E4E5E6' }}
      >
        <div className="flex flex-col py-3" style={{ width: '60%' }}>
          <span className="text-lg font-semibold truncate w-64 sm:w-56" title={name}>
            {isAllGuides
              ? intl.formatMessage({
                  id: 'dashboard.composer.posts.all-guides',
                  defaultMessage: 'Todas as músicas'
                })
              : name}
          </span>

          {!isSearching && (
            <>
              <span className="text-sm text-gray-700">
                {isAllGuides ? (
                  <FormattedMessage
                    id="dashboard.composer.posts.plays-and-indications"
                    values={{ plays: totalPostsPlays, indications: totalPostsIndications }}
                    defaultMessage="{plays} plays {indications} indicações"
                  />
                ) : (
                  <FormattedMessage
                    id="dashboard.composer.posts.plays-and-indications"
                    values={isAllGuides ? { totalPostsPlays, totalPostsIndications } : { plays, indications }}
                    defaultMessage="{plays} plays {indications} indicações"
                  />
                )}
              </span>
            </>
          )}

          {!isAllGuides && (
            <span className="text-xs text-gray-700 uppercase">
              <FormattedMessage
                id="dashboard.composer.posts.posted-in"
                values={{ date: dateFormated, time: timeFormated }}
                defaultMessage="Postada em {date} às {time}"
              />
            </span>
          )}
        </div>

        <button onClick={() => handleSelect} type="button">
          {!isAllGuides && <img src={MPEye} className="cursor-pointer" alt="logo-eyes" />}
        </button>
      </div>
    </PostContainer>
  );
});

const PollPost: React.FC<PostPollProps> = React.memo(({ pollInfo, isSearching, handleSelect, handleSelectPlay, hasFinished }) => {
  let name = '';
  let datetime_created = '';
  let datetime_expiration = '';

  if (pollInfo) {
    datetime_created = pollInfo.datetime_created || String(new Date());
    datetime_expiration = pollInfo.datetime_expiration || String(new Date());
    name = pollInfo.post_name || '';
  }

  const dateParsed = moment(datetime_created).format('L LT');

  const dateFormated = dateParsed.split(' ')[0];
  const timeFormated = dateParsed.split(' ')[1];

  const dateExpirationParsed = moment(datetime_expiration).format('L LT');

  const dateExpirationFormated = dateExpirationParsed.split(' ')[0];

  const { activeQuiz } = useContext(WhoMatchesContext);

  const { activeComposition } = useContext(ComposerDashContext);

  const { activePoll } = useContext(DashContext);

  let isPostActive = false;

  if (activeQuiz || activeComposition || activePoll) {
    isPostActive = activeQuiz?._id === pollInfo?._id || activeComposition?._id === pollInfo?._id || activePoll?._id === pollInfo?._id;
  }

  return (
    <PostContainer isActive={isPostActive}>
      <div style={{ width: '40%', minWidth: 80, maxWidth: 96 }}>
        <button onClick={handleSelectPlay} type="button" className="flex h-full w-full justify-center items-center bg-transparent">
          <img src={MPlayButton} alt="icon-play" />
        </button>
      </div>
      <div
        className="flex justify-around items-center w-full"
        onClick={handleSelect}
        role="button"
        tabIndex={0}
        style={{ borderBottom: '1px solid #E4E5E6' }}
      >
        <div className="flex flex-col py-3" style={{ width: '60%' }}>
          <span className="text-lg font-semibold truncate" style={{ width: '15rem' }} title={name}>
            {name}
          </span>

          {!isSearching && (
            <>
              <span className={`text-sm ${hasFinished ? 'text-red-500' : 'text-green-500'}`}>
                {hasFinished ? (
                  <FormattedMessage id="dashboard.composer.poll-posts.has-finished" defaultMessage="Enquete encerrada" />
                ) : (
                  <FormattedMessage
                    id="dashboard.composer.poll-posts.expiration-date"
                    values={{ date: dateExpirationFormated }}
                    defaultMessage="Encerra em ???"
                  />
                )}
              </span>
            </>
          )}
          <span className="text-xs text-gray-700 uppercase">
            <FormattedMessage
              id="dashboard.composer.posts.posted-in"
              values={{ date: dateFormated, time: timeFormated }}
              defaultMessage="Postada em {date} às {time}"
            />
          </span>
        </div>

        <img src={MPEye} className="cursor-pointer" alt="logo-eyes" />
      </div>
    </PostContainer>
  );
});

const PublishedPostsSearch: React.FC<Props> = ({
  className,
  inputValue,
  changeInput,
  isArtist,
  isComposer,
  compositionData,
  pollData,
  quizData,
  style,
  isPoll,
  isLoading,
  isWhoMatches,
  showTotalsPostsInfos,
  onPostSelect
}) => {
  const intl = useIntl();
  // const dropdownRef = useRef<HTMLDivElement>(null);

  const { token } = useContext(AuthContext);

  // This context function should be passed to this component through a prop instead of using it directly.
  const { handleSelectSearchItem, globalTogglePlayPause } = useContext(DashContext);

  const isMain = !isPoll && !isWhoMatches;

  // const filterTypesObj: any = {
  //   all: intl.formatMessage({
  //     id: 'dashboard.composer.posts.filters.all',
  //     defaultMessage: 'Todas as músicas'
  //   }),
  //   relevants: intl.formatMessage({
  //     id: 'dashboard.composer.posts.filters.relevants',
  //     defaultMessage: 'Mais relevantes'
  //   }),
  //   newest: intl.formatMessage({
  //     id: 'dashboard.composer.posts.filters.newest',
  //     defaultMessage: 'Mais recentes'
  //   }),
  //   oldest: intl.formatMessage({
  //     id: 'dashboard.composer.posts.filters.oldest',
  //     defaultMessage: 'Mais antigos'
  //   }),
  //   nameAtoZ: intl.formatMessage({
  //     id: 'dashboard.composer.posts.filters.nameAtoZ',
  //     defaultMessage: 'Por nome A-Z'
  //   }),
  //   nameZtoA: intl.formatMessage({
  //     id: 'dashboard.composer.posts.filters.nameZtoA',
  //     defaultMessage: 'Por nome Z-A'
  //   })
  // };

  // const arrWithNames = Object.values(filterTypesObj);

  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setFocus] = useState(false);
  // const [isDropdownActive, setDropdownState] = useState(false);
  // const [filterType, setFilterType] = useState(filterTypesObj.all);

  const setBlur = () => {
    inputRef.current?.blur();
  };

  // const setFalse = () => {
  //   setDropdownState(false);
  // };

  // useOutsideIdentifier(dropdownRef, setFalse);

  return (
    <Container id="published-post-container" className={className} isLoading={isLoading} isFocused={isFocused} style={style}>
      <div className="search--container">
        {isPoll ? (
          <>
            <span>
              <FormattedMessage id="dashboard.published-posts-search.published-pools" defaultMessage="Enquetes Publicadas" />
            </span>
            <span className="see-details">
              <FormattedMessage
                id="dashboard.published-posts-search.see-details-pools-desc"
                defaultMessage="Clique em uma das enquetes para ver os detalhes"
              />
            </span>
          </>
        ) : null}
        {isWhoMatches ? (
          <>
            <span>
              <FormattedMessage id="dashboard.published-posts-search.published-whomatches" defaultMessage="Com quem combina?" />
            </span>
            <span className="see-details">
              <FormattedMessage
                id="dashboard.published-posts-search.see-details-desc-whomatches"
                defaultMessage="Clique em um dos Com quem combina? para ver os detalhes"
              />
            </span>
          </>
        ) : null}
        {isMain &&
          (isArtist ? (
            <>
              <span>
                <FormattedMessage id="dashboard.published-posts-search.published-posts-artist" defaultMessage="Músicas indicadas" />
              </span>
              <span className="see-details">
                <FormattedMessage
                  id="dashboard.published-posts-search.see-details-desc"
                  defaultMessage="Clique em uma das músicas para ver os detalhes"
                />
              </span>
            </>
          ) : (
            <>
              <span>
                <FormattedMessage id="dashboard.published-posts-search.published-posts" defaultMessage="Músicas publicadas" />
              </span>
              <span className="see-details">
                <FormattedMessage
                  id="dashboard.published-posts-search.see-details-desc"
                  defaultMessage="Clique em uma das músicas para ver os detalhes"
                />
              </span>
            </>
          ))}
        <hr className="my-4" />
        <div className="flex w-full items-center relative">
          <input
            className={`search-input ${inputValue.length && 'pr-20'}`}
            value={inputValue}
            placeholder={intl.formatMessage({
              id: 'dashboard.published-posts-search.search-for-a-post',
              defaultMessage: 'Digite o que você procura...'
            })}
            onChange={(e) => changeInput(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            ref={inputRef}
          />
          <IoIosSearch size={24} color={isFocused ? '#0D65FF' : '#ACACAC'} className="absolute ml-2" />
          {isFocused && !inputValue && (
            <button type="button" className="absolute right-0 mr-2 text-sm font-normal" onClick={setBlur}>
              <FormattedMessage id="dashboard.published-posts-search.cancel" defaultMessage="Cancelar" />
            </button>
          )}
          {inputValue && (
            <button type="button" className="absolute right-0 mr-2 text-sm font-normal" onClick={() => changeInput('')}>
              <FormattedMessage id="dashboard.published-posts-search.clear" defaultMessage="Limpar" />
            </button>
          )}
        </div>
      </div>
      {isFocused && !inputValue && (
        <span className="font-semibold text-sm" style={{ color: '#817B7B', padding: 20 }}>
          <FormattedMessage id="dashboard.published-posts-search.search-historic" defaultMessage="Histórico de busca" />
        </span>
      )}
      {inputValue && (
        <span className="font-semibold text-sm" style={{ color: '#817B7B', padding: 20 }}>
          <FormattedMessage id="dashboard.published-posts-search.search-results" defaultMessage="Resultados da pesquisa" />
        </span>
      )}
      {!inputValue && (
        <div className="flex flex-col">
          {!isFocused && (
            <>
              <div className="flex justify-between relative">
                <span className="text-sm" style={{ color: '#ACACAC', padding: 20 }}>
                  <FormattedMessage
                    id="dashboard.published-posts-search.guides-count"
                    values={{
                      quantity: compositionData?.length || pollData?.length || quizData?.length || 0
                    }}
                    defaultMessage="{quantity} música(as) encontradas"
                  />
                </span>
                {/* {isMain && (
                  <>
                    <button
                      type="button"
                      className="font-normal flex items-center"
                      onClick={() => setDropdownState((prevState) => !prevState)}
                      style={{ paddingRight: 20 }}
                    >
                      {filterType}
                      <IoIosArrowDown size={20} className="ml-2" color="#6E6969" />
                    </button>
                  </>
                )} */}
              </div>
            </>
          )}
        </div>
      )}
      <div className="results--container h-full overflow-y-auto overflow-x-hidden">
        {isLoading ? (
          <div className="h-full w-full flex items-center justify-center">
            <Spinner isLoading />
          </div>
        ) : (
          <>
            {inputValue && (
              <div className="flex flex-col">
                {isPoll &&
                  (pollData?.length ? (
                    pollData?.map(
                      (item) =>
                        item.post_name
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .includes(
                            inputValue
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                          ) && (
                          <PollPost
                            handleSelect={() => handleSelectSearchItem(item._id, token, 'poll')}
                            handleSelectPlay={() => handleSelectSearchItem(item._id, token, 'poll', true)}
                            key={item._id}
                            isSearching={inputValue.length > 0}
                            hasFinished={item.finished}
                            pollInfo={item}
                          />
                        )
                    )
                  ) : (
                    <Message isPublishedSearch message="Nenhuma enquete foi publicada" />
                  ))}
                {isWhoMatches &&
                  (quizData?.length ? (
                    quizData?.map(
                      (item) =>
                        item.post_name
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .includes(
                            inputValue
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                          ) && (
                          <PollPost
                            key={item._id}
                            handleSelect={() => (onPostSelect ? onPostSelect(item._id) : undefined)}
                            handleSelectPlay={() => (onPostSelect ? onPostSelect(item._id, true) : undefined)}
                            isSearching={inputValue.length > 0}
                            hasFinished={item.finished}
                            pollInfo={item}
                          />
                        )
                    )
                  ) : (
                    <Message isPublishedSearch message="Nenhuma enquete foi publicada" />
                  ))}
                {isArtist &&
                  (compositionData?.length ? (
                    compositionData?.map(
                      (item) =>
                        item.title
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .includes(
                            inputValue
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                          ) && (
                          <ArtistPost
                            key={item._id}
                            handleSelect={() => (onPostSelect ? onPostSelect(item._id) : undefined)}
                            handlePlaySelect={() => (onPostSelect ? onPostSelect(item._id, true) : undefined)}
                            isSearching={inputValue.length > 0}
                            composition={item}
                          />
                        )
                    )
                  ) : (
                    <Message isPublishedSearch message="Nenhuma indicação foi encontrada" />
                  ))}
                {isComposer &&
                  (compositionData?.length ? (
                    compositionData?.map(
                      (item) =>
                        item.title
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .includes(
                            inputValue
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                          ) && (
                          <Post
                            showTotalsPostsInfos={showTotalsPostsInfos}
                            key={item._id}
                            handleSelectPlay={() => (onPostSelect ? onPostSelect(item._id, token, true) : undefined)}
                            handleSelect={() => (onPostSelect ? onPostSelect(item._id) : undefined)}
                            isSearching={inputValue.length > 0}
                            postInfo={item}
                          />
                        )
                    )
                  ) : (
                    <Message isPublishedSearch message="Nenhuma música foi publicada" />
                  ))}
              </div>
            )}
            {!inputValue && isFocused && (
              <div className="flex flex-col">
                {isPoll &&
                  (pollData?.length ? (
                    pollData?.map((item) => (
                      <PollPost
                        handleSelect={() => handleSelectSearchItem(item._id, token, 'poll')}
                        handleSelectPlay={() => handleSelectSearchItem(item._id, token, 'poll', true)}
                        key={item._id}
                        hasFinished={item.finished}
                        isSearching
                        pollInfo={item}
                      />
                    ))
                  ) : (
                    <Message isPublishedSearch message="Nenhuma enquete foi publicada" />
                  ))}
                {isWhoMatches &&
                  (quizData?.length ? (
                    quizData?.map((item) => (
                      <PollPost
                        handleSelect={() => (onPostSelect ? onPostSelect(item._id) : undefined)}
                        handleSelectPlay={() => (onPostSelect ? onPostSelect(item._id, true) : undefined)}
                        key={item._id}
                        hasFinished={item.finished}
                        isSearching
                        pollInfo={item}
                      />
                    ))
                  ) : (
                    <Message isPublishedSearch message="Nenhuma enquete foi publicada" />
                  ))}
                {isArtist &&
                  (compositionData?.length ? (
                    compositionData?.map((item) => (
                      <ArtistPost
                        handlePlaySelect={() => (onPostSelect ? onPostSelect(item._id, true) : undefined)}
                        handleSelect={() => (onPostSelect ? onPostSelect(item._id) : undefined)}
                        key={item._id}
                        isSearching
                        composition={item}
                      />
                    ))
                  ) : (
                    <Message isPublishedSearch message="Nenhuma indicação foi encontrada" />
                  ))}
                {isComposer &&
                  (compositionData?.length ? (
                    compositionData?.map((item) => (
                      <Post
                        showTotalsPostsInfos={showTotalsPostsInfos}
                        handleSelect={() => (onPostSelect ? onPostSelect(item._id) : undefined)}
                        handleSelectPlay={() => (onPostSelect ? onPostSelect(item._id, token, true) : undefined)}
                        key={item._id}
                        isSearching
                        postInfo={item}
                      />
                    ))
                  ) : (
                    <Message isPublishedSearch message="Nenhuma música publicada" />
                  ))}
              </div>
            )}
            {!inputValue && !isFocused && (
              <div className="flex flex-col">
                {!isFocused && !isPoll && !isWhoMatches && !isArtist && compositionData?.length ? (
                  <Post compositionData={compositionData} handleSelect={() => onPostSelect && onPostSelect(undefined)} isAllGuides />
                ) : null}
                {isPoll &&
                  (pollData?.length ? (
                    pollData?.map((item) => (
                      <PollPost
                        key={item._id}
                        handleSelect={() => handleSelectSearchItem(item._id, token, 'poll')}
                        handleSelectPlay={() => handleSelectSearchItem(item._id, token, 'poll', true)}
                        hasFinished={item.finished}
                        isSearching={inputValue.length > 0}
                        pollInfo={item}
                      />
                    ))
                  ) : (
                    <Message isPublishedSearch message="Nenhuma enquete foi publicada" />
                  ))}
                {isWhoMatches &&
                  (quizData?.length ? (
                    quizData?.map((item) => (
                      <PollPost
                        key={item._id}
                        handleSelect={() => (onPostSelect ? onPostSelect(item._id) : undefined)}
                        handleSelectPlay={() => (onPostSelect ? onPostSelect(item._id, true) : undefined)}
                        hasFinished={item.finished}
                        isSearching={inputValue.length > 0}
                        pollInfo={item}
                      />
                    ))
                  ) : (
                    <Message isPublishedSearch message="Nenhuma enquete foi publicada" />
                  ))}
                {isArtist &&
                  (compositionData?.length ? (
                    compositionData?.map((item) => (
                      <ArtistPost
                        key={item._id}
                        handleSelect={() => (onPostSelect ? onPostSelect(item._id) : undefined)}
                        handlePlaySelect={() => (onPostSelect ? onPostSelect(item._id, true) : undefined)}
                        isSearching={inputValue.length > 0}
                        composition={item}
                      />
                    ))
                  ) : (
                    <Message isPublishedSearch message="Nenhuma indicação foi encontrada" />
                  ))}
                {isComposer &&
                  (compositionData?.length ? (
                    compositionData?.map((item) => (
                      <Post
                        showTotalsPostsInfos={showTotalsPostsInfos}
                        key={item._id}
                        handleSelect={() => (onPostSelect ? onPostSelect(item._id) : undefined)}
                        handleSelectPlay={() => (onPostSelect ? onPostSelect(item._id, token, true) && globalTogglePlayPause() : undefined)}
                        isSearching={inputValue.length > 0}
                        postInfo={item}
                      />
                    ))
                  ) : (
                    <Message isPublishedSearch message="Nenhuma música foi publicada" />
                  ))}
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default PublishedPostsSearch;

/*
  isDropdownActive && (
    <div
      ref={dropdownRef}
      className="absolute right-0 w-48 h-56 flex mt-12 mr-5 bg-white p-6 flex-col"
      style={{ boxShadow: '0px 2px 4px #E4E5E6', borderRadius: 4 }}
    >
      {arrWithNames.map((filterTitle) => (
        <span
          className={`navbar--item select-none ${filterType === filterTitle && 'item--active'}`}
          onKeyPress={() => setFilterType(filterTitle)}
          role="button"
          tabIndex={0}
          key={String(filterTitle)}
          onClick={() => {
            setFilterType(filterTitle);
            setDropdownState(false);
          }}
        >
          {/* @ts-ignore}
          {filterTitle}
        </span>
      ))}
    </div>
  );
        */
