import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import close from '../../images/close.svg';
import CharCounter from '../CharCounter/CharCounter';
import { showToast } from '../Toast/Toast';

import { ChipsContainer, ChipsItems, ChipsInput, ChipsSuggestion } from './styles';

type Props = {
  values: any[];
  suggestions: any[];
  maxItems?: number;
  chipMaxLength?: number;
  hasCounter?: boolean;
  setTagsUpdated(tags: string[]): string[];
  setTagsDeleted(tags: any): any[];
} & React.InputHTMLAttributes<HTMLInputElement>;

interface Artist {
  _id: string;
  name: string;
  rank: number;
  picture_profile: {
    _id: string;
    url: string;
    type: string;
    datetime_created: string;
    datetime_updated: string;
    processed: boolean;
    resources: string[];
  };
}

export default function ProjectTags({
  values,
  suggestions,
  hasCounter,
  placeholder,
  maxItems,
  chipMaxLength,
  setTagsUpdated,
  setTagsDeleted
}: Props) {
  const [searchVal, setSearchVal] = useState<string>('');
  const [tags, setTags] = useState<any[]>([]);
  // eslint-disable-next-line
  const [sugValues, setSugValues] = useState<Artist[]>([]);
  const [delTags, setDelTags] = useState<any>([]);

  function add(data: any) {
    if (/[^a-z0-9]+/gm.test(data)) {
      showToast({ type: 'error', message: `Só são aceitos números e letras minúsculas` });
      return setSearchVal('');
    }

    if (!data) {
      return false;
    }

    if (tags.filter((i) => i.label === data).length > 0) {
      showToast({ type: 'error', message: `Este item já existe na lista` });
      return setSearchVal('');
    }

    if (maxItems && data !== '' && tags.length < maxItems) {
      const arr = [...tags];
      arr.push({ _id: uniqueId('new_'), label: data });
      setTags(arr);
      setTagsUpdated(
        arr.map((t) => {
          return t;
        })
      );
      return setSearchVal('');
    }

    if (!maxItems) {
      const arr = [...tags];
      arr.push({ _id: uniqueId('new_'), label: data });
      setTags(arr);
      setTagsUpdated(
        arr.map((t) => {
          return t;
        })
      );
      return setSearchVal('');
    }

    showToast({ type: 'error', message: `Limite máximo de ${maxItems} ${maxItems > 1 ? 'itens' : 'item'}` });
    return setSearchVal('');
  }

  function remove(value: string) {
    const arr: any[] = [...delTags];
    tags.forEach((t) => {
      if (t._id === value) {
        arr.push({
          _id: value,
          label: t.label
        });
      }
    });

    setTags(tags.filter((t) => t._id !== value));
    setTagsDeleted(arr);
    setDelTags(arr);
  }

  function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      return add(searchVal);
    }
  }

  useEffect(() => {
    setTags(values);
  }, [values]);

  return (
    <>
      <ChipsContainer>
        <ChipsInput>
          <input
            autoComplete="off"
            className="my-1 outline-none border-none"
            name="main_artist"
            id="main_artist"
            type="text"
            placeholder={placeholder}
            maxLength={chipMaxLength || 99999}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            onKeyPress={(e) => {
              handleKeyPress(e);
            }}
            data-cy="input-main_artist"
          />
        </ChipsInput>
        {hasCounter && <CharCounter currentLength={searchVal.length} maxLength={chipMaxLength || 99999} />}
      </ChipsContainer>
      {!!sugValues && sugValues.length > 0 && (
        <ChipsSuggestion>
          {sugValues.map((i, index) => {
            return (
              <div key={index} tabIndex={0} role="button" className="suggestion-items" onKeyDown={() => add(i)} onClick={() => add(i)}>
                {i.name}
              </div>
            );
          })}
        </ChipsSuggestion>
      )}
      <ChipsItems>
        {!!tags &&
          tags.map((i, index) => {
            return (
              <div key={index} className="item">
                {i.label} {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img src={close} alt="close" onKeyDown={() => remove(i._id)} style={{ marginLeft: 10 }} onClick={() => remove(i._id)} />
              </div>
            );
          })}
      </ChipsItems>
    </>
  );
}
