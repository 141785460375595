import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { IoIosArrowDown } from 'react-icons/io';
import ReactStars from 'react-stars';
import { Board, Lane, KanbanCard, CardTags } from '../../interfaces';
import useOutsideIdentifier from '../../utils/customHooks/useOutsideIdentifier';
import { KanbanFilters, Collapse } from './styles';
// import searchBoardTags from './services/searchBoardTags';
import { KanbanContext } from '../../context/dash/producer/kanban';

type Props = {
  boardData: Board | undefined;
  onFiltersUpdated(filtered: Board | undefined): void;
};

interface FilterValues {
  orderBy: string;
  tags: CardTags[];
  rating: number;
}

const KanbanFiltersCard: React.FC<Props> = ({ boardData, onFiltersUpdated }) => {
  const intl = useIntl();

  const { boardTags } = useContext(KanbanContext);

  const [tagList, setTagList] = useState<CardTags[] | undefined>([]);

  useEffect(() => {}, [tagList]);

  // eslint-disable-next-line
  // async function loadTags() {
  // eslint-disable-next-line
  // const data = await searchBoardTags(boardData?._id || '');
  // }

  // eslint-disable-next-line
  const [filtersActive, setFiltersActive] = useState([
    intl.formatMessage({
      id: 'dashboard.composer.posts.filters.all',
      defaultMessage: 'Todas as guias'
    })
  ]);

  const filterTypesObj: any = {
    all: intl.formatMessage({
      id: 'dashboard.composer.posts.filters.all',
      defaultMessage: 'Todas as guias'
    }),
    // relevants: intl.formatMessage({
    //   id: 'dashboard.composer.posts.filters.relevants',
    //   defaultMessage: 'Mais relevantes'
    // }),
    newest: intl.formatMessage({
      id: 'dashboard.composer.posts.filters.newest',
      defaultMessage: 'Mais recentes'
    }),
    oldest: intl.formatMessage({
      id: 'dashboard.composer.posts.filters.oldest',
      defaultMessage: 'Mais antigos'
    }),
    nameAtoZ: intl.formatMessage({
      id: 'dashboard.composer.posts.filters.nameAtoZ',
      defaultMessage: 'Por nome A-Z'
    }),
    nameZtoA: intl.formatMessage({
      id: 'dashboard.composer.posts.filters.nameZtoA',
      defaultMessage: 'Por nome Z-A'
    }),
    stars1to5: intl.formatMessage({
      id: 'dashboard.composer.posts.filters.stars1to5',
      defaultMessage: 'Por estrelas 1-5'
    }),
    stars5to1: intl.formatMessage({
      id: 'dashboard.composer.posts.filters.stars5to1',
      defaultMessage: 'Por estrelas 5-1'
    })
  };

  const [filterType, setFilterType] = useState(filterTypesObj.all);
  const arrWithNames = Object.values(filterTypesObj);

  const [isDropdownActive, setDropdownState] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line
  const musicDropDown = useRef<HTMLDivElement>();

  const [boardFiltered, setBoardFiltered] = useState<Board | undefined>();
  const [filters, setFilters] = useState<FilterValues>({
    orderBy: '',
    tags: [],
    rating: 0
  });

  useOutsideIdentifier(dropdownRef, () => setDropdownState(false));

  function orderCardsBy(value: string) {
    setFilterType(value);
    setDropdownState(false);
    Object.entries(filterTypesObj).forEach((i) => {
      if (i[1] === value) {
        setFilters({ ...filters, orderBy: i[0] });
      }
    });
  }

  function changeOrder(orderBy: string) {
    const arr: Lane[] = [];

    switch (orderBy) {
      case 'all': {
        setBoardFiltered(boardData);
        break;
      }
      case 'newest': {
        boardData?.lanes.forEach((lane: Lane) => {
          lane.cards.sort((a: KanbanCard, z: KanbanCard) =>
            new Date(a?.custom_content?.post?.datetime_created || '') < new Date(z?.custom_content?.post?.datetime_created || '') ? 1 : -1
          );

          arr.push(lane);
        });

        setBoardFiltered((oldState: Board | any) => ({ ...oldState, lanes: arr }));
        break;
      }
      case 'oldest': {
        boardData?.lanes.forEach((lane: Lane) => {
          lane.cards.sort((a: KanbanCard, z: KanbanCard) =>
            new Date(a?.custom_content?.post?.datetime_created || '') > new Date(z?.custom_content?.post?.datetime_created || '') ? 1 : -1
          );

          arr.push(lane);
        });

        setBoardFiltered((oldState: Board | any) => ({ ...oldState, lanes: arr }));
        break;
      }
      case 'nameAtoZ': {
        boardData?.lanes.forEach((lane: Lane) => {
          lane.cards.sort((a: KanbanCard, z: KanbanCard) =>
            a?.custom_content?.post?.name.localeCompare(z?.custom_content?.post?.name || '') === 1 ? 1 : -1
          );

          arr.push(lane);
        });

        setBoardFiltered((oldState: Board | any) => ({ ...oldState, lanes: arr }));
        break;
      }
      case 'nameZtoA': {
        boardData?.lanes.forEach((lane: Lane) => {
          lane.cards.sort((a: KanbanCard, z: KanbanCard) =>
            a?.custom_content?.post?.name.localeCompare(z?.custom_content?.post?.name || '') === 1 ? -1 : 1
          );
          arr.push(lane);
        });

        setBoardFiltered((oldState: Board | any) => ({ ...oldState, lanes: arr }));
        break;
      }
      case 'stars1to5': {
        boardData?.lanes.forEach((lane: Lane) => {
          lane.cards.sort((a: KanbanCard, z: KanbanCard) =>
            (a?.custom_content?.classification || 0) > (z?.custom_content?.classification || 0) ? 1 : -1
          );
          arr.push(lane);
        });

        setBoardFiltered((oldState: Board | any) => ({ ...oldState, lanes: arr }));
        break;
      }
      case 'stars5to1': {
        boardData?.lanes.forEach((lane: Lane) => {
          lane.cards.sort((a: KanbanCard, z: KanbanCard) =>
            (a?.custom_content?.classification || 0) < (z?.custom_content?.classification || 0) ? 1 : -1
          );
          arr.push(lane);
        });

        setBoardFiltered((oldState: Board | any) => ({ ...oldState, lanes: arr }));
        break;
      }
      default:
        break;
    }
  }

  function findByName(value: string) {
    const values: Board | any = { ...boardData };
    if (!value) {
      return setBoardFiltered(values);
    }

    const lns: Lane[] = [];
    boardData?.lanes?.forEach((lane: Lane) => {
      const crd: KanbanCard[] = [];
      lane.cards.forEach((card: KanbanCard) => {
        if (card.custom_content && card.custom_content?.post?.name && card.custom_content.post.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          crd.push(card);
        }
      });
      lns.push({ ...lane, cards: crd });
    });

    return setBoardFiltered((state: Board | any) => ({ ...state, lanes: lns }));
  }

  function searchByTags(tags: CardTags[]) {
    const values: Board | any = { ...boardData };
    if (!tags || tags.length === 0) {
      return setBoardFiltered(values);
    }

    const tagIds: string[] = tags.map((t: CardTags) => t._id);

    const lns: Lane[] = [];
    boardData?.lanes.forEach((lane: Lane) => {
      const crd: KanbanCard[] = [];
      lane.cards.forEach((card: KanbanCard) => {
        card.id_tags.forEach((id: string) => {
          const checkAllValues = tagIds.every((i: string) => card.id_tags.includes(i));
          if (checkAllValues && !crd.includes(card)) {
            crd.push(card);
          }
        });
      });

      lns.push({ ...lane, cards: crd });
    });

    return setBoardFiltered((state: Board | any) => ({ ...state, lanes: lns }));
  }

  function checkStarsRating(value: number) {
    setFilters((oldState: FilterValues) => ({ ...oldState, rating: value === filters.rating ? 0 : value }));
  }

  function searchByStarsRating(rating: number) {
    const values: Board | any = { ...boardData };
    if (!rating || rating === 0) {
      return setBoardFiltered(values);
    }

    const lns: Lane[] = [];
    boardData?.lanes.forEach((lane: Lane) => {
      const crd: KanbanCard[] = [];
      lane.cards.forEach((card: KanbanCard) => {
        if (card?.custom_content?.classification === rating) {
          crd.push(card);
        }
      });

      lns.push({ ...lane, cards: crd });
    });

    return setBoardFiltered((state: Board | any) => ({ ...state, lanes: lns }));
  }

  useEffect(() => {
    searchByStarsRating(filters.rating);
    // eslint-disable-next-line
  }, [filters.rating]);

  useEffect(() => {
    changeOrder(filters.orderBy);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    searchByTags(filters.tags);
    // eslint-disable-next-line
  }, [filters.tags]);

  useEffect(() => {
    onFiltersUpdated(boardFiltered);
    // eslint-disable-next-line
  }, [boardFiltered]);

  useEffect(() => {
    (async () => {
      setBoardFiltered(boardData);
      setTagList(boardData?.tags);
    })();
    // eslint-disable-next-line
  }, [boardData]);

  return (
    <Collapse className="flex flex-col p-6">
      {/* <span className="mb-5 text-lg font-semibold">Filtros</span> */}
      <KanbanFilters className="flex border-b-2 border-gray-200 mb-4 pb-6 flex-col lg:flex-row">
        <div className="flex flex-col lg:flex-row w-full lg:w-1/3">
          <input
            className="bg-gray-200 rounded p-2 text-sm flex filter-input"
            placeholder="Digite o que você procura..."
            onChange={(e) => findByName(e.target.value)}
          />
        </div>
        <div className="flex flex-row flex-1 align-center justify-end text-sm w-full class-ord">
          <div className="classification lg:mr-8">
            <div className="filters-title">Classificação:</div>
            <div className="rounded react-stars-container ml-0 lg:ml-2 mt-2 lg:mt-0">
              <ReactStars
                count={5}
                half={false}
                value={filters.rating}
                onChange={(value: number) => checkStarsRating(value)}
                size={24}
                // eslint-disable-next-line
                color2={'#ffd700'}
              />
            </div>
          </div>
          <div className="ordenation">
            <div className="filters-title">Ordenar itens nas colunas por:</div>
            <div className="filters-container">
              <div
                className="flex justify-center items-center ml-0 lg:ml-5 mt-2 lg:mt-0 font-bold"
                onClick={() => setDropdownState(!isDropdownActive)}
                aria-hidden="true"
              >
                {filterType} <IoIosArrowDown className="ml-2" />
              </div>
              {isDropdownActive && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 w-48 flex mt-12 mr-5 bg-white p-4 flex-col z-10 text-gray-500 h-auto"
                  style={{ boxShadow: '0px 2px 4px #E4E5E6', borderRadius: 4, height: 'auto' }}
                >
                  {arrWithNames.map((filterTitle, index) => {
                    return (
                      <span
                        className={`navbar--item select-none ${filterType === filterTitle && 'item--active'} my-2 pl-2`}
                        onKeyPress={() => setFilterType(filterTitle)}
                        role="button"
                        tabIndex={0}
                        key={index}
                        onClick={() => {
                          orderCardsBy(filterTitle as string);
                        }}
                      >
                        {filterTitle as string}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </KanbanFilters>
      <div className="flex flex-row text-sm my-2">
        <div>Tags de guias</div>
        <div className="ml-5 text-gray-500">
          {boardTags?.length} tags (
          {filters?.tags?.length === 1
            ? `${filters?.tags?.length} tag selecionada`
            : filters?.tags?.length > 1
            ? `${filters?.tags?.length} tags selecionadas`
            : 'nenhuma selecionada'}
          )
        </div>
      </div>
      <div className="flex flex-row text-sm">
        <Select
          className="w-full border border-gray-300 rounded "
          isMulti
          onChange={(e: CardTags[]) => setFilters((oldState: FilterValues) => ({ ...oldState, tags: e }))}
          placeholder="Digite uma tag para filtrar"
          options={boardTags}
          closeMenuOnSelect
          getOptionValue={(opt: any) => opt._id}
        />
      </div>
    </Collapse>
  );
};

export default KanbanFiltersCard;
