/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import moment from 'moment';
import 'moment/locale/pt-br';
import * as typeformEmbed from '@typeform/embed';

import RectButton from '../../components/RectButton/RectButton';
import { CheckoutContext } from '../../context/checkout';
import { PriceObj, userSubscriptions, CustomerObj } from '../../interfaces';
import { getLastSubscriptionIndex, canTrial, getCurrentSubscription } from '../../utils/checkoutHelpers';

type Props = {
  price?: PriceObj;
  activePrice?: PriceObj;
};

const makeButton = (
  userSubscriptions: userSubscriptions[],
  customer: CustomerObj,
  price: PriceObj,
  activePrice: PriceObj,
  handleSelectPrice: (priceId: string) => void
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();

  const redirectToPayment = (priceId: string) => {
    history.push(`/checkout/make-payment/${priceId}`);
    handleSelectPrice(priceId);
  };

  const isBeginner =
    process.env.REACT_APP_NODE_ENV === 'prod' ? price.id_product === '5f6c23949d3957ac67ae9633' : price.id_product === '5f3db81dbd9e96ba6c4cabd4';

  const userCanTrial = canTrial(customer, price);

  const currentSubscription = getCurrentSubscription(userSubscriptions);

  const cancelAt = moment((currentSubscription?.stripe_subscription?.cancel_at as number) * 1000).format('L');

  const typeformModal = typeformEmbed.makePopup('https://form.typeform.com/to/zpZcvq', {
    mode: 'popup',
    hideHeaders: true,
    hideFooter: true,
    autoClose: 2,
    onSubmit: () => {}
  });

  if (price?.id_product === activePrice?.id_product) {
    return (
      <RectButton theme="disabled" className="mt-auto m-8 flex justify-center items-center">
        <AiOutlineCheckCircle className="text-lg text-gray-500 mr-1" /> Plano Ativo
      </RectButton>
    );
  }

  if (price?.tier >= activePrice?.tier) {
    if (price?.available && price.has_trial && userCanTrial) {
      return (
        <RectButton theme="success" className="mt-auto m-8" onClick={() => redirectToPayment(`${price._id}`)}>
          Experimente {price.trial_days} dias Grátis
        </RectButton>
      );
    }

    if (price?.available && price.has_trial && !userCanTrial) {
      return (
        <RectButton theme="success" className="mt-auto m-8" onClick={() => redirectToPayment(`${price._id}`)}>
          Comprar plano
        </RectButton>
      );
    }

    if (price?.available && !price.has_trial) {
      return (
        <RectButton theme="success" className="mt-auto m-8" onClick={() => redirectToPayment(`${price._id}`)}>
          Comprar plano
        </RectButton>
      );
    }
    return (
      <RectButton theme="warning" className="mt-auto m-8" onClick={() => typeformModal.open()}>
        Lista de Espera
      </RectButton>
    );
  }

  if (currentSubscription?.stripe_subscription?.cancel_at_period_end && isBeginner) {
    return (
      <RectButton theme="disabled" className="mt-auto m-8 flex justify-center items-center">
        Será ativo em {cancelAt}
      </RectButton>
    );
  }

  return (
    <Link className="mt-auto m-8 flex justify-center items-center" to={`/checkout/change-plan/${activePrice?._id}`}>
      <RectButton theme="primary" className="w-full">
        Voltar para este plano
      </RectButton>
    </Link>
  );
};

const BenefitsCard: React.FC<Props> = ({ price, activePrice, ...rest }) => {
  const isSongwriter = price?.id_product === (process.env.REACT_APP_NODE_ENV === 'prod' ? '5f64d83ccc18cb2f53ec1e6e' : '5f3db8bbbd9e96ba6c4cabd6');
  const isBeginner = price?.id_product === (process.env.REACT_APP_NODE_ENV === 'prod' ? '5f6c23949d3957ac67ae9633' : '5f3db81dbd9e96ba6c4cabd4');

  const isActive = price?.id_product === activePrice?.id_product;

  const { handleSelectPrice, userSubscriptions, customer } = useContext(CheckoutContext);

  const lastSubscriptionIndex = userSubscriptions ? getLastSubscriptionIndex(userSubscriptions) : 0;

  const ButtonToRender = makeButton(
    userSubscriptions as userSubscriptions[],
    customer as CustomerObj,
    price as PriceObj,
    activePrice as PriceObj,
    handleSelectPrice
  );

  const currentPeriodEnd =
    userSubscriptions?.length && (userSubscriptions[lastSubscriptionIndex].stripe_subscription?.current_period_end as number) * 1000;

  const startSubscriptionDate =
    userSubscriptions?.length && (userSubscriptions[lastSubscriptionIndex].stripe_subscription?.start_date as number) * 1000;

  const subscriptionStatus = userSubscriptions?.length && userSubscriptions[lastSubscriptionIndex].stripe_subscription?.status;
  return (
    <div
      className={`flex flex-col bg-white rounded-md h-full shadow-lg ${isSongwriter && !isActive ? 'border-green-500 border-2' : 'border-gray-500'}`}
      style={{ borderRadius: 16 }}
      {...rest}
    >
      <span className="pt-8 text-center text-gray-600 text-2xl">{price?.name}</span>
      <span className="p-4 pb-8 uppercase text-sm text-center text-gray-600">
        {isBeginner && 'Gratuito para sempre'}
        {isSongwriter && !isActive && <span className="rounded-full bg-green-500 p-2 text-white px-4">RECOMENDADO</span>}
        {isActive && !isBeginner && (
          <span className="text-sm text-gray-600 leading-6 font-normal">ATIVO ATÉ {moment(currentPeriodEnd).calendar()}</span>
        )}
        {!price?.available && 'Plano Beta'}
      </span>
      <span className="p-1 uppercase text-3xl text-center font-bold">
        {price?.price === 0 && price.available && 'GRÁTIS'}
        {!price?.available && 'CONSULTE'}
        {price?.price !== 0 && price?.available && (
          <span className="flex items-center justify-center">
            R$ {price.price} <span className="text-sm text-gray-500 font-normal lowercase mb-1 ml-1">/ mês</span>
          </span>
        )}
      </span>
      {isActive
        ? subscriptionStatus === 'trialing'
          ? price?.available && <span className="text-center mt-4 text-sm text-gray-600 leading-6 font-normal">CANCELE A QUALQUER MOMENTO</span>
          : !isBeginner && (
              <span className="text-center mt-4 text-sm text-gray-600 leading-6 font-normal">
                ATIVO DESDE {moment(startSubscriptionDate).format('L')}
              </span>
            )
        : price?.has_trial &&
          price.available && <span className="text-center mt-4 text-sm text-gray-600 leading-6 font-normal">CANCELE A QUALQUER MOMENTO</span>}
      {!price?.available && <span className="text-center mt-4 text-sm text-gray-600 leading-6 font-normal">LISTA DE ESPERA</span>}
      <div className="my-5 mx-auto">
        {price?.benefits.map((planBenefit, index) => (
          <span className="flex items-center my-2" key={index}>
            <AiOutlineCheckCircle className="text-lg text-blue-500" /> <span className="ml-2 text-gray-600">{planBenefit}</span>
          </span>
        ))}
      </div>
      {ButtonToRender}
    </div>
  );
};

export default BenefitsCard;
