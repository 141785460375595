import * as Yup from 'yup';

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path
    },
    test(value) {
      return value === this.resolve(ref);
    }
  });
}
Yup.addMethod(Yup.string, 'equalTo', equalTo);

const ResetPasswordValidation = Yup.object().shape({
  code: Yup.string()
    .required('Digite o código para prosseguir')
    .min(6, 'Código de verificação incompleto')
    .matches(/[\d+$]/, 'O código deve possuir apenas caracteres numéricos'),
  newPassword: Yup.string().required('A senha é obrigatória').min(8, 'A senha deve ter no mínimo 8 caracteres'),
  confirmNewPassword: Yup.string()
    .required('A nova senha é obrigatória')
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .equalTo(Yup.ref('newPassword'), 'As senhas devem ser iguais')
});

export default ResetPasswordValidation;
