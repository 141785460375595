import styled from 'styled-components';

import bg_image_01 from '../../images/background-checkout.jpg';

export const Container = styled.div`
  .bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    display: flex;
    background-image: url(${bg_image_01});
    width: 100%;
  }

  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.8;
  }

  @media (min-width: 1020px) {
    .leading-14 {
      font-size: 40px;
    }
  }

  @media (min-width: 1280px) {
    .leading-14 {
      line-height: 3.5rem;
    }
    .pl-44 {
      padding-left: 11.5rem;
    }
  }
`;
