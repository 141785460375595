import React from 'react';
import RectButton from '../../../../../components/RectButton/RectButton';
import iconArrowRigth from '../../../../../images/icon-arrow.svg';
import ReactTooltip from 'react-tooltip';

type Props = {
  postId: string;
  name: string;
  plays: number;
  indications: number;
  shares: number;
  formatedValue: (value: number) => any;
  handleSelectPost: (postId: string) => void;
};

const MusicOutsideAudition: React.FC<Props> = ({ name, plays = 0, indications = 0, shares = 0, postId, formatedValue, handleSelectPost }) => {
  return (
    <div
      style={{ border: '1px solid #E4E5E6', color: '#817B7B', minWidth: '1080px' }}
      className="px-6 py-5 rounded flex items-center mb-3 justify-between"
    >
      <div
        data-tip={name}
        data-for="not-submitted-post-name"
        style={{ minWidth: '300px' }}
        className="w-full whitespace-no-wrap mr-8  cursor-pointer"
      >
        <ReactTooltip id="not-submitted-post-name" />
        <span style={{ color: '#6E6969' }} className="text-xl font-semibold leading-6 ">
          {name.replace(/(.{25})..+/, '$1...')}
        </span>
      </div>
      <div className="w-full whitespace-no-wrap mr-8 flex flex-col">
        <span>{formatedValue(plays)}</span>
        <span>Plays</span>
      </div>
      <div className="w-full whitespace-no-wrap mr-8 flex flex-col">
        <span>{formatedValue(indications)}</span>
        <span>Indicações</span>
      </div>
      <div className="w-full whitespace-no-wrap mr-8 flex flex-col">
        <span>{formatedValue(shares)}</span>
        <span>Compartilhamentos</span>
      </div>
      <div style={{ minWidth: '212px' }} className="w-full whitespace-no-wrap flex justify-end">
        <RectButton onClick={() => handleSelectPost(postId)} className="flex items-center text-base" theme="success" id="add-to-audition">
          <span>Enviar para audição</span>
          <img className="ml-3" src={iconArrowRigth} alt="icon-arrow-rigth" />
        </RectButton>
      </div>
    </div>
  );
};

export default MusicOutsideAudition;
