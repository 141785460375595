import { createGlobalStyle } from 'styled-components';

export const styles = {
  text: {
    colors: {
      wakanda_900: '#202020',
      surface_600: '#8f8f8f'
    }
  }
};

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  
  body {
    min-height: 100%;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    -webkit-font-smoothing: antialiased !important;
    background-color: #f5f5f5;
  }

  html {
    height: -webkit-fill-available;
  }

  #root {
    height: 100%;
  }

  body, input, button {
    color: #222;
    font-family: 'Inter', sans-serif;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px white inset !important;
  }

  button {
    cursor: pointer;
    font-weight: 500;
  }

  input:focus,
  button:focus {
    outline: none;
    border-color: #cfcfcf;
  }

  @keyframes modal-wrapper {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #header3_semibold {
    font-family: inherit;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: left;
    color: ${styles.text.colors.wakanda_900};
  }

  .header6-semibold{
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }

  .paragrafo-small{
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    color: ${styles.text.colors.surface_600};
  }

  .web-overline{
    font-family: inherit;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: ${styles.text.colors.surface_600};
  }


  #section-search-input{
    width: 453px;
    font-size: 0.9rem;
  }

  @media screen and (max-width: 767px) { 
    #section-search-input{
      width: 100%;
      font-size: 0.9rem;
    }
   }

  #button-primary{
    padding: 0.7rem 2rem;
    background: #1DB954;
    font-size: 0.85rem;
    padding: 0.6rem 1.8rem;
    border-radius: 4px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
  }

  /* .button-secondary{
    position: absolute;
    background: #F5F5F5;
    border: 1px solid #E4E5E6;
    border-radius: 4px;
  } */


  #dynamic-table-header{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #CFCFCF;
  }

  .dynamic-table-row{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #6E6969;
  }

  .RgHZG{
    width: 100%;
    display: flex;
    padding: 20px 15px;
    margin-top: 0px !important;
  }

  /* ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #616161;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #545454;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #d6d6d6;
  }
  ::-webkit-scrollbar-track:active {
    background: #333333;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  } */


  .add-bottom-radius {
    border-radius: 8px;
  }

  .rm-bottom-radius{
    border-radius: 8px 8px 0px 0px;
  }
`;
