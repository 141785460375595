import { Axios, withAuth } from '../../../../utils/axios';

interface Artist {
  _id: string;
  name: string;
  rank: number;
  picture_profile: {
    _id: string;
    url: string;
    type: string;
    datetime_created: string;
    datetime_updated: string;
    processed: boolean;
    resources: string[];
  };
}

interface createProjectResponse {
  data: {
    data: Artist[];
  };
}

export const searchArtists = async (query: string, defaultToken?: string) => {
  try {
    const { data }: createProjectResponse = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/users/find/${query}`, withAuth(defaultToken));
    const response: Artist[] = [];
    data.data.forEach((item) => {
      if (item.picture_profile) response.push(item);
    });
    return response;
  } catch (error) {
    console.error('Error on getting artists: ', error);
  }
};
