import React from 'react';
import { Poll, Quiz } from '../../../interfaces';

interface Polls {
  polls: Poll[];
  isGettingPolls: boolean;
  isPollsGetted: boolean;
  fetchPollsByToken: (defaultToken?: string) => Promise<Quiz[]>;
}

const initialState: Polls = {
  polls: [],
  isGettingPolls: false,
  isPollsGetted: false,
  fetchPollsByToken: async () => []
};

const PollsContext = React.createContext<Polls>(initialState);

const PollsConsumer = PollsContext.Consumer;

export { PollsContext, PollsConsumer };

export type { Polls };
