import styled, { css } from 'styled-components';

type Props = {
  isFocused?: boolean;
  isAllGuides?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  isAllGuidesActive?: boolean;
};

export const Container = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  min-height: ${(props: Props) => (props.isLoading ? '400px' : 'none')};
  max-height: 928px !important;
  background: pink;
  background: white;
  box-shadow: 0px 30px 40px -20px #e4e5e6;

  @media (max-width: 768px) {
    & {
      width: 100%;
      min-width: initial;
    }
  }

  .sticky {
    position: fixed;
    top: 0;
  }

  div.results--container {
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      min-height: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  div.search--container {
    width: 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
    }

    span.see-details {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #817b7b;
    }

    input.search-input {
      background: #f5f5f5;
      caret-color: #0d65ff;
      height: 40px;
      border: 1px solid ${(props: Props) => (props.isFocused ? '#0d65ff' : '#e4e5e6')};
      border-radius: 4px;
      padding-left: 40px;
      width: 100%;
    }
  }

  span.navbar--item {
    color: #8f8f8f;
    padding: 0 0 5px 0;
  }

  span.item--active {
    color: #ef233c;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 5px;
      height: 24px;
      background: #ef233c;
      border-radius: 0px 8px 8px 0px;
    }
  }
`;

export const PostContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .composition-cover{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
  }

  ${(props: Props) =>
    props.isAllGuides &&
    css`
      height: 90px;
    `}

  ${(props: Props) =>
    props.isAllGuidesActive &&
    props.isAllGuides &&
    css`
      background-color: rgb(13, 101, 255, 0.1);
    `}

  ${(props: Props) =>
    props.isActive
      ? css`
          background-color: rgb(13, 101, 255, 0.1);
        `
      : css``}
`;
