import styled from 'styled-components';

export const KanbanFilters = styled.div`
  .react-stars-container {
    /* background: #efefef; */
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filter-input {
    width: 453px;
    background: #f5f5f5;
    border: 1px solid #e4e5e6;
    border-radius: 4px;
    color: #f5f5f5;
    font-size: 0.9rem;
    line-height: 22px;
    color: #acacac;
  }

  @media screen and (max-width: 1160px) {
    .filter-input {
      width: 100%;
    }

    .class-ord {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 676px) {
    .class-ord {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .classification,
    .ordenation {
      margin-left: 0px !important;
      margin: 5px 0;
      width: 100% !important;
    }

    .filters-title {
      justify-content: start !important;
      align-items: center !important;
    }
  }

  .classification,
  .ordenation {
    margin-left: 20px;
    width: fit-content;
    display: flex;
  }

  .filters-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filters-container {
    display: flex;
    flex-direction: row;
  }

  .filters-title {
    width: 110px;
  }
  .filters {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    width: fit-content;
    text-align: center;
    color: #c3c3c3;
    font-weight: bold;
    // border: 1px solid #c3c3c3;
    padding: 0 5px;
    border-radius: 6px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
  }

  .active {
    color: #000;
    border-color: #000;
  }

  span.item--active {
    color: #ef233c;
  }

  span.item--active::before {
    content: '';
    display: block;
    position: absolute;
    background-color: #ef233c;
    width: 4px;
    height: 20px;
    left: 0;
  }

  .filters-container {
    width: 170px;
  }
`;

export const StyledBoard = styled.div`
  section.react-trello-lane {
    padding: 0;

    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  div.vertical {
    margin: 6px;
  }
`;

export const AddMusicDropDown = styled.div`
  background-color: #fff;
  width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 78px;
  /* box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25); */
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 6px 8px #e4e5e6;
  /* border-radius: 6px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px !important;

  .add-music-item {
    width: 80%;
    padding: 10px 5px;
  }

  .border-t {
    border-top: 1px solid #c3c3c3;
  }
`;

export const Card = styled.div`
  width: 100%;
  background: #fff;
  height: fit-content;
  box-shadow: 0px 30px 40px -20px #e4e5e6;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  padding: 0 1.5rem 1.5rem 1.5rem;

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  button {
    img {
      animation: spinner infinite 1s linear;
      text-align: center;
    }
  }
`;

export const Collapse = styled.div`
  width: 100%;
  background: #fff;
  height: fit-content;
  box-shadow: 0px 30px 40px -20px #e4e5e6;
  display: flex;
  border-radius: 0px 0px 8px 8px;
  padding: 0 1.5rem 1.5rem 1.5rem;

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  button {
    img {
      animation: spinner infinite 1s linear;
      text-align: center;
    }
  }
`;
