import styled from 'styled-components';

export const SearchInput = styled.button`
  /* Surface / 200 */

  background: #f5f5f5;
  /* Surface / 300 */

  border: 1px solid #e4e5e6;
  border-radius: 4px;
  padding: 6.5px 6.5px 6.5px 35px;
`;

export const ResponsiveFlexDirection = styled.div`
  flex-direction: row;

  #async-select {
    margin-top: 7px;
  }

  .async-input {
    border-bottom: 1px solid #c3c3c4;
  }

  .form-projects-column-left {
    margin-right: 10px;
  }

  .form-projects-column-right {
  }

  @media (max-width: 640px) {
    flex-direction: column;

    .form-projects-column-left {
      margin-right: 0;
    }

    .form-projects-column-right {
    }
  }
`;
