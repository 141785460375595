import styled from 'styled-components';

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (min-width: 1400px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 768px) {
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
`;
