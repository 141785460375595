import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { PostContainer } from './styles';
import { Composition } from '../../interfaces';

import MPEye from '../../images/mpf-eye.svg';
import MPlayButton from '../../images/mpf-play-button.svg';
// import { ArtistDashContext } from '../../context';

interface Props {
  composition?: Composition;
  isSearching?: boolean;
  isActive?: boolean;
  hasFinished?: boolean;
  handleSelect?: () => void;
  handlePlaySelect?: () => void;
}

export const ArtistPost: React.FC<Props> = ({ composition, isSearching, handleSelect, hasFinished, handlePlaySelect }) => {
  let name = '';
  let plays = 0;
  let indications = 0;
  let datetime_uploaded: any = '';

  // const { currentComposition } = useContext(ArtistDashContext);

  if (composition) {
    datetime_uploaded = composition.createdAt || String(new Date());
    plays = composition.playsCount || 0;
    indications = composition.indicationsCount || 0;
    name = composition.title || '';
  }

  const dateParsed = moment(datetime_uploaded).format('L LT');
  const dateFormated = dateParsed.split(' ')[0];
  const timeFormated = dateParsed.split(' ')[1];

  return (
    <PostContainer isActive={!!composition?._id}>
      <div style={{ width: '40%', minWidth: 80, maxWidth: 96 }}>
        <button onClick={handlePlaySelect} type="button" className={`flex h-full w-full justify-center items-center ${'bg-transparent'}`}>
          <img src={MPlayButton} alt="icon-play" />
        </button>
      </div>
      <div
        className="flex justify-around items-center w-full"
        onClick={handleSelect}
        onKeyDown={handleSelect}
        role="button"
        tabIndex={0}
        style={{ borderBottom: '1px solid #E4E5E6' }}
      >
        <div className="flex flex-col py-3" style={{ width: '60%' }}>
          <span className="text-lg font-semibold truncate w-64 sm:w-56" title={name}>
            {name}
          </span>

          {!isSearching && (
            <>
              <span className="text-sm text-gray-700">
                <FormattedMessage
                  id="dashboard.composer.posts.plays-and-indications"
                  values={{ plays, indications }}
                  defaultMessage="{plays} plays {indications} indicações"
                />
              </span>
            </>
          )}

          <span className="text-xs text-gray-700 uppercase">
            <FormattedMessage
              id="dashboard.composer.posts.posted-in"
              values={{ date: dateFormated, time: timeFormated }}
              defaultMessage="Postada em {date} às {time}"
            />
          </span>
        </div>

        <img src={MPEye} className="cursor-pointer" alt="logo-eyes" />
      </div>
    </PostContainer>
  );
};
