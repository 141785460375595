/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Media } from 'react-breakpoints';

import moment from 'moment';
import 'moment/locale/pt-br';
import DashboardContainer from '../DashboardContainer';

import IndicationTimeGraph from '../../components/IndicationTimeGraph/IndicationTimeGraph';
import MapComponent from '../../components/MapComponent/MapComponent';
import InfoCard from '../../components/InfoCard/InfoCard';
import PublishedPostsSearch from '../../components/PublishedPostsSearch/PublishedPostsSearch';

import { MediaOptions, PriceObj } from '../../interfaces';

import MPClock from '../../images/mpf-clock.svg';
import SliderCards from '../../components/SliderCards/SliderCards';
// eslint-disable-next-line import/no-named-as-default-member
import DashCard from '../../components/DashCard/DashCard';

import { WhoMatchesContext, AuthContext, ArtistDashContext, ComposerDashContext } from '../../context';
import { DashContext } from '../../context/dash/Context';

type Props = {
  activePlan?: PriceObj;
};

const WhoMatches: React.FC<Props> = React.memo(({ activePlan }) => {
  const [inputValue, setInputValue] = useState('');
  const [showResults, setShowResults] = useState<boolean>(false);

  const { token } = useContext(AuthContext);

  const { setActiveArtistTrack } = useContext(ArtistDashContext);
  const { setActivePollsTrack } = useContext(DashContext);
  const { setActiveCompositionsTrack } = useContext(ComposerDashContext);

  useEffect(() => {
    setActiveArtistTrack(undefined);
    setActivePollsTrack(undefined);
    setActiveCompositionsTrack(undefined);
  }, []);

  const {
    quizes,
    activeQuiz,
    activeQuizPost,
    selectedQuizTopArtists,
    composers,
    quizIndicationsByDay,
    quizLocationIndications,
    isGettingQuizInfos,
    quizIndicationByMoment,
    quizPlaysByDay,
    handleQuizSelect,
    fetchQuizesByToken
  } = useContext(WhoMatchesContext);

  useEffect(() => {
    (async () => {
      if (token) {
        if (!quizes) {
          await fetchQuizesByToken(token);
        }
        setShowResults(true);
      }
    })();
  }, [quizes, activeQuiz, token]);

  const composersName = composers?.map((item) => item.name);

  const ordenatedQuizes = quizes?.sort((a, b) => {
    const x = a.datetime_created.toLowerCase();
    const y = b.datetime_created.toLowerCase();
    if (x > y) {
      return -1;
    }
    if (x < y) {
      return 1;
    }
    return 0;
  });

  return (
    <DashboardContainer activePlan={activePlan}>
      <div className="w-full flex flex-col" id="page-top-div">
        <div className="w-full hidden sm:block">
          <span className="font-semibold text-2xl">
            <FormattedMessage id="dashboard.whomatches.page-main-title" defaultMessage="Com quem Combina?" />
          </span>
          {ordenatedQuizes?.length ? (
            <span className="text-gray-700 flex items-center">
              <img src={MPClock} alt="icon-clock" />
              <span className="ml-1">
                <FormattedMessage
                  id="dashboard.whomatches.last-published-post"
                  values={{
                    date: moment(ordenatedQuizes[0]?.datetime_created).format('DD/MM/YYYY'),
                    hourDate: moment(ordenatedQuizes[0]?.datetime_created).format('LT')
                  }}
                  defaultMessage="Último post publicado em: {date}"
                />
              </span>
            </span>
          ) : null}
        </div>
        <div className="flex flex-col lg:flex-row mt-0 sm:mt-8">
          <div className="flex h-full justify-center ">
            <PublishedPostsSearch
              onPostSelect={handleQuizSelect}
              changeInput={setInputValue}
              inputValue={inputValue}
              isWhoMatches
              isLoading={isGettingQuizInfos && !activeQuiz}
              quizData={quizes?.sort((a, b) => b.indications - a.indications)}
              className="rounded-none sm:rounded-lg"
              style={{ width: '100%' }}
            />
          </div>
          <hr className="block sm:hidden" />
          <div className="flex flex-col pt-0 sm:pt-8 md:pt-8 md:pl-0 lg:pt-0 lg:pl-8 w-full">
            {isGettingQuizInfos ? (
              <div className="pb-8 hidden sm:block">
                <InfoCard guideName={activeQuiz?.post_name || undefined} composersName={composersName} isLoading={isGettingQuizInfos} />
              </div>
            ) : quizes?.length ? (
              <div className="pb-8 hidden sm:block">
                <InfoCard guideName={activeQuiz?.post_name || undefined} composersName={composersName} isLoading={isGettingQuizInfos} />
              </div>
            ) : null}

            <SliderCards>
              <DashCard
                isLoading={isGettingQuizInfos}
                title="Plays"
                width="w-full"
                isPlays
                showResults={showResults}
                playsByDay={activeQuiz && activeQuiz?.plays > 0 ? quizPlaysByDay : []}
                totalValue={activeQuiz?.plays}
              />
              <DashCard
                isLoading={isGettingQuizInfos}
                title="Indicações"
                width="w-full"
                isIndications
                showResults={showResults}
                totalValue={activeQuiz?.indications}
                indicationsByDay={activeQuiz && activeQuiz?.indications > 0 ? quizIndicationsByDay : []}
              />
              <DashCard
                isLoading={isGettingQuizInfos}
                title="Com quem combina?"
                isWhoMatches
                showResults={showResults}
                topArtists={activeQuiz && activeQuiz?.indications > 0 ? selectedQuizTopArtists : undefined}
                width="w-full h-full"
              />
            </SliderCards>

            <Media>
              {({ breakpoints, currentBreakpoint }: MediaOptions) =>
                breakpoints[currentBreakpoint] > breakpoints.sm ? (
                  <div className="my-8">
                    <MapComponent isWhoMatches data={quizLocationIndications} />
                    <div className="w-full flex flex-col">
                      <IndicationTimeGraph
                        isActive={!!activeQuizPost}
                        isWhoMatches
                        isLoading={isGettingQuizInfos}
                        data={
                          quizIndicationByMoment?.data?.length
                            ? activeQuiz && activeQuiz.indications > 0
                              ? quizIndicationByMoment
                              : undefined
                            : undefined
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex flex-col">
                    <IndicationTimeGraph
                      isActive={!!activeQuizPost}
                      isWhoMatches
                      isLoading={isGettingQuizInfos}
                      data={
                        quizIndicationByMoment?.data?.length
                          ? activeQuiz && activeQuiz.indications > 0
                            ? quizIndicationByMoment
                            : undefined
                          : undefined
                      }
                    />
                    <hr />
                  </div>
                )
              }
            </Media>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
});

export default WhoMatches;
