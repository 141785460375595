/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import reaisConverter from '../../utils/simpleReaisConverter/reaisConverter';
import 'moment/locale/pt-br';
import AccountManagementContainer from '../AccountManagementContainer';
import MySignatureComponent from '../MySignatureComponent/MySignatureComponent';
import { Table } from './styles';
import useUserData from '../../utils/customHooks/useUserData';
import { CheckoutContext, CheckoutConsumer } from '../../context/checkout';
import { getActiveProduct, getCurrentInvoice, getCurrentSubscription, canTrial } from '../../utils/checkoutHelpers';
import Spinner from '../../components/Spinner/Spinner';
import { AuthContext } from '../../context/Auth';

const Receipts: React.FC = () => {
  // criei esse estado para impedir que os dados sejam mostrados na tela antes que houvessem as chamadas na api
  const [showResults, setShowResults] = useState<boolean>(false);

  const user = useUserData();
  const { token } = useContext(AuthContext);
  const {
    prices,
    userSubscriptions,
    userInvoices,
    customer,
    userPaymentMethod,
    userUpcomingInvoice,
    getPrices,
    getUserUpcomingInvoice,
    getUserSubscriptions,
    getUserPaymentMethod,
    getUserInvoices
  } = useContext(CheckoutContext);

  useEffect(() => {
    (async () => {
      if (token) {
        if (!prices) await getPrices();
        if (!userSubscriptions?.length) await getUserSubscriptions(token);
        if (!userPaymentMethod) await getUserPaymentMethod(token);
        if (!userInvoices?.length) await getUserInvoices(token);
        if (!userUpcomingInvoice) await getUserUpcomingInvoice(token);
      }
    })();
    setShowResults(true);
  }, [token]);

  const activePrice = getActiveProduct(userSubscriptions, prices);

  const nameFormated = user?.name;

  const priceName = activePrice?.name;

  const currentSubscription = userSubscriptions && getCurrentSubscription(userSubscriptions);

  const currentInvoice = userInvoices && getCurrentInvoice(userInvoices);

  const trialEndDate = moment((currentSubscription?.stripe_subscription?.trial_end as number) * 1000).format('LL');

  const userCanTrial = canTrial(customer, activePrice);

  const getPriceNameByIdProduct = (idProduct: string) => {
    const price = prices?.find((price) => price.product?.id_stripe_product === idProduct);
    return price?.name;
  };

  return (
    <CheckoutConsumer>
      {({
        isGettingPrices,
        isGettingUserSubscriptions,
        isGettingUserInvoices,
        isGettingUserUpcomingInvoice,
        isGettingUserPayemntMethod
      }) => (
        <AccountManagementContainer title="Recibos">
          {isGettingPrices ||
          isGettingUserSubscriptions ||
          isGettingUserPayemntMethod ||
          isGettingUserInvoices ||
          isGettingUserUpcomingInvoice ||
          !token ? (
            <div className="w-full mt-8 flex justify-center items-center">
              <Spinner
                isLoading={
                  isGettingPrices ||
                  isGettingUserSubscriptions ||
                  isGettingUserPayemntMethod ||
                  isGettingUserInvoices ||
                  isGettingUserUpcomingInvoice ||
                  !token
                }
              />
            </div>
          ) : (
            showResults &&
            (userInvoices?.length ? (
              <div className="flex flex-col lg:flex-row w-full items-start justify-between">
                {activePrice?.id_product !==
                (process.env.REACT_APP_NODE_ENV === ('dev' || 'local')
                  ? '5f3db81dbd9e96ba6c4cabd4'
                  : '5f6c23949d3957ac67ae9633') ? (
                  <MySignatureComponent
                    showButtons={false}
                    componentType={1}
                    currentInvoice={currentInvoice}
                    currentSubscription={currentSubscription}
                    nameFormated={nameFormated}
                    trialEndDate={trialEndDate}
                    userUpcomingInvoice={userUpcomingInvoice}
                    userCanTrial={userCanTrial}
                    activePrice={activePrice}
                    userPaymentMethod={userPaymentMethod}
                    priceName={priceName}
                    className="w-full lg:w-2/5"
                    isFullHeight
                  />
                ) : null}

                <div
                  style={{ border: '1px solid #E4E5E6' }}
                  className={`flex bg-white rounded-lg p-8 w-full ml-0 mt-10 lg:${
                    userSubscriptions?.length ? 'ml-10' : 'ml-0'
                  } lg:mt-0 flex-col overflow-x-auto`}
                >
                  <span className="text-xl font-semibold py-3">Detalhes da cobrança</span>
                  <hr />
                  <Table className="overflow-x-auto w-full">
                    <thead>
                      <tr>
                        <th className="text-sm font-normal text-left">
                          <span className="mr-40">Vencimento</span>
                        </th>
                        <th className="text-sm font-normal text-left whitespace-no-wrap">
                          <span className="mr-10">Valor mensal</span>
                        </th>
                        <th className="text-sm font-normal text-left">
                          <span className="mr-16">Descrição</span>
                        </th>
                        {/* estou comentando porque a rota de invoices não exibe as informações do método de pagamento <th className="text-sm font-normal text-left">Forma de pagamento</th> */}
                        <th className="text-sm font-normal text-left">
                          {' '}
                          <span className="mr-10">Situação</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userInvoices?.length &&
                        userInvoices?.map((item, index) => (
                          <tr key={index}>
                            <td className="font-semibold" style={{ color: '#0D65FF' }}>
                              {moment(
                                ((item?.lines?.data[0]?.period?.end as number) &&
                                  (item?.lines?.data[0]?.period?.end as number)) * 1000
                              ).format('LL')}
                            </td>
                            <td className="text-sm">{reaisConverter((item.total as number) / 100)}</td>
                            <td className="text-sm">{getPriceNameByIdProduct(item.lines.data[0].price.product)}</td>
                            <td className="text-sm">{item.paid ? 'Pago' : 'Pendente'}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <span className="text-sm text-gray-600">
                    OBS: Mostramos apenas até um ano do histórico de cobranças.
                  </span>
                </div>
              </div>
            ) : (
              <span>Você não possui histórico de recibos.</span>
            ))
          )}
        </AccountManagementContainer>
      )}
    </CheckoutConsumer>
  );
};

export default Receipts;
