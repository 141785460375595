import styled from 'styled-components';

type Props = {
  isCheckout?: boolean;
};

export const DropdownContainer = styled.div`
  position: absolute;
  right: 0px;
  background-image: ${(props: Props) => 'none'};
  top: 88px;
  width: 12rem;
  display: flex;
  align-items: start;
  background-color: white;
  flex-direction: column;
  padding: 0.5rem 0;
  border-radius: 4px;
  z-index: 9999999;
  animation: softAppear 0.2s;

  @keyframes softAppear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  a {
    padding: 10px 0 10px 15px;
    width: 100%;
    font-size: 14px;
    text-align: left;
    color: #ccc;
  }

  a.leave {
    color: #222;
  }

  a.active {
    color: #222;

    &::before {
      content: '';
      left: 0;
      position: absolute;
      width: 2%;
      border-radius: 0 8px 8px 0;
      margin-top: -5px;
      height: 35px;
      background: #ef233c;
    }
  }
`;
