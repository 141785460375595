/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import NumberFormat from 'react-number-format';
import MPuparrow from '../../images/mpf-up-arrow.svg';
import MPdownarrow from '../../images/mpf-down-arrow.svg';
import defaultUser from '../../images/default_avatar.png';
import {
  Poll,
  TopArtist,
  TopShare,
  VoteByDay,
  CompositionPlay,
  CompositionIndication,
  CompositionVote,
  CompositionTopArtists
} from '../../interfaces';

import Spinner from '../Spinner/Spinner';
import SocialMedia from '../SocialMedia/SocialMedia';
import Message from '../Message/Message';
import { formatTimestamp } from '../../utils/firebase';

type Props = {
  title?: string;
  totalValue?: number;
  todayValue?: number;
  width: string;
  isPlays?: boolean;
  isIndications?: boolean;
  isMostIndicated?: boolean;
  isMyPosition?: boolean;
  isTopIndicated?: boolean;
  isSocialMedia?: boolean;
  position?: number;
  positionIndictaion?: number;
  percentage?: number;
  isUp?: boolean;
  actualPositions?: number;
  lastIndicationDate?: string;
  isWhoMatches?: boolean;
  isPollResult?: boolean;
  mostIndicated?: TopArtist;
  isLoading?: boolean;
  playsByDay?: CompositionPlay[];
  indicationsByDay?: CompositionIndication[];
  topArtists?: CompositionTopArtists[];
  topShares?: TopShare[];
  isTotal?: boolean;
  showResults?: boolean;
  selectedPoll?: Poll;
  isVotesNumber?: boolean;
  votesByDay?: VoteByDay[];
  isCompositions?: boolean;
  isPoll?: boolean;
  isActive?: boolean;
  myRanking?: any;
} & React.HTMLAttributes<HTMLDivElement>;

type ProgressProps = {
  topIndicatedArray?: CompositionTopArtists[];
  socialMediasArray?: TopShare[];
  playsByDay?: CompositionPlay[];
  indicationsByDay?: CompositionIndication[];
  isPlays?: boolean;
  isIndications?: boolean;
  isTopIndications?: boolean;
  isSocialMedia?: boolean;
  isWhoMatches?: boolean;
  topArtistsArray?: CompositionTopArtists[];
  isPollResult?: boolean;
  poll?: Poll;
  todayPlays?: number;
  todayVotes?: number;
  isVotesNumber?: boolean;
  votesByDay?: CompositionVote[];
  todayIndications?: number;
  isLoading?: boolean;
};

export const ProgressBar = ({
  playsByDay,
  indicationsByDay,
  isPlays,
  isIndications,
  isTopIndications,
  topIndicatedArray,
  isSocialMedia,
  socialMediasArray,
  isWhoMatches,
  isPollResult,
  poll,
  topArtistsArray,
  todayPlays,
  todayIndications,
  votesByDay,
  todayVotes,
  isVotesNumber
}: ProgressProps) => {
  const topArtistsWithRank = topArtistsArray?.map((item, idx) => {
    return {
      ...item,
      rank: idx + 1
    };
  });

  const today = moment().format('DD/MM');
  const formatedDate = (item: CompositionPlay | CompositionIndication | CompositionVote) => moment(item?.createdAt).format('DD/MM');

  let whatsappItem;
  let instagramItem;
  let facebookItem;
  let twitterItem;
  let telegramItem;
  let otherMediasItem;

  socialMediasArray?.forEach((item) => {
    if (item?.target === 'TEXT') whatsappItem = item;
    if (item?.target === 'INSTAGRAM') instagramItem = item;
    if (item?.target === 'FACEBOOK') facebookItem = item;
    if (item?.target === 'TWITTER') twitterItem = item;
    if (item?.target === 'TELEGRAM') telegramItem = item;
    if (item?.target === 'OTHER') otherMediasItem = item;
  });

  const socialMediasComponents = [
    <SocialMedia item={whatsappItem} isWhatsApp />,
    <SocialMedia item={instagramItem} isInstagram />,
    <SocialMedia item={facebookItem} isFacebook />,
    <SocialMedia item={twitterItem} isTwitter />,
    <SocialMedia item={telegramItem} isTelegram />,
    <SocialMedia item={otherMediasItem} isOtherMedias />
  ];

  const sortedSocialMediaArrayByCount = socialMediasComponents.sort(
    (a, b) => (b.props.item ? b.props.item.count : 0) - (a.props.item ? a.props.item.count : 0)
  );

  return (
    <>
      {isVotesNumber && todayVotes && todayVotes > 0
        ? votesByDay?.length &&
          votesByDay.map(
            (item, idx) =>
              moment(formatTimestamp(item?.createdAt) + '+00:00').isBefore(moment()) &&
              formatedDate(item) !== today && (
                <React.Fragment key={idx}>
                  <div className="w-full flex flex-col mb-8">
                    <div className="w-full flex justify-between">
                      <span>{moment(formatTimestamp(item?.createdAt)).format('DD/MM')}</span>
                      {/* <span>{item?.count}</span> */}
                    </div>
                    <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
                      <div style={{ borderBottom: '2px solid #0D65FF', width: `${(item?.percentage || 0) * 100}%` }} className="rounded-full"></div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )
        : votesByDay?.length &&
          votesByDay.map(
            (item, idx) =>
              idx <= 4 && (
                <React.Fragment key={idx}>
                  <div className="w-full flex flex-col mb-8">
                    <div className="w-full flex justify-between">
                      <span>{moment(item?.createdAt).format('DD/MM')}</span>
                      <span>{item?.count}</span>
                    </div>
                    <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
                      <div style={{ borderBottom: '2px solid #0D65FF', width: `${(item?.percentage || 0) * 100}%` }} className="rounded-full"></div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )}
      {isPlays && todayPlays && todayPlays > 0
        ? playsByDay?.length &&
          playsByDay.map(
            (item, idx) =>
              moment(formatTimestamp(item?.createdAt)).isBefore(moment()) &&
              formatedDate(item) !== today && (
                <React.Fragment key={idx}>
                  <div className="w-full flex flex-col mb-8">
                    <div className="w-full flex justify-between">
                      <span>{moment(formatTimestamp(item?.createdAt)).format('DD/MM')}</span>
                      <span>{item?.count}</span>
                    </div>
                    <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
                      <div style={{ borderBottom: '2px solid #0D65FF', width: `${item?.percentage}%` }} className="rounded-full"></div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )
        : playsByDay?.length &&
          playsByDay.map(
            (item, idx) =>
              idx <= 4 && (
                <React.Fragment key={idx}>
                  <div className="w-full flex flex-col mb-8">
                    <div className="w-full flex justify-between">
                      <span>{moment(formatTimestamp(item?.createdAt)).format('DD/MM')}</span>
                      <span>{item?.count}</span>
                    </div>
                    <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
                      <div style={{ borderBottom: '2px solid #0D65FF', width: `${item?.percentage}%` }} className="rounded-full"></div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )}
      {isIndications && todayIndications && todayIndications > 0
        ? indicationsByDay?.length &&
          indicationsByDay.map(
            (item, idx) =>
              moment(formatTimestamp(item?.createdAt)).isBefore(moment()) &&
              formatedDate(item) !== today && (
                <React.Fragment key={idx}>
                  <div className="w-full flex flex-col mb-8">
                    <div className="w-full flex justify-between">
                      <span>{moment(formatTimestamp(item?.createdAt)).format('DD/MM')}</span>
                      <span>{item?.count}</span>
                    </div>
                    <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
                      <div style={{ borderBottom: '2px solid #0D65FF', width: `${item?.percentage}%` }} className="rounded-full"></div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )
        : indicationsByDay?.length &&
          indicationsByDay.map(
            (item, idx) =>
              idx <= 4 && (
                <React.Fragment key={idx}>
                  <div className="w-full flex flex-col mb-8">
                    <div className="w-full flex justify-between">
                      <span>{moment(formatTimestamp(item?.createdAt)).format('DD/MM')}</span>
                      <span>{item?.count}</span>
                    </div>
                    <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
                      <div style={{ borderBottom: '2px solid #0D65FF', width: `${item?.percentage}%` }} className="rounded-full"></div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )}
      {/* @TOP INDICADOS */}
      {isTopIndications &&
        topIndicatedArray?.map((item, idx) => (
          <React.Fragment key={idx}>
            <div className="w-full flex mt-4">
              <img
                style={{ width: '56px', height: '56px', minWidth: '56px', borderRadius: '100%' }}
                className="mr-6"
                src={item?.userProfile?.avatarUrl || defaultUser}
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = defaultUser;
                }}
                alt="user-avatar"
              />
              <div className="w-full flex flex-col items-center">
                <div className="w-full flex relative">
                  <div className="w-full flex items-start">
                    <span style={{ color: '#817B7B' }} className="mb-8 md:mb-6">
                      {item?.userProfile?.name}
                    </span>
                  </div>

                  <span style={{ color: '#0D65FF' }} className="whitespace-no-wrap font-semibold text-base leading-6 absolute bottom-0 right-0 mb-2">
                    {item?.indicationsCount} ({(item?.percentage).toFixed(item?.percentage % 2 === 0 ? 0 : 2)}
                    %)
                  </span>
                </div>
                <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
                  <div style={{ borderBottom: '4px solid #0D65FF', width: `${item?.percentage}%` }} className="rounded-full"></div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      {isSocialMedia && sortedSocialMediaArrayByCount.map((item, idx) => <React.Fragment key={idx}> {item} </React.Fragment>)}
      {isWhoMatches &&
        topArtistsWithRank?.map((item, idx) => (
          <React.Fragment key={idx}>
            {idx === 0 && (
              <img
                style={{ width: '54px', height: '54px', borderRadius: '100%' }}
                src={item?.userProfile?.avatarUrl || defaultUser}
                alt="user-avatar"
              />
            )}
            <div
              style={{ color: `${idx === 0 ? '#202020' : '#504D4D'}` }}
              className={`${idx === 0 && 'text-base font-semibold leading-6 mt-8'} ${idx === 1 && 'mt-8'} w-full flex mt-2 text-sm`}
            >
              <span className="mr-3">{item?.rank}#</span>
              <div className="w-full flex flex-col">
                <div className="w-full flex justify-between">
                  <div className="flex items-start h-full">
                    <span className="mb-2">{item?.userProfile?.name}</span>
                  </div>
                  <span
                    style={{ color: `${idx === 0 ? '#0D65FF' : '#504D4D'}` }}
                    className={`${idx === 0 && 'text-base font-semibold leading-6'} text-sm whitespace-no-wrap font-normal leading-6 ml-2`}
                  >
                    {(item?.percentage * 100).toFixed(2)}%
                  </span>
                </div>
                <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
                  <div
                    style={{
                      borderBottom: `${idx === 0 ? '4px' : '2px'} solid #0D65FF`,
                      width: `${item?.percentage * 100}%`
                    }}
                    className="rounded-full"
                  ></div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      {isPollResult && poll && (
        <div className="w-full md:h-full flex flex-col justify-between">
          <span style={{ color: '#202020', maxWidth: '100%' }} className="font-normal text-2xl leading-8 whitespace-pre-line">
            {poll?.question}
          </span>
          <div className="w-full">
            <div className="w-full flex justify-between mb-3">
              <div style={{ color: '#0D65FF' }} className="w-full flex flex-col">
                <span className="text-base leading-6 font-normal">Sucesso</span>
                <span style={{ fontSize: '40px', lineHeight: '56px' }} className="font-semibold">
                  {((poll.votes > 0 ? poll.votes1 / poll.votes : 0) * 100).toFixed(0)}%
                </span>
                <span style={{ fontSize: '10px' }} className="leading-4">
                  {poll.votes1} {poll.votes1 === 0 ? 'votos' : poll.votes1 > 1 ? 'votos' : 'voto'}
                </span>
              </div>
              <div style={{ color: '#817B7B' }} className="w-full flex flex-col items-end">
                <span className="text-base leading-6 font-normal">Hoje não</span>
                <span style={{ fontSize: '40px', lineHeight: '56px' }} className="font-semibold">
                  {((poll.votes > 0 ? poll.votes2 / poll.votes : 0) * 100).toFixed(0)}%
                </span>
                <span style={{ fontSize: '10px' }} className="leading-4">
                  {poll.votes2} {poll.votes2 === 0 ? 'votos' : poll.votes2 > 1 ? 'votos' : 'voto'}
                </span>
              </div>
            </div>
            <div style={{ background: '#E4E5E6', height: '10px' }} className="w-full rounded-full">
              <div
                style={{
                  background: '#0D65FF',
                  height: '10px',
                  width: `${(poll.votes > 0 ? poll?.votes1 / poll?.votes : 0) * 100}%`
                }}
                className="rounded-full"
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DashCard = ({
  title,
  totalValue,
  width,
  isPlays,
  isIndications,
  isTopIndicated,
  isSocialMedia,
  isMostIndicated,
  isMyPosition,
  isWhoMatches,
  isPollResult,
  isUp,
  position,
  actualPositions,
  positionIndictaion,
  percentage,
  lastIndicationDate,
  mostIndicated,
  playsByDay,
  indicationsByDay,
  topArtists,
  topShares,
  isLoading,
  showResults,
  selectedPoll,
  isVotesNumber,
  votesByDay,
  myRanking
}: Props) => {
  const today = moment().format('DD/MM');

  let todayPlays = 0;
  let todayIndications = 0;
  let todayVotes = 0;

  votesByDay?.forEach((item) => {
    const formatedDate = moment(item?.date).format('DD/MM');
    if (today === formatedDate) {
      todayVotes++;
    }
  });

  playsByDay?.forEach((item) => {
    const formatedDate = moment(formatTimestamp(item?.createdAt)).format('DD/MM');
    if (today === formatedDate) {
      todayPlays++;
    }
  });

  indicationsByDay?.forEach((item) => {
    const formatedDate = moment(formatTimestamp(item?.createdAt)).format('DD/MM');
    if (today === formatedDate) {
      todayIndications++;
    }
  });

  const formatedTotalValue = <NumberFormat value={(totalValue as number) / 1000} displayType="text" decimalSeparator="," />;
  const todayFormatedValue = isPlays
    ? todayPlays && <NumberFormat value={todayPlays / 1000} displayType="text" decimalSeparator="," />
    : isIndications
    ? todayIndications && <NumberFormat value={(todayIndications as number) / 1000} displayType="text" decimalSeparator="," />
    : isVotesNumber && <NumberFormat value={(todayVotes as number) / 1000} displayType="text" decimalSeparator="," />;

  const formatedTodayValueMessage = () => {
    if (isVotesNumber) {
      const message = todayVotes === 0 ? 'novos votos hoje' : todayVotes > 1 ? 'novos votos hoje' : 'novo voto hoje';
      return message;
    }

    if (isPlays) {
      const message = todayPlays === 0 ? 'novos plays hoje' : todayPlays > 1 ? 'novos plays hoje' : 'novo play hoje';
      return message;
    }

    if (isIndications) {
      const message = todayIndications === 0 ? 'novas indicações hoje' : todayIndications > 1 ? 'novas indicações hoje' : 'nova indicação hoje';
      return message;
    }

    if (isMyPosition) {
      let message = '';
      if (!isUp) {
        if (actualPositions && actualPositions > 1) {
          message = `descendo ${actualPositions} posições hoje`;
        } else if (actualPositions) {
          message = `descendo ${actualPositions} posição hoje`;
        }
      }

      if (isUp) {
        if (actualPositions && actualPositions > 1) {
          message = `subindo ${actualPositions} posições hoje`;
        } else if (actualPositions) {
          message = `subindo ${actualPositions} posição hoje`;
        }
      }

      return message;
    }

    return null;
  };

  return (
    <div
      style={{
        padding: `${isTopIndicated || isSocialMedia ? '8px 32px 0' : '32px'}`,
        boxShadow: `${isTopIndicated || isSocialMedia ? null : '0px 30px 40px -20px #E4E5E6'}`,
        height: '100%',
        minHeight: 403
      }}
      className={`${width} h-full flex-1 bg-white flex flex-col rounded-none sm:rounded-lg`}
    >
      {isTopIndicated || isSocialMedia ? null : (
        <span style={{ color: '#817B7B' }} className="font-normal text-xl leading-7 mb-6">
          {title}
        </span>
      )}
      {isMostIndicated ? (
        isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Spinner isLoading={isLoading} />
          </div>
        ) : (
          showResults &&
          (mostIndicated ? (
            <>
              <img
                src={mostIndicated.artist_picture_url || defaultUser}
                style={{ width: '3.5rem', height: '3.5rem', minWidth: '3.5rem' }}
                className="mb-6 rounded-full text-left"
                alt="most-indicated"
              />
              <h1 style={{ color: '#202020', fontSize: '1.75rem' }} className="font-semibold leading-9">
                {mostIndicated.artist_name}
              </h1>

              <span style={{ color: '#817B7B', marginBottom: '2.625rem' }} className="text-left text-xs font-normal leading-5">
                Última indicação: {moment(mostIndicated.last_indication).format('DD/MM/YY')} às {moment(mostIndicated.last_indication).format('LT')}
              </span>
              <div className="w-full flex flex-col justify-between">
                <div className="w-full flex flex-col mb-8">
                  <div className="w-full flex justify-between">
                    <span style={{ color: '#817B7B' }}>Indicações</span>
                    <span style={{ color: '#0D65FF' }} className="font-semibold text-base leading-6">
                      {mostIndicated.indications} ({(mostIndicated.percentage * 100).toFixed((mostIndicated.percentage * 100) % 2 === 0 ? 0 : 2)}%)
                    </span>
                  </div>
                  <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full mt-2">
                    <div style={{ borderBottom: '4px solid #0D65FF', width: `${mostIndicated.percentage * 100}%` }} className="rounded-full"></div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Message type="notFound" message="Nenhuma indicação até agora" />
          ))
        )
      ) : null}
      {isPlays && (
        <>
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spinner isLoading={isLoading} />
            </div>
          ) : (
            showResults &&
            (playsByDay?.length ? (
              <>
                <h1 style={{ color: '#202020', lineHeight: '3.5rem', fontSize: '3.5rem' }} className="font-semibold mb-3">
                  {totalValue && totalValue >= 1000 ? formatedTotalValue : totalValue}
                  {totalValue && totalValue >= 1000 ? 'k' : null}
                </h1>
                {playsByDay ? (
                  <div className="flex mb-5">
                    {todayPlays > 0 && <img src={MPuparrow} className="mr-2" alt="mp-up-arrow" />}
                    <span style={{ color: '#817B7B' }} className="text-base leading-6 font-normal">
                      {todayPlays >= 1000 ? todayFormatedValue : todayPlays}
                      {todayPlays >= 1000 ? 'k' : null} {formatedTodayValueMessage()}
                    </span>
                  </div>
                ) : undefined}
                {playsByDay?.length ? (
                  <div className="w-full">
                    <ProgressBar playsByDay={playsByDay} isPlays todayPlays={todayPlays} />
                  </div>
                ) : undefined}
              </>
            ) : (
              <Message type="notFound" message="Nenhum play até agora" />
            ))
          )}
        </>
      )}
      {isVotesNumber && (
        <>
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spinner isLoading={isLoading} />
            </div>
          ) : (
            showResults &&
            (votesByDay?.length ? (
              <>
                <h1 style={{ color: '#202020', lineHeight: '3.5rem', fontSize: '3.5rem' }} className="font-semibold mb-3">
                  {totalValue && totalValue >= 1000 ? formatedTotalValue : totalValue}
                  {totalValue && totalValue >= 1000 ? 'k' : null}
                </h1>
                <div className="flex mb-5">
                  {todayVotes > 0 && <img src={MPuparrow} className="mr-2" alt="mp-up-arrow" />}
                  <span style={{ color: '#817B7B' }} className="text-base leading-6 font-normal">
                    {todayVotes >= 1000 ? todayFormatedValue : todayVotes}
                    {todayVotes >= 1000 ? 'k' : null} {formatedTodayValueMessage()}
                  </span>
                </div>
                <div className="w-full">{/* <ProgressBar votesByDay={votesByDay} isVotesNumber todayVotes={todayVotes} /> */}</div>
              </>
            ) : (
              <Message type="notFound" message="Nenhum voto até agora" />
            ))
          )}
        </>
      )}
      {isIndications &&
        (isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Spinner isLoading={isLoading} />
          </div>
        ) : (
          showResults &&
          (indicationsByDay?.length ? (
            <>
              <h1 style={{ color: '#202020', lineHeight: '3.5rem', fontSize: '3.5rem' }} className="font-semibold mb-3">
                {totalValue && totalValue >= 1000 ? formatedTotalValue : totalValue}
                {totalValue && totalValue >= 1000 ? 'k' : null}
              </h1>
              {indicationsByDay ? (
                <div className="flex mb-5">
                  {todayIndications > 0 ? <img src={MPuparrow} className="mr-2" alt="mp-up-arrow" /> : null}
                  <span style={{ color: '#817B7B' }} className="text-base leading-6 font-normal">
                    {todayIndications >= 1000 ? todayFormatedValue : todayIndications}
                    {todayIndications >= 1000 ? 'k' : null} {formatedTodayValueMessage()}
                  </span>
                </div>
              ) : undefined}
              {indicationsByDay?.length ? (
                <div className="w-full">
                  <ProgressBar indicationsByDay={indicationsByDay} isIndications todayIndications={todayIndications} />
                </div>
              ) : undefined}
            </>
          ) : (
            <Message type="notFound" message="Nenhuma indicação até agora" />
          ))
        ))}

      {isMyPosition && (
        <>
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spinner isLoading={isLoading} />
            </div>
          ) : (
            showResults &&
            (myRanking ? (
              <>
                <h1 style={{ color: '#202020', lineHeight: '3.5rem', fontSize: '3.5rem' }} className="font-semibold mb-3">
                  #{position}
                </h1>
                <div className="flex mb-2">
                  {actualPositions! > 1 ? <img src={isUp ? MPuparrow : MPdownarrow} className="mr-2" alt="mp-up-down" /> : undefined}
                  <span style={{ color: '#817B7B' }} className="text-base leading-6 font-normal">
                    {formatedTodayValueMessage()}
                  </span>
                </div>
                <span className="text-sm text-gray-600">Última indicação: {lastIndicationDate}</span>
                <div className="w-full flex flex-col justify-between mt-auto mb-6">
                  <div className="w-full flex flex-col mb-8">
                    <div className="w-full flex justify-between mb-2">
                      <span>Indicações</span>
                      <span style={{ color: '#0D65FF' }} className="font-semibold text-base leading-6">
                        {positionIndictaion} {`(${(percentage! * 100).toFixed(1)}%)`}
                      </span>
                    </div>
                    <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
                      <div style={{ borderBottom: '4px solid #0D65FF', width: `${50}%` }} className="rounded-full"></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Message type="notFound" message="Nenhuma indicação até agora" />
            ))
          )}
        </>
      )}
      {isWhoMatches && (
        <div className="w-full h-full">
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spinner isLoading={isLoading} />
            </div>
          ) : (
            showResults &&
            (topArtists?.length ? (
              <ProgressBar isLoading={isLoading} topArtistsArray={topArtists} isWhoMatches />
            ) : (
              <Message type="notFound" message="Nenhuma indicação até agora" />
            ))
          )}
        </div>
      )}
      {isPollResult && (
        <div className="w-full flex flex-1 md:h-full">
          {isLoading ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spinner isLoading={isLoading} />
            </div>
          ) : (
            showResults &&
            (selectedPoll ? (
              <ProgressBar isPollResult isLoading={isLoading} poll={selectedPoll} />
            ) : (
              <Message type="notFound" message="Nenhum voto até agora" />
            ))
          )}
        </div>
      )}
      {isTopIndicated && (
        <div className={`w-full h-full flex-1 flex flex-col ${!topArtists?.length && 'items-center justify-center'}`}>
          {isLoading ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spinner isLoading={isLoading} />
            </div>
          ) : (
            showResults &&
            (topArtists?.length ? (
              <ProgressBar topIndicatedArray={topArtists} isLoading={isLoading} isTopIndications />
            ) : (
              <Message type="notFound" message="Nenhuma indicação até agora" />
            ))
          )}
        </div>
      )}
      {isSocialMedia && (
        <div className="w-full h-full flex-1">
          {isLoading ? (
            <div style={{ minHeight: '448px' }} className="w-full h-full flex justify-center items-center">
              <Spinner isLoading={isLoading} />
            </div>
          ) : (
            showResults &&
            (topShares?.length ? (
              <ProgressBar socialMediasArray={topShares} isLoading={isLoading} isSocialMedia />
            ) : (
              <div style={{ marginTop: '110px' }} className="w-full h-full items-center justify-center ">
                <Message type="notFound" message="Nenhum compartilhamento até agora" />
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default DashCard;
