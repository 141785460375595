/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FormattedMessage } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import SecondCheckoutContainer from '../secondCheckoutContainer/SecondCheckoutContainer';
import { Container } from './styles';
import reloadIcon from '../../images/mpf-reload-white.svg';
import checkedIconWhite from '../../images/mpf-checked-white.svg';
import Button from '../../components/Button/Button';
import { CheckoutConsumer, CheckoutContext } from '../../context/checkout';
import { getCurrentSubscription, getActiveProduct, getCurrentInvoice } from '../../utils/checkoutHelpers';
import Spinner from '../../components/Spinner/Spinner';
import reaisConverter from '../../utils/simpleReaisConverter/reaisConverter';
import { AuthContext } from '../../context/Auth';

const ReactivateSubscription: React.FC = () => {
  const {
    prices,
    userSubscriptions,
    userInvoices,
    getPrices,
    getUserSubscriptions,
    getUserInvoices,
    handleReactivateSubscription
  } = useContext(CheckoutContext);

  const { token } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (!prices) await getPrices();
      if (token) {
        if (!userSubscriptions?.length) await getUserSubscriptions(token);
        if (!userInvoices?.length) await getUserInvoices(token);
      }
    })();
  }, [token]);

  const currentSubscription = userSubscriptions && getCurrentSubscription(userSubscriptions);

  const activePrice = getActiveProduct(userSubscriptions, prices);

  const currentInvoice = userInvoices && getCurrentInvoice(userInvoices);

  const cancelAt = moment((currentSubscription?.stripe_subscription?.cancel_at as number) * 1000).format('LL');

  const handleReactivateSub = (subscriptionId: string) => {
    (async () => {
      if (token) await handleReactivateSubscription(subscriptionId, token);
    })();
  };

  return (
    <CheckoutConsumer>
      {({
        isGettingPrices,
        isGettingUserSubscriptions,
        isGettingUserInvoices,
        isReactivatingSubscription,
        reactivatedSubscription
      }) =>
        reactivatedSubscription ? (
          <Redirect to="/account-management" />
        ) : (
          <SecondCheckoutContainer hasGoBack goBackRoute="/checkout/changed-plan" title="reactivate-subscription">
            <Container className="max-w-md xl:max-w-none w-full pb-4 flex flex-col h-full">
              {isGettingPrices || isGettingUserSubscriptions || isGettingUserInvoices ? (
                <div className="w-full mt-8 flex justify-center items-center">
                  <Spinner isLoading={isGettingPrices || isGettingUserSubscriptions || isGettingUserInvoices} />
                </div>
              ) : prices?.length && userSubscriptions?.length && userInvoices?.length ? (
                currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                  <div style={{ maxWidth: '600px' }} className="w-full flex flex-col">
                    <span className="font-normal text-xl lg:leading-10 lg:text-3xl " style={{ color: '#202020' }}>
                      <FormattedMessage
                        id="checkout.reactivate-subscription.plan-type"
                        values={{
                          planName: activePrice?.name
                        }}
                      />
                    </span>
                    <div className="w-full flex flex-col justify-between mb-8 mt-2">
                      <span style={{ color: '#202020' }} className="text-xl font-semibold leading-7">
                        <FormattedMessage
                          id="checkout.reactivate-subscription.plan-price"
                          values={{
                            planPrice: reaisConverter((currentInvoice?.total as number) / 100)
                          }}
                        />
                      </span>
                      <p style={{ color: '#817B7B' }} className="text-sm leading-5 w-full lg:text-base lg:leading-6">
                        <FormattedMessage
                          id="checkout.reactivate-subscription.description-reactivate"
                          values={{
                            cancelAt
                          }}
                        />
                      </p>
                    </div>
                    <div className="w-full max-w-md lg:max-w-none flex items-center jus justify-between mb-5">
                      <Button
                        style={{
                          margin: '0 20px 0 0',
                          borderRadius: '4px',
                          padding: '17px 10px',
                          maxWidth: '285px'
                        }}
                        className="w-full  flex items-center font-semibold justify-center text-xs lg:text-base py-4"
                        theme="secondary"
                        onClick={() => handleReactivateSub(currentSubscription._id)}
                      >
                        {!isReactivatingSubscription && !reactivatedSubscription && (
                          <FormattedMessage id="checkout.reactivate-subscription.reactivate-subscription" />
                        )}
                        {(isReactivatingSubscription || isGettingUserSubscriptions) && !reactivatedSubscription && (
                          <>
                            <FormattedMessage id="checkout.confirm-plan.reactivating-subscription" />
                            <img className="spin" src={reloadIcon} alt="spinner-icon" />
                          </>
                        )}
                        {!isReactivatingSubscription && reactivatedSubscription && (
                          <>
                            <FormattedMessage id="checkout.confirm-plan.reactivated-subscription-success" />
                            <img className="ml-6" src={checkedIconWhite} alt="checked-icon" />
                          </>
                        )}
                      </Button>

                      <Link to="/checkout" className="w-full">
                        <Button
                          style={{
                            margin: '0',
                            borderRadius: '4px',
                            padding: '17px 10px',
                            border: '1px solid #202020',
                            maxWidth: '285px'
                          }}
                          className="w-full text-xs lg:text-base py-4 font-semibold"
                          theme="default"
                        >
                          <FormattedMessage id="checkout.reactivate-subscription.goback" />
                        </Button>
                      </Link>
                    </div>
                    <p style={{ color: '#817B7B' }} className="w-full font-normal text-xs leading-5">
                      <FormattedMessage id="checkout.changed-plan.terms-initial" />
                      <a href="https://static.musicplayce.com/assets/terms_of_use.pdf" className="text-blue-600">
                        <FormattedMessage id="checkout.changed-plan.terms.initial-2" />
                      </a>
                      <FormattedMessage id="checkout.changed-plan.terms.initial-3" />
                      <a
                        className="text-blue-600"
                        href="https://static.musicplayce.com/assets/privacy_policy.pdf"
                        target="blank"
                      >
                        <FormattedMessage id="checkout.changed-plan.terms.initial-4" />
                      </a>
                      <FormattedMessage id="checkout.changed-plan.terms.initial-5" />
                    </p>
                  </div>
                ) : (
                  <Redirect to={`/checkout/changed-plan/${activePrice?._id}`} />
                )
              ) : (
                <span>Você não possui assinatura.</span>
              )}
            </Container>
          </SecondCheckoutContainer>
        )
      }
    </CheckoutConsumer>
  );
};

export default ReactivateSubscription;
