/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Media } from 'react-breakpoints';
import moment from 'moment';

import DashboardContainer from '../../DashboardContainer';
import DashCard from '../../../components/DashCard/DashCard';
import IndicationTimeGraph from '../../../components/IndicationTimeGraph/IndicationTimeGraph';
import SliderCards from '../../../components/SliderCards/SliderCards';
import MapComponent from '../../../components/MapComponent/MapComponent';
import InfoCard from '../../../components/InfoCard/InfoCard';
import DashCardList from '../../../components/DashCardList/DashCardList';

import { MediaOptions, PriceObj } from '../../../interfaces';

import PublishedPostsSearch from '../../../components/PublishedPostsSearch/PublishedPostsSearch';

import MPClock from '../../../images/mpf-clock.svg';
import { useWindowDimensions } from '../../../utils/useWindowsDimension/useWindowsDimension';
import { ArtistDashContext, ComposerDashContext, WhoMatchesContext } from '../../../context';
import { DashContext } from '../../../context/dash/Context';

type Props = {
  activePlan?: PriceObj;
};

const Artist: React.FC<Props> = ({ activePlan }) => {
  const [inputValue, setInputValue] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [composersName, setComposerNames] = useState<string[] | undefined>(undefined);

  const { setActiveCompositionsTrack } = useContext(ComposerDashContext);
  const { setActivePollsTrack } = useContext(DashContext);
  const { setActiveWhoMatchesTrack } = useContext(WhoMatchesContext);

  useEffect(() => {
    setActiveCompositionsTrack(undefined);
    setActivePollsTrack(undefined);
    setActiveWhoMatchesTrack(undefined);
  }, []);

  const {
    compositions,
    currentComposition,
    myRanking,
    playsByDay,
    indicationsByDay,
    locationData,
    indicationMomentData,
    topArtists,
    topShares,
    isLoading,
    composers,
    handlePostSelect,
    fetchPostsByProfileToken
  } = useContext(ArtistDashContext);

  useEffect(() => {
    setShowResults(false);
    (async () => {
      if (!compositions || compositions.length === 0) {
        await fetchPostsByProfileToken();
      }

      if (currentComposition) setComposerNames(composers?.map((item) => item?.name));

      setShowResults(true);
    })();
  }, [compositions, currentComposition]);

  const { width } = useWindowDimensions();

  // '02/08/2020 às 14:17'

  return (
    <DashboardContainer activePlan={activePlan}>
      <div className="w-full flex flex-col" id="page-top-div">
        <div className="w-full hidden sm:block">
          <span className="font-semibold text-2xl">
            <FormattedMessage id="dashboard.artist.page-main-title" defaultMessage="Indicações" />
          </span>
          {!isLoading && compositions.length ? (
            <span className="text-gray-700 flex items-center">
              <img src={MPClock} alt="icon-clock" />
              <span className="ml-1">
                <FormattedMessage
                  id="dashboard.artist.last-published-post"
                  values={{ date: `${moment(myRanking?.last_indication).format('DD/MM/YYYY [às] h:mm')}` }}
                  defaultMessage="Última guia indicada em: ???"
                />
              </span>
            </span>
          ) : null}
        </div>
        <div className="flex flex-col lg:flex-row mt-0 sm:mt-8">
          <div className="flex h-full justify-center ">
            <PublishedPostsSearch
              onPostSelect={handlePostSelect}
              changeInput={setInputValue}
              inputValue={inputValue}
              isLoading={isLoading && !currentComposition}
              compositionData={compositions}
              isArtist
              className="rounded-none sm:rounded-lg"
              style={{ width: '100%' }}
            />
          </div>
          <hr className="block sm:hidden" />
          <div className="flex flex-col pt-0 sm:pt-8 md:pt-8 md:pl-0 lg:pt-0 lg:pl-8 w-full">
            {isLoading ? (
              <div className="pb-8 hidden sm:block">
                <InfoCard guideName={currentComposition?.title} isLoading={isLoading} composersName={composersName} />
              </div>
            ) : compositions.length ? (
              <div className="pb-8 hidden sm:block">
                <InfoCard guideName={currentComposition?.title} isLoading={isLoading} composersName={composersName} />
              </div>
            ) : null}
            <SliderCards>
              <DashCard
                title="Plays"
                width="w-full"
                isPlays
                isActive={!!currentComposition}
                isLoading={isLoading}
                showResults={showResults}
                totalValue={currentComposition?.playsCount}
                playsByDay={currentComposition && currentComposition?.playsCount > 0 ? playsByDay : []}
              />
              <DashCard
                title="Indicações"
                width="w-full"
                isIndications
                isActive={!!currentComposition}
                isLoading={isLoading}
                showResults={showResults}
                totalValue={currentComposition?.indicationsCount}
                indicationsByDay={currentComposition && currentComposition?.indicationsCount > 0 ? indicationsByDay : []}
              />
              <DashCard
                title="Minha posição"
                width="w-full h-full"
                isMyPosition
                myRanking={myRanking || undefined}
                isLoading={isLoading}
                showResults={showResults}
                lastIndicationDate={`${moment(myRanking?.last_indication).format('D/M/YYYY [às] h:mm')}`}
                actualPositions={
                  myRanking ? (myRanking?.rank_yesterday === myRanking?.rank_today ? 0 : myRanking?.rank_yesterday - myRanking?.rank_today) : 0
                }
                position={myRanking ? myRanking?.rank_today + 1 : 0}
                isUp={myRanking ? myRanking?.rank_today >= myRanking?.rank_yesterday : false}
                positionIndictaion={myRanking?.indications}
                percentage={myRanking?.percentage}
              />
            </SliderCards>

            <IndicationTimeGraph data={indicationMomentData?.data.length ? indicationMomentData : undefined} isLoading={isLoading} />
            <hr className="block sm:hidden" />

            <Media>
              {({ breakpoints, currentBreakpoint }: MediaOptions) =>
                breakpoints[currentBreakpoint] > breakpoints.sm ? (
                  <>
                    <MapComponent data={locationData} />
                    <div className={`w-full grid ${width > 1390 && 'grid-cols-2'} gap-8 mt-8 grid-cols-1 md:col-gap-8`}>
                      <DashCardList
                        isTopIndications
                        isLoading={isLoading}
                        showResults={showResults}
                        topArtists={topArtists?.length ? topArtists : []}
                      />
                      <DashCardList isSocialMedia isLoading={isLoading} showResults={showResults} topShares={topShares} />
                    </div>
                  </>
                ) : (
                  <div className={`w-full grid ${width > 1390 && 'grid-cols-2'} gap-8 mt-8 grid-cols-1 md:col-gap-8`}>
                    <DashCardList isSocialMedia isLoading={isLoading} showResults={showResults} topShares={topShares} />
                    <hr className="block sm:hidden" />
                    <DashCardList isTopIndications isLoading={isLoading} showResults={showResults} topArtists={topArtists} />
                  </div>
                )
              }
            </Media>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Artist;
