import React from 'react';
import { Project } from '../../../interfaces';

interface Artist {
  _id: string;
  name: string;
  rank: number;
  picture_profile: {
    _id: string;
    url: string;
    type: string;
    datetime_created: string;
    datetime_updated: string;
    processed: boolean;
    resources: string[];
  };
}

interface ProducerDashState {
  projects: Project[];
  isFetching: boolean;
  pagination: any;
  artistsSugestions: Artist[];
  selectedArtists: Artist[];
  selectedTags: string[];
  setProjects: (projects: any[]) => void;
  setSelectedArtists: (selectedArtists: Artist[]) => void;
  setSelectedTags: (selectedTags: string[]) => void;
  setArtistsSugestions: (artistsSugestions: Artist[]) => void;
  fetch: (page?: number, limit?: number, offset?: number) => Promise<any>;
  search: (name: string) => Promise<any>;
  filter: (collection: Project[], filterText: string) => Project[] | any;
  handleChangePage: (page: number, totalRows: number) => any | void;
  handleChangeRowsPerPage: (currentRowsPerPage: number, currentPage: number) => any | void;
  deleteOne: (id: string) => Promise<any>;
}

const initialState: ProducerDashState = {
  projects: [],
  isFetching: false,
  pagination: {
    page: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10
  },
  artistsSugestions: [],
  selectedArtists: [],
  selectedTags: [],
  setProjects: (projects: any[]) => {},
  setSelectedArtists: () => {},
  setSelectedTags: () => {},
  setArtistsSugestions: () => {},
  fetch: async (page?: number, limit?: number, offset?: number) => {},
  search: async (name: string) => {},
  filter: (collection: Project[], filterText: string) => {},
  handleChangePage: (page: number, totalRows: number) => {},
  handleChangeRowsPerPage: (currentRowsPerPage: number, currentPage: number) => {},
  deleteOne: async (id: string) => {}
};

const ProducerDashContext = React.createContext<ProducerDashState>(initialState);

const ProducerDashConsumer = ProducerDashContext.Consumer;

export { ProducerDashContext, ProducerDashConsumer };

export type { ProducerDashState };
