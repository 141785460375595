import React, { useContext, useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import RectButton from '../../../components/RectButton/RectButton';

import { PriceObj, Project } from '../../../interfaces';
import DashboardContainer from '../../DashboardContainer';
import { SearchInput } from './styles';
import { DeleteModal, DataTableComponent } from './components';
import { showToast } from '../../../components/Toast/Toast';
import { ProducerDashContext } from '../../../context';

// import Stylesheet from '../../../style-vars';

type Props = {
  activePlan?: PriceObj;
};

export const MyProjects: React.FC<Props> = ({ activePlan }) => {
  const [searchInputText, setInputText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [actualProject, setActualProject] = useState<Project | any>({});

  const { projects, setProjects, isFetching, pagination, fetch, search, filter, handleChangePage, handleChangeRowsPerPage, deleteOne } = useContext(
    ProducerDashContext
  );

  const filteredItems = filter(projects, searchInputText);

  const [startSearching, setStartSearching] = useState<any>(null);

  const intl = useIntl();
  const history = useHistory();

  async function loadData() {
    if (searchInputText.length) {
      await search(searchInputText);
      return true;
    }
    await fetch();
  }

  useEffect(() => {
    (async () => {
      await loadData();
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [projects]);

  const showSuccess = () => {
    showToast({
      type: 'error',
      message: intl.formatMessage({ id: 'dashboard.my-projects.modal.v1.success', defaultMessage: 'Projeto excluído com sucesso' })
    });
  };

  return (
    <>
      <ReactTooltip />
      <DeleteModal
        showSuccess={showSuccess}
        isOpen={openModal}
        projectData={actualProject}
        deleteFunction={deleteOne}
        closeFunction={() => setOpenModal(false)}
      />
      <DashboardContainer activePlan={activePlan} hideButton>
        <div className="w-full p-4 sm:p-0 flex flex-col">
          <span 
          id="header3_semibold"
          >
            <FormattedMessage id="dashboard.my-projects.main-title" defaultMessage="Meus Projetos" />
          </span>
          <div
            className="flex flex-col sm:flex-row rounded-md shadow-lg w-full justify-between items-center bg-white mt-8"
            style={{ padding: '20px 25px' }}
          >
            <div className="relative flex items-center w-full sm:w-auto">
              <SearchInput
                type="text"
                value={searchInputText}
                onKeyUp={(e) => {
                  setProjects([]);
                  clearTimeout(startSearching);
                  setStartSearching(
                    setTimeout(() => {
                      loadData();
                    }, 1000)
                  );
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputText(e.target.value)}
                placeholder={intl.formatMessage({
                  id: 'dashboard.my-projects.search-project-placeholder',
                  defaultMessage: 'Buscar projeto'
                })}
                id="section-search-input"
                // className="w-full sm:w-auto"
              />
              <IoIosSearch size={20} color="#ACACAC" className="absolute ml-2" />
            </div>
            <RectButton theme="success" id="button-primary" className="mt-4 w-full sm:w-auto sm:mt-0" onClick={() => history.push('/dash/producer/new-project')}>
              <FormattedMessage id="dashboard.my-projects.new-project-button" defaultMessage="Novo Projeto" />
            </RectButton>
          </div>
          <DataTableComponent
            isLoading={isFetching}
            data={filteredItems}
            paginationTotalRows={pagination.totalDocs}
            setActualProject={setActualProject}
            setOpenModal={setOpenModal}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </DashboardContainer>
    </>
  );
};
