import React, { useContext, useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Container } from './styles';
import AuthContainer from '../AuthContainer';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import DefaultEmailValidations from '../validations/defaultEmailValidation';
import { AuthContext } from '../../context/Auth';
import reloadIcon from '../../images/mpf-reload-black.svg';

type FuncType = () => void;

const ForgotPassword: React.FC = () => {
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, func: FuncType) => {
    func();
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  let [count, setCount] = useState<number>(5);

  const { setUsername, forgotPassword, errorMessage, setErrorMessage } = useContext(AuthContext);

  async function send(values: any) {
    setIsLoading(true);
    const sent: any = await forgotPassword(values.email);
    console.log("sent", sent)
    if (sent) {
      setIsLoading(false);
      setUsername(values.email);
      setSuccessMessage('auth.forgot.success');
      startCounter();
    }
    setIsLoading(false);
  }

  function startCounter(){
    setInterval(() => {
      if(count === 0){
        history.push('/auth/email');
      }
      setCount(count--);
    }, 1000);
  }

  return (
    <AuthContainer theme="tertiary" hasGoBack goBackRoute="/auth/email">
      <Container className="h-full flex flex-col items-center">
        <span
          data-cy="main-title"
          style={{ color: '#202020' }}
          className=" max-w-xs text-center w-5/6 text-2xl font-normal leading-8 mt-20 sm:mt-40 sm:mb-32 mb-12"
        >
          <FormattedMessage id={!successMessage ? 'auth.forgot.main-title' : 'auth.forgot.success'} />
        </span>
        {!successMessage ? (
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={DefaultEmailValidations}
            onSubmit={(values) => {
              send(values);
            }}
          >
            {({ errors, values, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
              <>
                <div className="text-left w-5/6 max-w-xs sm:max-w-sm text-xs sm:text-sm mb-12">
                  <FormattedMessage id="auth.email.placeholder-email">
                    {(placeholder: string) => (
                      <Input
                        autoComplete="off"
                        className="my-1 "
                        data-cy="input-email"
                        name="email"
                        id="email"
                        type="email"
                        placeholder={placeholder}
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        onClickCapture={() => setErrorMessage('')}
                        hasSuccess={!(!values.email || (errors.email && touched.email))}
                        errorMessage={errors.email && touched.email ? errors.email : ''}
                      />
                    )}
                  </FormattedMessage>
                  {values.email === '' && setErrorMessage('')}
                  {errorMessage !== '' && values.email !== '' && !errors.email && (
                    <span style={{ color: 'red' }} className="mt-1 text-xs">
                      {errorMessage}
                    </span>
                  )}
                </div>
                <Button
                  style={{ margin: '8px 0' }}
                  className="outline-none flex font-normal items-center justify-center w-5/6 max-w-xs sm:max-w-sm text-sm sm:text-base "
                  data-cy="button-send-email"
                  disabled={!isValid}
                  theme={isValid ? 'default' : 'disabled'}
                  onClick={(e) => handleClick(e, handleSubmit)}
                >
                  <FormattedMessage id="auth.forgot.send-email" />
                  {isLoading && <img src={reloadIcon} alt="spinner-icon" />}
                </Button>
              </>
            )}
          </Formik>
        ) : (
          <>Redirecionando em {count}...</>
        )}
        <p
          style={{ color: '#8F8F8F' }}
          className="terms sm:hidden sm:max-w-lg mt-10 mb-12 sm:mb-10 leading-4 sm:leading-6 items-center text-xs sm:text-sm w-full max-w-72 sm:w-78"
        >
          <FormattedMessage id="auth.terms-initial" />
          <a className="link--terms" href="https://static.musicplayce.com/assets/terms_of_use.pdf" target="blank">
            <FormattedMessage id="auth.terms" />
          </a>{' '}
          <FormattedMessage id="auth.terms-initial-2" />
          <a className="link--terms" href="https://static.musicplayce.com/assets/privacy_policy.pdf" target="blank">
            <FormattedMessage id="auth.politics" />
          </a>
          <FormattedMessage id="auth.terms-initial-3" />
        </p>
      </Container>
    </AuthContainer>
  );
};

export default ForgotPassword;
