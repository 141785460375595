/**
 * This hook must be used to get query parameters on the url.
 */
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default useQuery;
