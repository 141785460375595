import React, { useContext, useState } from 'react';
import { KanbanContext } from './Context';
import { Axios, withAuth } from '../../../../utils/axios';
import { Board, PostProd } from '../../../../interfaces';
import { AuthContext } from '../../../Auth';

export const KanbanProvider: React.FC = ({ children }) => {
  const [boardData, setBoardData] = useState<Board | undefined>();
  // eslint-disable-next-line
  const [idProject, setIdProject] = useState<string | undefined>();
  const [currentTrack, setCurrentTrack] = useState<PostProd | undefined>(undefined);
  // const [boardTags, setBoardTags] = useState([]);

  const { token } = useContext(AuthContext);

  // const loadBoardTags = async (idBoard: string, defaultToken?: string) => {
  //   try {
  //     const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/kanban/board/${idBoard}/tag`, withAuth(defaultToken || token));

  //     setBoardTags(response.data.data);

  //     return { success: true, data: response.data.data };
  //   } catch (error) {
  //     console.error('Error on fetching board tags: ', error);
  //   }
  // };

  const loadInitialBoard = async (idProject: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(
        `/v1/producer/project/${idProject}/kanban/board?resolve=["lanes", "cards", "tags", "custom_content", "metadata"]`,
        withAuth(defaultToken || token)
      );
      setBoardData(response.data.data);
      setIdProject(idProject);

      return { success: true, data: response.data.data };
    } catch (error) {
      console.error('Error on fetching board data: ', error);
    }
  };

  const reloadBoard = async (idProject: string) => {
    await loadInitialBoard(idProject);
  };

  const editPostName = async (newName: string, postId: string, defaultToken?: string) => {
    try {
      await Axios(process.env.REACT_APP_NODE_ENV).put(`/v1/posts/${postId}`, { name: newName }, withAuth(defaultToken || token));
    } catch (error) {
      console.error('Error on editing post: ', error);
    }
  };

  const deleteCard = async (cardId: string, defaultToken?: string) => {
    try {
      await Axios(process.env.REACT_APP_NODE_ENV).delete(`/v1/kanban/card/${cardId}`, withAuth(defaultToken || token));
    } catch (error) {
      console.error('Error on delete card: ', error);
    }
  };

  const createLane = async (title: string) => {
    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).post(
        '/v1/kanban/lane',
        {
          type: 'public',
          title,
          id_board: boardData?._id
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
          }
        }
      );
      return { success: true, data };
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleMoveCard = async (cardId: string, targetLaneId: string, position: number, defaultToken?: string) => {
    try {
      await Axios(process.env.REACT_APP_NODE_ENV).put(
        `/v1/kanban/card/move/${cardId}`,
        { laneIdTo: targetLaneId, newPosition: position },
        withAuth(defaultToken || token)
      );
    } catch (error) {
      console.error('Error on moving card: ', error);
    }
  };

  const handleMoveLane = async (newPosition: number, laneId: string, defaultToken?: string) => {
    try {
      await Axios(process.env.REACT_APP_NODE_ENV).put(`/v1/kanban/lane/move/${laneId}`, { newPosition }, withAuth(defaultToken || token));
    } catch (error) {
      console.error('Error on moving lane: ', error);
    }
  };

  const handleUploadFile = async (projectId: string, media: any, defaultToken?: string) => {
    const formData = new FormData();
    formData.append('media', media);

    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).post(`/v1/producer/project/${projectId}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token || defaultToken}`,
          'content-type': 'multipart/form-data'
        }
      });
      return { success: true, data: response };
    } catch (error) {
      console.error('Error on uploading media: ', error);
      return { success: false, data: error };
    }
  };

  const handleClassify = async (idProject: string, idProjectPost: string, classification: number, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).put(
        `/v1/producer/project/${idProject}/posts/${idProjectPost}/classify`,
        {
          classification
        },
        withAuth(defaultToken || token)
      );
      return { success: true, data: response };
    } catch (error) {
      console.error('Error on classifying post: ', error);
      return { success: false, data: error };
    }
  };

  const handleView = async (idProject: string, idProjectPost: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).put(
        `/v1/producer/project/${idProject}/posts/${idProjectPost}/view`,
        {},
        withAuth(defaultToken || token)
      );
      return { success: true, data: response };
    } catch (error) {
      console.error('Error on view post: ', error);
      return { success: false, data: error };
    }
  };

  const handleAddTag = async (idProject: string, idCard: string, label: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).put(
        `/v1/producer/project/${idProject}/kanban/card/${idCard}/add-tag`,
        { label },
        withAuth(defaultToken || token)
      );

      await reloadBoard(idProject || '');

      return { success: true, data: response };
    } catch (error) {
      console.error('Error on adding tag: ', error);
      return { success: false, data: error };
    }
  };

  const handleRemoveTag = async (idProject: string, idCard: string, label: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).put(
        `/v1/producer/project/${idProject}/kanban/card/${idCard}/remove-tag`,
        { label },
        withAuth(defaultToken || token)
      );

      await reloadBoard(idProject || '');

      return { success: true, data: response };
    } catch (error) {
      console.error('Error on removing tag: ', error);
      return { success: false, data: error };
    }
  };

  const handleShutDownAudition = async (idAudition: string, idProject: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).put(`/v2/auditions/close/${idAudition}`, {}, withAuth(defaultToken || token));
      await reloadBoard(idProject);
      return { success: true, data: response };
    } catch (error) {
      console.error('Error on closing audition: ', error);
      return { success: false, data: error };
    }
  };

  return (
    <KanbanContext.Provider
      value={{
        boardData,
        currentTrack,
        handleRemoveTag,
        handleAddTag,
        handleView,
        handleClassify,
        handleUploadFile,
        handleMoveLane,
        reloadBoard,
        handleMoveCard,
        deleteCard,
        editPostName,
        setCurrentTrack,
        loadInitialBoard,
        createLane,
        setBoardData,
        handleShutDownAudition
      }}
    >
      {children}
    </KanbanContext.Provider>
  );
};
