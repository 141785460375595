import { CompositionTopArtists } from '../../interfaces';
import * as firebase from '../../utils/firebase/firebaseUtils';

export async function getCompositionTopIndicationsByCompositionId(compositionId: string): Promise<CompositionTopArtists[]> {
  return new Promise((resolve) => {
    const compositionTopIndications: CompositionTopArtists[] = [];
    let count: number = 0;
    firebase.firestore
      .collection('compositions')
      .doc(compositionId)
      .collection('indicationArtist')
      .orderBy('indicationsCount', 'desc')
      .limit(5)
      .get()
      .then((data) => {
        data.docs.map((p) => {
          compositionTopIndications.push(p.data() as CompositionTopArtists);
        });

        compositionTopIndications.forEach((cti: CompositionTopArtists) => {
          count += cti.indicationsCount;
        });

        compositionTopIndications.map((i: CompositionTopArtists) => {
          i.percentage = (i.indicationsCount / count) * 100 || 0;
        });

        return resolve(compositionTopIndications);
      });
  });
}
