import styled from 'styled-components';

export const SongsSection = styled.div`
  .blocked-content {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const VideoContent = styled.div`
  .blocked-video {
    background: #000000b5;
    position: absolute;
    z-index: 99999;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .border-radius {
    border-radius: 10px;
  }

  .minWidth-300 {
    min-width: 300px;
  }

  .minWidth-400 {
    min-width: 400px;
  }

  .blur {
    filter: blur(2.5px);
  }
`;

export const SpinnerContainer = styled.div`
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spinner {
    animation: spinner infinite 1s linear;
  }
`;

export const PaginateContainer = styled.div`
  .pagination {
    list-style: none;
    cursor: pointer;
  }

  .pagination a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: #202020;
  }

  .pagination__link {
    font-weight: bold;
  }

  .pagination__link--active a {
    color: #202020;
    background: #ccc;
  }

  .pagination__link--disabled a {
    color: #202020;
    border: 1px solid rgb(198, 197, 202);
  }
`;
