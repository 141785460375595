// import { useContext } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export function withAuth(token?: string): AxiosRequestConfig {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
}

// function registerAxiosInterceptors(instance: AxiosInstance, type: string) {
//   instance.interceptors.response.use(
//     (res) => res,
//     (error) => {
//       if ((error && error.response && error.response.status === 401) || error.response.status === 403) {
//         const currentRoute = window.location.href.toString().split(window.location.host)[1];
//         if (currentRoute !== '/auth') {
//           localStorage.removeItem('LOCAL_TOKEN');
//           localStorage.removeItem('LOCAL_REFRESH_TOKEN');
//           window.location.replace(`${type === 'prod' ? 'https://beta.musicplayce.com' : 'http://localhost:3000'}/auth`);
//         }
//       }

//       return Promise.reject(error);
//     }
//   );
// }

export const Axios = (type?: string, port: number = 80) => {
  let instance: AxiosInstance;
  let baseURL = `http://localhost:${port}`;

  if (type === 'local') {
    instance = axios.create({
      baseURL
    });
  } else if (type === 'dev') {
    baseURL = 'https://api-beta.musicplayce.com';
    instance = axios.create({
      baseURL
    });
  } else {
    type = 'prod';
    baseURL = 'https://api.musicplayce.com';
    instance = axios.create({
      baseURL
    });
  }

  // registerAxiosInterceptors(instance, type);
  return instance;
};
