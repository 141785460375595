import React from 'react';
import { FeatureCollection } from 'geojson';

import { Composer, Composition, CompositionIndication, CompositionPlay, CompositionTopArtists, PostData, TopShare, Track } from '../../../interfaces';
import { HistogramData } from '../../../components/IndicationTimeGraph/IndicationTimeGraph';

interface ArtistDashState {
  compositions: Composition[];
  currentComposition?: Composition;
  playsByDay?: CompositionPlay[];
  indicationsByDay?: CompositionIndication[];
  locationData?: FeatureCollection;
  indicationMomentData?: HistogramData;
  topShares?: TopShare[];
  topArtists?: CompositionTopArtists[];
  myRanking?: any;
  isLoading: boolean;
  composers?: Composer[];
  activeArtistTrack?: Track;
  artistPlayList: Track[];
  isGettingArtistPlayList: boolean;
  setActiveArtistTrack: (activeArtistTrack?: Track) => void;
  handlePostSelect: (id: string, isPlay?: boolean) => Promise<any>;
  fetchPostsByProfileToken: (defaultToken?: string) => Promise<PostData[]>;
}

const initialState: ArtistDashState = {
  compositions: [],
  currentComposition: undefined,
  myRanking: undefined,
  locationData: undefined,
  indicationMomentData: undefined,
  topShares: [],
  topArtists: [],
  playsByDay: [],
  indicationsByDay: [],
  isLoading: false,
  artistPlayList: [],
  isGettingArtistPlayList: false,
  setActiveArtistTrack: () => {},
  fetchPostsByProfileToken: async () => [],
  handlePostSelect: async () => {}
};

const ArtistDashContext = React.createContext<ArtistDashState>(initialState);

const ArtistDashConsumer = ArtistDashContext.Consumer;

export { ArtistDashContext, ArtistDashConsumer };

export type { ArtistDashState };
