import styled from 'styled-components';

export const Container = styled.div`
  div.results--container {
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      min-height: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

`;
