/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { ProducerDashContext } from '../../../../../context';
import close from '../../../../../images/close.svg';
import CharCounter from '../../../../../components/CharCounter/CharCounter';
import { showToast } from '../../../../../components/Toast/Toast';

import { ChipsContainer, ChipsItems, ChipsInput, ChipsSuggestion } from './styles';
import { searchTags } from '../../services/createAudition';

interface Tag {
  _id: string | null;
  label: string;
  datetime_created?: string;
  datetime_updated?: string;
}

type Props = {
  values: any[];
  maxItems?: number;
  chipMaxLength?: number;
  onTagsUpdated(tags: Tag[]): void;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function TagsSuggestion({ values, placeholder, maxItems, chipMaxLength, onTagsUpdated }: Props) {
  const [searchVal, setSearchVal] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>([]);

  const { setSelectedTags } = useContext(ProducerDashContext);

  useEffect(() => {
    setSelectedTags(items);
    // eslint-disable-next-line
  }, [items]);

  function add(data: Tag) {
    if (items.filter((i: Tag) => (i._id && i._id === data._id) || (!i._id && i.label === data.label)).length > 0) {
      showToast({ type: 'error', message: `Este item já existe na lista` });
      setFilter([]);
      return setSearchVal('');
    }

    if (maxItems && data.label !== '' && items.length < maxItems) {
      setItems([...items, data]);
      setFilter([]);
      return setSearchVal('');
    }

    if (!maxItems) {
      setItems([...items, data]);
      setFilter([]);
      return setSearchVal('');
    }

    showToast({ type: 'error', message: `Limite máximo de ${maxItems} ${maxItems > 1 ? 'itens' : 'item'}` });
    setFilter([]);
    return setSearchVal('');
  }

  function remove(item: Tag) {
    const arr = [...items];
    items.forEach((i: Tag) => {
      if (i === item) {
        arr.splice(arr.indexOf(item), 1);
        return setItems(arr);
      }
    });
  }

  async function filterValues(value: string) {
    setSearchVal(value);
    const sugg: any = await searchTags(value);
    return setFilter(sugg ? sugg.data : []);
  }

  function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      const newTag: Tag = {
        _id: null,
        label: searchVal
      };
      return add(newTag);
    }
  }

  useEffect(() => {
    setItems(values);
  }, [values]);

  useEffect(() => {
    onTagsUpdated(items);
  }, [items, onTagsUpdated]);

  return (
    <>
      <ChipsContainer>
        <ChipsInput>
          <input
            autoComplete="off"
            className="my-1 outline-none border-none w-full"
            name="main_artist"
            id="main_artist"
            type="text"
            maxLength={chipMaxLength || 99999}
            placeholder={placeholder}
            value={searchVal}
            onKeyPress={(e) => {
              handleKeyPress(e);
            }}
            onChange={(e) => filterValues(e.target.value)}
            data-cy="input-main_artist"
          />
        </ChipsInput>
        <CharCounter currentLength={searchVal.length} maxLength={chipMaxLength || 99999} />
      </ChipsContainer>
      {!!filter && filter.length > 0 && (
        <ChipsSuggestion>
          {filter.map((i: Tag, index: any) => {
            return (
              <div key={index} onClick={(e) => add(i)}>
                {i.label}
              </div>
            );
          })}
        </ChipsSuggestion>
      )}
      <ChipsItems>
        {!!items &&
          items.map((i: Tag, index: any) => {
            return (
              <div key={index} className="item">
                {i.label} <img src={close} alt="close" style={{ marginLeft: '10px' }} onClick={() => remove(i)} />
              </div>
            );
          })}
      </ChipsItems>
    </>
  );
}
