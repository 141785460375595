/* eslint-disable import/named */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';

import { CheckoutContext, CheckoutConsumer } from '../../context/checkout';
import BenefitsCard from '../../checkout/PlansBenefitsCard/BenefitsCard';
import useUserData from '../../utils/customHooks/useUserData';
import Spinner from '../Spinner/Spinner';
import {
  getRemainingTrialDays,
  getTrialEndDateCalendar,
  getCurrentSubscription,
  getActiveProduct
} from '../../utils/checkoutHelpers';
import 'moment/locale/pt-br';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context';

const PlansChoiceCards: React.FC = () => {
  const user = useUserData();

  const { userSubscriptions, prices, setAtachedPaymentMethod, getUserSubscriptions, getPrices } = useContext(
    CheckoutContext
  );

  const { token } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (!prices?.length) await getPrices();
      if (token) await getUserSubscriptions(token);
    })();
    setAtachedPaymentMethod(false);
  }, [token]);

  // clear persisted selected product on plan choice screen
  useEffect(() => {
    localStorage.removeItem('selected-price');
  }, []);

  const nameFormated = user?.name;

  const remainingTrialDays = userSubscriptions && getRemainingTrialDays(userSubscriptions);

  const trialEndDateCalendar = userSubscriptions && getTrialEndDateCalendar(userSubscriptions);

  const currentSubscription = userSubscriptions && getCurrentSubscription(userSubscriptions);

  const activeProduct = getActiveProduct(userSubscriptions, prices);

  return (
    <CheckoutConsumer>
      {({ isGettingPrices, isGettingUserSubscriptions }) => (
        <div className="flex flex-col px-5 sm:px-4 md:px-2 lg:px-2 xl:px-0">
          <span className="text-2xl pt-10">Escolha o plano certo para sua carreira</span>

          {isGettingPrices || isGettingUserSubscriptions || !user ? (
            <div className="flex mt-8 items-center justify-center">
              <Spinner isLoading={isGettingPrices || isGettingUserSubscriptions || !user} />
            </div>
          ) : prices?.length ? (
            <>
              <span className="text-sm text-gray-600">
                {userSubscriptions?.length ? (
                  currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                    <>
                      <FormattedMessage
                        id="checkout.invoicing.description-invoicing-requested-cancellation"
                        values={{
                          userName: nameFormated,
                          cancelAtDate: moment(
                            (currentSubscription.stripe_subscription.cancel_at as number) * 1000
                          ).format('LL')
                        }}
                      />
                      <Link
                        className="text-blue-500 underline ml-2"
                        to={`/checkout/reactivate-subscription/${currentSubscription._id}`}
                      >
                        Reativar assinatura
                      </Link>
                    </>
                  ) : currentSubscription?.stripe_subscription?.status === 'trialing' ? (
                    <>
                      {`${nameFormated || null}`}
                      {nameFormated ? ', s' : 'S'}eu período de teste irá terminar em {remainingTrialDays} dias (
                      {trialEndDateCalendar}). Selecione um dos planos abaixo:
                    </>
                  ) : (
                    'Selecione um dos planos abaixo:'
                  )
                ) : (
                  'Selecione um dos planos abaixo:'
                )}
              </span>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 pt-10">
                {prices
                  ?.sort(({ tier: previousTier }, { tier: currentTier }) => previousTier - currentTier)
                  .map((item) => {
                    return (
                      !isGettingUserSubscriptions &&
                      !isGettingPrices &&
                      (process.env.REACT_APP_NODE_ENV === 'prod' ? (
                        item._id !== '5f64dba5cc18cb2f53ec1e72' &&
                        item._id !== '5f64dc3acc18cb2f53ec1e73' && (
                          <BenefitsCard key={item._id as string} price={item} activePrice={activeProduct} />
                        )
                      ) : (
                        <BenefitsCard key={item._id as string} price={item} activePrice={activeProduct} />
                      ))
                    );
                  })}
              </div>
            </>
          ) : (
            <span className="mt-8">Nenhum plano encontrado.</span>
          )}
        </div>
      )}
    </CheckoutConsumer>
  );
};

export default PlansChoiceCards;
