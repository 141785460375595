/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, ProducerDashContext } from '../../context';
import { searchArtists } from '../../dashboard/Producer/NewProject/services/searchArtists';
import close from '../../images/close.svg';
import { showToast } from '../Toast/Toast';

import { ChipsContainer, ChipsItems, ChipsInput, ChipsSuggestion, ChipsSuggestionItems } from './styles';

type Props = {
  values: any[];
  suggestions: any[];
  maxItems: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

interface Artist {
  _id: string;
  name: string;
  rank: number;
  picture_profile: {
    _id: string;
    url: string;
    type: string;
    datetime_created: string;
    datetime_updated: string;
    processed: boolean;
    resources: string[];
  };
}

export default function ArtistChips({ value, values, suggestions, placeholder, maxItems }: Props) {
  const [searchVal, setSearchVal] = useState<string>('');
  const [items, setItems] = useState<Artist[]>([]);
  const [sugValues, setSugValues] = useState<Artist[]>([]);

  const { setArtistsSugestions, setSelectedArtists } = useContext(ProducerDashContext);
  const { token } = useContext(AuthContext);

  function add(data: Artist) {
    if (items.filter((i) => i._id === data._id).length > 0) {
      showToast({ type: 'error', message: `Este item já existe na lista` });
      return setSearchVal('');
    }

    if (items.length < maxItems) {
      setItems([...items, data]);
      return setSearchVal('');
    }

    showToast({ type: 'error', message: `Limite máximo de ${maxItems} artistas relacionados` });
    return setSearchVal('');
  }

  function remove(value: string) {
    setItems(items.filter((item) => item._id !== value));
  }

  useEffect(() => {
    setSugValues(suggestions);
  }, [suggestions]);

  useEffect(() => {
    setTimeout(() => {
      searchArtists(searchVal, token).then((resp) => resp !== undefined && setArtistsSugestions(resp?.map((item) => item)));
    }, 500);
    // eslint-disable-next-line
  }, [searchVal]);

  useEffect(() => {
    setSelectedArtists(items);
    // eslint-disable-next-line
  }, [items]);

  return (
    <>
      <ChipsContainer>
        <ChipsInput>
          <input
            autoComplete="off"
            className="my-1 outline-none border-none"
            name="main_artist"
            id="main_artist"
            type="text"
            placeholder={placeholder}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            data-cy="input-main_artist"
          />
        </ChipsInput>
      </ChipsContainer>
      {!!sugValues && sugValues.length > 0 && (
        <ChipsSuggestion>
          {sugValues.map((i, index) => {
            return (
              <ChipsSuggestionItems key={index} onClick={() => add(i)}>
                {i.name}
              </ChipsSuggestionItems>
            );
          })}
        </ChipsSuggestion>
      )}
      <ChipsItems>
        {!!items &&
          items.map((i, index) => {
            return (
              <div key={index} className="item">
                {i.name} <img src={close} alt="close" style={{ marginLeft: '10px' }} onClick={() => remove(i._id)} />
              </div>
            );
          })}
      </ChipsItems>
    </>
  );
}
