/* eslint-disable consistent-return */
import { useContext } from 'react';
import { AuthContext, AuthState } from '../../context/Auth';

import { User } from '../../interfaces/index';

const useUserData = (): User | undefined => {
  const { user }: AuthState = useContext(AuthContext);
  return user;
  // const userData = user;
  // if (userData) {
  //   const {
  //     name: { firstName, lastName, fullName },
  //     email,
  //     picture_profile,
  //     username,
  //     description,
  //     info_fb_page,
  //     info_insta,
  //     info_website,
  //     location,
  //     placeId,
  //     phone,
  //     role,
  //     _id,
  //     idUser,
  //     meta
  //   } = userData;

  //   return {
  //     name: {
  //       firstName,
  //       lastName,
  //       fullName
  //     },
  //     email,
  //     picture_profile,
  //     username,
  //     description,
  //     info_fb_page,
  //     info_insta,
  //     info_website,
  //     location,
  //     placeId,
  //     phone,
  //     role,
  //     _id,
  //     idUser,
  //     meta
  //   };
  // }
};

export default useUserData;
