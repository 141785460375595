import styled from 'styled-components';

export const DefaultButton = styled.button`
  outline: none;
  background-color: transparent;
  color: #202020;
  font-weight: 600;
  padding: 0.7rem 2rem;
  border: 2px solid #202020;
  border-radius: 100px;
  margin: 0.5rem;
  font-weight: 500;
  -webkit-appearance: none;
    -moz-appearance: none;
`;

export const PrimaryButton = styled(DefaultButton)`
  background: linear-gradient(90deg, #d81b60 0%, #f06262 100%);
  color: white;
  border: none;
  outline: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export const SecondaryButton = styled(DefaultButton)`
  background: #1db954;
  color: white;
  border: none;
  outline: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export const TertiaryButton = styled(DefaultButton)`
  background: #0D65FF;
  color: white;
  border: none;
  outline: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export const QuaternaryButton = styled(DefaultButton)`
  background: #EF233C;
  color: white;
  border: none;
  outline: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export const DisabledButton = styled(DefaultButton)`
  opacity: 50%;
  cursor: not-allowed;
  outline: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
`;
