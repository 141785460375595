import styled from "styled-components";

export const Container = styled.div`
    .max-w-72 {
    max-width: 18rem;
  }

  .max-w-76 {
    max-width: 22rem;
  }
  
  a.link--terms {
    color: #ef233c;
  }

  input {
    background: transparent;
  }
    
  @keyframes spinner {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg)
    }
  }

  button {
    img {
      animation: spinner infinite 1s linear;
      margin-left: 20px;
    }
  }

  @media (min-width: 640px) {
    .terms {
      max-width: 350px;
    }
  }

`;