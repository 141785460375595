/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import 'moment/locale/pt-br';
import DashboardContainer from '../../DashboardContainer';
import { CardContent } from './styles';
import rocketIcon from '../../../images/rocket-icon.svg';
import avatar from '../../../images/pv-avatar.svg';
import { Card } from '../../styles';

const BuildingPage: React.FC = () => {
  return (
    <DashboardContainer>
      <div className="w-full flex flex-col" id="page-top-div">
        <div className="w-full hidden sm:block">
          <span className="font-semibold text-2xl">
            <FormattedMessage id="dashboard.building.page-main-title" defaultMessage="Audições" />
          </span>
          <span className="text-gray-700 flex items-center">
            <img src={rocketIcon} alt="rocket-icon" />
            <span className="ml-1 text-sm">
              <FormattedMessage id="dashboard.building.coming-soon" defaultMessage="Em breve" />
            </span>
          </span>
        </div>
        <Card className="mt-10 flex justify-center align-center" style={{paddingTop: '5.5rem', paddingBottom: '5.5rem'}}>
          <CardContent className="content">
            <img src={avatar} alt="pv-avatar" />
            <div className="main-info">Aguarde, estamos preparando <br /> uma novidade para você!</div>
            <div className="secondary-info">Em breve você poderá contar com mais uma funcionalidade dentro da nossa plataforma.</div>
          </CardContent>
        </Card>
      </div>
    </DashboardContainer>
  );
};

export default BuildingPage;
