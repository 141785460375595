import React from 'react';

type Props = {
  children: React.ReactNode;
  theme: 'primary' | 'warning' | 'success' | 'danger' | 'disabled' | 'outline' | 'dark';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const RectButton: React.FC<Props> = ({ theme, children, className, ...rest }) => {
  const classNamesObj = {
    primary: 'bg-blue-500 hover:bg-blue-600 text-white font-semibold',
    warning: 'bg-yellow-500 hover:bg-yellow-600 text-white font-semibold',
    success: 'bg-green-500 hover:bg-green-600 text-white font-semibold',
    danger: 'bg-red-500 hover:bg-red-600 text-white font-semibold',
    disabled: 'bg-gray-200 text-gray-500 cursor-not-allowed text-white font-semibold',
    outline: 'bg-white text-black border-black border-2 font-semibold',
    dark: 'bg-black text-white font-semibold'
  };

  return (
    <button
      type="button"
      disabled={theme === 'disabled'}
      className={`text-sm transition-colors duration-200 py-3 px-4 rounded ${classNamesObj[theme]} ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default RectButton;
