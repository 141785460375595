/* eslint-disable no-undef-init */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Board from 'react-trello';
import songIcon from '../../../images/song.svg';
import { Board as BoardData, KanbanCard as IKanbanCard, Lane, PriceObj, Project } from '../../../interfaces';
import DashboardContainer from '../../DashboardContainer';
import { LaneHeader, DashedCard, NewLaneForm, UploadModal } from './components';
import { Collapse } from '../../styles';
import RectButton from '../../../components/RectButton/RectButton';
import Tags from '../../../components/Tags/Tags';
import { StyledBoard, AddMusicDropDown, CollapseButton } from './styles';
import { KanbanContext } from '../../../context/dash/producer/kanban';
import KanbanCard, { KanbanCardWithoutData } from '../../../components/KanbanCard/KanbanCard';
import KanbanFiltersCard from '../../../components/KanbanFiltersCard/KanbanFiltersCard';
import { AuthContext } from '../../../context';
import { showToast } from '../../../components/Toast/Toast';
import useOutsideIdentifier from '../../../utils/customHooks/useOutsideIdentifier';
import { IoIosArrowBack, IoIosArrowDown } from 'react-icons/io';
import Spinner from '../../../components/Spinner/Spinner';

import { getProjectData } from './services';

type Props = {
  activePlan?: PriceObj;
};

type URLParam = {
  id: string;
};

export const Kanban: React.FC<Props> = ({ activePlan }: any) => {
  const intl = useIntl();
  const history = useHistory();
  const { id } = useParams<URLParam>();
  const {
    boardData,
    //boardTags,
    loadInitialBoard,
    createLane,
    handleMoveCard,
    handleMoveLane,
    handleUploadFile,
    reloadBoard,
    handleShutDownAudition
  } = useContext(KanbanContext);
  const [boardFiltered, setBoardFiltered] = useState<BoardData | undefined>();
  const musicDropDown = useRef<HTMLDivElement>(null);
  const [isAddMusic, setIsAddMusic] = useState(false);
  const [isUploadModalOpen, setOpenUploadModal] = useState(false);
  const [projectData, setProjectData] = useState<Project>();
  const [projectTags, setProjectTags] = useState<string[]>([]);
  const [isGettingBoard, setIsGettingBoard] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);

  const [counterTimeout, setCounterTimeout] = useState<any>(null);

  const [openProjectData, setOpenProjectData] = useState<boolean>(false);
  const [openFilters, setOpenFilters] = useState<boolean>(false);

  const { token } = useContext(AuthContext);

  useOutsideIdentifier(musicDropDown, () => setIsAddMusic(false));

  useEffect(() => {
    (async () => {
      if (!history.location.state) {
        const res = await getProjectData(id);
        return setProjectData(res.data.data as Project);
      }

      setProjectData(history.location.state as Project);
    })();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectData) {
      const tags: string[] = [];
      projectData.tags.map((tag: any) => {
        return tags.push(tag.label as string);
      });
      setProjectTags(tags);
    }
  }, [projectData]);

  useEffect(() => {
    setBoardFiltered(boardData);
  }, [boardData]);

  const onLaneAdd = async (params: any) => {
    const project = history.location.state as Project;

    const res = await createLane(params.title, id);

    if (res?.success) {
      showToast({
        type: 'success',
        message: intl.formatMessage({ id: 'dashboard.kanban.modal.new-lane.success-alert', defaultMessage: 'Coluna criada com sucesso!' })
      });
      loadInitialBoard(project?._id, token);
    }

    if (!res?.success) {
      showToast({
        type: 'error',
        message: intl.formatMessage({
          id: 'dashboard.kanban.modal.new-lane.error-alert',
          defaultMessage: 'Falha ao criar a coluna! tente novamente mais tarde.'
        })
      });
      loadInitialBoard(project?._id, token);
    }
  };

  useEffect(() => {
    (async () => {
      setIsGettingBoard(true);
      if (token && projectData) {
        const response = await loadInitialBoard(projectData?._id, token);
        setBoardFiltered(response?.data);
      }
      setIsGettingBoard(false);
      setShowResults(true);
    })();
    // eslint-disable-next-line
  }, [token, projectData]);

  const canDragCard = (cardId: string, sourceLaneId: string, targetLaneId: string, position: any, cardDetails: IKanbanCard) => {
    const targetLaneDeatils = boardFiltered?.lanes?.find((item) => item.id === targetLaneId);
    const canDrag = targetLaneId === cardDetails.id_lane_origin ? true : targetLaneDeatils?.type === 'public';
    if (!canDrag) {
      showToast({
        type: 'error',
        message: 'Você não pode mover esse card para essa coluna'
      });
      return canDrag;
    } else if (canDrag) {
      handleMoveCard(cardId, targetLaneId, position + 1);
      reload();
      return true;
    }
    return true;
  };

  function reload() {
    const timeout = setTimeout(() => {
      reloadBoard(projectData?._id || '');
    }, 2000);

    if (timeout && counterTimeout) {
      clearTimeout(counterTimeout);
      return setCounterTimeout(timeout);
    }

    return setCounterTimeout(timeout);
  }

  const canDragLane = (removedIndex: number, addedIndex: number, payload: Lane) => {
    handleMoveLane(addedIndex + 1, payload?.id);
  };

  return (
    <>
      <UploadModal
        uploadFunction={handleUploadFile}
        reloadBoardFunction={() => reloadBoard(projectData?._id as string)}
        projectId={projectData?._id as string}
        closeFunction={() => setOpenUploadModal(false)}
        isOpen={isUploadModalOpen}
      />
      <DashboardContainer activePlan={activePlan} hideButton>
        <div className="w-full p-4 sm:p-0">
          <div className="w-full flex flex-row justify-between">
            <span className="font-semibold text-2xl flex items-center">
              <img className="mr-3" src={songIcon} alt="song icon" />
              {projectData?.name}
            </span>
            <div className="flex justify-end items-center">
              <RectButton
                theme="outline"
                className="mr-2"
                style={{ border: '1px solid #202020', height: 48, backgroundColor: 'transparent' }}
                onClick={() => history.push(`/dash/producer/edit-project/${projectData?._id}`)}
              >
                <FormattedMessage id="dashboard.my-projects.kanban.edit-project" defaultMessage="Editar project" />
              </RectButton>
              <RectButton style={{ height: 48 }} theme="success" onClick={() => setIsAddMusic(!isAddMusic)}>
                <FormattedMessage id="dashboard.my-projects.kanban.add-music" defaultMessage="Adicionar guia" />
              </RectButton>
              {isAddMusic && (
                <AddMusicDropDown className="" ref={musicDropDown}>
                  <div className="add-music-item">
                    <button type="button" className="text-gray-500 text-sm" onClick={() => setOpenUploadModal(true)}>
                      <FormattedMessage id="dashboard.my-projects.kanban.add-music.upload" defaultMessage="Upload" />
                    </button>
                  </div>
                  <div className="add-music-item border-t">
                    <button
                      type="button"
                      className="text-gray-500 text-sm"
                      onClick={() => history.push({ pathname: '/dash/audition/new-audition', state: projectData })}
                    >
                      <FormattedMessage id="dashboard.my-projects.kanban.add-music.a-digital" defaultMessage="Audição Digital" />
                    </button>
                  </div>
                </AddMusicDropDown>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <CollapseButton
              className={'mt-5 p-4 ' + (openProjectData ? 'rm-bottom-radius' : 'add-bottom-radius')}
              onClick={() => setOpenProjectData(!openProjectData)}
            >
              <span className="text-lg font-semibold">
                <FormattedMessage id="dashboard.my-projects.kanban.collapse.project-data" defaultMessage="Dados do projeto" />
              </span>
              {openProjectData ? <IoIosArrowDown /> : <IoIosArrowBack />}
            </CollapseButton>
            {openProjectData ? (
              <Collapse className="flex flex-col">
                <div className="flex flex-row border-b-2 border-gray-200 mb-4  pb-6">
                  <div className="flex flex-col mr-16">
                    <span className="text-gray-500 text-sm">
                      <FormattedMessage id="dashboard.my-projects.kanban.title.main-artist" defaultMessage="Artista principal" />
                    </span>
                    <div className="mt-4 font-semibold">
                      {projectData &&
                        projectData.target_artists.map((artist: any) => {
                          return artist.name;
                        })}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-500 text-sm">
                      <FormattedMessage id="dashboard.my-projects.kanban.title.related-artists" defaultMessage="Artistas relacionados" />
                    </span>
                    <div className="flex flex-row">
                      {projectData && projectData.related_artists.length > 0 ? (
                        projectData?.related_artists.map((artist: any, idx: any) => {
                          return (
                            <div key={idx} className="mt-4 font-semibold mr-6">
                              {artist.name}
                            </div>
                          );
                        })
                      ) : (
                        <span className="mt-4 font-semibold mr-6">
                          <FormattedMessage
                            id="dashboard.my-projects.kanban.title.related-artists.none"
                            defaultMessage="Nenhum artista relacionado"
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row text-sm">
                  <div className="flex flex-col mr-16 w-1/2">
                    <span className="text-gray-500 text-sm">
                      <FormattedMessage id="dashboard.my-projects.kanban.project.description" defaultMessage="Descrição do projeto" />
                    </span>
                    <div className="mt-4 text-sm">{projectData && projectData?.description}</div>
                  </div>
                  <div className="flex flex-col mr-16 w-56 sm:w-1/2">
                    <span className="text-gray-500 text-sm">
                      <FormattedMessage id="dashboard.my-projects.kanban.project.tags" defaultMessage="Tags do projeto" />
                    </span>
                    <div className="mt-4 text-sm">
                      <Tags clickable={false} data={projectTags} />
                    </div>
                  </div>
                </div>
              </Collapse>
            ) : (
              ''
            )}
            <CollapseButton
              className={'mt-5 p-4 ' + (openFilters ? 'rm-bottom-radius' : 'add-bottom-radius')}
              onClick={() => setOpenFilters(!openFilters)}
            >
              <span className="text-lg font-semibold">
                <FormattedMessage id="dashboard.my-projects.kanban.collapse.filters" defaultMessage="Filtros" />
              </span>
              {openFilters ? <IoIosArrowDown /> : <IoIosArrowBack />}
            </CollapseButton>
            {openFilters ? (
              <KanbanFiltersCard boardData={boardData} onFiltersUpdated={(filtered: BoardData | undefined) => setBoardFiltered(filtered)} />
            ) : (
              ''
            )}
            <StyledBoard>
              {boardFiltered && showResults ? (
                <Board
                  className="flex flex-col mt-1 p-6"
                  data={boardFiltered}
                  canAddLanes={boardFiltered.lanes.length <= 9}
                  editable
                  draggable
                  handleDragEnd={canDragCard}
                  handleLaneDragEnd={canDragLane}
                  laneStyle={{ backgroundColor: '#fff' }}
                  onLaneAdd={onLaneAdd}
                  style={{ height: '100%', backgroundColor: '#E5E5E5', padding: '5px 0' }}
                  components={{
                    Card: KanbanCard,
                    WithoutDataCard: KanbanCardWithoutData,
                    LaneHeader: (props: any) => (
                      <LaneHeader props={{ ...props, idProject: projectData?._id }} handleShutDownAudition={handleShutDownAudition} />
                    ),
                    NewLaneSection: DashedCard,
                    NewLaneForm,
                    AddCardLink: () => <span />
                  }}
                />
              ) : isGettingBoard ? (
                <div className="loading-container">
                  <Spinner isLoading={isGettingBoard} />
                </div>
              ) : (
                <span>Nenhuma board foi encontrada para este projeto</span>
              )}
            </StyledBoard>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};
