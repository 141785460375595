import React, { useContext, useState } from 'react';
import qs from 'qs';
import { removeDiacritics } from '../../../utils/removeDiacritics/removeDiacritics';
import { Project } from '../../../interfaces';

import { Axios, withAuth } from '../../../utils/axios';
import { ProducerDashContext } from './Context';
import { AuthContext } from '../../Auth';

interface Artist {
  _id: string;
  name: string;
  rank: number;
  picture_profile: {
    _id: string;
    url: string;
    type: string;
    datetime_created: string;
    datetime_updated: string;
    processed: boolean;
    resources: string[];
  };
}

export const ProducerDashProvider: React.FC = ({ children }) => {
  const [artistsSugestions, setArtistsSugestions] = useState<Artist[]>([]);
  const [selectedArtists, setSelectedArtists] = useState<Artist[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { token } = useContext(AuthContext);

  const [projects, setProjects] = useState<Project[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    totalDocs: 0,
    limit: 10
  });
  const [isFetching, setIsFetching] = useState(false);

  const fetch = async (page?: number, limit?: number, offset?: number) => {
    setIsFetching(true);

    try {
      let query: string | any = {
        page: page || pagination.page,
        limit: limit || pagination.limit,
        sortField: 'datetime_created',
        sortOrder: 'DESC'
      };

      query = qs.stringify(query);

      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(
        `/v1/producer/project?resolve=["target_artists", "tags", "related_artists"]&archived=false${query ? `&${query}` : ''}`,
        withAuth(token || '')
      );

      setProjects(data.data.docs);
      setPagination({ ...pagination, totalDocs: data.data.totalDocs, page: data.data.page });
      setIsFetching(false);

      return data.data.docs;
    } catch (error) {
      setIsFetching(false);
      return [];
    }
  };

  const search = async (name: string) => {
    setIsFetching(true);

    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(
        `/v1/producer/project/search?resolve=["tags", "target_artists", "related_artists"]&archived=false&name=${name}` || '',
        withAuth(token || '')
      );

      setProjects(data.data);
      setPagination({ ...pagination, totalDocs: data.data.length, page: 1 });
      setIsFetching(false);

      return data.data;
    } catch (error) {
      setIsFetching(false);
      return [];
    }
  };

  const filter = (collection: Project[], filterText: string) => {
    return collection.filter((item) => {
      return removeDiacritics(item.name).toLowerCase().indexOf(filterText.toLowerCase()) > -1;
    });
  };

  const handleChangePage = async (page: number, _: number) => {
    await fetch(page, pagination.limit);
  };

  const handleChangeRowsPerPage = async (currentRowsPerPage: number, currentPage: number) => {
    setPagination({ ...pagination, limit: currentRowsPerPage });
    await fetch(currentPage, currentRowsPerPage);
  };

  const deleteOne = async (id: string) => {
    try {
      const data = await Axios(process.env.REACT_APP_NODE_ENV).delete(`/v1/producer/project/${id}`, withAuth(token));

      await fetch();
      return data;
    } catch (error) {
      return false;
    }
  };

  return (
    <ProducerDashContext.Provider
      value={{
        projects,
        isFetching,
        pagination,
        artistsSugestions,
        selectedArtists,
        selectedTags,
        setProjects,
        setSelectedArtists,
        setSelectedTags,
        setArtistsSugestions,
        fetch,
        search,
        filter,
        handleChangePage,
        handleChangeRowsPerPage,
        deleteOne
      }}
    >
      {children}
    </ProducerDashContext.Provider>
  );
};
