import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthConsumer } from '../context/Auth';

export const ProtectedRoute: React.FC<RouteProps> = ({ component: Component }) => {
  if (!Component) return null;

  return (
    <AuthConsumer>
      {({ isAuth }) => <Route render={(props) => (isAuth ? <Component {...props} /> : <Redirect to="/auth" />)} />}
    </AuthConsumer>
  );
};
