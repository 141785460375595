import { Axios, withAuth } from '../../../../utils/axios';

interface successApiResponse {
  status: number;
  data: any;
}

interface errorApiResponse {
  response: {
    status: number;
    data: {
      error?: {
        code: string;
        message: string;
      };
    };
  };
}

// export default async function addProjectTags(label: string, project_id: string) {
//   const token = localStorage.getItem('LOCAL_TOKEN');
//   const res: successApiResponse = await Axios(process.env.REACT_APP_NODE_ENV).post(
//     '/v1/producer/project/' + project_id + '/tags',
//     {
//       label,
//       id_producer_project: project_id
//     },
//     withAuth(token || '')
//   );
//   return res;
// }

export default async function addProjectTags(tags: any[], project_id: string) {
  const token = localStorage.getItem('LOCAL_TOKEN');
  const res: successApiResponse = await Axios(process.env.REACT_APP_NODE_ENV).post(
    '/v1/producer/project/' + project_id + '/tags',
    tags,
    withAuth(token || '')
  );
  return res;
}
