import styled from 'styled-components';

export const Container = styled.div`
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  button {
    .spin {
      animation: spinner infinite 1s linear;
      margin-left: 22px;
    }
  }
`;
