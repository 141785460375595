import React from 'react'

import { Container, Logo, Call, CallContainer } from './styles'

import graveOnlineImg from '../../images/grave-online.jpeg';


export const GraveOnline = () => {
  return (
    <Container> 
      <Logo src={graveOnlineImg} alt="Grave Online" />
      <CallContainer>
        <Call>
        <h1>EI CANTOR,</h1> Quer ganhar uma produção musical?
        O MusicPlayce vai presentear 5 cantores com uma produção musical na maior gravadora online do Brasil, a Grave Online.
        Pra participar é só abrir uma Audição Digital pedindo a composição que você quer gravar.
        Você também pode encontrar seu hit nas playlists disponíveis no app. E deixa as notificações ligadas que a gente vai entrar em contato com você pelo chat do app 😍
        </Call>
      </CallContainer>
    </Container>
  )
}
