import React, { useState, useEffect } from 'react';
import { AuthContext } from './Context';
import { User } from '../../interfaces';
import { Axios, withAuth } from '../../utils/axios';
import { showToast } from '../../components/Toast/Toast';
import showError from '../../utils/error';
import * as firebase from '../../utils/firebase';
import * as userProfile from '../../repositories/userProfile';

const localToken = localStorage.getItem('LOCAL_TOKEN');
const localAvatar = localStorage.getItem('LOCAL_AVATAR');
const localRefreshToken = localStorage.getItem('LOCAL_REFRESH_TOKEN');

interface successApiResponse {
  status: number;
  data: any;
}

interface errorApiResponse {
  response: {
    status: number;
    data: {
      error?: {
        code: string;
        message: string;
      };
    };
  };
}

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>();
  const [username, setUsername] = useState<string>('');
  const [isLogginIn, setIsLogginIn] = useState(false);
  const [isSending] = useState(false);
  const [isSended] = useState(false);
  const [isResetingPassword, setIsResetingPassword] = useState<boolean>(false);
  const [resetedPassword, setResetedPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [avatar, setAvatar] = useState(localAvatar || undefined);
  const [token] = useState(localToken || undefined);
  const [refreshToken] = useState(localRefreshToken || undefined);
  const [isGettingUser, setIsGettingUser] = useState<boolean>(false);
  const [isCreatingPortal, setIsCreatingPortal] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setIsGettingUser(true);
      if (localToken) await me(localStorage.getItem('LOCAL_USER_ID') || '');
      setIsGettingUser(false);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkUserIsAuth();
  }, [isAuth]);

  const login = async (email: string, password: string) => {
    try {
      setIsLogginIn(true);
      const loggedUser: any = await firebase.auth.signInWithEmailAndPassword(email, password);

      console.log("USER__", firebase.auth.currentUser)

      if (loggedUser) {
        setIsLogginIn(false);
        await me(loggedUser.user.uid);
        localStorage.setItem('LOCAL_USER_ID', loggedUser.user.uid);
        await checkUserIsAuth();
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(showError(error?.code));
      setIsLogginIn(false);
    }
  };

  const checkUserIsAuth = async (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const data = localStorage.getItem('LOCAL_USER_ID');
      if (data) {
        setIsAuth(true);
        return resolve(true);
      }
      setIsAuth(false);
      return reject(false);
    });
  };

  const getCurrentUserId = () => {
    return localStorage.getItem('LOCAL_USER_ID') || '';
  };

  const logout = async () => {
    await firebase.auth.signOut();
    setIsAuth(false);
    localStorage.removeItem('LOCAL_USER_ID');
    setUser(undefined);
    window.location.href = '/';
  };

  const me = async (userId: string) => {
    setIsGettingUser(true);
    try {
      const response: User = await userProfile.getUserProfileById(userId);
      if (response) {
        setUser(response);
        setAvatar(response?.avatarUrl || '');
      }
      setIsGettingUser(false);
      return response;
    } catch (err) {
      const error: errorApiResponse = err;
      console.error(error);
      setErrorMessage('Ocorreu um erro interno, contate os responsáveis pelo sistema.');
      setIsGettingUser(false);
      await logout();
    }
  };

  const refreshMe = () => me(localStorage.getItem('LOCAL_USER_ID') || '');

  const forgotPassword = async (email: string) => {
    try {
      await firebase.auth.sendPasswordResetEmail(email);
      return true;
    } catch (error) {
      setErrorMessage(showError(error?.code));
      return false;
    }
  };

  // eslint-disable-next-line no-shadow
  const handleResetPassword = async (username: string, code: string, newPassword: string) => {
    setIsResetingPassword(true);
    try {
      // await Auth.forgotPasswordSubmit(username, code, newPassword);
      setResetedPassword(true);
      setIsResetingPassword(false);
      return true;
    } catch (err) {
      if (err.code === 'CodeMismatchException') {
        showToast({ type: 'error', message: 'Código de validação inválido ou expirado. Verifique se foi digitado corretamente.' });
      }
      setIsResetingPassword(false);
      setResetedPassword(false);
      return false;
    }
  };

  const createCustomerPortal = async (id: string, defaultToken?: string) => {
    setIsCreatingPortal(true);
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).post(
        `/v2/subscriptions/customer-portal/${id}`,
        {},
        withAuth(defaultToken || token || '')
      );
      setIsCreatingPortal(false);
      return response.data;
    } catch (error) {
      setIsCreatingPortal(false);
      console.error('Error on creating customer portal: ', error);
      return undefined;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        refreshToken,
        avatar,
        user,
        username,
        isAuth,
        isLogginIn,
        isSending,
        isSended,
        errorMessage,
        isResetingPassword,
        resetedPassword,
        isGettingUser,
        isCreatingPortal,
        createCustomerPortal,
        handleResetPassword,
        login,
        forgotPassword,
        logout,
        refreshMe,
        setErrorMessage,
        setUsername,
        checkUserIsAuth,
        getCurrentUserId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
