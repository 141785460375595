import styled from 'styled-components';

export const SearchInput = styled.input`
  /* Surface / 200 */

  background: #f5f5f5;
  /* Surface / 300 */

  border: 1px solid #e4e5e6;
  border-radius: 4px;
  padding: 6.5px 6.5px 6.5px 35px;
`;

export const Container = styled.div`
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spinner {
    animation: spinner infinite 1s linear;
  }
`;
