import { CompositionIndication } from '../../interfaces';
import * as firebase from '../../utils/firebase/firebaseUtils';

export async function getCompositionIndicationsByCompositionId(compositionId: string): Promise<CompositionIndication[]> {
  const indicationsAggr: any[] = [];
  return new Promise((resolve, reject) => {
    firebase.firestore
      .collection('compositions')
      .doc(compositionId)
      .collection('indicationsCountAggregation')
      .orderBy('createdAt', 'desc')
      .limit(5)
      .get()
      .then(
        (res) => {
          res.docs.map((indication: any) => {
            indicationsAggr.push(indication.data() as CompositionIndication);
          });

          firebase.firestore
            .collection('compositions')
            .doc(compositionId)
            .get()
            .then((res) => {
              const data = res.data();
              const indicationsTotalCount = data?.indicationsCount;

              indicationsAggr.map((i: CompositionIndication) => {
                i.percentage = (i.count / indicationsTotalCount) * 100;
              });

              return resolve(indicationsAggr);
            });
        },
        (error) => {
          return reject(error);
        }
      );
  });
}
