import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router-dom';
import { PriceObj } from '../../../interfaces';
import DashboardContainer from '../../DashboardContainer';
import { Card } from '../../styles';
import { ResponsiveFlexDirection } from './styles';
import Chips from '../../../components/Chips/Chips';
import RectButton from '../../../components/RectButton/RectButton';
import { searchArtists } from './services/searchArtists';
import { AuthContext, ProducerDashContext } from '../../../context';
import ArtistChips from '../../../components/ArtistChips/ArtistChips';
import { createProject } from './services/createProject';
import reloadIcon from '../../../images/mpf-reload-white.svg';
import { showToast } from '../../../components/Toast/Toast';
import CharCounter from '../../../components/CharCounter/CharCounter';

interface Artist {
  _id: string;
  name: string;
  rank: number;
  picture_profile: {
    _id: string;
    url: string;
    type: string;
    datetime_created: string;
    datetime_updated: string;
    processed: boolean;
    resources: string[];
  };
}

interface NewProjectFormFields {
  name: string;
  description: string;
  main_artist: string;
  related_artists: string[];
  tags: string[];
}

interface MainArtist {
  label: string;
  value: string;
}

type Props = {
  activePlan?: PriceObj;
};

export const NewProject: React.FC<Props> = ({ activePlan }) => {
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [mainArtist, setMainArtist] = useState<MainArtist>();
  const [projectName, setProjectName] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');
  const { token } = useContext(AuthContext);
  const { artistsSugestions, selectedArtists, selectedTags } = useContext(ProducerDashContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mapOptionsToValues = (items: Artist[]) => {
    return items.map((option) =>
      option.picture_profile
        ? {
            value: option._id,
            label: option.name
          }
        : []
    );
  };

  const promiseOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      return callback([]);
    }
    searchArtists(inputValue, token).then((response) => {
      if (response?.map((item) => item.picture_profile)) callback(mapOptionsToValues(response));
      else return false;
    });
  };

  const GetFormikContext = () => {
    const { values } = useFormikContext<NewProjectFormFields>();

    useEffect(() => {
      setProjectName(values.name);
    }, [values.name]);

    useEffect(() => {
      setProjectDescription(values.description);
    }, [values.description]);

    return null;
  };

  const handleSaveProject = async () => {
    const object = {
      projectName,
      projectDescription,
      mainArtist,
      tags: selectedTags,
      relatedArtists: selectedArtists
    };

    setIsLoading(true);
    const response: any = await createProject(
      object.projectName,
      object.projectDescription,
      object.relatedArtists,
      object?.mainArtist?.value as string,
      object.tags,
      token
    );
    setIsLoading(false);

    if (response?.error) {
      showToast({ type: 'error', message: response.error });
    } else {
      showToast({ type: 'success', message: 'Novo projeto criado com sucesso!' });
      setTimeout(() => {
        history.push('/dash/producer/my-projects');
      }, 1200);
    }
  };

  const history = useHistory();

  return (
    <>
      <DashboardContainer activePlan={activePlan} hideButton>
        <div className="w-full p-4 sm:p-0">
          <span className="font-semibold text-2xl">
            <FormattedMessage id="dashboard.project.save-project.create-title" defaultMessage="Criar projeto" />
          </span>
          <div className="flex flex-col mt-5">
            <Card className="flex flex-col py-10 px-6">
              <Formik
                initialValues={{
                  name: '',
                  description: '',
                  main_artist: '',
                  related_artists: [],
                  tags: []
                }}
                onSubmit={(values) => {}}
              >
                {({ errors, values, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
                  <>
                    <GetFormikContext />
                    <ResponsiveFlexDirection className="flex responsive-flexbox-direction">
                      <div className="form-projects-column-left flex-1">
                        <FormattedMessage id="dashboard.project.save-project.form.project-name">
                          {(placeholder: string) => (
                            <div className="flex flex-col mt-1 mb-6">
                              <label className="text-xs">{placeholder}</label>
                              <input
                                autoComplete="off"
                                name="name"
                                id="name"
                                className="async-input w-full py-2"
                                type="text"
                                maxLength={70}
                                value={values.name}
                                onChange={handleChange('name')}
                                onBlur={handleBlur('name')}
                                data-cy="input-name"
                                onClickCapture={() => setErrorMessage('')}
                              />
                              <CharCounter currentLength={values.name.length} maxLength={70} />
                            </div>
                          )}
                        </FormattedMessage>

                        <FormattedMessage id="dashboard.project.save-project.form.main-artist">
                          {(placeholder: string) => (
                            <div className="flex flex-col mb-6">
                              <label className="text-xs">{placeholder}</label>
                              <AsyncSelect
                                styles={{
                                  placeholder: () => ({
                                    color: '#919fb1eb'
                                  })
                                }}
                                defaultOptions
                                cacheOptions
                                placeholder=""
                                noOptionsMessage={() => 'Nenhum resultado encontrado'}
                                loadingMessage={() => 'Pesquisando...'}
                                value={mainArtist}
                                onChange={(e: any) => setMainArtist(e)}
                                loadOptions={promiseOptions}
                                className="async-input"
                                id="async-select"
                              />
                            </div>
                          )}
                        </FormattedMessage>

                        <FormattedMessage id="dashboard.project.save-project.form.related-artists">
                          {(placeholder: string) => (
                            <>
                              <label className="text-xs">{placeholder}</label>
                              <ArtistChips
                                id="related-artists"
                                className="py-2 mb-6"
                                values={values.related_artists}
                                suggestions={artistsSugestions}
                                maxItems={5}
                                onChange={handleChange('related_artists')}
                                onBlur={handleBlur('related_artists')}
                                data-cy="input-related_artists"
                                onClickCapture={() => setErrorMessage('')}
                              />
                            </>
                          )}
                        </FormattedMessage>
                      </div>
                      <div className="form-projects-column-right flex flex-1 justify-between flex-col">
                        <FormattedMessage id="dashboard.project.save-project.form.project-description">
                          {(placeholder: string) => (
                            <div className="flex flex-col mt-1 mb-4">
                              <label className="text-xs">{placeholder}</label>
                              <textarea
                                id="description"
                                className="async-input w-full py-2"
                                name="description"
                                rows={5}
                                maxLength={450}
                                value={values.description}
                                onChange={handleChange('description')}
                                onBlur={handleBlur('description')}
                                data-cy="input-description"
                                onClickCapture={() => setErrorMessage('')}
                              />
                              <CharCounter currentLength={values.description.length} maxLength={450} />
                            </div>
                          )}
                        </FormattedMessage>

                        <FormattedMessage id="dashboard.project.save-project.form.project-tags">
                          {(placeholder: string) => (
                            <div className="" style={{ marginBottom: '1px' }}>
                              <label className="text-xs">{placeholder}</label>
                              <Chips
                                id="tags"
                                values={values.tags}
                                maxItems={10}
                                suggestions={[]}
                                onChange={handleChange('tags')}
                                onBlur={handleBlur('tags')}
                                data-cy="input-tags"
                                onClickCapture={() => setErrorMessage('')}
                              />
                            </div>
                          )}
                        </FormattedMessage>
                      </div>
                    </ResponsiveFlexDirection>

                    <div className="flex flex-col sm:flex-row justify-end mt-6">
                      <RectButton
                        onClick={() => history.push('/dash/producer/my-projects')}
                        theme="outline"
                        className="mr-0 mb-2 sm:mr-2 sm:mb-0 w-full sm:w-32"
                        style={{ border: '1px solid #000' }}
                      >
                        <FormattedMessage id="dashboard.project.save-project.form.button.cancel" defaultMessage="Cancelar" />
                      </RectButton>
                      <RectButton
                        onClick={handleSaveProject}
                        theme="success"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        className="w-full sm:w-32"
                      >
                        {isLoading ? (
                          <img src={reloadIcon} alt="spinner-icon" />
                        ) : (
                          <FormattedMessage id="dashboard.project.save-project.form.button.save" defaultMessage="Salvar" />
                        )}
                      </RectButton>
                    </div>
                  </>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};
