/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Poll } from '../../../interfaces';
import { Axios, withAuth } from '../../../utils/axios';
import { AuthContext } from '../../Auth';
import { ArtistDashContext } from '../artist';
import { ComposerDashContext } from '../composer';
import { WhoMatchesContext } from '../whomatches';
import { PollsContext } from './Context';

interface Props {
  children: React.ReactNode;
}

export const PollsProvider: React.FC<Props> = ({ children }) => {
  const [polls, setPolls] = useState<Poll[]>([]);
  const [isGettingPolls, setIsGettingPolls] = useState<boolean>(false);
  const [isPollsGetted, setIsPollGetted] = useState(false);

  const { token } = useContext(AuthContext);

  const { setActiveArtistTrack } = useContext(ArtistDashContext);
  const { setActiveCompositionsTrack } = useContext(ComposerDashContext);
  const { setActiveWhoMatchesTrack } = useContext(WhoMatchesContext);

  useEffect(() => {
    setActiveArtistTrack(undefined);
    setActiveCompositionsTrack(undefined);
    setActiveWhoMatchesTrack(undefined);
  }, []);

  const fetchPollsByToken = async (defaultToken?: string) => {
    setIsGettingPolls(true);

    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get('/v1/data/dashboard/search/poll/', withAuth(defaultToken || token));
      const sorted: Poll[] = response.data;
      setPolls(sorted?.sort((a, b) => b.plays - a.plays));
      setIsGettingPolls(false);
      setIsPollGetted(true);
      return response.data;
    } catch (error) {
      setIsGettingPolls(false);
      setIsPollGetted(true);
      console.error(error);
      return [];
    }
  };

  return <PollsContext.Provider value={{ polls, isGettingPolls, fetchPollsByToken, isPollsGetted }}>{children}</PollsContext.Provider>;
};
