import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Logo from '../../images/logo-musicplayce.svg';
import { Container } from './styles';

type Props = {
  children: React.ReactNode;
  hasGoBack: boolean;
  goBackRoute: string;
  title: string;
};

const SecondCheckoutContainer = ({ children, title }: Props) => {
  return (
    <Container id="modal-wrapper" className="overflow-y-hidden max-h-screen flex">
      <div className="overflow-y-hidden w-full lg:w-5/12 hidden lg:flex">
        <div className="bg-image h-full">
          <div className="px-5 h-full w-full flex flex-col bg-overlay justify-center items-center">
            <div className=" text-white text-center flex flex-col py-48">
              <h1 className="w-full max-w-md text-left font-normal leading-14 mb-10 ">
                <FormattedMessage id={`checkout.container.${title}.main-title`} />
              </h1>
              <p className="font-normal leading-7 w-full max-w-md text-left text-xl">
                <FormattedMessage id="auth.secondary-poster-title" />
              </p>
            </div>
            <Link to="/account-management" className="text-white flex w-full items-center justify-start max-w-md text-left">
              <IoMdArrowBack className="text-2xl" color="white" />
              <span className="text-sm font-normal leading-6 ml-2">
                <FormattedMessage id="checkout.container.goback-to-my-painel" />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full overflow-y-scroll flex flex-col px-5 lg:pr-8 pl-44 items-center lg:w-7/12 bg-white min-h-screen mb-4">
        <div className="w-full max-w-md xl:max-w-none flex flex-col">
          <header className="w-full justify-center lg:justify-start flex mt-12 mb-8 lg:mt-24 lg:mb-12">
            <img className="lg:w-64 w-48" src={Logo} alt="logo-MusicPlayce" />
          </header>
          <h1 style={{ color: '#202020' }} className="font-semibold text-2xl leading-14 mb-2 lg:mb-12">
            <FormattedMessage id={`checkout.container.${title}`} />
          </h1>
          <span style={{ color: '#817B7B' }} className="font-normal text-xs lg:text-sm lg:leading-6 mb-2 lg:mb-4">
            <FormattedMessage id={`checkout.container.${title}.sub-title`} />
          </span>
          <div style={{ background: '#cfcfcf' }} className="mb-3 lg:mb-5 w-full h-px" />
        </div>
        <section className="h-full w-full bg-white flex items-center justify-center xl:justify-start">{children}</section>
      </div>
    </Container>
  );
};

export default SecondCheckoutContainer;
