import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import { Container } from './styles';

interface post {
  _id: string;
  name: string;
  plays: number;
  indications: number;
  shares: number;
}

type Props = {
  isOpen: boolean;
  post?: post;
  audition?: any;
  toggleModal: () => void;
};

const ConfirmationModal: React.FC<Props> = ({ isOpen, post, audition, toggleModal, children }) => (
  <>
    {isOpen && (
      <Container>
        <div className="content-container text-center">
          <button type="button" onClick={toggleModal} className="close">
            <span className="close-text">
              <FormattedMessage id="dashboard.my-projects.modal.close-text" defaultMessage="FECHAR" />
            </span>
            <AiOutlineCloseCircle color="#CFCFCF" size={18} />
          </button>
          <span className="leading-8 font-semibold mb-4" style={{ fontSize: 28 }}>
            Você está enviando a guia ”{post?.name}” para Audição Digital “{audition?.title}”
          </span>
          <span className="text-sm leading-6 mb-12">
            Após ativar a participação desta guia na Audição Digital, não será mais possível reverter essa ação. Você confirma a ativação desta guia
            nesta Audição?
          </span>
          <div style={{ maxWidth: '440px' }} className="w-full flex flex-col-reverse md:flex-row items-center text-center justify-between">
            {children}
          </div>
        </div>
      </Container>
    )}
  </>
);

export default ConfirmationModal;
