/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { ProducerDashContext } from '../../context';
import close from '../../images/close.svg';
import CharCounter from '../CharCounter/CharCounter';
import { showToast } from '../Toast/Toast';

import { ChipsContainer, ChipsItems, ChipsInput, ChipsSuggestion } from './styles';

type Props = {
  values: any[];
  suggestions: any[];
  maxItems?: number;
  chipMaxLength?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function Chips({ values, suggestions, placeholder, maxItems, chipMaxLength }: Props) {
  const [searchVal, setSearchVal] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>([]);
  const [sugValues, setSugValues] = useState<any[]>([]);

  const { setSelectedTags } = useContext(ProducerDashContext);

  useEffect(() => {
    setSelectedTags(items);
    // eslint-disable-next-line
  }, [items]);

  function add(data: string) {
    if (/[^a-z0-9]+/gm.test(data)) {
      showToast({ type: 'error', message: `Só são aceitos números e letras minúsculas` });
      return setSearchVal('');
    }

    if (items.filter((i) => i === data).length > 0) {
      showToast({ type: 'error', message: `Este item já existe na lista` });
      return setSearchVal('');
    }

    if (maxItems && data !== '' && items.length < maxItems) {
      setItems([...items, data]);
      setFilter([]);
      return setSearchVal('');
    }

    if (!maxItems) {
      setItems([...items, data]);
      setFilter([]);
      return setSearchVal('');
    }

    showToast({ type: 'error', message: `Limite máximo de ${maxItems} ${maxItems > 1 ? 'itens' : 'item'}` });
    setFilter([]);
    return setSearchVal('');
  }

  function remove(value: string) {
    const arr = [...items];
    items.forEach((i: string) => {
      if (i === value) {
        arr.splice(arr.indexOf(value), 1);
        return setItems(arr);
      }
    });
  }

  function filterValues(value: string) {
    setSearchVal(value);
    if (!value || value === null || value === '') {
      return setFilter([]);
    }

    const arr: any = [];
    sugValues.forEach((i: string) => {
      if (i) {
        const val = i.toLowerCase();
        if (val.indexOf(value.toLowerCase()) > -1) {
          arr.push(i);
          items.forEach((s: any) => {
            if (Object.entries(i).toString() === Object.entries(s).toString()) {
              arr.pop();
            }
          });
        }
      }
    });
    return setFilter(arr);
  }

  function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      return add(searchVal);
    }
  }

  useEffect(() => {
    setItems(values);
  }, [values]);

  useEffect(() => {
    setSugValues(suggestions);
  }, [suggestions]);

  return (
    <>
      <ChipsContainer>
        <ChipsInput>
          <input
            autoComplete="off"
            className="my-1 outline-none border-none w-full"
            name="main_artist"
            id="main_artist"
            type="text"
            maxLength={chipMaxLength || 99999}
            placeholder={placeholder}
            value={searchVal}
            onKeyPress={(e) => {
              handleKeyPress(e);
            }}
            onChange={(e) => filterValues(e.target.value)}
            data-cy="input-main_artist"
          />
        </ChipsInput>
        {chipMaxLength && <CharCounter currentLength={searchVal.length} maxLength={chipMaxLength || 99999} />}
      </ChipsContainer>
      {!!filter && filter.length > 0 && (
        <ChipsSuggestion>
          {filter.map((i: any, index: any) => {
            return (
              <div
                key={index}
                style={{
                  borderBottom: '1px solid #c3c3c3'
                }}
                onClick={(e) => add(i)}
              >
                {i}
              </div>
            );
          })}
        </ChipsSuggestion>
      )}
      <ChipsItems>
        {!!items &&
          items.map((i: string, index: any) => {
            return (
              <div key={index} className="item">
                {i} <img src={close} alt="close" style={{ marginLeft: '10px' }} onClick={() => remove(i)} />
              </div>
            );
          })}
      </ChipsItems>
    </>
  );
}
