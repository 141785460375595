import styled from 'styled-components';

export const Container = styled.div`
  .w-68 {
    max-width: 250px;
  }

  @media (min-width: 1020px) {
    .w-68 {
      max-width: 286px;
    }
  }

  .max-w-plan-type {
    max-width: 80px;
  }

  .mb-14 {
    margin-bottom: 3.625rem;
  }

  .mb-15 {
    margin-bottom: 3.75rem;
  }

  @media (min-width: 425px) {
    .max-w-plan-type {
      max-width: 150px;
    }
  }

  @media (min-width: 640px) {
    .mr-23 {
      margin-right: 5.625rem;
    }
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  button {
    .spin {
      animation: spinner infinite 1s linear;
      margin-left: 22px;
    }
  }
`;
