import React, { useContext, useState } from 'react';
import { IoIosClose, IoIosMenu } from 'react-icons/io';
import { slide as Menu } from 'react-burger-menu';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// import crownIcon from '../../images/crown.svg';
import { PriceObj } from '../../interfaces';
import { Container } from './styles';
import { AuthContext } from '../../context';

type Props = {
  isMenuOpen: boolean;
  handleMenuButtonClick: () => void;
  isAccountManagement?: boolean;
  isDash?: boolean;
  activePrice?: PriceObj;
} & React.HTMLAttributes<HTMLDivElement>;

const MobileSidebar = ({ isMenuOpen, isDash, activePrice, handleMenuButtonClick }: Props) => {
  const isBeginner = activePrice?._id === (process.env.REACT_APP_NODE_ENV === 'prod' ? '5f6c29669d3957ac67ae9637' : '5f3db5ed0bbae19bb46f573e');

  const [openAuditionOptions] = useState<boolean>(false);
  const { logout } = useContext(AuthContext);

  return (
    <Container isAuditionMenuActive={openAuditionOptions}>
      <Menu
        pageWrapId="page-wrap"
        outerContainerId="root"
        styles={{
          bmCrossButton: {
            width: '40',
            height: '40'
          },
          bmCross: {
            width: '40',
            height: '40'
          }
        }}
        customCrossIcon={<IoIosClose size={18} />}
        customBurgerIcon={<IoIosMenu size={18} />}
        width="16rem"
        isOpen={isMenuOpen}
        onStateChange={handleMenuButtonClick}
      >
        <div
          style={{
            borderBottom: `${isBeginner ? '1px solid #E4E5E6' : 'none'}`,
            display: 'flex',
            justifyContent: 'space-between'
          }}
          className="w-full flex-col flex-1"
        >
          <div style={{ color: '#ACACAC' }} className="w-full">
            <ul className="">
              <li style={{ color: '#817B7B' }} className="flex items-center text-xs leading-5 pl-8 mt-6">
                {/* <FormattedMessage id="sidebar.dashboards" defaultMessage="DASHBOARDS" /> */}
              </li>
              <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink
                  to="/dash/compositions"
                  isActive={(_, { pathname }) => pathname.includes('/dash/compositions')}
                  exact
                  className="w-full pl-8 font-semibold"
                >
                  <FormattedMessage id="sidebar.compositions" defaultMessage="GUIAS" />
                </NavLink>
              </li>
              {/* <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink to="/dash/artist" isActive={(_, { pathname }) => pathname.includes('/dash/artist')} exact className="w-full pl-8">
                  <FormattedMessage id="sidebar.artist" defaultMessage="sidebar.artist" />
                </NavLink>
              </li>

              <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink to="/dash/who-matches" exact className="w-full pl-8">
                  <FormattedMessage id="sidebar.who-matches" defaultMessage="sidebar.who-matches" />
                </NavLink>
              </li>

              {user?.role.type === 'producer' || user?.role.type === 'admin' || user?.role.type === 'master' ? (
              <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink
                  to="/dash/producer/my-projects"
                  isActive={(_, { pathname }) => pathname.includes('/dash/producer/')}
                  exact
                  className="w-full pl-8"
                >
                  <FormattedMessage id="sidebar.my-projects" defaultMessage="Meus Projetos" />
                </NavLink>
              </li>
              ) : null} */}

              <li className="flex items-center cursor-pointer text-sm leading-6 mt-4 font-semibold">
                <NavLink className="w-full pl-8" to="/dash/building" exact isActive={(_, { pathname }) => pathname.includes('/dash/building')}>
                  <FormattedMessage id="sidebar.auditions" defaultMessage="AUDIÇÕES" />
                  <span className="coming-soon">EM BREVE</span>
                </NavLink>
              </li>

              {/* <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink className="w-full pl-8" to="#" exact isActive={(_, { pathname }) => pathname.includes('/dash/auditions')}>
                  <div onClick={() => setOpenAuditionOptions(!openAuditionOptions)}>
                    <FormattedMessage id="sidebar.audition" defaultMessage="Audições Digitais" />
                  </div>
                </NavLink>
              </li>
              <div className="options">
                <NavLink to="/dash/auditions" exact className="w-full">
                  <FormattedMessage id="sidebar.audition.opened" defaultMessage="Abertas" />
                </NavLink>
                <NavLink to="/dash/auditions-closed" exact className="w-full">
                  <FormattedMessage id="sidebar.audition.closed" defaultMessage="Encerradas" />
                </NavLink>
              </div>
              <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink to="/dash/polls" exact className="w-full pl-8">
                  <FormattedMessage id="sidebar.polls" defaultMessage="sidebar.polls" />
                </NavLink>
              </li>

              <li style={{ color: '#E4E5E6' }} className="w-full pl-8 flex items-center cursor-pointer text-sm leading-6 mt-4">
                <FormattedMessage id="sidebar.letters-review" defaultMessage="sidebar.letters-review" />
              </li>
              <li style={{ color: '#817B7B' }} className="flex items-center text-xs leading-5 pl-8 mt-4">
                <FormattedMessage id="sidebar.account-management" defaultMessage="sidebar.account-management" />
              </li>
              <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink to="/account-management" exact className="w-full pl-8">
                  <FormattedMessage id="sidebar.general-account-view" defaultMessage="sidebar.general-account-view" />
                </NavLink>
              </li>

              <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink to="/account-management/signature" exact className="w-full pl-8">
                  <FormattedMessage id="sidebar.my-signature" defaultMessage="sidebar.my-signature" />
                </NavLink>
              </li>

              <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink to="/checkout" exact className="w-full pl-8">
                  <FormattedMessage id="sidebar.others-plans" defaultMessage="sidebar.others-plans" />
                </NavLink>
              </li>

              <li className="flex items-center cursor-pointer text-sm leading-6 mt-4">
                <NavLink to="/account-management/receipts" exact className="w-full pl-8">
                  <FormattedMessage id="sidebar.receipts" defaultMessage="sidebar.receipts" />
                </NavLink>
              </li> */}
            </ul>
          </div>
          <div style={{ color: '#ACACAC' }} className={`w-full px-8 ${isDash ? 'py-12' : 'py-6'}`}>
            <ul>
              <li style={{ color: '#EF233C', cursor: 'pointer' }} className="text-sm leading-6 mt-2 font-semibold" onClick={() => logout()}>
                <FormattedMessage id="sidebar.logout" defaultMessage="SAIR" />
              </li>
              {/* {isDash && (
                <li style={{ color: '#EF233C', cursor: 'pointer' }} className="text-sm leading-6 mt-2 ">
                  <a href="http://link.musicplayce.com/baixar">
                    <FormattedMessage id="sidebar.app-download" defaultMessage="sidebar.app-download" />
                  </a>
                </li>
              )}
              <li className="text-sm leading-6 mt-2 cursor-pointer ">
                <a href="mailto:contato@musicplayce.com">
                  <FormattedMessage id="sidebar.do-sugestion" defaultMessage="sidebar.do-sugestion" />
                </a>
              </li>
              <li className="text-sm leading-6 mt-2 cursor-pointer ">
                <Link to="/checkout">
                  <FormattedMessage id="sidebar.whats-next" defaultMessage="sidebar.whats-next" />
                </Link>
              </li>
              <li className="text-sm leading-6 mt-2 cursor-pointer ">
                <a href="https://intercom.help/musicplayce/pt-BR">
                  <FormattedMessage id="sidebar.need-help" defaultMessage="sidebar.need-help" />
                </a>
              </li>
              <li className="text-sm leading-6 mt-2 cursor-pointer ">
                <a href="mailto:contato@musicplayce.com">
                  <FormattedMessage id="sidebar.report-error" defaultMessage="sidebar.report-error" />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        {/* {isBeginner && (
          <div style={{ padding: '1.125rem 1.5rem' }} className="w-full flex flex-col ">
            <div style={{ color: '#202020' }} className="w-full flex">
              <img src={crownIcon} alt="crown-icon" />
              <span className="text-sm leading-6 ml-2">
                <FormattedMessage id="sidebar.active-composer-plan" defaultMessage="sidebar.active-composer-plan" />
              </span>
            </div>
            <span style={{ color: '#817B7B' }} className="text-xs leading-5">
              <FormattedMessage id="sidebar.try-free" defaultMessage="sidebar.try-free" />
              <Link style={{ color: '#0D65FF' }} to="/checkout">
                <FormattedMessage id="sidebar.know-more" defaultMessage="sidebar.know-more" />
              </Link>
            </span>
          </div>
        )} */}
      </Menu>
    </Container>
  );
};

export default MobileSidebar;
