import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Formik, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';

import { Card } from '../../styles';
import { ResponsiveFlexDirection } from './styles';
import ProjectTags from '../../../components/ProjectTags/ProjectTags';
import RectButton from '../../../components/RectButton/RectButton';
import { searchArtists } from '../NewProject/services/searchArtists';
import { AuthContext, ProducerDashContext } from '../../../context';
import ObjectChips from '../../../components/ObjectChips/ObjectChips';
import DashboardContainer from '../../DashboardContainer';
import editProject from './services/editProject';
import getProject from './services/getProject';

import { showToast } from '../../../components/Toast/Toast';
import Spinner from '../../../components/Spinner/Spinner';
import { PriceObj, Artist, NewProjectFormFields } from '../../../interfaces';
import addProjectTags from './services/addProjectTags';
import deleteProjectTags from './services/deleteProjectTags';
import CharCounter from '../../../components/CharCounter/CharCounter';

interface EditProjectFormFields {
  _id: string;
  name: string;
  description: string;
  main_artist: MainArtist | undefined;
  related_artists: string[];
  tags: string[];
}

interface MainArtist {
  value: string;
  label: string;
}

interface Params {
  id: string;
}

type Props = {
  activePlan?: PriceObj;
};

export const EditProject: React.FC<Props> = ({ activePlan }) => {
  const history = useHistory();
  const { token } = useContext(AuthContext);
  const params: Params = useParams();

  const [relatedArtists, setRelatedArtists] = useState<any[]>([]);
  const [projectName, setProjectName] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');
  const [tags, setTags] = useState<any[]>([]);
  const [tagsDeleted, setTagsDeleted] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { artistsSugestions, selectedArtists } = useContext(ProducerDashContext);
  const [project, setProject] = useState<EditProjectFormFields | undefined>(undefined);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [mainArtist, setMainArtist] = useState<MainArtist>({
    label: '',
    value: ''
  });

  const [results, setResults] = useState<boolean>(false);

  const mapOptionsToValues = (items: Artist[]) => {
    return items.map((option) =>
      option
        ? {
            value: option._id,
            label: option.name
          }
        : []
    );
  };

  const promiseOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      return callback([]);
    }
    searchArtists(inputValue, token).then((response) => {
      if (response?.map((item) => item)) callback(mapOptionsToValues(response));
      else return false;
    });
  };

  const GetFormikContext = () => {
    const { values } = useFormikContext<NewProjectFormFields>();

    useEffect(() => {
      setProjectName(values.name);
    }, [values.name]);

    useEffect(() => {
      setProjectDescription(values.description);
    }, [values.description]);

    return null;
  };

  function updateTags(tagValues: any[]): any[] {
    setTags(tagValues);
    return [];
  }

  function deleteTags(tagsDel: any[]): any[] {
    const arr: any[] = [];
    tagsDel.forEach((tag: any) => {
      arr.push(tag._id);
    });

    setTagsDeleted(arr);
    return [];
  }

  async function callDeleteTags() {
    await deleteProjectTags(tagsDeleted, params.id);
  }

  async function callAddTags() {
    const arr: any[] = [];
    tags.forEach((tag) => {
      if (tag._id.startsWith('new_')) {
        arr.push({
          label: tag.label,
          id_producer_project: params.id
        });
      }
    });

    if (arr.length > 0) {
      await addProjectTags(arr, params.id);
    }
  }

  async function handleSaveProject() {
    const object = {
      projectId: params.id,
      projectName,
      projectDescription,
      mainArtist: [mainArtist.value],
      relatedArtists: selectedArtists.map((a: any) => {
        return a._id;
      })
    };

    setIsLoading(true);
    try {
      const response: any = await editProject(params.id, object.projectName, object.projectDescription, object.mainArtist, object.relatedArtists);
      await callAddTags();
      await callDeleteTags();

      if (response?.error) {
        showToast({ type: 'error', message: response.error });
        return setIsLoading(false);
      }

      showToast({ type: 'success', message: 'Projeto editado com sucesso' });
      setTimeout(() => {
        history.push(`/dash/producer/project/${params.id}`);
      }, 1200);
    } catch (e) {
      setIsLoading(false);
      showToast({ type: 'error', message: e });
    }
  }

  async function loadProject(projectId: string) {
    const response = await getProject(projectId);

    if (response?.status !== 200) return console.error('Error');

    const res = response.data.data;
    setProject(res);
    setTags(res?.tags);
    setMainArtist({
      value: res?.target_artists[0]?._id || '',
      label: res?.target_artists[0]?.name || ''
    });

    setRelatedArtists(
      res.related_artists.map((a: any) => {
        return {
          _id: a._id,
          name: a.name
        };
      })
    );
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (params && params.id) {
        await loadProject(params.id);
      }
      setIsLoading(false);
      setResults(true);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DashboardContainer activePlan={activePlan} hideButton>
        <div className="w-full p-4 sm:p-0">
          <span className="font-semibold text-2xl">
            <FormattedMessage id="dashboard.project.save-project.edit-title" defaultMessage="Criar projeto" />
          </span>
          <div className="flex flex-col mt-5 shadow-md">
            <Card className="flex flex-col py-10 px-6">
              <div className="flex justify-center items-center">
                {/* {isLoading && !results && <Spinner isLoading={isLoading && !results} />} */}
                <Spinner isLoading={isLoading} />
              </div>
              {!isLoading && results && project && (
                <Formik
                  initialValues={{
                    projectId: params.id,
                    name: project?.name,
                    description: project?.description,
                    main_artist: mainArtist,
                    related_artists: relatedArtists,
                    tags: []
                  }}
                  onSubmit={(values) => {}}
                >
                  {({ errors, values, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
                    <>
                      <GetFormikContext />
                      <ResponsiveFlexDirection className="flex responsive-flexbox-direction">
                        <div className="form-projects-column-left flex-1">
                          <FormattedMessage id="dashboard.project.save-project.form.project-name">
                            {(placeholder: string) => (
                              <div className="flex flex-col mt-1 mb-6">
                                <label className="text-xs">{placeholder}</label>
                                <input
                                  autoComplete="off"
                                  className="async-input w-full py-2"
                                  name="name"
                                  id="name"
                                  type="text"
                                  placeholder={placeholder}
                                  maxLength={70}
                                  value={values.name}
                                  onChange={handleChange('name')}
                                  onBlur={handleBlur('name')}
                                  data-cy="input-name"
                                  onClickCapture={() => setErrorMessage('')}
                                />
                                <CharCounter currentLength={values.name.length} maxLength={70} />
                              </div>
                            )}
                          </FormattedMessage>

                          <FormattedMessage id="dashboard.project.save-project.form.main-artist">
                            {(placeholder: string) => (
                              <div className="flex flex-col mb-6">
                                <label className="text-xs">{placeholder}</label>
                                <AsyncSelect
                                  defaultOptions
                                  cacheOptions
                                  styles={{
                                    placeholder: () => ({
                                      color: '#919fb1eb'
                                    })
                                  }}
                                  place
                                  noOptionsMessage={() => 'Nenhum resultado encontrado'}
                                  loadingMessage={() => 'Pesquisando...'}
                                  placeholder=""
                                  value={mainArtist}
                                  loadOptions={promiseOptions}
                                  onChange={(e: any) => {
                                    setMainArtist({
                                      value: e.value,
                                      label: e.label
                                    });
                                  }}
                                  className="async-input"
                                />
                              </div>
                            )}
                          </FormattedMessage>

                          <FormattedMessage id="dashboard.project.save-project.form.related-artists">
                            {(placeholder: string) => (
                              <>
                                <label className="text-xs">{placeholder}</label>
                                <ObjectChips
                                  id="related-artists"
                                  className="py-3 mt-1 mb-7"
                                  placeholder=""
                                  values={relatedArtists}
                                  suggestions={artistsSugestions}
                                  maxItems={5}
                                  onChange={handleChange('related_artists')}
                                  onBlur={handleBlur('related_artists')}
                                  data-cy="input-related_artists"
                                  onClickCapture={() => setErrorMessage('')}
                                />
                              </>
                            )}
                          </FormattedMessage>
                        </div>
                        <div className="form-projects-column-right flex flex-1 justify-between flex-col">
                          <FormattedMessage id="dashboard.project.save-project.form.project-description">
                            {(placeholder: string) => (
                              <div className="flex flex-col mt-1 mb-4">
                                <label className="text-xs">{placeholder}</label>
                                <textarea
                                  id="description"
                                  className="async-input w-full py-2"
                                  name="description"
                                  rows={5}
                                  maxLength={450}
                                  value={values.description}
                                  onChange={handleChange('description')}
                                  onBlur={handleBlur('description')}
                                  data-cy="input-description"
                                  onClickCapture={() => setErrorMessage('')}
                                />
                                <CharCounter currentLength={values.description.length} maxLength={450} />
                              </div>
                            )}
                          </FormattedMessage>

                          <FormattedMessage id="dashboard.project.save-project.form.project-tags">
                            {(placeholder: string) => (
                              <div className="" style={{ marginBottom: '4px' }}>
                                <label className="text-xs">{placeholder}</label>
                                <ProjectTags
                                  id="tags"
                                  placeholder=""
                                  values={tags}
                                  maxItems={10}
                                  chipMaxLength={40}
                                  hasCounter={false}
                                  suggestions={[]}
                                  onChange={handleChange('tags')}
                                  onBlur={handleBlur('tags')}
                                  setTagsUpdated={(t: any[]) => updateTags(t)}
                                  setTagsDeleted={(t: any[]) => deleteTags(t)}
                                  data-cy="input-tags"
                                  onClickCapture={() => setErrorMessage('')}
                                />
                              </div>
                            )}
                          </FormattedMessage>
                        </div>
                      </ResponsiveFlexDirection>

                      <div className="flex flex-col sm:flex-row justify-end mt-6">
                        <RectButton
                          onClick={() => history.push('/dash/producer/my-projects')}
                          theme="outline"
                          className="mr-0 mb-2 sm:mr-2 sm:mb-0 w-full sm:w-32"
                          style={{ border: '1px solid #000' }}
                        >
                          <FormattedMessage id="dashboard.project.save-project.form.button.cancel" defaultMessage="Cancelar" />
                        </RectButton>
                        <RectButton className="w-full sm:w-32" onClick={handleSaveProject} theme="success">
                          <FormattedMessage id="dashboard.project.save-project.form.button.save" defaultMessage="Salvar" />
                        </RectButton>
                      </div>
                    </>
                  )}
                </Formik>
              )}
            </Card>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};
