import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Container } from './styles';
import Input from '../../components/Input/Input';
import InputPassword from '../../components/InputPassword/InputPassword';
import Button from '../../components/Button/Button';
import AuthContainer from '../AuthContainer';
import EmailLoginValidation from '../validations/emailLoginValidation';
import { AuthConsumer, AuthContext } from '../../context/Auth';
import reloadIcon from '../../images/mpf-reload-black.svg';
import useQuery from '../../utils/customHooks/useQuery';
import Spinner from '../../components/Spinner/Spinner';
import ToastAnimated from '../../components/Toast/Toast';

type FuncType = () => void;

const EmailLogin: React.FC = () => {
  const query = useQuery();

  const { isLogginIn: isReqSending, user, token, login, isAuth } = useContext(AuthContext);

  const redirectToCustomerPortal = query.get('customer_portal');
  const redirectToken = query.get('redirect_token');
  // const redirectAudition = query.get('audition_id');

  const [results, setResults] = useState<boolean>(false);
  // const [urlToRedirect, setUrlToRedirect] = useState<string | undefined>(undefined);
  // const [showResults, setShowResults] = useState<boolean>(false);

  const triggerEnter = (event: React.KeyboardEvent<HTMLInputElement>, submitFunc: () => void) => {
    const code = event.keyCode || event.which;
    if (code === 13 && !isReqSending) {
      submitFunc();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, func: FuncType) => {
    event.preventDefault();
    func();
  };

  useEffect(() => {
    (async () => {
      // if (redirectToCustomerPortal) {
      // if (user && token) {
      // const createPortal = await createCustomerPortal(user?.idUser, token);
      // if (createPortal?.url) {
      //   setUrlToRedirect(createPortal?.url);
      // } else {
      //   setUrlToRedirect(undefined);
      // }
      // setShowResults(true);
      // }
      // } else {
      if (redirectToken) {
        await login('', '');
      }
      setResults(true);
      // }
    })();
    // eslint-disable-next-line
  }, [user, token, redirectToCustomerPortal, redirectToken]);

  // useEffect(() => {
  //  (async () => {
  //   await checkUserIsAuth();
  //  })()
  // }, [isAuth])

  return (
    <AuthConsumer>
      {({ errorMessage, isLogginIn, login, setErrorMessage }) =>
        isAuth ? (
          <Redirect to="/dash/compositions" />
        ) : // redirectToken ? (
        //   isReqSending ? (
        //     <div className="w-full flex flex-1 h-full items-center justify-center">
        //       <Spinner isLoading={isCreatingPortal} />
        //     </div>
        //   ) : (
        //     <Redirect to="/dash/compositions" />
        //   )
        // ) : redirectToCustomerPortal === 'true' ? (
        //   isCreatingPortal ? (
        //     <div className="w-full flex flex-1 h-full items-center justify-center">
        //       <Spinner isLoading={isCreatingPortal} />
        //     </div>
        //   ) : (
        //     showResults && <RedirectToCustomerPortal url={urlToRedirect} />
        //   )
        // ) : redirectAudition ? (
        //   <Redirect to={`/dash/auditions/${redirectAudition}`} />
        // ) : (
        //   <Redirect to="/dash/compositions" />
        // )
        results ? (
          <AuthContainer theme="secondary" hasGoBack goBackRoute="/auth">
            <ToastAnimated />
            <Container className="h-full flex flex-col items-center">
              <span
                style={{ color: '#202020' }}
                className=" max-w-xs flex justify-center items-center text-center w-5/6 text-2xl font-normal leading-8 mt-20 sm:mt-40 sm:mb-32 mb-12"
                data-cy="main-title"
              >
                <FormattedMessage id="auth.email.main-title" />
              </span>
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={EmailLoginValidation}
                onSubmit={(values) => {
                  login(values.email, values.password);
                }}
              >
                {({ errors, values, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
                  <>
                    <div className="text-left w-5/6 max-w-xs sm:max-w-sm text-xs sm:text-sm">
                      <FormattedMessage id="auth.email.placeholder-email">
                        {(placeholder: string) => (
                          <Input
                            autoComplete="off"
                            className="my-1 outline-none border-none"
                            name="email"
                            id="email"
                            type="email"
                            onKeyPress={(e) => triggerEnter(e, handleSubmit)}
                            placeholder={placeholder}
                            value={values.email}
                            onChange={handleChange('email')}
                            onBlur={handleBlur('email')}
                            data-cy="input-email"
                            onClickCapture={() => setErrorMessage('')}
                            hasSuccess={!(!values.email || (errors.email && touched.email))}
                            errorMessage={errors.email && touched.email ? errors.email : ''}
                          />
                        )}
                      </FormattedMessage>
                      <FormattedMessage id="auth.email.placeholder-password">
                        {(placeholder: string) => (
                          <InputPassword
                            className="my-1 mt-4 outline-none border-none"
                            name="senha"
                            id="senha"
                            placeholder={placeholder}
                            onKeyPress={(e) => triggerEnter(e, handleSubmit)}
                            value={values.password}
                            onChange={handleChange('password')}
                            data-cy="input-password"
                            onBlur={handleBlur('password')}
                            onClickCapture={() => setErrorMessage('')}
                            minLength={8}
                            hasSuccess={!(!values.password || (errors.password && touched.password))}
                            errorMessage={errors.password && touched.password ? errors.password : ''}
                          />
                        )}
                      </FormattedMessage>

                      {values.password === '' && setErrorMessage('')}
                      {errorMessage !== '' && values.password !== '' && (
                        <span style={{ color: 'red' }} className="mt-1 text-xs">
                          {errorMessage}
                        </span>
                      )}
                    </div>

                    <div className="underline hidden sm:block w-5/6 max-w-xs sm:max-w-sm text-sm sm:text-base mt-4 mb-16 text-left">
                      <Link style={{ color: '#ef233c' }} to="/auth/forgot">
                        <FormattedMessage id="auth.email.forgot" />
                      </Link>
                    </div>
                    <div className="underline sm:hidden w-5/6 max-w-xs sm:max-w-sm text-sm sm:text-base mt-4 mb-10 text-left">
                      <Link style={{ color: '#817B7B' }} to="/auth/forgot">
                        <FormattedMessage id="auth.email.forgot-sm" />
                      </Link>
                    </div>
                    <Button
                      className="outline-none flex font-normal items-center justify-center w-5/6 max-w-xs sm:max-w-sm text-sm sm:text-base mb-10"
                      id="button"
                      disabled={!isValid}
                      data-cy="button-login"
                      theme={isValid ? 'default' : 'disabled'}
                      onClick={(e) => handleClick(e, handleSubmit)}
                    >
                      {isLogginIn && (
                        <span className="text-sm sm:text-base">
                          <FormattedMessage id="auth.email.signin-in" />
                        </span>
                      )}
                      {isLogginIn && <img src={reloadIcon} alt="spinner-icon" />}
                      {!isLogginIn && (
                        <span>
                          <FormattedMessage id="auth.email.signin" />
                        </span>
                      )}
                    </Button>
                  </>
                )}
              </Formik>
              <p
                style={{ color: '#8F8F8F' }}
                className="terms sm:hidden sm:max-w-lg mt-10 mb-12 sm:mb-10 leading-4 sm:leading-6 items-center text-xs sm:text-sm w-full max-w-72 sm:w-78"
              >
                <FormattedMessage id="auth.terms-initial" />
                <a className="link--terms" href="https://static.musicplayce.com/assets/terms_of_use.pdf" target="blank">
                  <FormattedMessage id="auth.terms" />
                </a>{' '}
                <FormattedMessage id="auth.terms-initial-2" />
                <a className="link--terms" href="https://static.musicplayce.com/assets/privacy_policy.pdf" target="blank">
                  <FormattedMessage id="auth.politics" />
                </a>{' '}
                <FormattedMessage id="auth.terms-initial-3" />
              </p>
            </Container>
          </AuthContainer>
        ) : (
          <div className="w-full h-full flex-1 flex justify-center items-center">
            <Spinner isLoading={!results} />
          </div>
        )
      }
    </AuthConsumer>
  );
};

export default EmailLogin;
