/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */
import moment from 'moment';
import 'moment/locale/pt-br';
import { userSubscriptions, InvoiceObj, CustomerObj, PriceObj } from '../../interfaces';

export const getLastSubscriptionIndex = (userSubscriptions: userSubscriptions[]) =>
  userSubscriptions ? userSubscriptions?.length - 1 : 0;

export const getLastProductId = (userSubscriptions: userSubscriptions[]) => {
  const lastSubscriptionIndex = userSubscriptions ? userSubscriptions.length - 1 : 0;

  const lastProductIndex =
    userSubscriptions[lastSubscriptionIndex] !== undefined &&
    userSubscriptions[lastSubscriptionIndex].id_products.length - 1;

  const currentProductId = lastProductIndex && userSubscriptions[lastSubscriptionIndex].id_products[lastProductIndex];
  return currentProductId;
};

export const getRemainingTrialDays = (userSubscriptions: userSubscriptions[]) => {
  const lastSubscriptionIndex = userSubscriptions.length - 1;

  const trialEnd =
    userSubscriptions[lastSubscriptionIndex] !== undefined &&
    (userSubscriptions[lastSubscriptionIndex].stripe_subscription?.trial_end as number) * 1000;

  return trialEnd ? moment(trialEnd).diff(moment(), 'days') : 0;
};

export const getTrialEndDateCalendar = (userSubscriptions: userSubscriptions[]) => {
  const lastSubscriptionIndex = userSubscriptions.length - 1;

  const trialEnd =
    userSubscriptions[lastSubscriptionIndex] !== undefined &&
    (userSubscriptions[lastSubscriptionIndex].stripe_subscription?.trial_end as number) * 1000;

  return trialEnd ? moment(trialEnd).calendar() : undefined;
};

export const getTrialEndDate = (userSubscriptions: userSubscriptions[], lastSubscriptionIndex: number) => {
  const trialEnd = (userSubscriptions[lastSubscriptionIndex].stripe_subscription?.trial_end as number) * 1000;

  return trialEnd ? moment(trialEnd).format('LL') : undefined;
};

export const getCurrentInvoice = (userInvoices: InvoiceObj[]) => {
  const currentInvoice = userInvoices[0];

  return currentInvoice;
};

export const getCurrentSubscription = (userSubscriptions?: userSubscriptions[]) => {
  if (userSubscriptions?.length) {
    const lastSubscriptionIndex = userSubscriptions && userSubscriptions.length - 1;

    const currentSubscription = userSubscriptions[lastSubscriptionIndex];

    return currentSubscription;
  }

  return undefined;
};

export const canTrial = (customer?: CustomerObj, price?: PriceObj) => {
  if (customer?.id_tried_products?.length) {
    if (customer.id_tried_products?.indexOf(price?.id_product as string) < 0) {
      return true;
    }
    return false;
  }
  // if (price?.has_trial) {
  //   return true;
  // }
  return true;
};

export const getActiveProduct = (userSubscriptions?: userSubscriptions[], prices?: PriceObj[]) => {
  const beginnerProduct = prices?.find((item) =>
    process.env.REACT_APP_NODE_ENV === 'prod'
      ? item.id_product === '5f6c23949d3957ac67ae9633'
      : item.id_product === '5f3db81dbd9e96ba6c4cabd4'
  );

  const currentSubscription = getCurrentSubscription(userSubscriptions);

  const isSubscriptionCanceled =
    !currentSubscription ||
    currentSubscription.stripe_subscription?.status === 'canceled' ||
    !userSubscriptions?.length;

  if (userSubscriptions?.length && !isSubscriptionCanceled) {
    const lastSubscriptionIndex = userSubscriptions.length - 1;
    const currentSubscription = userSubscriptions[lastSubscriptionIndex];

    if (currentSubscription.id_products) {
      const lastProductIdIndex = currentSubscription.id_products.length - 1;
      const activeProduct = prices?.find(
        (item) => item.id_product === currentSubscription.id_products[lastProductIdIndex]
      );
      return activeProduct;
    }

    return beginnerProduct;
  }

  return beginnerProduct;
};
