/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

// This is a custom hook for identify clicks outside a ref. used in dropdowns, most of the time.

function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement> | React.RefObject<HTMLButtonElement>,
  execFunc: () => void
) {
  const handleClick = (e: MouseEvent) => {
    // @ts-ignore
    if (ref.current && ref?.current.contains(e.target as Node)) {
      // inside click
      return;
    }
    // outside click
    execFunc();
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref]);
}

export default useOutsideAlerter;
