import styled from 'styled-components';

export const Table = styled.table`
  border-spacing: 0px 1em;
  border-collapse: separate;

  th {
    color: #817b7b;
    font-size: 14px;
  }

  td {
    color: #817b7b;
    font-size: 16px;
    white-space: nowrap;
  }
`;
