/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { FormattedMessage } from 'react-intl';
import AccountManagementContainer from '../AccountManagementContainer';
import reaisConverter from '../../utils/simpleReaisConverter/reaisConverter';
import MySignatureComponent from '../MySignatureComponent/MySignatureComponent';
import BenefitsForm from '../../components/BenefitsForm/BenefitsForm';
import visaCard from '../../images/visa-card.svg';
import masterCard from '../../images/mc-card.svg';
import unknownCard from '../../images/unknown-card.svg';
import { Table } from './styles';
import { CheckoutContext, CheckoutConsumer } from '../../context/checkout';
import { getActiveProduct, getCurrentSubscription, getCurrentInvoice, canTrial } from '../../utils/checkoutHelpers';
import useUserData from '../../utils/customHooks/useUserData';
import { AuthContext } from '../../context/Auth';
import Spinner from '../../components/Spinner/Spinner';

const MySignature = () => {
  // criei esse estado para impedir que os dados sejam mostrados na tela antes que houvessem as chamadas na api
  const [showResults, setShowResults] = useState<boolean>(false);

  const user = useUserData();

  const { token } = useContext(AuthContext);

  const {
    prices,
    userSubscriptions,
    userInvoices,
    customer,
    userPaymentMethod,
    userUpcomingInvoice,
    getPrices,
    getUserUpcomingInvoice,
    getUserSubscriptions,
    getUserPaymentMethod,
    getUserInvoices
  } = useContext(CheckoutContext);

  useEffect(() => {
    (async () => {
      if (!prices) await getPrices();
      if (token) {
        if (!userSubscriptions?.length) {
          await getUserSubscriptions(token);
        }
        if (!userPaymentMethod) await getUserPaymentMethod(token);
        if (!userInvoices?.length) await getUserInvoices(token);
        if (!userUpcomingInvoice) await getUserUpcomingInvoice(token);
      }
    })();
    setShowResults(true);
  }, [token]);

  const stripe = useStripe();
  const elements = useElements();

  const activePrice = getActiveProduct(userSubscriptions, prices);

  const nameFormated = user?.name;

  const priceName = activePrice?.name;

  const currentSubscription = userSubscriptions && getCurrentSubscription(userSubscriptions);

  const currentInvoice = userInvoices && getCurrentInvoice(userInvoices);

  const trialEndDate = moment((currentSubscription?.stripe_subscription?.trial_end as number) * 1000).format('LL');

  const userCanTrial = canTrial(customer, activePrice);

  return (
    <CheckoutConsumer>
      {({
        isGettingPrices,
        isGettingUserSubscriptions,
        isGettingUserInvoices,
        isGettingUserUpcomingInvoice,
        isGettingUserPayemntMethod
      }) => {
        return (
          <AccountManagementContainer title="Minha assinatura">
            {isGettingPrices ||
            isGettingUserSubscriptions ||
            isGettingUserPayemntMethod ||
            isGettingUserInvoices ||
            isGettingUserUpcomingInvoice ||
            !token ? (
              <div className="w-full mt-8 flex justify-center items-center">
                <Spinner
                  isLoading={
                    isGettingPrices ||
                    isGettingUserSubscriptions ||
                    isGettingUserPayemntMethod ||
                    isGettingUserInvoices ||
                    isGettingUserUpcomingInvoice ||
                    !token
                  }
                />
              </div>
            ) : (
              showResults && (
                <div className="flex flex-col lg:flex-row w-full items-start justify-between">
                  {activePrice?.id_product !==
                  (process.env.REACT_APP_NODE_ENV === ('dev' || 'local')
                    ? '5f3db81dbd9e96ba6c4cabd4'
                    : '5f6c23949d3957ac67ae9633') ? (
                    <>
                      <MySignatureComponent
                        showButtons
                        componentType={2}
                        currentInvoice={currentInvoice}
                        currentSubscription={currentSubscription}
                        nameFormated={nameFormated}
                        trialEndDate={trialEndDate}
                        userUpcomingInvoice={userUpcomingInvoice}
                        userCanTrial={userCanTrial}
                        activePrice={activePrice}
                        userPaymentMethod={userPaymentMethod}
                        priceName={priceName}
                        className="w-full"
                      />

                      <div className="flex flex-col mt-5 lg:ml-5 lg:mt-0 w-full lg:w-3/4 h-full justify-between">
                        <div
                          style={{ border: '1px solid #E4E5E6' }}
                          className="flex bg-white rounded-lg p-8 flex-col overflow-x-auto w-full"
                        >
                          <span className="text-xl font-semibold py-3">Próxima cobrança</span>
                          <hr />
                          {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                            <span>Não existe cobrança futura. Motivo: solicitação de cancelamento realizada.</span>
                          ) : (
                            <Table className="overflow-x-auto w-full">
                              <thead>
                                <tr>
                                  <th className="text-left">
                                    <span className="mr-10">Vencimento</span>
                                  </th>
                                  <th className="text-left whitespace-no-wrap">
                                    <span className="mr-10">Valor mensal</span>
                                  </th>
                                  <th className="text-left">
                                    <span className="mr-12">Descrição</span>
                                  </th>
                                  <th className="text-left">
                                    <span className="mr-10">Forma de pagamento</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="font-semibold" style={{ color: '#0D65FF' }}>
                                    <span>
                                      {currentSubscription?.stripe_subscription?.cancel_at_period_end
                                        ? moment(
                                            (currentSubscription?.stripe_subscription?.cancel_at as number) * 1000
                                          ).format('L')
                                        : currentSubscription?.stripe_subscription?.status === 'trialing'
                                        ? moment(
                                            (currentSubscription.stripe_subscription.trial_end as number) * 1000
                                          ).format('L')
                                        : moment(
                                            (userCanTrial
                                              ? (currentInvoice?.period_end as number)
                                              : (userUpcomingInvoice?.period_end as number)) * 1000
                                          ).format('L')}
                                    </span>
                                  </td>
                                  <td style={{ minWidth: '100px' }}>
                                    {reaisConverter((userUpcomingInvoice?.total as number) / 100)}
                                  </td>
                                  <td>{activePrice?.name}</td>
                                  <td style={{ width: '280px' }} className="no-break">
                                    {userPaymentMethod ? (
                                      <div style={{ color: 'black' }} className="w-full flex">
                                        <div
                                          style={{ minWidth: '220px' }}
                                          className="w-full text-sm leading-6 font-semibold h-8 flex "
                                        >
                                          {userPaymentMethod?.card.brand === 'visa' && (
                                            <img className="mr-4" src={visaCard} alt="brand-card" />
                                          )}

                                          {userPaymentMethod?.card.brand === 'mastercard' && (
                                            <img className="mr-4" src={masterCard} alt="brand-card" />
                                          )}

                                          {!userPaymentMethod?.card.brand && (
                                            <img className="mr-4" src={unknownCard} alt="brand-card" />
                                          )}
                                          <b>
                                            <span className="mr-4">
                                              <FormattedMessage
                                                id="checkout.invoicing.card-numbers"
                                                values={{
                                                  last4CardNumbers: userPaymentMethod?.card.last4
                                                }}
                                              />
                                            </span>
                                          </b>
                                        </div>
                                        <div>
                                          <span
                                            style={{ color: '#817B7B' }}
                                            className="text-sm font-normal leading-6 mr-8"
                                          >
                                            <FormattedMessage
                                              id="checkout.invoicing.expiration-card-date-calendar"
                                              values={{
                                                expirationCardDate: `${
                                                  userPaymentMethod?.card.exp_month < 10
                                                    ? `0${userPaymentMethod?.card.exp_month}`
                                                    : userPaymentMethod.card.exp_month
                                                }/${userPaymentMethod.card.exp_year}`
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <span className="mb-8 w-full">
                                        <FormattedMessage id="checkout.invoicing.payment-method-not-found" />{' '}
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </div>
                        <div
                          style={{ border: '1px solid #E4E5E6' }}
                          className="flex bg-white rounded-lg flex-col mt-2 px-0 lg:px-5"
                        >
                          <BenefitsForm stripe={stripe} elements={elements} product={activePrice} isMySignature />
                        </div>
                      </div>
                    </>
                  ) : (
                    <span>Você não possui assinatura</span>
                  )}
                </div>
              )
            )}
          </AccountManagementContainer>
        );
      }}
    </CheckoutConsumer>
  );
};

export default MySignature;
