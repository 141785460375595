import React from 'react';
import { FormattedMessage } from 'react-intl';

import notFoundImage from '../../../../../images/not-found-image.svg';

export const NoDataComponent: React.FC = () => {
  return (
    <div className="w-full my-8 flex flex-col">
      <img src={notFoundImage} alt="not-found-icon" />
      <span className="text-center font-semibold leading-7 text-xl mt-8 mb-2 text-gray-500 select-none">
        <FormattedMessage id="dashboard.audition.list-component.no-data" defaultMessage="Nenhuma audição encontrada" />
      </span>
    </div>
  );
};
