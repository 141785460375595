import styled from 'styled-components';

export const StyledBoard = styled.div`
  section.react-trello-lane {
    padding: 0;
    width: 420px;
    min-height: 260px;
    background: 'red';
    border-radius: 8px;
    box-shadow: 0px 30px 40px -20px #E4E5E6;
  }

  div.vertical {
    /* margin: 6px; */
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spinner {
    animation: spinner infinite 1s linear;
    margin-left: 10px;
  }

  .loading-container{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const AddMusicDropDown = styled.div`
  background-color: #fff;
  width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 78px;
  /* box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25); */
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 6px 8px #e4e5e6;
  /* border-radius: 6px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px !important;

  .add-music-item {
    width: 80%;
    padding: 10px 5px;
  }

  .border-t {
    border-top: 1px solid #c3c3c3;
  }
`;

export const CollapseButton = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;
