import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import MainLogin from './mainLogin/MainLogin';
import EmailLogin from './emailLogin/EmailLogin';
import ForgotPassword from './forgotPassword/ForgotPassword';
// import ResetPasswordPasswordWithCodeVerify from './resetPasswordPasswordWithCodeVerify/ResetPasswordPasswordWithCodeVerify';
import ResetPassword from './resetPassword/ResetPassword';

export default function AuthRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <MainLogin />
      </Route>
      <Route path={`${path}/email`}>
        <EmailLogin />
      </Route>
      <Route path={`${path}/forgot`}>
        <ForgotPassword />
      </Route>
      {/* <Route path={`${path}/verify`}>
        <ResetPasswordPasswordWithCodeVerify />
      </Route> */}
      <Route path={`${path}/reset-password/:token`}>
        <ResetPassword />
      </Route>
    </Switch>
  );
}
