import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CompositionTopArtists, TopShare } from '../../interfaces';
import { useWindowDimensions } from '../../utils/useWindowsDimension/useWindowsDimension';
import DashCard from '../DashCard/DashCard';
import { Container } from './styles';

type Props = {
  isTopIndications?: boolean;
  isSocialMedia?: boolean;
  topArtists?: CompositionTopArtists[];
  topShares?: TopShare[];
  dataToRender?: any[];
  isLoading?: boolean;
  showResults?: boolean;
};

const DashCardList: React.FC<Props> = React.memo(
  ({ isTopIndications, isSocialMedia, isLoading, topArtists, topShares, showResults }) => {
    const { width } = useWindowDimensions();
    return (
      <Container>
        <div
          className="w-full h-full bg-white flex flex-col rounded-none sm:rounded-lg"
          style={{
            boxShadow: '0px 30px 40px -20px #E4E5E6',
            maxHeight: `${'594px'}`,
            minHeight: `${isSocialMedia && '594px'}`
          }}
        >
          <div className="w-full flex flex-col" style={{ padding: '32px 32px 0px' }}>
            {/* <span className="text-2xl" style={{ color: '#202020' }}> */}
            <span style={{ color: '#817B7B' }} className="font-normal text-xl leading-7">
              {isTopIndications ? (
                <FormattedMessage
                  id="dashboard.composer.infocard-list.top-indications"
                  defaultMessage="Top indicados"
                />
              ) : (
                <FormattedMessage
                  id="dashboard.composer.infocard-list.where-shared"
                  defaultMessage="Onde foram compartilhados"
                />
              )}
            </span>
            {/* <span className="text-sm" style={{ color: '#817B7B' }}>
              {!isSocialMedia ? (
                <FormattedMessage
                  id="dashboard.composer.infocard-list.top-indications-desc"
                  defaultMessage="O ranking dos artistas mais indicados para aquela guia"
                />
              ) : (
                <FormattedMessage
                  id="dashboard.composer.infocard-list.where-shared-desc"
                  defaultMessage="Canais em que ocorreu o compartilhamento"
                />
              )}
            </span> */}
            {/* <hr className="mt-6" /> */}
          </div>
          <div className="w-full h-full">
            <div
              style={{
                maxHeight: `${width >= 768 ? '480px' : '504px'}`,
                borderBottom: `${isTopIndications && '32px solid #fff'}`
              }}
              className={`w-full ${isTopIndications && `results--container overflow-x-hidden overflow-y-auto`}`}
            >
              {isTopIndications && (
                <DashCard
                  width="w-full"
                  isTopIndicated
                  showResults={showResults}
                  isLoading={isLoading}
                  topArtists={topArtists}
                />
              )}
              {isSocialMedia && (
                <DashCard
                  width="w-full"
                  isLoading={isLoading}
                  isSocialMedia
                  showResults={showResults}
                  topShares={topShares}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    );
  }
);

export default DashCardList;
