/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

const CreateAuditionValidation = Yup.object().shape({
  auditionBriefing: Yup.string().required('O campo briefing da audição é obrigatório'),
  tags: Yup.array()
    .required('O campo tags da audição é obrigatório')
    .min(1, 'Informe pelo menos uma tag para esta audição')
    .max(10, 'As audições podem ter no máximo 10 tags'),
  relatedArtists: Yup.array()
    .required('O campo artistas relacionados da audição é obrigatório')
    .min(1, 'Informe pelo menos um artista relacionado para esta audição')
    .max(5, 'As audições podem ter no máximo 5 artistas relacionados'),
  mainArtist: Yup.string().required('O campo artista principal da audição é obrigatório'),
  auditionTitle: Yup.string().required('O campo título da audição é obrigatório').max(70)
});

export default CreateAuditionValidation;
