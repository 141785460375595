import React, { useContext, useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { MusicBox } from './styles';
import playLogo from '../../../../../images/mpf-play-button.svg';
import pauseLogo from '../../../../../images/pct-volume.svg';
import { KanbanContext } from '../../../../../context/dash/producer/kanban';
import { showToast } from '../../../../../components/Toast/Toast';

type Props = {
  data: any;
  handleView: () => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
  custom_content: any;
  reloadBoard: () => Promise<void>;
};

const KanbanPlayer: React.FC<Props> = ({ data, custom_content, reloadBoard, handleView }) => {
  const playerRef = useRef<AudioPlayer>(null);

  const [isPlaying, setPlaying] = useState(false);
  const [wasPaused, setWasPaused] = useState(false);

  const { setCurrentTrack } = useContext(KanbanContext);

  const playMusic = () => {
    if (!wasPaused) stopAllAudio();

    setCurrentTrack(data);
    if (mediaExists(data?.media?.url)) {
      if (playerRef) {
        playerRef.current?.audio.current?.play();

        setPlaying(true);
        setWasPaused(false);
        if (!custom_content?.viewed) {
          handleView();
          reloadBoard();
        }
      }
    } else {
      showToast({
        type: 'error',
        message: 'Houve um problema na reprodução da guia. Valide o url da media da guia'
      });
    }
  };

  const pauseMusic = () => {
    if (playerRef) {
      playerRef.current?.audio.current?.pause();
      setPlaying(false);
      setWasPaused(true);
    }
  };

  const urlImage: string = data?.picture?.url;

  const mediaExists = (image_url: string) => {
    if (image_url) {
      const http = new XMLHttpRequest();

      http.open('HEAD', image_url, false);
      http.send();

      return http.status !== 404;
    }
  };

  const stopAllAudio = () => {
    var sounds = document.getElementsByTagName('audio');
    for (var i = 0; i < sounds.length; i++) {
      sounds[i].pause();
      sounds[i].currentTime = 0;
    }
  };

  return (
    <div className="w-full">
      <MusicBox
        className="shadow-lg h-full"
        imgUrl={mediaExists(urlImage) !== false ? urlImage : undefined}
        onClick={isPlaying ? pauseMusic : playMusic}
      >
        {isPlaying ? <img src={pauseLogo} alt="Pause Logo Button" /> : <img src={playLogo} alt="Play Logo Button" />}
      </MusicBox>
      <div className="player--box">
        <AudioPlayer
          src={data?.media?.url}
          autoPlay={false}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          ref={playerRef}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
};

export default KanbanPlayer;
