import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import PlanChoice from './planChoice/PlanChoice';
import MakePayment from './makePayment/MakePayment';
import ConfirmPlan from './confirmPlan/ConfirmPlan';
import Invoicing from './invoicing/Invoicing';
import ChangePlan from './changePlan/ChangePlan';
import ChangedPlan from './changedPlan/ChangedPlan';
import ReactivateSubscription from './reactivateSubscription/ReactivateSubscription';

const CheckoutRouter = () => {
  const { path } = useRouteMatch();
  // É preciso usar o "priceObjectId" para ter uma forma de associoar as informações do price salvas
  // no banco (benefits, etc..),  com as informações do price salvas no stripe
  return (
    <Switch>
      <Route exact path={path}>
        <PlanChoice />
      </Route>
      <Route exact path={`${path}/make-payment/:priceId`}>
        <MakePayment />
      </Route>
      <Route exact path={`${path}/confirm-plan/:priceId`}>
        <ConfirmPlan />
      </Route>
      <Route exact path={`${path}/invoicing/:priceId`}>
        <Invoicing />
      </Route>
      <Route exact path={`${path}/change-plan/:priceId`}>
        <ChangePlan />
      </Route>
      <Route exact path={`${path}/changed-plan/:priceId`}>
        <ChangedPlan />
      </Route>
      <Route exact path={`${path}/reactivate-subscription/:subscriptionId`}>
        <ReactivateSubscription />
      </Route>
    </Switch>
  );
};

export default CheckoutRouter;
