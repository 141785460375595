/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { RefObject, useContext, useEffect, useState } from 'react';
import { ProducerDashContext } from '../../context';
import close from '../../images/close.svg';
import { showToast } from '../Toast/Toast';
import cancelButton from '../../images/icon-close.svg';

import { ChipsContainer, ChipsItems, ChipsInput, ChipsSuggestion } from './styles';
import Spinner from '../Spinner/Spinner';
// import { CardTags, KanbanCard, Lane } from '../../interfaces';
// import { KanbanContext } from '../../context/dash/producer/kanban';

type Props = {
  values: any[];
  suggestions: any[];
  maxItems?: number;
  chipMaxLength?: number;
  showTagInput?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  hasPlayed?: boolean;
  toggleTagInput?: () => void;
  handleAddTag: (
    idProject: string,
    idCard: string,
    label: string,
    defaultToken?: string
  ) => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
  handleRemoveTag: (
    idProject: string,
    idCard: string,
    label: string,
    defaultToken?: string
  ) => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
  card: any;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function KanbanChips({
  values,
  suggestions,
  placeholder,
  maxItems,
  chipMaxLength,
  showTagInput,
  inputRef,
  hasPlayed,
  card,
  handleAddTag,
  handleRemoveTag,
  toggleTagInput
}: Props) {
  const [searchVal, setSearchVal] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>([]);
  const [sugValues, setSugValues] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setSelectedTags } = useContext(ProducerDashContext);

  // const { boardData } = useContext(KanbanContext);

  useEffect(() => {
    setSelectedTags(items);
    // eslint-disable-next-line
  }, [items]);

  function add(data: string) {
    if (items.filter((i) => i === data).length > 0) {
      showToast({ type: 'error', message: `Este item já existe na lista` });
      return setSearchVal('');
    }

    if (maxItems && data !== '' && items.length < maxItems) {
      setItems([...items, data]);
      setFilter([]);
      return setSearchVal('');
    }

    if (!maxItems) {
      setItems([...items, data]);
      setFilter([]);
      return setSearchVal('');
    }

    showToast({ type: 'error', message: `Limite máximo de ${maxItems} ${maxItems > 1 ? 'itens' : 'item'}` });
    setFilter([]);
    return setSearchVal('');
  }

  async function remove(value: string) {
    setIsLoading(true);
    const arr = [...items];
    items.forEach((i: string) => {
      if (i === value) {
        arr.splice(arr.indexOf(value), 1);
        return setItems(arr);
      }
    });
    await handleRemoveTag(card?.custom_content?.id_project, card?.id, value);
    setIsLoading(false);
  }

  function filterValues(value: string) {
    setSearchVal(value);
    if (!value || value === null || value === '') {
      return setFilter([]);
    }

    const arr: any = [];
    sugValues.forEach((i: string) => {
      if (i) {
        const val = i.toLowerCase();
        if (val.indexOf(value.toLowerCase()) > -1) {
          arr.push(i);
          items.forEach((s: any) => {
            if (Object.entries(i).toString() === Object.entries(s).toString()) {
              arr.pop();
            }
          });
        }
      }
    });
    return setFilter(arr);
  }

  async function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      setIsLoading(true);
      await handleAddTag(card?.custom_content?.id_project, card?.id, searchVal);
      setIsLoading(false);
      return add(searchVal);
    }
  }

  useEffect(() => {
    setItems(values);
  }, [values]);

  useEffect(() => {
    setSugValues(suggestions);
  }, [suggestions]);

  return (
    <>
      <ChipsContainer>
        {showTagInput && !isLoading && (
          <>
            <ChipsInput>
              <input
                autoComplete="off"
                className="my-1 outline-none border-none w-full"
                name="main_artist"
                id="main_artist"
                type="text"
                ref={inputRef}
                maxLength={chipMaxLength || 99999}
                placeholder={placeholder}
                value={searchVal}
                onKeyPress={(e) => {
                  handleKeyPress(e);
                }}
                style={{ background: !hasPlayed ? '#f3f7ff' : '#fff' }}
                onChange={(e) =>
                  filterValues(
                    e.target.value
                      .replace(/[^\w\s]/gi, '')
                      .replace(' ', '')
                      .toLowerCase()
                  )
                }
                data-cy="input-main_artist"
              />
              <button onClick={toggleTagInput} className="ml-3" type="button">
                <img src={cancelButton} alt="cance-button" />
              </button>
            </ChipsInput>
          </>
        )}
      </ChipsContainer>
      {!!filter && filter.length > 0 && (
        <ChipsSuggestion>
          {filter.map((i: any, index: any) => {
            return (
              <div
                key={index}
                style={{
                  borderBottom: '1px solid #c3c3c3'
                }}
                onClick={(e) => add(i)}
              >
                {i}
              </div>
            );
          })}
        </ChipsSuggestion>
      )}

      {isLoading ? (
        <div className="loading-content">
          <Spinner isLoading={true} size={4} />
        </div>
      ) : (
        <ChipsItems>
          {!!items &&
            items.map((i: string, index: any) => {
              if (i) {
                return (
                  <div key={index} className="item">
                    {i} <img src={close} alt="close" style={{ marginLeft: '10px' }} onClick={() => remove(i)} />
                  </div>
                );
              }
              return null;
            })}
        </ChipsItems>
      )}
    </>
  );
}
