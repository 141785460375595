import React from 'react';
import { TopShare } from '../../interfaces';
import WhatsAppIcon from '../../images/icon-whatsapp.svg';
import InstagramIcon from '../../images/icon-instagram.svg';
import TwitterIcon from '../../images/icon-twitter.svg';
import FaceIcon from '../../images/icon-facebook.svg';
import TelegramIcon from '../../images/icon-telegram.svg';
import OtherMediasIcon from '../../images/other-social-medias.svg';

type MediaProps = {
  item?: TopShare;
  isWhatsApp?: boolean;
  isFacebook?: boolean;
  isTwitter?: boolean;
  isTelegram?: boolean;
  isInstagram?: boolean;
  isOtherMedias?: boolean;
};

const SocialMedia = ({
  item,
  isWhatsApp,
  isFacebook,
  isInstagram,
  isOtherMedias,
  isTelegram,
  isTwitter
}: MediaProps) => (
  <div className="w-full h-full flex mt-4">
    <div
      style={{ background: '#e4e5e6', maxWidth: '56px', height: '56px', borderRadius: '50%' }}
      className="w-full mr-6 flex items-center justify-center"
    >
      {isWhatsApp && <img src={WhatsAppIcon} alt="social_media-avatar" />}
      {isInstagram && <img src={InstagramIcon} alt="social_media-avatar" />}
      {isTelegram && <img src={TelegramIcon} alt="social_media-avatar" />}
      {isTwitter && <img src={TwitterIcon} alt="social_media-avatar" />}
      {isFacebook && <img src={FaceIcon} alt="social_media-avatar" />}
      {isOtherMedias && <img src={OtherMediasIcon} alt="social_media-avatar" />}
    </div>

    <div className="w-full flex flex-col items-center">
      <div className="w-full flex relative">
        <div className="w-full flex items-start h-full">
          {isWhatsApp && (
            <span style={{ color: '#817B7B' }} className="mb-5">
              WhatsApp
            </span>
          )}
          {isInstagram && (
            <span style={{ color: '#817B7B' }} className="mb-5">
              Instagram
            </span>
          )}
          {isTelegram && (
            <span style={{ color: '#817B7B' }} className="mb-5">
              Telegram
            </span>
          )}
          {isTwitter && (
            <span style={{ color: '#817B7B' }} className="mb-5">
              Twitter
            </span>
          )}
          {isFacebook && (
            <span style={{ color: '#817B7B' }} className="mb-5">
              Facebook
            </span>
          )}
          {isOtherMedias && (
            <span style={{ color: '#817B7B' }} className="mb-5">
              Outras redes
            </span>
          )}
        </div>

        {isWhatsApp && (
          <span
            style={{ color: '#0D65FF' }}
            className="whitespace-no-wrap font-semibold text-base leading-6 absolute bottom-0 right-0 mb-2"
          >
            {item?.target === 'TEXT' ? item?.count : 0} (
            {item?.target === 'TEXT' ? (item?.percentage * 100).toFixed((item?.percentage * 100) % 2 === 0 ? 0 : 2) : 0}
            %)
          </span>
        )}
        {isInstagram && (
          <span
            style={{ color: '#0D65FF' }}
            className="whitespace-no-wrap font-semibold text-base leading-6 absolute bottom-0 right-0 mb-2"
          >
            {item?.target === 'INSTAGRAM' ? item?.count : 0} (
            {item?.target === 'INSTAGRAM'
              ? (item?.percentage * 100).toFixed((item?.percentage * 100) % 2 === 0 ? 0 : 2)
              : 0}
            %)
          </span>
        )}
        {isFacebook && (
          <span
            style={{ color: '#0D65FF' }}
            className="whitespace-no-wrap font-semibold text-base leading-6 absolute bottom-0 right-0 mb-2"
          >
            {item?.target === 'FACEBOOK' ? item?.count : 0} (
            {item?.target === 'FACEBOOK'
              ? (item?.percentage * 100).toFixed((item?.percentage * 100) % 2 === 0 ? 0 : 2)
              : 0}
            %)
          </span>
        )}
        {isTwitter && (
          <span
            style={{ color: '#0D65FF' }}
            className="whitespace-no-wrap font-semibold text-base leading-6 absolute bottom-0 right-0 mb-2"
          >
            {item?.target === 'TWITTER' ? item?.count : 0} (
            {item?.target === 'TWITTER'
              ? (item?.percentage * 100).toFixed((item?.percentage * 100) % 2 === 0 ? 0 : 2)
              : 0}
            %)
          </span>
        )}
        {isTelegram && (
          <span
            style={{ color: '#0D65FF' }}
            className="whitespace-no-wrap font-semibold text-base leading-6 absolute bottom-0 right-0 mb-2"
          >
            {item?.target === 'TELEGRAM' ? item?.count : 0} (
            {item?.target === 'TELEGRAM'
              ? (item?.percentage * 100).toFixed((item?.percentage * 100) % 2 === 0 ? 0 : 2)
              : 0}
            %)
          </span>
        )}
        {isOtherMedias && (
          <span
            style={{ color: '#0D65FF' }}
            className="whitespace-no-wrap font-semibold text-base leading-6 absolute bottom-0 right-0 mb-2"
          >
            {item?.target === 'OTHER' ? item?.count : 0} (
            {item?.target === 'OTHER'
              ? (item?.percentage * 100).toFixed((item?.percentage * 100) % 2 === 0 ? 0 : 2)
              : 0}
            %)
          </span>
        )}
      </div>
      {isWhatsApp && (
        <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
          <div
            style={{
              borderBottom: '4px solid #0D65FF',
              width: `${item?.target === 'TEXT' ? item?.percentage * 100 : 0}%`
            }}
            className="rounded-full"
          />
        </div>
      )}
      {isInstagram && (
        <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
          <div
            style={{
              borderBottom: '4px solid #0D65FF',
              width: `${item?.target === 'INSTAGRAM' ? item?.percentage * 100 : 0}%`
            }}
            className="rounded-full"
          />
        </div>
      )}
      {isFacebook && (
        <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
          <div
            style={{
              borderBottom: '4px solid #0D65FF',
              width: `${item?.target === 'FACEBOOK' ? item?.percentage * 100 : 0}%`
            }}
            className="rounded-full"
          />
        </div>
      )}
      {isTwitter && (
        <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
          <div
            style={{
              borderBottom: '4px solid #0D65FF',
              width: `${item?.target === 'TWITTER' ? item?.percentage * 100 : 0}%`
            }}
            className="rounded-full"
          />
        </div>
      )}
      {isTelegram && (
        <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
          <div
            style={{
              borderBottom: '4px solid #0D65FF',
              width: `${item?.target === 'TELEGRAM' ? item?.percentage * 100 : 0}%`
            }}
            className="rounded-full"
          />
        </div>
      )}
      {isOtherMedias && (
        <div style={{ borderBottom: '1px solid #E4E5E6' }} className="w-full">
          <div
            style={{
              borderBottom: '4px solid #0D65FF',
              width: `${item?.target === 'OTHER' ? item?.percentage * 100 : 0}%`
            }}
            className="rounded-full"
          />
        </div>
      )}
    </div>
  </div>
);

export default SocialMedia;
