import React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { FaSpinner } from 'react-icons/fa';

type Props = {
  succededFiles: number[];
  failedFiles: number[];
  actualFileUploading: number | null;
  actualIndex: number;
};

export const UploadStatus: React.FC<Props> = ({ succededFiles, failedFiles, actualFileUploading, actualIndex }) => {
  let returnedStatus: Element | any = <span></span>;

  const isSucceeded = succededFiles.includes(actualIndex);
  const isFailure = failedFiles.includes(actualIndex);
  const isLoading = actualFileUploading === actualIndex && !isSucceeded && !isFailure;
  const isPending = !isSucceeded && !isFailure && !isLoading;

  if (isSucceeded) {
    returnedStatus = <AiOutlineCheckCircle size={24} color="#1db954" />;
  }

  if (isFailure) {
    returnedStatus = <IoIosCloseCircleOutline size={24} color="#cc1c35" />;
  }

  if (isLoading || isPending) {
    returnedStatus = <FaSpinner size={24} color="#ACACAC" className="animate-spin" />;
  }

  return returnedStatus;
};
