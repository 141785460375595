import React, { useEffect, useRef, useState } from 'react';
import verticalMoreIcon from '../../../../../images/mpf-more-vertical-white.svg';
import useOutsideAlerter from '../../../../../utils/customHooks/useOutsideIdentifier';
import { LaneHeaderDropContainer } from './styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import { showToast } from '../../../../../components/Toast/Toast';
import reloadiIcon from '../../../../../images/mpf-reload-black.svg';
import hearthIcon from '../../../../../images/mpf-heart-1-active.svg';

type Props = {
  handleShutDownAudition: (
    idAudition: string,
    idProject: string,
    defaultToken?: string
  ) => Promise<
    | {
        success: boolean;
        data: any;
      }
    | undefined
  >;
  props: any;
};

export const LaneHeader: React.FC<Props> = ({ handleShutDownAudition, props }) => {
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const [showDropdown, setShowDropDown] = useState(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [isClosingAudition, setIsClosingAudition] = useState<boolean>(false);

  const hideDropDown = () => {
    setShowDropDown(false);
  };

  useOutsideAlerter(dropdownRef, hideDropDown);

  const { title, metadata } = props;

  const isAuditionLane = props?.metadata && !props?.metadata?.is_musicplayce_top;
  const auditionDateCreated = new Date(`${metadata?.datetime_created}`);
  const auditionDateClosed = new Date(`${metadata?.datetime_closed}`);

  useEffect(() => {
    if (copied) {
      showToast({
        type: 'success',
        message: 'Link copiado para área de transferência'
      });
      setCopied(false);
      hideDropDown();
    }
  }, [copied]);

  const handleCloseAudition = async (idAudition: string) => {
    setIsClosingAudition(true);
    const resp = await handleShutDownAudition(idAudition, props?.idProject);
    setIsClosingAudition(false);
    if (resp) {
      if (resp?.data?.response?.data?.error?.code === 'audition/already-closed') {
        showToast({
          type: 'error',
          message: 'A audição já está encerrada.'
        });
      } else if (resp?.success) {
        showToast({
          type: 'success',
          message: 'Audição encerrada com sucesso.'
        });
      } else {
        showToast({
          type: 'error',
          message: 'Ocorreu um erro ao encerrar a audição, tente novamente.'
        });
      }
    }

    hideDropDown();
  };

  return (
    <>
      <div
        ref={dropdownRef as any}
        style={{ height: '68px', background: isAuditionLane ? (metadata?.status === 'open' ? '#1DB954' : '#F24C60') : '#202020' }}
        className="flex flex-col items-center justify-center w-full rounded-t-lg p-4 relative"
      >
        <span className="text-white font-semibold truncate flex">
          {title} {props?.metadata?.is_musicplayce_top && <img className="mx-2" src={hearthIcon} alt="hearth-icon" />}{' '}
          {`${props?.cards?.length ? `(${props?.cards?.length} ${props?.cards?.length > 1 ? 'itens' : 'item'})` : ''}`}
        </span>

        {isAuditionLane && (
          <>
            <span style={{ fontSize: '10px' }} className="leading-3 opacity-75 text-white font-semibold">
              AUDIÇÃO{' '}
              {metadata?.status === 'open'
                ? `ABERTA EM ${auditionDateCreated.toLocaleDateString('pt-BR')}`
                : `ENCERRADA EM ${auditionDateClosed.toLocaleDateString('pt-BR')}`}
            </span>

            <button style={{ position: 'absolute', right: '46px', padding: '5px' }} onClick={() => setShowDropDown((prevState) => !prevState)}>
              <img src={verticalMoreIcon} alt="more-icon" />
            </button>
            {showDropdown && (
              <LaneHeaderDropContainer>
                <a href={metadata?.url} target="_blank" rel="noopener noreferrer" className="cursor-pointer" onClick={() => hideDropDown()}>
                  Abrir página da audição
                </a>
                <div className="w-full flex my-2" style={{ height: 1, background: '#E4E5E6' }} />
                <CopyToClipboard text={metadata?.url} onCopy={() => setCopied(true)}>
                  <span style={{ color: '#8F8F8F' }} className="cursor-pointer">
                    Copiar link da audição
                  </span>
                </CopyToClipboard>

                <div className="w-full flex my-2" style={{ height: 1, background: '#E4E5E6' }} />
                <span style={{ color: '#CC1C35' }} className="cursor-pointer flex items-center" onClick={() => handleCloseAudition(metadata?._id)}>
                  Encerrar audição
                  {isClosingAudition && <img className="spinner" src={reloadiIcon} alt="reload-icon" />}
                </span>
              </LaneHeaderDropContainer>
            )}
          </>
        )}
        {/* <span className="text-white font-semibold ml-2">({cardsAmount})</span> */}
      </div>
    </>
  );
};
