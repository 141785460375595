import React from 'react';
import { IoIosArrowUp } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';

import heartIcon from '../../images/mpf-heart-1-active.svg';

type Props = {
  hideButton?: boolean;
};

const FooterWithButton: React.FC<Props> = ({ hideButton }) => {
  const scrollToTop = () => {
    const pageWrap = document.getElementById('page-top-div');
    pageWrap?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <div
      className={`flex w-full ${
        hideButton ? 'justify-center' : 'justify-around sm:justify-between'
      } items-center pt-8 pb-5`}
    >
      {!hideButton && <div className="w-1 h-1" />}
      <span className="flex items-center text-sm select-none" style={{ color: '#817B7B' }}>
        <FormattedMessage id="dashboard.composer.footer-made-with" defaultMessage="©2021 Feito com" />{' '}
        <img src={heartIcon} alt="heart-icon" className="mx-1" />{' '}
        <FormattedMessage id="dashboard.composer.footer-in-goiania" defaultMessage="no Brasil" />
      </span>
      {!hideButton && (
        <button type="button" className="bg-black p-1" onClick={scrollToTop}>
          <IoIosArrowUp size={20} color="#FFF" />
        </button>
      )}
    </div>
  );
};

export default FooterWithButton;
