import { Axios, withAuth } from '../../../../utils/axios';

interface createProjectResponse {
  data: {
    data: {
      _id: string;
      name: string;
      description: string;
      archived: boolean;
      id_board: string;
      target_artists: [string];
      related_artists: [string];
      user_created: string;
      datetime_created: string;
      datetime_updated: string;
      tags: Tag[];
    };
  };
}

interface Artist {
  _id: string;
  name: string;
  rank: number;
  picture_profile: {
    _id: string;
    url: string;
    type: string;
    datetime_created: string;
    datetime_updated: string;
    processed: boolean;
    resources: string[];
  };
}

interface Tag {
  label: string;
  id_producer_project: string;
}

const createTags = async (tagsArray: Tag[], idProject: string, defaultToken?: string) => {
  try {
    await Axios(process.env.REACT_APP_NODE_ENV).post(`/v1/producer/project/${idProject}/tags`, tagsArray, withAuth(defaultToken));
  } catch (error) {
    console.error('Error on creating tags: ', error.response);
  }
};

export const createProject = async (
  name: string,
  description: string,
  related_artists: Artist[],
  target_artist: string,
  tags?: string[],
  defaultToken?: string
) => {
  try {
    const targetArtists = [target_artist];
    const idRelatedArtists = related_artists.map((item) => item._id);

    const { data }: createProjectResponse = await Axios(process.env.REACT_APP_NODE_ENV).post(
      '/v1/producer/project',
      {
        name,
        description,
        target_artists: targetArtists,
        related_artists: idRelatedArtists || ['']
      },
      withAuth(defaultToken)
    );

    if (tags?.length) {
      const tagsArray: Tag[] = [];
      tags.forEach((item) => {
        tagsArray.push({
          label: item,
          id_producer_project: data.data._id
        });
      });

      await createTags(tagsArray, data.data._id, defaultToken);
    }

    return data;
  } catch (error) {
    console.error('Error on creating new project: ', error.response);
    if (error.response.data.error.code === 'producer/body-validation-error') {
      if (error.response.data.error.messages[0].field === 'name') {
        return {
          error: 'Por favor, preencha o campo "Nome do projeto" antes de salvar'
        };
      }
      if (error.response.data.error.messages[0].field === 'description') {
        return {
          error: 'Por favor, preencha o campo "Descrição do projeto" antes de salvar'
        };
      }
      if (error.response.data.error.messages[0].field === 'target_artists') {
        return {
          error: 'Por favor, preencha o campo "Artista principal" antes de salvar'
        };
      }
      if (error.response.data.error.messages[0].field === 'target_artists[0]') {
        return {
          error: 'Por favor, preencha o campo "Artista principal" antes de salvar'
        };
      }
    }
  }
};
