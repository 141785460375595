import styled from 'styled-components';

export const CardContent = styled.div`
  height: auto;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main-info {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #202020;
    margin-bottom: 6px;
  }

  .secondary-info {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #8f8f8f;
  }
`;
