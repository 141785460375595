/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactStars from 'react-stars';
import { FormattedMessage } from 'react-intl';
import { Container } from './styles';
import trash from '../../images/trash.svg';
import soundActived from '../../images/icon-sound-active.svg';
import soundDisabled from '../../images/icon-sound.svg';
import edit from '../../images/edit.svg';
import KanbanChips from '../KanbanChips/KanbanChips';
import cancelButton from '../../images/icon-close.svg';
import confirmButton from '../../images/checked-green.svg';
import KanbanPlayer from '../../dashboard/Producer/Kanban/components/KanbanPlayer/KanbanPlayer';
import { KanbanContext } from '../../context/dash/producer/kanban';
import Message from '../Message/Message';
import ReactTooltip from 'react-tooltip';
import reloadIcond from '../../images/mpf-reload-green.svg';
import { styles } from '../../globalStyle';

const KanbanCard: React.FC = (props: any) => {
  const [showTagInput, setShowTagInput] = useState<boolean>(false);
  const [showEditInput, setShowEditInput] = useState<boolean>(false);
  const [postName, setPostName] = useState<string>(props?.custom_content?.post?.name);
  // eslint-disable-next-line
  const [hasPlayed, setHasPlayed] = useState<boolean>(props?.custom_content?.viewed);
  const [tags, setTags] = useState<string[]>([]);
  const [starsValue, setStarsValue] = useState<number>(props?.custom_content?.classification);
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const { boardData, editPostName, deleteCard, reloadBoard, handleClassify, handleView, handleAddTag, handleRemoveTag } = useContext(KanbanContext);

  const tagInputRef = useRef<HTMLInputElement>(null);
  const editPostNameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props?.custom_content?.viewed) setHasPlayed(true);
  }, [props?.custom_content?.viewed]);

  const ratingChanged = async (newRating: number) => {
    setStarsValue(newRating);
    await handleClassify(props?.custom_content?.id_project, props?.custom_content?.id_project_post, newRating);
    await reloadBoard(props?.custom_content?.id_project);
  };
  const getRenderedItems = (items: string[], maxItems: number) => {
    return items?.slice(0, maxItems);
  };

  const toggleShowTagInput = () => setShowTagInput(!showTagInput);
  const toggleShowEditInput = () => setShowEditInput(!showEditInput);

  const confirmPostNameEdit = async (postId: string) => {
    if (postName !== props?.custom_content?.post?.name) {
      setIsEditingName(true);
      await editPostName(editPostNameInputRef?.current?.value as string, postId);
      await reloadBoard(props?.custom_content?.id_project);
      setIsEditingName(false);
      toggleShowEditInput();
    }
    toggleShowEditInput();
  };

  const cancelPostNameEdit = () => {
    setPostName(props?.custom_content?.post?.name);
    toggleShowEditInput();
  };

  const handleDeleteCard = async (cardId: string) => {
    await deleteCard(cardId);
    await reloadBoard(props?.custom_content?.id_project as string);
  };

  useEffect(() => {
    if (showEditInput) editPostNameInputRef?.current?.focus();
    if (showTagInput) tagInputRef?.current?.focus();
  }, [showEditInput, showTagInput]);

  const getFormatDate = (date: Date) => {
    const dateType = new Date(date);
    const calendarDate = dateType?.toLocaleDateString('pt-br');
    const hoursDate = dateType?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return (
      <span>
        {calendarDate} às {hoursDate}
      </span>
    );
  };

  useEffect(() => {
    const filteredTags: string[] = [];
    if (boardData) {
      props?.id_tags?.forEach((tagId: string) => {
        const filteredtag = boardData?.tags?.find((item: any) => item?._id === tagId);
        filteredTags?.push(filteredtag?.label as string);
      });

      setTags(filteredTags);
    }
  }, [boardData]);

  const [composers, setComposers] = useState<any[]>([]);

  useEffect(() => {
    setComposers(props?.custom_content?.post?.composers?.map((item: any) => item?.name).sort());
  }, [props?.custom_content?.post?.composers]);

  const disallowEdit = props?.custom_content?.is_musicplayce_top || props?.custom_content?.id_audition;

  return (
    <Container hasPlayed={hasPlayed} key={props?._id}>
      <div className="flex flex-row w-full">
        <div style={{ background: !hasPlayed ? '#f3f7ff' : '#FFF' }} className="player-box">
          <div className="w-24 h-24 flex justify-center items-center mb-3">
            <KanbanPlayer
              custom_content={props?.custom_content}
              handleView={async () => await handleView(props?.custom_content?.id_project, props?.custom_content?.id_project_post)}
              data={props?.custom_content?.post}
              reloadBoard={async () => await reloadBoard(props?.custom_content?.id_project)}
            />
          </div>
          <ReactStars
            className="stars"
            half={false}
            value={starsValue}
            count={5}
            onChange={ratingChanged}
            size={18}
            color1={styles.text.colors.surface_600}
            color2={styles.text.colors.wakanda_900}
          />
        </div>
        <div className="text-box">
          <div className="flex flex-row justify-between ">
            {showEditInput ? (
              <div className="flex w-full items-center mr-5 ">
                <input
                  ref={editPostNameInputRef}
                  style={{ borderBottom: '1px solid #c3c3c4', fontWeight: 'bold', maxWidth: '150px', background: !hasPlayed ? '#f3f7ff' : '#FFF' }}
                  value={postName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostName(e.currentTarget.value)}
                  type="text"
                />

                <div style={{ minWidth: '50px' }} className="flex items-center ml-4">
                  <button onClick={cancelPostNameEdit} className="mr-3" type="button">
                    <img src={cancelButton} alt="cance-button" />
                  </button>
                  <button onClick={() => confirmPostNameEdit(props?.custom_content?.post?._id)} type="button">
                    {isEditingName ? (
                      <img className="reload-icon" src={reloadIcond} alt="reload-icon" />
                    ) : (
                      <img src={confirmButton} alt="confirm-button" />
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div
                aria-hidden="true"
                onClick={disallowEdit ? () => {} : toggleShowEditInput}
                className="title cursor-pointer header6-semibold"
                data-tip={postName}
                data-for="post-name"
              >
                <ReactTooltip id="post-name" />
                {postName?.replace(/(.{20})..+/, '$1...')}
                <img className="icon" src={disallowEdit ? '' : edit} alt="" />
              </div>
            )}
            <div style={{ minWidth: !disallowEdit ? '42px' : 'none', minHeight: '24px' }} className="flex items-center">
              {!disallowEdit && <img onClick={() => handleDeleteCard(props?.id)} className="icon mr-3 cursor-pointer" src={trash} alt="trash" />}
              {hasPlayed ? (
                <img src={soundDisabled} alt="sound-disabled" />
              ) : (
                <img
                  style={{ boxShadow: '0px 0px 15px rgba(13, 101, 255, 0.8)', background: 'rgb(191, 212, 255)' }}
                  src={soundActived}
                  alt="sound-actived"
                />
              )}
            </div>
          </div>
          {disallowEdit && (
            <div className="plays-indications-container">
              <span className="plays-indications paragrafo-small">{props?.custom_content?.post?.plays} plays</span>
              <span className="plays-indications paragrafo-small">{props?.custom_content?.post?.indications} indicações</span>
            </div>
          )}
          <div className="updated-at web-overline">Adicionada em {getFormatDate(props?.datetime_created)}</div>
          {disallowEdit ? (
            <div style={{ color: '#8F8F8F', maxWidth: '300px' }} className="flex items-center">
              <div className="text-sm">
                {composers?.length ? (
                  composers?.length > 1 ? (
                    <>
                      <span>{getRenderedItems(composers, 1)}</span>
                      <ReactTooltip>
                        <div className="flex">
                          <div className="flex flex-col p-4">
                            <span style={{ color: '#fff', fontSize: '10px', marginBottom: '5px' }}>Compositores(as)</span>
                            {composers?.map((item: any, idx: number) => (
                              <span className="text-sm leading-6 my-1" style={{ color: '#CFCFCF' }} key={idx}>
                                {item}
                              </span>
                            ))}
                          </div>
                          <div className="flex flex-col p-4">
                            <span style={{ color: '#fff', fontSize: '10px', marginBottom: '5px' }}>Intérprete</span>
                            {props?.custom_content?.post?.interpreters?.length ? (
                              props?.custom_content?.post?.interpreters?.map((item: any, idx: number) => (
                                <span className="text-sm leading-6 my-1" style={{ color: '#CFCFCF' }} key={idx}>
                                  {item?.name}
                                </span>
                              ))
                            ) : (
                              <span className="text-sm leading-6 my-1" style={{ color: '#CFCFCF' }}>
                                Desconhecido
                              </span>
                            )}
                          </div>
                        </div>
                      </ReactTooltip>
                      <span data-tip className="underline text-black ml-2 cursor-pointer">
                        ver mais
                      </span>
                    </>
                  ) : (
                    <span>{composers[0]?.name}</span>
                  )
                ) : null}
              </div>
            </div>
          ) : (
            <span className="text-sm" style={{ color: '#8F8F8F' }}>
              Upload do Produtor
            </span>
          )}
          <div className="card-bottom">
            {!showTagInput && (
              <div onClick={toggleShowTagInput} className={`add-tags cursor-pointer ${!showTagInput && 'my-4'}`} aria-hidden="true">
                +ADICIONAR TAGS
              </div>
            )}
            <FormattedMessage id="dashboard.my-projects.kanban.card.tags">
              {(placeholder: string) => (
                <KanbanChips
                  placeholder={placeholder}
                  id="tags"
                  hasPlayed={hasPlayed}
                  showTagInput={showTagInput}
                  card={props}
                  handleAddTag={handleAddTag}
                  handleRemoveTag={handleRemoveTag}
                  values={tags}
                  maxItems={10}
                  chipMaxLength={40}
                  suggestions={[]}
                  data-cy="input-tags"
                  inputRef={tagInputRef}
                  toggleTagInput={toggleShowTagInput}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default KanbanCard;

export const KanbanCardWithoutData: React.FC = (props: any) => {
  return (
    <div>
      <Message isKanban type="notFound" message="Nenhum card encontrado" />
    </div>
  );
};
