import React from 'react';

import { CharCounterContainer } from './styles';

type Props = {
  currentLength: number;
  maxLength: number;
  margin?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export default function CharCounter({ currentLength, maxLength, margin }: Props) {
  return (
    <CharCounterContainer className={`flex flex-row justify-end text-xs text-gray-500 ${margin}`}>
      {currentLength}/{maxLength}
    </CharCounterContainer>
  );
}
