import React, { useState, useRef } from 'react';
import { Media } from 'react-breakpoints';
import Slider, { Settings as SliderSettings } from 'react-slick';

import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import { MediaOptions } from '../../interfaces';
import { GridContainer } from './styles';

const SliderCards: React.FC = ({ children }) => {
  const [activeSlide, setSlide] = useState(0);
  const slider = useRef<Slider | null>(null);

  const sliderSettings: SliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setSlide(next)
  };

  const nextSlide = () => {
    if (slider.current !== null) slider.current.slickNext();
  };

  const previousSlide = () => {
    if (slider.current !== null) slider.current.slickPrev();
  };

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }: MediaOptions) =>
        breakpoints[currentBreakpoint] > breakpoints.sm ? (
          <GridContainer>{children}</GridContainer>
        ) : (
          <div className="relative">
            <div className="absolute flex mr-6 right-0 top-0 mt-6 z-50">
              <IoIosArrowBack
                size={24}
                className="mr-5"
                onClick={previousSlide}
                color={activeSlide < 1 ? '#CFCFCF' : '#202020'}
              />
              <IoIosArrowForward size={24} onClick={nextSlide} color={activeSlide >= 2 ? '#CFCFCF' : '#202020'} />
            </div>
            <Slider {...sliderSettings} className="z-10" ref={slider}>
              {children}
            </Slider>
            <hr className="block sm:hidden" />
          </div>
        )
      }
    </Media>
  );
};

export default SliderCards;
