import React from 'react';

import FloatingLabel from 'floating-label-react';
import 'floating-label-react/styles.css';

import { Container } from './styles';

type Props = {
  errorMessage?: string;
  hasSuccess?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

function Input({ hasSuccess, errorMessage, ...rest }: Props) {
  return (
    <Container hasSuccess={hasSuccess}>
      <FloatingLabel className="floating--label" {...rest} style={{ borderBottom: '1px solid #CFCFCF' }} />
      {errorMessage && (
        <div className="align--errormessage">
          <span className="error w-full text-xs">{errorMessage}</span>
        </div>
      )}
    </Container>
  );
}

export default Input;
