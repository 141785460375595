import styled from 'styled-components';

export const MainContainer = styled.div`
  a {
    position: relative;
  }

  a.active {
    color: #222;
  }

  a.active::before {
    content: '';
    left: 0;
    position: absolute;
    width: 2%;
    border-radius: 0 8px 8px 0;
    margin-top: -5px;
    height: 35px;
    background: #ef233c;
  }
`;

export const Container = styled.div`
  .bg-nav {
    mix-blend-mode: multiply;
  }

  .picture--container {
    margin-top: -30px;
    z-index: 999;
  }

  .picture--title--container {
    margin-top: -50px;
  }

  .texts--container {
    margin-top: -25px;
    z-index: 999;
    margin-left: 25px;
  }

  .header-logo {
    margin-left: 50px;
  }

  @media (max-width: 640px) {
    .picture--container {
      margin-top: 0px;
    }

    .picture--title--container {
      margin-top: 0px;
      background: #f5f5f5;
    }

    .texts--container {
      margin-top: 0px;
      z-index: 999;
      margin-left: 0px;
    }
  }

  @media (min-width: 1650px) {
    .header-logo {
      margin-left: 0;
    }
  }
`;
