import styled from 'styled-components';

export const Container = styled.div`
  .w-68 {
    max-width: 190px;
  }

  @media (min-width: 640px) {
    .w-68 {
      max-width: 250px;
    }
  }

  @media (min-width: 1020px) {
    .w-68 {
      max-width: 286px;
    }
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  button {
    .spin {
      animation: spinner infinite 1s linear;
      margin-left: 22px;
    }
  }
`;
