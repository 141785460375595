import React, { useRef, MutableRefObject } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { ContainerGraph } from './styles';
import Spinner from '../Spinner/Spinner';
import { VotesByTime } from '../../interfaces';
import Message from '../Message/Message';

type Props = {
  className?: React.CSSProperties;
  data?: HistogramData;
  isPoll?: boolean;
  isWhoMatches?: boolean;
  isPerHour?: boolean;
  perHourData?: VotesByTime[];
  isLoading?: boolean;
  isActive?: boolean;
};

export interface HistogramData {
  data: HistogramPoint[];
  post_duration: number;
}

export interface HistogramPoint {
  x: number;
  class1: number;
  class2?: number;
  total?: number;
}

const monthTranslate: any = {};
monthTranslate[1] = 'Jan';
monthTranslate[2] = 'Fev';
monthTranslate[3] = 'Mar';
monthTranslate[4] = 'Abr';
monthTranslate[5] = 'Mai';
monthTranslate[6] = 'Jun';
monthTranslate[7] = 'Jul';
monthTranslate[8] = 'Ago';
monthTranslate[9] = 'Set';
monthTranslate[10] = 'Out';
monthTranslate[11] = 'Nov';
monthTranslate[12] = 'Dez';

function mapHistogramData(resp: HistogramData): any[] {
  if (resp == null) return [];

  const labels: any[] = [];
  const class1: any[] = [];
  const class2: any[] = [];
  const converted_labels: string[] = [];
  const mappedData: any[] = [];

  if (resp?.post_duration !== null && resp?.post_duration !== 0) {
    for (let i = 0; i < resp?.post_duration + 1; i++) {
      labels.push(i);
      class1.push(0);
      class2.push(0);
    }
  }
  resp?.data?.sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0));
  resp?.data?.forEach((obj) => {
    // eslint-disable-next-line
    if (obj.x === null || obj.x === NaN) return;
    if (resp?.post_duration !== null && resp?.post_duration !== 0) {
      class1[obj.x] = obj.class1;
      if (obj.class2 !== null) class2[obj.x] = obj.class2;
    } else {
      labels.push(obj.x);
      class1.push(obj.class1);
      if (obj.class2 !== null) class2.push(obj.class2);
    }
  });

  labels.forEach((l) => {
    converted_labels.push(`${('00' + Math.floor(l / 60)).slice(-2)}:${('00' + (l % 60)).slice(-2)}`);
  });

  converted_labels.forEach((label, idx) => {
    const find = mappedData.find((x) => x.label === label);

    if (!find) mappedData.push({ label, indications: class1[idx] });
    else find.indications += class1[idx];
  });

  return mappedData;
}

const mapToPerHour = (resp: VotesByTime[]): any[] => {
  if (!resp) return [];

  const labels: any[] = [];
  const values: any[] = [];
  resp.sort((a, b) => {
    const aDate = new Date(b.x.year, b.x.month, b.x.date, b.x.hours).getTime();
    const bDate = new Date(a.x.year, a.x.month, a.x.date, a.x.hours).getTime();

    return bDate - aDate;
  });
  resp.forEach((obj) => {
    values.push(obj.y);
    labels.push(`${obj.x.date}/${monthTranslate[obj.x.month]} ${('00' + obj.x.hours).slice(-2)}:00`);
  });
  // @ts-ignore
  return { labels, values };
};

const IndicationTimeGraph: React.FC<Props> = ({
  className,
  isWhoMatches,
  perHourData,
  isPoll,
  isPerHour,
  data,
  isLoading
}) => {
  const intl = useIntl();
  const container = useRef() as MutableRefObject<HTMLDivElement>;
  const [tableData, setTableData] = React.useState<any[]>([]);

  const isNormal = !isPoll && !isWhoMatches;

  React.useEffect(() => {
    if (!data?.data) {
      setTableData([]);
    }
    if (data?.data?.length) {
      const mappedData = mapHistogramData(data);
      setTableData(mappedData);
    }
  }, [data]);

  React.useEffect(() => {
    if (perHourData) {
      const mappedData = mapToPerHour(perHourData);
      setTableData(mappedData);
    }
  }, [perHourData]);

  let calculatedWidthBasedOnData = 0;

  for (let i = 0; i < tableData.length; i += 1) {
    calculatedWidthBasedOnData += 170;
  }

  const goBack = () => {
    if (container) {
      sideScroll(container, 'left', 25, 100, 10);
    }
  };

  const goForward = () => {
    if (container) {
      sideScroll(container, 'right', 25, 100, 10);
    }
  };

  function sideScroll(
    element: React.RefObject<HTMLDivElement>,
    direction: 'left' | 'right',
    speed: number,
    distance: number,
    step: number
  ) {
    let scrollAmount = 0;

    const slideTimer = setInterval(() => {
      if (direction === 'left') {
        // @ts-ignore
        element.current.scrollLeft -= step;
      } else {
        // @ts-ignore
        element.current.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  return (
    <div
      className="bg-white my-0 sm:my-8 p-6 flex flex-col rounded-none sm:rounded-lg w-full"
      style={{ boxShadow: '0px 30px 40px -20px #E4E5E6', ...className }}
    >
      {isPerHour ? (
        <>
          <span className="text-2xl" style={{ color: '#202020' }}>
            {isPoll && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-perhour-poll-main-title"
                defaultMessage="Votos por hora"
              />
            )}
            {isWhoMatches && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-perhour-whomatches-main-title"
                defaultMessage="Indicações por hora"
              />
            )}
            {isNormal && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-perhour-main-title"
                defaultMessage="Indicações por hora"
              />
            )}
          </span>
          <span className="text-sm" style={{ color: '#817B7B' }}>
            {isPoll && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-perhour-poll-desc"
                defaultMessage="Quantidade de votos por hora de duração da enquete."
              />
            )}
            {isWhoMatches && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-perhour-whomatches-desc"
                defaultMessage="Quantidade de indicações por hora de duração do Quem com quem combina?"
              />
            )}
            {isNormal && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-perhour-desc"
                defaultMessage="Segundos da guia em que a indicação ocorreu"
              />
            )}
          </span>
        </>
      ) : (
        <>
          <span className="text-2xl" style={{ color: '#202020' }}>
            {isPoll && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-poll-main-title"
                defaultMessage="Tempo do voto"
              />
            )}
            {isWhoMatches && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-whomatches-main-title"
                defaultMessage="Tempo da indicação"
              />
            )}
            {isNormal && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-main-title"
                defaultMessage="Tempo de indicação"
              />
            )}
          </span>
          <span className="text-sm" style={{ color: '#817B7B' }}>
            {isPoll && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-poll-desc"
                defaultMessage="Tempo da guia em que o voto ocorreu"
              />
            )}
            {isWhoMatches && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-whomatches-desc"
                defaultMessage="Segundos da guia em que a indicação ocorreu"
              />
            )}
            {isNormal && (
              <FormattedMessage
                id="dashboard.composer.indication-graph-desc"
                defaultMessage="Segundos da guia em que a indicação ocorreu"
              />
            )}
          </span>
        </>
      )}

      <hr className="my-4" />
      {isLoading ? (
        <div className="flex items-center justify-center py-10">
          <Spinner isLoading />
        </div>
      ) : isPoll ? (
        tableData.length ? (
          <ContainerGraph ref={container}>
            <BarChart
              data={tableData}
              height={250}
              width={calculatedWidthBasedOnData}
              margin={{
                top: 5,
                bottom: 5,
                right: 5
              }}
              barSize={20}
            >
              <XAxis
                dataKey="label"
                domain={['auto', 'auto']}
                scale="point"
                tickLine={false}
                padding={{ left: 40, right: 40 }}
                tick={{ fill: '#8F8F8F', fontSize: 10 }}
                allowDataOverflow
              />
              <YAxis axisLine={false} tickLine={false} tick={{ fill: '#8F8F8F', fontSize: 14 }} />
              <Tooltip
                labelFormatter={(label) =>
                  `${intl.formatMessage({
                    id: 'widgets.indication.timegraph-tooltip-label',
                    defaultMessage: 'Momento'
                  })}: ${label}`
                }
                formatter={(value) => [
                  value,
                  intl.formatMessage({
                    id: 'widgets.indication.timegraph-tooltip-data-label',
                    defaultMessage: 'Indicações'
                  })
                ]}
              />
              <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#E4E5E6" />
              <Bar dataKey="indications" fill="#0D65FF" />
            </BarChart>
          </ContainerGraph>
        ) : (
          <Message type="notFound" message="Nenhum voto até agora" />
        )
      ) : tableData.length ? (
        <ContainerGraph ref={container}>
          <BarChart
            data={tableData}
            height={250}
            width={calculatedWidthBasedOnData}
            margin={{
              top: 5,
              bottom: 5,
              right: 5
            }}
            barSize={20}
          >
            <XAxis
              dataKey="label"
              domain={['auto', 'auto']}
              scale="point"
              tickLine={false}
              padding={{ left: 40, right: 40 }}
              tick={{ fill: '#8F8F8F', fontSize: 10 }}
              allowDataOverflow
            />
            <YAxis axisLine={false} tickLine={false} tick={{ fill: '#8F8F8F', fontSize: 14 }} />
            <Tooltip
              labelFormatter={(label) =>
                `${intl.formatMessage({
                  id: 'widgets.indication.timegraph-tooltip-label',
                  defaultMessage: 'Momento'
                })}: ${label}`
              }
              formatter={(value) => [
                value,
                intl.formatMessage({
                  id: 'widgets.indication.timegraph-tooltip-data-label',
                  defaultMessage: 'Indicações'
                })
              ]}
            />
            <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#E4E5E6" />
            <Bar dataKey="indications" fill="#0D65FF" />
          </BarChart>
        </ContainerGraph>
      ) : (
        <Message type="notFound" message="Nenhuma indicação até agora" />
      )}

      {!isLoading &&
        (tableData.length ? (
          <div className="flex w-full justify-between">
            <button type="button" onClick={() => goBack()}>
              <IoIosArrowBack size={26} />
            </button>
            <button type="button" onClick={() => goForward()}>
              <IoIosArrowForward size={26} />
            </button>
          </div>
        ) : null)}
    </div>
  );
};

export default React.memo(IndicationTimeGraph);
