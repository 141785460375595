import React from 'react';
import ReactStars from 'react-stars';
import { Stars } from '../../../../../components/AuditionCard/styles';
import ReactTooltip from 'react-tooltip';

type Props = {
  name: string;
  plays: number;
  indications: number;
  shares: number;
  classification: number;
  datetime_submitted: string;
  formatedValue: (value: number) => any;
};

const MusicInsideAudition: React.FC<Props> = ({
  name,
  plays = 0,
  indications = 0,
  shares = 0,
  classification = 0,
  datetime_submitted,
  formatedValue
}) => {
  return (
    <div
      style={{ background: '#F5F5F5', border: '1px solid #E4E5E6', color: '#202020', minWidth: '1180px' }}
      className="px-6 py-5 rounded flex items-center mb-3 justify-between"
    >
      <div data-tip={name} data-for="submitted-post-name" style={{ minWidth: '300px' }} className="w-full whitespace-no-wrap mr-12 cursor-pointer ">
        <ReactTooltip id="submitted-post-name" />
        <span className="text-xl font-semibold leading-6 ">{name.replace(/(.{25})..+/, '$1...')}</span>
      </div>
      <div className="w-full whitespace-no-wrap mr-12 flex flex-col">
        <span>{formatedValue(plays)}</span>
        <span>Plays</span>
      </div>
      <div className="w-full whitespace-no-wrap mr-12 flex flex-col">
        <span>{formatedValue(indications)}</span>
        <span>Indicações</span>
      </div>
      <div className="w-full whitespace-no-wrap mr-12 flex flex-col">
        <span>{formatedValue(shares)}</span>
        <span>Compartilhamentos</span>
      </div>
      <div className="w-full flex mr-12 flex-col whitespace-no-wrap">
        <div style={{ width: '100px' }}>
          <Stars>
            <ReactStars className="stars" half={false} value={classification} edit={false} count={5} size={20} color1="#bad2fa" color2="#0D65FF" />
          </Stars>
        </div>
        <span>Avaliação do Produtor</span>
      </div>
      <div className="w-full flex flex-col items-end whitespace-no-wrap">
        <span>{new Date(datetime_submitted).toLocaleDateString('pt-BR')}</span>
        <span>Guia submetida em</span>
      </div>
    </div>
  );
};

export default MusicInsideAudition;
