import styled from 'styled-components';

export const LaneHeaderDropContainer = styled.div`
  position: absolute;
  right: 0px;
  width: 224px;
  display: flex;
  align-items: start;
  margin-top: 13rem;
  background-color: white;
  flex-direction: column;
  padding: 1rem 1.25rem;
  box-shadow: 0px 6px 8px #e4e5e6;
  border-radius: 0px 0px 4px 4px;
  z-index: 9999999;
  animation: softAppear 0.2s;

  a {
    font-size: 16px;
    color: #8f8f8f;
  }

  @supports (-moz-appearance: none) {
    & {
      margin-top: 8rem;
    }
  }

  @keyframes softAppear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;
