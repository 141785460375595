import React from 'react';
import { userSubscriptions, CouponObj, UserPaymentMethod, PriceObj, InvoiceObj, CustomerObj } from '../../interfaces';

interface CheckoutState {
  customer?: CustomerObj;
  isChangingPlan: boolean;
  isChangedPlan: boolean;
  isChangingPaymentMethod: boolean;
  userSubscriptions?: userSubscriptions[];
  coupon?: CouponObj;
  isValidCoupon: number;
  isAtachingPaymentMethod: boolean;
  atachedPaymentMethod: boolean;
  discountedPrice: number;
  selectedPrice?: PriceObj;
  userPaymentMethod?: UserPaymentMethod;
  prices?: PriceObj[];
  userActivePlan?: PriceObj;
  isGettingPlan?: boolean;
  isGettingPrices: boolean;
  gettedPrices: boolean | undefined;
  isGettingUserSubscriptions: boolean;
  gettedUserSubscriptions: boolean;
  isValidatingCoupon: boolean;
  userPrice?: PriceObj;
  isGettingUserPrice: boolean;
  gettedUserPrice: boolean;
  isGettingUserPayemntMethod: boolean;
  gettedUserPayemntMethod: boolean;
  isCancelingSubscription: boolean;
  canceledSubscription: number;
  userInvoices?: InvoiceObj[];
  errorOnConfirmSubscription?: string;
  userUpcomingInvoice?: InvoiceObj | any;
  isGettingUserUpcomingInvoice: boolean;
  gettedUserUpcomingInvoice: boolean;
  isGettingUserInvoices: boolean;
  gettedUserInvoices: boolean;
  isReactivatingSubscription: boolean;
  reactivatedSubscription: boolean;
  atachPaymentError?: string;
  setAtachPaymentError: (atachPaymentError: string) => void;
  setErrorOnConfirmSubscription: (errorOnConfirmSubscription: string) => void;
  setAtachedPaymentMethod: (atachedPaymentMethod: boolean) => void;
  setIsChangingPaymentMethod: (isChangingPaymentMethod: boolean) => void;
  setCanceledSubscription: (canceledSubscription: number) => void;
  handleReactivateSubscription: (subscriptionId: string, defaultToken?: string) => Promise<void>;
  setIsAtachingPaymentMethod: (isAtachingPaymentMethod: boolean) => void;
  getUserUpcomingInvoice: (defaultToken?: string) => Promise<void>;
  getUserInvoices: (defaultToken?: string) => Promise<void>;
  handleCancelSubscription: (subscriptionId?: string) => Promise<void>;
  getUserPaymentMethod: (defaultToken?: string) => Promise<void>;
  getPriceById: (priceId: string, defaultToken?: string) => Promise<void>;
  handleConfirmSubscription: (priceId?: string | null, coupon?: string) => Promise<void>;
  setSelectedPrice: (selectedPrice?: PriceObj) => void;
  getPrices: () => Promise<any>;
  handleSelectPrice: (planId: string) => void;
  setDiscountedPrice: (discountedPrice: number) => void;
  atachPaymentMethod: (paymentMethod: UserPaymentMethod, defaultToken?: string) => Promise<void>;
  setIsValidCoupon: (isValidCoupon: number) => void;
  getActualPlan?: (
    defaultToken?: string
  ) => Promise<{ isGettingPlan: boolean; userActivePlan: PriceObj | undefined } | null> | any;
  validateCoupon: (couponId?: string) => Promise<void>;
  getUserSubscriptions: (defaultToken?: string) => Promise<any>;
  setIsChangingPlan: (isChangingPlan: boolean) => void;
  setIsChangedPaln: (isChangedPlan: boolean) => void;
}

export const initialState: CheckoutState = {
  customer: undefined,
  isChangingPlan: false,
  isChangedPlan: false,
  isChangingPaymentMethod: false,
  userSubscriptions: undefined,
  userPaymentMethod: undefined,
  coupon: undefined,
  isValidCoupon: 0,
  isAtachingPaymentMethod: false,
  atachedPaymentMethod: false,
  discountedPrice: 0,
  selectedPrice: undefined,
  prices: undefined,
  isGettingPrices: false,
  gettedPrices: undefined,
  isGettingUserSubscriptions: false,
  gettedUserSubscriptions: false,
  isValidatingCoupon: false,
  userActivePlan: undefined,
  userPrice: undefined,
  isGettingUserPrice: false,
  isGettingPlan: false,
  gettedUserPrice: false,
  isGettingUserPayemntMethod: false,
  gettedUserPayemntMethod: false,
  isCancelingSubscription: false,
  canceledSubscription: 0,
  userInvoices: undefined,
  errorOnConfirmSubscription: undefined,
  gettedUserUpcomingInvoice: false,
  isGettingUserUpcomingInvoice: false,
  userUpcomingInvoice: undefined,
  isGettingUserInvoices: false,
  gettedUserInvoices: false,
  isReactivatingSubscription: false,
  reactivatedSubscription: false,
  atachPaymentError: undefined,
  setAtachPaymentError: () => {},
  setErrorOnConfirmSubscription: () => {},
  setAtachedPaymentMethod: () => {},
  setIsChangingPaymentMethod: () => {},
  setCanceledSubscription: () => {},
  handleReactivateSubscription: async () => {},
  setIsAtachingPaymentMethod: () => {},
  getUserUpcomingInvoice: async () => {},
  getUserInvoices: async () => {},
  handleCancelSubscription: async () => {},
  getUserPaymentMethod: async () => {},
  getPriceById: async () => {},
  handleConfirmSubscription: async () => {},
  setSelectedPrice: () => {},
  getPrices: async () => [],
  handleSelectPrice: () => {},
  setDiscountedPrice: () => {},
  atachPaymentMethod: async () => {},
  setIsValidCoupon: () => {},
  validateCoupon: async () => {},
  getUserSubscriptions: async () => undefined,
  setIsChangingPlan: () => {},
  setIsChangedPaln: () => {}
};

const CheckoutContext = React.createContext<CheckoutState>(initialState);

const CheckoutConsumer = CheckoutContext.Consumer;

export { CheckoutContext, CheckoutConsumer };

export type { CheckoutState };
