import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import logoSound from '../../images/mpf-sound-bars-1.svg';
import Spinner from '../Spinner/Spinner';

type Props = {
  guideName?: string ;
  composersName?: string[];
  isLoading?: boolean;
  isSelect?: boolean;
};

const InfoCard: React.FC<Props> = ({ guideName, composersName, isLoading, isSelect }) => {
  return (
    <div className="w-full bg-white flex flex-col" style={{ boxShadow: '0px 30px 40px -20px #E4E5E6', borderRadius: 8 }}>
      {isLoading ? (
        <div className="h-24 flex items-center justify-center">
          <Spinner isLoading />
        </div>
      ) : (
        <>
          <div className="flex px-6 py-4">
            <img src={logoSound} alt="logo-sound" />
            <div className="flex flex-col ml-4 justify-center">
              {isSelect ? (
                <>
                  <span className="text-2xl font-semibold" style={{ color: '#333' }}>
                    <FormattedMessage id="dashboard.composer.infocard-clickon" defaultMessage="Selecione uma enquete" />
                  </span>
                </>
              ) : (
                <>
                  <span className="text-sm" style={{ color: '#817B7B' }}>
                    <FormattedMessage id="dashboard.composer.infocard-showing-data-from" defaultMessage="Exibindo dados de" />
                  </span>
                  <span className="text-2xl font-semibold" style={{ color: '#0D65FF' }}>
                    {guideName || 'Todas as Músicas'}
                  </span>
                </>
              )}
            </div>
          </div>
          {guideName && (
            <>
              <hr className="pb-4" />

              <div className="flex flex-col items-start px-6 pb-4">
                <div>
                  <span className="text-sm" style={{ color: '#817B7B' }}>
                    <FormattedMessage id="dashboard.composer.infocard-composers" defaultMessage="Compositor(es):" />
                  </span>
                  <span className="ml-2">{composersName?.map((name, index) => `${name} ${index !== composersName.length - 1 ? '/ ' : ''}`)}</span>
                </div>
                <a href="mailto:contato@musicplayce.com?subject=Informações erradas no Dashboard" className="flex py-2 items-center">
                  <AiOutlineInfoCircle size={18} />
                  <span className="ml-2 underline" style={{ color: '#202020' }}>
                    <FormattedMessage id="dashboard.composer.infocard-wrong-info" defaultMessage="Essa informação está errada? Nos avise." />
                  </span>
                </a>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InfoCard;
