import { English, Portuguese } from '../exportLanguages/index';

const languagesObj = {
  'pt-BR': Portuguese,
  'en-US': English
};

const UserLanguage = () => {
  const userLocale = navigator.language;
  // TODO: REMOVE THIS ON TRANSLATIONS
  if (userLocale !== 'pt-BR') return Portuguese;
  return languagesObj[userLocale];
};

const userLocale = navigator.language;
const userLanguage = UserLanguage();

export { userLanguage, userLocale };
