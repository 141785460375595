import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useRouteMatch, Redirect } from 'react-router-dom';
import AuthContainer from '../AuthContainer';
import Button from '../../components/Button/Button';
import { Container } from './styles';
import { AuthConsumer } from '../../context/Auth';

const MainLogin: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <AuthConsumer>
      {({ isAuth }) =>
        isAuth ? (
          <Redirect to="/dash/compositions" />
        ) : (
          <AuthContainer theme="primary" goBackRoute="" hasGoBack={false}>
            <Container className="h-full flex flex-col items-center">
              <span
                className="text-center items-center font-normal leading-4 sm:leading-8 mb-6 text-xl sm:text-2xl"
                data-cy="main-title"
                style={{ color: '#202020' }}
              >
                <FormattedMessage id="auth.main-title" />
              </span>
              <p
                style={{ color: '#8F8F8F' }}
                className="terms sm:max-w-lg mb-6 sm:mb-10 leading-4 sm:leading-6 items-center text-xs sm:text-sm w-full max-w-72  sm:w-78"
              >
                <FormattedMessage id="auth.terms-initial" />
                <a
                  className="link--terms"
                  href="https://firebasestorage.googleapis.com/v0/b/musicplayce-dev-65d73.appspot.com/o/documents%2Fterms_of_use.pdf?alt=media"
                  target="blank"
                >
                  <FormattedMessage id="auth.terms" />
                </a>
                <FormattedMessage id="auth.terms-initial-2" />
                <a
                  className="link--terms"
                  href="https://firebasestorage.googleapis.com/v0/b/musicplayce-dev-65d73.appspot.com/o/documents%2Fprivacy_policy.pdf?alt=media"
                  target="blank"
                >
                  <FormattedMessage id="auth.politics" />
                </a>
                <FormattedMessage id="auth.terms-initial-3" />
              </p>
              {/* <Button className="w-5/6 max-w-xs sm:max-w-sm text-sm sm:text-base" theme="secondary"><FormattedMessage id="auth.signin-spotify" /></Button> */}
              <Link className="flex items-center h-14 w-5/6 max-w-xs sm:max-w-sm " to={`${path}/email`}>
                <Button style={{ margin: '8px 0' }} className="w-full text-sm sm:text-base" theme="default" data-cy="button-login-email">
                  <FormattedMessage id="auth.signin-email" />
                </Button>
              </Link>
              <div className="w-5/6 max-w-xs sm:max-w-sm border-b-1 mt-6 mb-6 sm:mb-10 sm:mt-12" />
              <span style={{ color: '#202020' }} className="text-base font-medium leading-5 mb-3 sm:text-2xl sm:leading-8">
                <FormattedMessage id="auth.register-now" />
              </span>
              <a className="w-full" href="http://link.musicplayce.com/baixar">
                <Button className="w-5/6 max-w-xs sm:max-w-sm text-sm sm:text-base" theme="default" data-cy="button-try-free">
                  <FormattedMessage id="auth.try-app-free" />
                </Button>
              </a>
            </Container>
          </AuthContainer>
        )
      }
    </AuthConsumer>
  );
};

export default MainLogin;
