import React from 'react';
import ReactTooltip from 'react-tooltip';

import ToastAnimated from '../../../components/Toast/Toast';

import { PriceObj } from '../../../interfaces';

import DashboardContainer from '../../DashboardContainer';
import AuditionListClosed from './AuditionListClosed/AuditionListClosed';

import AuditionListOpen from './AuditionListOpen/AuditionListOpen';

type Props = {
  activePlan?: PriceObj;
  closed?: boolean;
};

export const AuditionList: React.FC<Props> = ({ activePlan, closed }) => {
  return (
    <>
      <ReactTooltip />
      <ToastAnimated />
      <DashboardContainer activePlan={activePlan} hideButton>
        {closed ? (
          <AuditionListClosed />
        ) : (
          <AuditionListOpen />
        )}
      </DashboardContainer>
    </>
  );
};
