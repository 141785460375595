import React, { useCallback, useEffect, useRef, useState } from 'react';
import bytes from 'bytes';
import { FormattedMessage, useIntl } from 'react-intl';
import { IoIosClose } from 'react-icons/io';
import { FileRejection, useDropzone } from 'react-dropzone';

import { Modal } from '../../../../../components/Modal';

import { CustomDropzoneContainer, UploadBox, UploadsContainer } from './styles';

import { UploadStatus } from './components';

import logoUpload from '../../../../../images/mpf-upload.svg';
import soundIconGray from '../../../../../images/mpf-sound-icon-gray.png';
import soundIconGreen from '../../../../../images/mpf-sound-green.png';
import RectButton from '../../../../../components/RectButton/RectButton';
import ToastAnimated, { showToast } from '../../../../../components/Toast/Toast';

type Props = {
  closeFunction: () => void;
  uploadFunction: (projectId: string, media: any, defaultToken?: string | undefined) => Promise<any>;
  reloadBoardFunction: () => Promise<void>;
  isOpen?: boolean;
  projectId: string;
};

export const UploadModal: React.FC<Props> = ({ closeFunction, isOpen, projectId, uploadFunction, reloadBoardFunction }) => {
  const intl = useIntl();

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [actualFileUploading, setActualFileUploading] = useState<number>(-1);
  const [succededFiles, setSuccededFiles] = useState<number[]>([]);
  const [failedFiles, setFailedFiles] = useState<number[]>([]);

  let actualFileIndex = useRef<number>(-1);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploadedFiles((prevFiles) => prevFiles.concat(acceptedFiles));

      setIsUploading(true);

      for (const file of acceptedFiles) {
        actualFileIndex.current = actualFileIndex.current + 1;
        setActualFileUploading(actualFileIndex.current);
        const res = await uploadFunction(projectId, file);

        if (res.success) {
          setSuccededFiles((prevFiles) => [...prevFiles, actualFileIndex.current]);
          setIsUploading(false);
        }

        if (!res.success) {
          showToast({ type: 'error', message: `Arquivo '${file.name}' falhou no upload.` });
          setFailedFiles((prevFiles) => [...prevFiles, actualFileIndex.current]);
          setIsUploading(false);
        }
      }
    },
    [projectId, uploadFunction]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } = useDropzone({
    onDrop,
    accept: 'audio/mpeg, audio/wav, audio/ogg, audio/opus',
    maxSize: 5242880,
    multiple: true
  });

  useEffect(() => {
    if (fileRejections.length) {
      fileRejections.map((reject: FileRejection) => {
        switch (reject.errors[0].code) {
          case 'file-too-large':
            showToast({
              type: 'error',
              message: intl.formatMessage(
                {
                  id: 'dashboard.kanban.modal.music-upload.dropzone-errors.file-too-large',
                  defaultMessage: 'Falha no upload do arquivo {filename}; ele ultrapassa o limite de upload (5 mb).'
                },
                { filename: reject.file.name }
              )
            });
            break;
          case 'file-invalid-type':
            showToast({
              type: 'error',
              message: intl.formatMessage(
                {
                  id: 'dashboard.kanban.modal.music-upload.dropzone-errors.file-invalid-type',
                  defaultMessage: 'Falha no upload do arquivo {filename}; apenas aceitamos os seguintes formatos de áudio: .mp3, .wav, .ogg e .opus.'
                },
                { filename: reject.file.name }
              )
            });
            break;
          case 'file-too-small':
            showToast({
              type: 'error',
              message: intl.formatMessage(
                {
                  id: 'dashboard.kanban.modal.music-upload.dropzone-errors.file-too-small',
                  defaultMessage: 'Falha no upload do arquivo {filename}; ele é muito pequeno pra ser enviado.'
                },
                { filename: reject.file.name }
              )
            });
            break;
          case 'too-many-files':
            showToast({
              type: 'error',
              message: intl.formatMessage({
                id: 'dashboard.kanban.modal.music-upload.dropzone-errors.too-many-files',
                defaultMessage: 'Você está tentando enviar muitos arquivos ao mesmo tempo.'
              })
            });
            break;
          default:
            showToast({
              type: 'error',
              message: intl.formatMessage(
                {
                  id: 'dashboard.kanban.modal.music-upload.dropzone-errors.generic',
                  defaultMessage: 'Oops! um erro aconteceu ao enviar o arquivo {filename}. Tente novamente!'
                },
                { filename: reject.file.name }
              )
            });
        }

        return null;
      });
    }
    // eslint-disable-next-line
  }, [fileRejections]);

  const closeModalAndCleanUploads = () => {
    setUploadedFiles([]);
    setActualFileUploading(-1);
    actualFileIndex.current = -1;
    setSuccededFiles([]);
    setFailedFiles([]);
    reloadBoardFunction();
    closeFunction();
  };

  return (
    <Modal
      closeFunc={isUploading ? () => {} : closeModalAndCleanUploads}
      isOpen={isOpen}
      title={<FormattedMessage id="dashboard.kanban.modal.music-upload.title" defaultMessage="Upload de guias" />}
    >
      <ToastAnimated />
      <span className="mt-2">
        <FormattedMessage
          id="dashboard.kanban.modal.music-upload.description"
          values={{ br: <br /> }}
          defaultMessage="Faça o upload de suas guias, nossa plataforma suporta os formatos de arquivos .mp3, .wav, .ogg e .opus.{br}As guias upadas não ficarão visíveis em sua playlist, no aplicativo. Limite de 5Mb por arquivo."
        />
      </span>
      <CustomDropzoneContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {isDragActive && !isDragReject && (
          <span>
            <FormattedMessage id="dashboard.kanban.modal.music-upload.leave-archives" defaultMessage="Solte os arquivos aqui..." />
          </span>
        )}
        {!isDragActive && (
          <div className="flex flex-col items-center">
            <img src={logoUpload} alt="logo upload" />
            <span>
              <FormattedMessage
                id="dashboard.kanban.modal.music-upload.dropzone"
                defaultMessage="Clique ou arraste o(s) arquivo(s) para dentro deste box para iniciar o upload"
              />
            </span>
          </div>
        )}
        {isDragReject && (
          <div className="flex flex-col items-center">
            <IoIosClose size={28} color="#CFCFCF" />
            <span>
              <FormattedMessage
                id="dashboard.kanban.modal.music-upload.dropzone-error"
                defaultMessage="Apenas aceitamos formatos de áudio (.mp3, .wav, .ogg e .opus)"
              />
            </span>
          </div>
        )}
      </CustomDropzoneContainer>
      <UploadsContainer className="mt-4">
        {uploadedFiles.length > 0 &&
          uploadedFiles.map((acceptedFile: File, index) => (
            <UploadBox
              key={acceptedFile.name + String(Math.random())}
              isFailed={failedFiles.includes(index)}
              isSucceed={succededFiles.includes(index)}
            >
              <div className="upload--infos--container truncate">
                <img src={succededFiles.includes(index) ? soundIconGreen : soundIconGray} alt="sound icon" />
                <div className="upload--infos">
                  <span className="upload--name" title={acceptedFile.name}>
                    {acceptedFile.name}
                  </span>
                  <span className="upload--size">{bytes.format(acceptedFile.size, { unit: 'MB' })}</span>
                </div>
              </div>
              <div className="upload--status">
                <UploadStatus actualIndex={index} actualFileUploading={actualFileUploading} failedFiles={failedFiles} succededFiles={succededFiles} />
              </div>
            </UploadBox>
          ))}
      </UploadsContainer>
      {uploadedFiles.length > 0 && (
        <div className="flex items-center justify-center mt-4">
          <RectButton disabled={isUploading} onClick={() => closeModalAndCleanUploads()} theme="outline" style={{ border: '1px solid black' }}>
            <FormattedMessage id="dashboard.kanban.modal.music-upload.button-done" defaultMessage="Encerrar" />
          </RectButton>
        </div>
      )}
    </Modal>
  );
};
