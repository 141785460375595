/* eslint-disable jsx-a11y/img-redundant-alt */
// @ts-ignore
import React from 'react';
import errorImage from '../../images/error-image.svg';
import notFoundImage from '../../images/not-found-image.svg';
import ipodImage from '../../images/ipod.svg';
import RectButton from '../RectButton/RectButton';

type Props = {
  message?: string;
  type?: 'error' | 'notFound';
  isPublishedSearch?: boolean;
  isKanban?: boolean;
  minWidth?: boolean;
};

const Message: React.FC<Props> = ({ message, type, isPublishedSearch, isKanban, minWidth }: Props) => {
  return (
    <div style={{ color: '#CFCFCF' }} className="w-full h-full flex-1 flex flex-col justify-center items-center">
      {type === 'error' ? (
        <img src={errorImage} alt="error-iamge" />
      ) : isPublishedSearch ? (
        <img src={ipodImage} alt="not-found-image" />
      ) : (
        <img src={notFoundImage} alt="not-found-image" />
      )}
      {isPublishedSearch ? (
        <span className="text-center font-semibold leading-7 text-xl mt-8 mb-2">{message}</span>
      ) : isKanban ? (
        <span className="w-full text-center font-semibold leading-7 text-xl mt-8 mb-2">{message}</span>
      ) : (
        <span style={{ maxWidth: minWidth ? '192px' : 'none' }} className="text-center font-semibold leading-7 text-xl mt-8 mb-2">
          {message}
        </span>
      )}

      {type === 'error' && !isKanban ? (
        <div style={{ maxWidth: '144px' }} className="w-full text-sm text-center flex flex-col">
          <RectButton
            className="items-center justify-center flex"
            style={{ padding: '10px 20px', borderRadius: '100px', fontSize: '10px', lineHeight: '10px' }}
            theme="primary"
          >
            Atualize a página
          </RectButton>
          <span className="mt-2">ou tente mais tarde</span>
        </div>
      ) : isPublishedSearch && !isKanban ? (
        <span className="text-center text-sm leading-6 mb-8">acesse o App e publique a sua 1ª música</span>
      ) : (
        !isKanban && (
          <span className="text-center text-sm leading-6">
            atualize a página <br /> ou volte mais tarde
          </span>
        )
      )}
    </div>
  );
};

export default Message;
