/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FormattedMessage } from 'react-intl';
import reaisConverter from '../../utils/simpleReaisConverter/reaisConverter';
import visaCard from '../../images/visa-card.svg';
import masterCard from '../../images/mc-card.svg';
import unknownCard from '../../images/unknown-card.svg';
import editIcon from '../../images/mpf-edit-two.svg';
import Button from '../../components/Button/Button';
import { userSubscriptions, InvoiceObj, PriceObj, UserPaymentMethod } from '../../interfaces';

type Props = {
  showButtons?: boolean;
  componentType: number;
  priceName?: string;
  currentSubscription?: userSubscriptions;
  currentInvoice?: InvoiceObj;
  nameFormated?: string;
  trialEndDate?: string;
  userUpcomingInvoice?: InvoiceObj;
  userCanTrial?: boolean;
  activePrice?: PriceObj;
  userPaymentMethod?: UserPaymentMethod;
  isFullHeight?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const MySignatureComponent: React.FC<Props> = ({
  showButtons,
  componentType,
  priceName,
  currentSubscription,
  currentInvoice,
  nameFormated,
  trialEndDate,
  userCanTrial,
  userUpcomingInvoice,
  activePrice,
  userPaymentMethod
}) => {
  return (
    <div
      style={{ border: '1px solid #E4E5E6', minWidth: '280px' }}
      className="flex flex-col bg-white w-full lg:w-1/4 rounded-lg p-6 "
    >
      <div className="w-full flex flex-col">
        <span className="font-bold">Sua assinatura</span>
        <hr className="my-2" />
        <span className="font-normal text-xl lg:leading-10 lg:text-3xl " style={{ color: '#202020' }}>
          <FormattedMessage
            id="checkout.invoicing.plan-type"
            values={{
              productName: priceName
            }}
          />
        </span>
        <div className="w-full flex flex-col justify-between mb-8 mt-2">
          <span style={{ color: '#202020' }} className="text-xl font-semibold leading-7">
            {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
              <FormattedMessage
                id="checkout.invoicing.plan-price"
                values={{
                  productPrice: reaisConverter((currentInvoice?.total as number) / 100)
                }}
              />
            ) : (
              <FormattedMessage
                id="checkout.invoicing.plan-price"
                values={{
                  productPrice: reaisConverter((currentInvoice?.total as number) / 100)
                }}
              />
            )}
          </span>
          <p style={{ color: '#817B7B' }} className="text-sm leading-5 w-full lg:text-base lg:leading-6">
            {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
              <>
                <FormattedMessage
                  id="checkout.invoicing.description-invoicing-requested-cancellation"
                  values={{
                    userName: nameFormated,
                    cancelAtDate: moment((currentSubscription.stripe_subscription.cancel_at as number) * 1000).format(
                      'LL'
                    )
                  }}
                />
                <Link
                  className="text-blue-500 underline ml-2"
                  to={`/checkout/reactivate-subscription/${currentSubscription._id}`}
                >
                  <FormattedMessage id="checkout.invoicing.reactivate-subscription" />
                </Link>
              </>
            ) : currentSubscription?.stripe_subscription?.status === 'trialing' ? (
              <FormattedMessage
                id="checkout.invoicing.description-invoicing-trial"
                values={{
                  userName: nameFormated,
                  trialEndDate,
                  productPrice: reaisConverter(
                    (userUpcomingInvoice?.total
                      ? (userUpcomingInvoice.total as number)
                      : (currentSubscription?.stripe_subscription?.items.data[0].price.unit_amount as number)) / 100
                  )
                }}
              />
            ) : (
              <FormattedMessage
                id="checkout.invoicing.description-invoicing"
                values={{
                  userName: nameFormated,
                  upcomingInvoiceDate: moment(
                    (userCanTrial
                      ? (currentInvoice?.period_end as number)
                      : userUpcomingInvoice?.period_end
                      ? (userUpcomingInvoice.period_end as number)
                      : (currentSubscription?.stripe_subscription?.current_period_end as number)) * 1000
                  ).format('LL'),
                  upcomingInvoicePrice: reaisConverter(
                    (userUpcomingInvoice?.total
                      ? (userUpcomingInvoice.total as number)
                      : (currentSubscription?.stripe_subscription?.items.data[0].price.unit_amount as number)) / 100
                  )
                }}
              />
            )}
          </p>
        </div>
        {componentType === 2 && (
          <>
            <div className="w-full flex justify-between">
              <span style={{ color: '#817B7B' }} className="text-sm font-normal leading-6">
                Período de cobrança
              </span>
              <span style={{ color: '#202020' }} className="text-sm font-normal leading-6">
                Mensal
              </span>
            </div>
            <div style={{ background: '#cfcfcf' }} className="mt-4 mb-4 w-full h-px" />
            <div className="w-full flex justify-between">
              <span style={{ color: '#817B7B' }} className="text-sm font-normal leading-6">
                Valor mensal
              </span>
              <span style={{ color: '#202020' }} className="text-sm font-normal leading-6">
                {reaisConverter((currentInvoice?.total as number) / 100)}
              </span>
            </div>
            <div style={{ background: '#cfcfcf' }} className="mt-4 mb-4 w-full h-px" />
            <div className="w-full flex justify-between">
              <span style={{ color: '#817B7B' }} className="text-sm font-normal leading-6">
                Tipo de cobrança
              </span>
              <span style={{ color: '#202020' }} className="text-sm text-right font-normal leading-6">
                Cartão de crédito
              </span>
            </div>
            <div style={{ background: '#cfcfcf' }} className="mt-4 mb-4 w-full h-px" />
            <div className="w-full flex justify-between">
              <span style={{ color: '#817B7B' }} className="text-sm font-normal leading-6">
                Vencimento
              </span>
              <span style={{ color: '#202020' }} className="text-sm font-normal leading-6">
                {currentSubscription?.stripe_subscription?.cancel_at_period_end
                  ? moment((currentSubscription?.stripe_subscription?.cancel_at as number) * 1000).format('L')
                  : currentSubscription?.stripe_subscription?.status === 'trialing'
                  ? moment((currentSubscription.stripe_subscription.trial_end as number) * 1000).format('L')
                  : moment(
                      (userCanTrial
                        ? (currentInvoice?.period_end as number)
                        : userUpcomingInvoice?.period_end
                        ? (userUpcomingInvoice.period_end as number)
                        : (currentSubscription?.stripe_subscription?.current_period_end as number)) * 1000
                    ).format('L')}
              </span>
            </div>
            <div style={{ background: '#cfcfcf' }} className="mt-4 mb-4 w-full h-px" />
          </>
        )}
        {showButtons && (
          <div className="w-full lg:max-w-none flex lg:flex-col flex-row  mb-8">
            <Link to="/checkout" className="w-full items-center mr-4 lg:mr-0 lg:mb-6">
              <Button
                style={{
                  margin: '0',
                  borderRadius: '4px',
                  padding: '13px 0'
                }}
                className="w-full flex items-center font-semibold justify-center text-sm lg:text-base py-4"
                theme="tertiary"
              >
                <FormattedMessage id="checkout.invoicing.change-plan" />
              </Button>
            </Link>
            {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
              <Link to={`/checkout/reactivate-subscription/${activePrice?._id}`} className="w-full">
                <Button
                  style={{
                    margin: '0',
                    borderRadius: '4px',
                    padding: '13px 0',
                    border: '1px solid #202020'
                  }}
                  className="w-full text-sm lg:text-base py-4 font-semibold"
                  theme="default"
                >
                  <FormattedMessage
                    id="checkout.change-plan.reactivate-subscription"
                    defaultMessage="checkout.change-plan.reactivate-subscription"
                  />
                </Button>
              </Link>
            ) : (
              <Link to={`/checkout/change-plan/${activePrice?._id}`} className="w-full">
                <Button
                  style={{
                    margin: '0',
                    borderRadius: '4px',
                    padding: '13px 0',
                    border: '1px solid #202020'
                  }}
                  className="w-full text-sm lg:text-base py-4 font-semibold"
                  theme="default"
                >
                  <FormattedMessage id="checkout.invoicing.cancel-plan" />
                </Button>
              </Link>
            )}
          </div>
        )}
        {componentType === 2 &&
          (currentSubscription?.stripe_subscription?.cancel_at_period_end ? null : currentSubscription
              ?.stripe_subscription?.status === 'trialing' ? null : (
            <p className="text-sm text-left text-gray-600">
              Será cobrado o valor total de <b>{reaisConverter((currentInvoice?.total as number) / 100)}</b> mensal até
              você cancelar sua assinatura. Pagamentos realizados anteriormente não serão reembolsados quando você
              cancelar.
            </p>
          ))}
        {componentType === 1 && (
          <span style={{ color: '#202020' }} className="font-semibold text-xl leading-7">
            <FormattedMessage id="checkout.invoicing.payment-type" />
          </span>
        )}
      </div>

      {componentType === 1 && (
        <>
          <div style={{ background: '#cfcfcf' }} className="mb-8 mt-4 w-full h-px"></div>

          <div className="w-full flex flex-col">
            {userPaymentMethod ? (
              <div className="w-full flex justify-between mb-10">
                <div className="w-full text-sm leading-6 font-semibold h-8 flex ">
                  {userPaymentMethod?.card.brand === 'visa' && <img className="mr-2" src={visaCard} alt="brand-card" />}

                  {userPaymentMethod?.card.brand === 'mastercard' && (
                    <img className="mr-2" src={masterCard} alt="brand-card" />
                  )}

                  {!userPaymentMethod?.card.brand && <img className="mr-2" src={unknownCard} alt="brand-card" />}
                  <span className="w-full">
                    <FormattedMessage
                      id="checkout.invoicing.card-numbers"
                      values={{
                        last4CardNumbers: userPaymentMethod?.card.last4
                      }}
                    />
                  </span>
                </div>
                <span style={{ color: '#817B7B' }} className="text-right text-sm font-normal leading-6">
                  <FormattedMessage
                    id="checkout.invoicing.expiration-card-date-calendar"
                    values={{
                      expirationCardDate: `${
                        userPaymentMethod?.card.exp_month < 10
                          ? `0${userPaymentMethod?.card.exp_month}`
                          : userPaymentMethod.card.exp_month
                      }/${userPaymentMethod.card.exp_year}`
                    }}
                  />
                </span>
              </div>
            ) : (
              <span className="mb-8 w-full">
                <FormattedMessage id="checkout.invoicing.payment-method-not-found" />{' '}
              </span>
            )}

            <Link to="/account-management/signature">
              <div className="w-full flex mb-15">
                <img src={editIcon} alt="edit-icon" />
                <span className="ml-2">
                  <FormattedMessage id="checkout.invoicing.update-payment-types" />
                </span>
              </div>
            </Link>
            <span className="font-semibold text-xl leading-7 mt-8">Próxima cobrança</span>
          </div>
          <div style={{ background: '#cfcfcf' }} className="mb-8 mt-4 w-full h-px"></div>
          <div className="sm:w-full text-right flex items-center justify-between">
            <span style={{ color: '#0D65FF' }} className="text-left font-semibold text-sm sm:text-base leading-7">
              {currentSubscription?.stripe_subscription?.cancel_at_period_end
                ? 'Cancelamento solicitado, não existe nenhuma cobrança futura'
                : moment(
                    (userCanTrial
                      ? (currentInvoice?.period_end as number)
                      : userUpcomingInvoice?.period_end
                      ? (userUpcomingInvoice.period_end as number)
                      : (currentSubscription?.stripe_subscription?.current_period_end as number)) * 1000
                  ).format('LL')}
            </span>
            <span style={{ color: '#817B7B' }} className="text-left font-normal text-sm sm:text-base leading-6 mx-2">
              {currentSubscription?.stripe_subscription?.cancel_at_period_end ? null : currentSubscription
                  ?.stripe_subscription?.status === 'trialing' ? (
                <FormattedMessage
                  id="checkout.invoicing.actual-plan-price"
                  values={{
                    productPrice: reaisConverter(
                      (userUpcomingInvoice?.total
                        ? (userUpcomingInvoice.total as number)
                        : (currentSubscription?.stripe_subscription?.items.data[0].price.unit_amount as number)) / 100
                    )
                  }}
                />
              ) : (
                <FormattedMessage
                  id="checkout.invoicing.actual-plan-price"
                  values={{
                    productPrice: reaisConverter(
                      (userUpcomingInvoice?.total
                        ? (userUpcomingInvoice.total as number)
                        : (currentSubscription?.stripe_subscription?.items.data[0].price.unit_amount as number)) / 100
                    )
                  }}
                />
              )}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default MySignatureComponent;
