/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { FeatureCollection } from 'geojson';

import { AuthContext } from '../../Auth';
import { ArtistDashContext } from './Context';
import { Axios, withAuth } from '../../../utils/axios';
import { Composer, Composition, CompositionIndication, CompositionPlay, CompositionTopArtists, TopShare, Track } from '../../../interfaces';
import { HistogramData } from '../../../components/IndicationTimeGraph/IndicationTimeGraph';
import { ComposerDashContext } from '../composer';
import { DashContext } from '../Context';
import { WhoMatchesContext } from '../whomatches';

interface Props {
  children: React.ReactNode;
}

export const ArtistDashProvider: React.FC<Props> = ({ children }) => {
  const { token } = useContext(AuthContext);

  const [compositions, setCompositions] = useState<Composition[]>([]);
  const [currentComposition, setCurrentComposition] = useState<Composition | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [topShares, setTopShares] = useState<TopShare[]>([]);
  const [topArtists, setTopArtists] = useState<CompositionTopArtists[]>([]);
  const [playsByDay, setPlaysByDay] = useState<CompositionPlay[]>([]);
  const [indicationsByDay, setIndicationsByDay] = useState<CompositionIndication[]>([]);
  const [myRanking, setMyRanking] = useState<any | undefined>();
  const [locationData, setLocationData] = useState<FeatureCollection | undefined>();
  const [indicationMomentData, setIndicationMomentData] = useState<HistogramData | undefined>();
  const [composers, setComposers] = useState<Composer[] | undefined>(undefined);
  const [activeArtistTrack, setActiveArtistTrack] = useState<undefined | Track>(undefined);
  const [artistPlayList, setArtistPlayList] = useState<Track[]>([]);
  const [isGettingArtistPlayList, setIsGettingArtistPlayList] = useState<boolean>(false);

  const { setActiveCompositionsTrack } = useContext(ComposerDashContext);
  const { setActivePollsTrack } = useContext(DashContext);
  const { setActiveWhoMatchesTrack } = useContext(WhoMatchesContext);

  useEffect(() => {
    setActiveCompositionsTrack(undefined);
    setActivePollsTrack(undefined);
    setActiveWhoMatchesTrack(undefined);
  }, []);

  useEffect(() => {
    (async () => {
      if (compositions?.length) {
        await getArtistPlayList(token);
      }
    })();
  }, [compositions]);

  const _getArtistTrackById = async (id: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/posts/${id}`, withAuth(defaultToken || token));
      return response.data.data;
    } catch (error) {
      console.error('Error on getting artist play list: ', error);
      return undefined;
    }
  };

  const getArtistPlayList = async (defaultToken?: string) => {
    setIsGettingArtistPlayList(true);
    compositions?.forEach(async (item, idx) => {
      const track = await _getArtistTrackById(item._id, defaultToken);
      setArtistPlayList((prev) => [...prev, track]);
      if (idx === compositions.length - 1) {
        setIsGettingArtistPlayList(false);
      }
    });
  };

  const fetchPostsByProfileToken = async () => {
    if (!isLoading) setIsLoading(true);

    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get('/v1/data/dashboard/search/indications/singer/', withAuth(token));

      await _fetchLastIndication();
      await _fetchComposers(data[0]._id);
      await _fetchTopShares(data[0]._id);
      await _fetchTopArtists(data[0]._id);
      await _fetchPlaysByDay(data[0]._id);
      await _fetchLocationData(data[0]._id);
      await _fetchRankingForPost(data[0]._id);
      await _fetchArtisIndicationByDay(data[0]._id);
      await _fetchIndicationMomentData(data[0]._id);

      const sorted: Composition[] = data;

      setCompositions(sorted?.sort((a: any, b: any) => b.plays - a.plays));
      setCurrentComposition(data[0]);
      setIsLoading(false);
      setActiveCompositionsTrack(undefined);
      return data;
    } catch (error) {
      console.error('error fetching compositions in artist provider: ', error);
      setIsLoading(false);
      return [];
    }
  };

  const handlePostSelect = async (id: string, isPlay?: boolean) => {
    if (!isLoading) setIsLoading(true);
    try {
      const post = compositions.find((x) => x._id === id);
      setCurrentComposition(post);

      if (isPlay) {
        const track = artistPlayList?.find((item) => item._id === id);
        setActiveArtistTrack(track);
      }

      await _fetchComposers(post?._id);
      await _fetchTopShares(post?._id);
      await _fetchTopArtists(post?._id);
      await _fetchPlaysByDay(post?._id);
      await _fetchLocationData(post?._id);
      await _fetchRankingForPost(post?._id);
      await _fetchArtisIndicationByDay(post?._id);
      await _fetchIndicationMomentData(post?._id);

      setIsLoading(false);
    } catch (error) {
      console.error('error selecting post in artist provider: ', error);
      setCurrentComposition(undefined);
      setIsLoading(false);
    }
  };

  const _fetchComposers = async (id?: string) => {
    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/posts/${id || currentComposition?._id}/`, withAuth(token));
      setComposers(data.data.composers);
    } catch (error) {
      setActiveArtistTrack(undefined);
      console.error('Error On Fetching ComposersName: ', error);
    }
  };

  const _fetchPlaysByDay = async (id?: string) => {
    if (!isLoading) setIsLoading(true);
    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(
        `/v1/data/dashboard/posts/${id || currentComposition?._id}/plays_by_day/`,
        withAuth(token)
      );

      setPlaysByDay(data);
    } catch (error) {
      console.dir(error);
    }
  };

  const _fetchArtisIndicationByDay = async (id?: string) => {
    if (!isLoading) setIsLoading(true);
    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(
        `/v1/data/dashboard/posts/${id || currentComposition?._id}/indications_by_day_artist/`,
        withAuth(token)
      );

      setIndicationsByDay(data);
    } catch (error) {
      console.dir(error);
    }
  };

  const _fetchRankingForPost = async (id?: string) => {
    if (!isLoading) setIsLoading(true);
    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(
        `/v1/data/dashboard/posts/${id || currentComposition?._id}/myranking/`,
        withAuth(token)
      );
      setMyRanking(data);
    } catch (error) {
      console.dir(error);
    }
  };

  const _fetchLocationData = async (id?: string) => {
    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/indications/${id}/location/?indications_to=me`, withAuth(token));

      setLocationData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const _fetchIndicationMomentData = async (id?: string) => {
    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/indications/${id}/moment/?indications_to=me`, withAuth(token));

      setIndicationMomentData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const _fetchTopArtists = async (id?: string) => {
    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/dashboard/posts/${id}/top_artists/`, withAuth(token));

      setTopArtists(data);
    } catch (error) {
      console.error(error);
    }
  };

  const _fetchTopShares = async (id?: string) => {
    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/dashboard/posts/${id}/top_shares/`, withAuth(token));
      setTopShares(data);
    } catch (error) {
      console.error(error);
    }
  };

  const _fetchLastIndication = async () => {
    try {
      // eslint-disable-next-line
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/dashboard/last_indication/`, withAuth(token));
      // setTopShares(data);
    } catch (error) {
      console.error('Error on fetching last_indication: ', error.response);
    }
  };

  return (
    <ArtistDashContext.Provider
      value={{
        compositions,
        topShares,
        topArtists,
        isLoading,
        playsByDay,
        indicationsByDay,
        currentComposition,
        locationData,
        indicationMomentData,
        myRanking,
        composers,
        activeArtistTrack,
        artistPlayList,
        isGettingArtistPlayList,
        setActiveArtistTrack,
        handlePostSelect,
        fetchPostsByProfileToken
      }}
    >
      {children}
    </ArtistDashContext.Provider>
  );
};
