/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router-dom';
import { PriceObj, Project } from '../../../interfaces';
import DashboardContainer from '../../DashboardContainer';
import { Card } from '../../styles';
import { ResponsiveFlexDirection } from './styles';
import Chips from '../../../components/Chips/Chips';
import RectButton from '../../../components/RectButton/RectButton';
import { searchArtists } from './services/searchArtists';
import { AuthContext, ProducerDashContext } from '../../../context';
import ArtistChips from '../../../components/ArtistChips/ArtistChips';
import { createAudition } from './services/createAudition';
import reloadIcon from '../../../images/mpf-reload-white.svg';
import ToastAnimated, { showToast } from '../../../components/Toast/Toast';
import CharCounter from '../../../components/CharCounter/CharCounter';
import { KanbanContext } from '../../../context/dash/producer/kanban';
import TagsSuggestion from './components/TagsSuggestion/TagsSuggestion';
import CreateAuditionValidation from '../../../accountManagement/validations/CreateAuditionValidation';

interface Artist {
  _id: string;
  name: string;
  rank: number;
  picture_profile: {
    _id: string;
    url: string;
    type: string;
    datetime_created: string;
    datetime_updated: string;
    processed: boolean;
    resources: string[];
  };
}

interface NewAuditionFormFields {
  title: string;
  main_artist: string;
  related_artists: string[];
  tags: string[];
  briefing: string;
  video_url: string;
}

interface MainArtist {
  label: string;
  value: string;
}

interface Tag {
  _id: string | null;
  label: string;
}

type Props = {
  activePlan?: PriceObj;
};

export const NewAudition: React.FC<Props> = ({ activePlan }) => {
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [mainArtist, setMainArtist] = useState<MainArtist>();
  const [auditionTitle, setAuditiontitle] = useState<string>('');
  const [auditionBriefing, setAuditionBriefing] = useState<string>('');
  const [auditionVideoUrl, setAuditionVideoUrl] = useState<string>('');
  const { token } = useContext(AuthContext);
  const { artistsSugestions, selectedArtists } = useContext(ProducerDashContext);
  const { boardData, reloadBoard } = useContext(KanbanContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const history = useHistory();

  const project = history.location.state as Project;

  const mapOptionsToValues = (items: Artist[]) => {
    return items.map((option) =>
      option.picture_profile
        ? {
            value: option._id,
            label: option.name
          }
        : []
    );
  };

  const promiseOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      return callback([]);
    }
    searchArtists(inputValue, token).then((response) => {
      if (response?.map((item) => item.picture_profile)) callback(mapOptionsToValues(response));
      else return false;
    });
  };

  const GetFormikContext = () => {
    const { values } = useFormikContext<NewAuditionFormFields>();

    useEffect(() => {
      setAuditiontitle(values.title);
    }, [values.title]);

    useEffect(() => {
      setAuditionBriefing(values.briefing);
    }, [values.briefing]);

    useEffect(() => {
      setAuditionVideoUrl(values.video_url);
    }, [values.video_url]);

    return null;
  };

  const handleSaveAudition = async () => {
    const object = {
      auditionTitle,
      auditionBriefing,
      mainArtist: mainArtist?.value as string,
      video_url: auditionVideoUrl,
      relatedArtists: selectedArtists,
      tags: selectedTags
    };

    const valid = await validFields(object);
    if (!valid) {
      return false;
    }

    setIsLoading(true);
    const response: any = await createAudition(
      project._id,
      object.auditionTitle,
      object.auditionBriefing,
      object.relatedArtists,
      object?.mainArtist,
      object.video_url,
      object.tags,
      token
    );

    await reloadBoard(project._id);
    setIsLoading(false);

    if (!response) {
      showToast({ type: 'error', message: 'Ocorreu um erro ao criar a audição, tente novamente.' });
    } else {
      showToast({ type: 'success', message: 'Nova audição criada com sucesso!' });
      setTimeout(() => {
        history.push({ pathname: `/dash/producer/project/${project._id}`, state: project });
      }, 1200);
    }
  };

  async function validFields(object: any) {
    try {
      await CreateAuditionValidation.validate(object);
    } catch (error) {
      showToast({ type: 'error', message: error.message });
      return false;
    }

    return true;
  }

  return (
    <>
      <ToastAnimated />
      <DashboardContainer activePlan={activePlan} hideButton>
        <div className="w-full p-4 sm:p-0">
          <span className="font-semibold text-2xl">
            <FormattedMessage id="dashboard.my-projects.kanban.new-audition.main-title" defaultMessage="Criar audição digital" />
          </span>
          <div className="flex flex-col mt-5">
            <Card className="flex flex-col py-10 px-6">
              <Formik
                validationSchema={CreateAuditionValidation}
                initialValues={{
                  title: '',
                  main_artist: '',
                  related_artists: [],
                  tags: [],
                  briefing: '',
                  video_url: ''
                }}
                onSubmit={(values) => {}}
              >
                {({ errors, values, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
                  <>
                    <GetFormikContext />
                    <ResponsiveFlexDirection className="flex responsive-flexbox-direction">
                      <div className="form-projects-column-left flex-1">
                        <FormattedMessage id="dashboard.my-projects.kanban.new-audition.audition-title">
                          {(placeholder: string) => (
                            <div className="flex flex-col mt-1 mb-6">
                              <label className="text-xs">{placeholder}</label>
                              <input
                                autoComplete="off"
                                name="title"
                                id="title"
                                className="async-input w-full py-2"
                                type="text"
                                maxLength={70}
                                value={values.title}
                                onChange={handleChange('title')}
                                onBlur={handleBlur('title')}
                                data-cy="input-title"
                                onClickCapture={() => setErrorMessage('')}
                              />
                              {errors.title && touched.title && (
                                <span className="error" data-cy="username-error">
                                  {errors.title}
                                </span>
                              )}
                              <CharCounter currentLength={values.title.length} maxLength={70} />
                            </div>
                          )}
                        </FormattedMessage>

                        <FormattedMessage id="dashboard.my-projects.kanban.new-audition.main-artist">
                          {(placeholder: string) => (
                            <div className="flex flex-col mb-6">
                              <label className="text-xs">{placeholder}</label>
                              <AsyncSelect
                                styles={{
                                  placeholder: () => ({
                                    color: '#919fb1eb'
                                  })
                                }}
                                defaultOptions
                                cacheOptions
                                placeholder=""
                                noOptionsMessage={() => 'Nenhum resultado encontrado'}
                                loadingMessage={() => 'Pesquisando...'}
                                value={mainArtist}
                                onChange={(e: any) => setMainArtist(e)}
                                loadOptions={promiseOptions}
                                className="async-input"
                                id="async-select"
                              />
                              {errors.main_artist && touched.main_artist && (
                                <span className="error" data-cy="username-error">
                                  {errors.main_artist}
                                </span>
                              )}
                            </div>
                          )}
                        </FormattedMessage>

                        <FormattedMessage id="dashboard.my-projects.kanban.new-audition.related-artists">
                          {(placeholder: string) => (
                            <>
                              <label className="text-xs">{placeholder}</label>
                              <ArtistChips
                                id="related-artists"
                                className="py-2 mb-6"
                                values={values.related_artists}
                                suggestions={artistsSugestions}
                                maxItems={5}
                                onChange={handleChange('related_artists')}
                                onBlur={handleBlur('related_artists')}
                                data-cy="input-related_artists"
                                onClickCapture={() => setErrorMessage('')}
                              />
                              {errors.related_artists && touched.related_artists && (
                                <span className="error" data-cy="username-error">
                                  {errors.related_artists}
                                </span>
                              )}
                            </>
                          )}
                        </FormattedMessage>

                        <FormattedMessage id="dashboard.my-projects.kanban.new-audition.tags">
                          {(placeholder: string) => (
                            <div className="py-2 mb-6 mt-1">
                              <label className="text-xs">{placeholder}</label>
                              <TagsSuggestion
                                id="tags"
                                values={values.tags}
                                maxItems={10}
                                chipMaxLength={40}
                                onTagsUpdated={(tags: Tag[]) => setSelectedTags(tags)}
                                onChange={handleChange('tags')}
                                onBlur={handleBlur('tags')}
                                data-cy="input-tags"
                                onClickCapture={() => setErrorMessage('')}
                              />
                              {errors.tags && touched.tags && (
                                <span className="error" data-cy="username-error">
                                  {errors.tags}
                                </span>
                              )}
                            </div>
                          )}
                        </FormattedMessage>
                      </div>
                      <div className="form-projects-column-right flex-1">
                        <FormattedMessage id="dashboard.my-projects.kanban.new-audition.briefing">
                          {(placeholder: string) => (
                            <div className="flex flex-col mt-1 mb-6">
                              <label className="text-xs">{placeholder}</label>
                              <textarea
                                id="briefing"
                                className="async-input w-full py-2"
                                name="briefing"
                                rows={7}
                                maxLength={500}
                                value={values.briefing}
                                onChange={handleChange('briefing')}
                                onBlur={handleBlur('briefing')}
                                data-cy="input-briefing"
                                onClickCapture={() => setErrorMessage('')}
                              />
                              {errors.briefing && touched.briefing && (
                                <span className="error" data-cy="username-error">
                                  {errors.briefing}
                                </span>
                              )}
                              <CharCounter currentLength={values.briefing.length} maxLength={500} />
                            </div>
                          )}
                        </FormattedMessage>

                        <FormattedMessage id="dashboard.my-projects.kanban.new-audition.video-link">
                          {(placeholder: string) => (
                            <div className="flex flex-col mt-1 mb-6">
                              <label className="text-xs">{placeholder}</label>
                              <input
                                autoComplete="off"
                                name="video_url"
                                id="video_url"
                                className="async-input w-full py-2"
                                type="text"
                                // maxLength={65}
                                value={values.video_url}
                                onChange={handleChange('video_url')}
                                onBlur={handleBlur('video_url')}
                                data-cy="input-video_url"
                                onClickCapture={() => setErrorMessage('')}
                              />
                            </div>
                          )}
                        </FormattedMessage>
                      </div>
                    </ResponsiveFlexDirection>

                    <div className="flex justify-end mt-6">
                      <RectButton
                        onClick={() => {
                          if(project._id)
                          return history.push({ pathname: `/dash/producer/project/${project._id}`, state: project })}}
                        theme="outline"
                        className="mr-2"
                      >
                        <FormattedMessage id="dashboard.my-projects.kanban.new-audition.button.cancel" defaultMessage="Cancelar" />
                      </RectButton>
                      <RectButton
                        onClick={handleSaveAudition}
                        theme="success"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '125px' }}
                      >
                        {isLoading ? (
                          <img src={reloadIcon} alt="spinner-icon" />
                        ) : (
                          <FormattedMessage id="dashboard.my-projects.kanban.new-audition.button.save" defaultMessage="Criar Audição" />
                        )}
                      </RectButton>
                    </div>
                  </>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};
