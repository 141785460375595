import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import Logo from '../images/logo-musicplayce.svg';
import heartEmoji from '../images/mpf-heart-1-active.svg';

import { Container } from './styles';

type Props = {
  children: React.ReactNode;
  hasGoBack: boolean;
  goBackRoute: string;
  theme?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | undefined | null;
};

function AuthContainer({ hasGoBack, goBackRoute, children, theme }: Props) {
  return (
    <Container className="flex min-h-screen w-full " theme={theme}>
      <div className=" w-full flex flex-col justify-between text-center my-0 mx-auto lg:w-5/12">
        {hasGoBack && (
          <Link className="goback--arrow--container" to={goBackRoute}>
            <IoMdArrowBack />
            <span className="hidden sm:block">
              <FormattedMessage id="auth.container-goback" />
            </span>
          </Link>
        )}
        <header className="flex justify-center items-center mt-16 sm:mt-40 mb-16 sm:mb-32">
          <img className="w-48 sm:w-auto" src={Logo} alt="logo-MusicPlayce" />
        </header>
        <section className="h-full">{children}</section>
        <footer className="w-full flex justify-center mb-6 sm:mb-10">
          <span className="w-full flex justify-center text-xs" style={{ color: '#8F8F8F' }}>
            <FormattedMessage id="auth.container-footer" /> <img className="mx-1" src={heartEmoji} alt="heartEmoji" />{' '}
            <FormattedMessage id="auth.container-footer-2" />
          </span>
        </footer>
      </div>
      <div className="w-full hidden lg:w-7/12 lg:visible lg:flex lg:justify-end">
        <div className="bg-image">
          <div className="container--section2--overlay">
            <div className="container--section2--text">
              <h1 className="container--section2--header">
                <FormattedMessage id="auth.main-poster-title" />
              </h1>
              <p className="container--section2--paragraph">
                <FormattedMessage id="auth.secondary-poster-title" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AuthContainer;
