import React, { useContext } from 'react';
import { IoIosHeart } from 'react-icons/io';
import Header from '../components/Header/Header';
import { CheckoutContext } from '../context';

type Props = {
  children: React.ReactNode;
  hasGoBack: boolean;
  goBackRoute: string;
} & React.HTMLAttributes<HTMLDivElement>;

const CheckoutContainer = ({ hasGoBack, goBackRoute, children, ...rest }: Props) => {
  const { userActivePlan, isGettingPlan, isGettingUserSubscriptions } = useContext(CheckoutContext);

  return (
    <div className="bg-gray-200 flex flex-col min-h-screen" {...rest}>
      <Header activePrice={userActivePlan} isGettingInfos={isGettingPlan || isGettingUserSubscriptions} isCheckout />
      <section className="mx-auto w-full max-w-screen-xl">
        <div className="pt-4 px-5 sm:px-4 md:px-2 lg:px-2 xl:px-0">
          <span>Meu Painel {'> '}</span>
          <span className="font-bold">Minha Assinatura</span>
        </div>
        {children}
      </section>
      <footer className="flex justify-center items-center py-8 w-full h-20 mt-auto">
        <span className="my-3 text-gray-600 text-xs flex w-full justify-center items-center">
          Feito com <IoIosHeart className="text-base text-red-600 mx-1" /> em Goiânia
        </span>
      </footer>
    </div>
  );
};

export default CheckoutContainer;
