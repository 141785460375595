import React, { useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import newsFirst from '../../images/news-first.svg';
import newsSecond from '../../images/news-second.svg';
import newsThird from '../../images/news-third.svg';
import newsFourth from '../../images/news-fourth.svg';
import newsFifth from '../../images/news-fifth.svg';
import newsSixth from '../../images/news-sixth.svg';
import newsSeventh from '../../images/news-seventh.svg';

import { Container, RandomShape, SecondRandomShape, Spacer, SliderGroup, CustomButton, Dot, DotsGroup } from './styles';
import { FormattedMessage } from 'react-intl';

type TextGroup = {
  main: string;
  description: string;
  image: string;
};

export const News: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const texts: TextGroup[] = [
    { main: 'news.first-text-title', description: 'news.first-text-description', image: newsFirst },
    { main: 'news.second-text-title', description: 'news.second-text-description', image: newsSecond },
    { main: 'news.third-text-title', description: 'news.third-text-description', image: newsThird },
    { main: 'news.fourth-text-title', description: 'news.fourth-text-description', image: newsFourth },
    // { main: 'news.fifth-text-title', description: 'news.fifth-text-description', image: newsFifth },
    { main: 'news.sixth-text-title', description: 'news.sixth-text-description', image: newsSixth },
    { main: 'news.seventh-text-title', description: 'news.seventh-text-description', image: newsSeventh },
  ];

  const onChange = (value: number) => setCurrentIndex(value);

  return (
    <Container>
      <SliderGroup>
        <RandomShape />
        <SecondRandomShape />
        <Carousel value={currentIndex} onChange={onChange}>
          {texts.map((texts, index) => (
            <img key={index} src={texts.image} alt={texts.main} className="select-none" />
          ))}
        </Carousel>
        <Spacer />
        <DotsGroup className="mt-8">
          {texts.map((texts, index) => (
            <Dot onClick={() => onChange(index)} key={index} active={index === currentIndex} />
          ))}
        </DotsGroup>
      </SliderGroup>
      <span className="text-xl font-semibold text-center mt-8" style={{ color: '#5E5A5A', maxWidth: 220 }}>
        <FormattedMessage id={texts[currentIndex].main} />
      </span>
      <span className="text-md font-medium text-center mt-6" style={{ color: '#8F8F8F', maxWidth: 400 }}>
        <FormattedMessage id={texts[currentIndex].description} />
      </span>
      {currentIndex < texts.length - 1 && (
        <CustomButton className="mt-8" onClick={() => onChange(currentIndex < texts.length - 1 ? currentIndex + 1 : currentIndex)}>
          <FormattedMessage id="continue" />
        </CustomButton>
      )}
    </Container>
  );
}