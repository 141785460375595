/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import { FeatureCollection } from 'geojson';
import React, { useContext, useEffect, useState } from 'react';
import { HistogramData } from '../../../components/IndicationTimeGraph/IndicationTimeGraph';
import { CompositionIndication, CompositionPlay, CompositionTopArtists, PollObj, Quiz, Track } from '../../../interfaces';
import { Axios, withAuth } from '../../../utils/axios';
import { AuthContext } from '../../Auth';
import { ArtistDashContext } from '../artist';
import { ComposerDashContext } from '../composer';
import { DashContext } from '../Context';
import { WhoMatchesContext } from './Context';

interface Props {
  children: React.ReactNode;
}

export const WhoMatchesProvider: React.FC<Props> = ({ children }) => {
  const [quizes, setQuizes] = useState<Quiz[] | undefined>(undefined);
  const [selectedQuizTopArtists, setSelectedQuizTopArtists] = useState<CompositionTopArtists[] | undefined>(undefined);
  const [activeQuiz, setActiveQuiz] = useState<Quiz>();
  const [activeQuizPost, setActiveQuizPost] = useState<PollObj>();
  const [quizLocationIndications, setQuizLocationIndications] = useState<FeatureCollection>();
  const [isGettingQuizInfos, setIsGettingQuizInfos] = useState<boolean>(false);
  const [composers, setComposers] = useState<any>([]);
  const [quizIndicationByMoment, setQuizIndicationByMoment] = useState<HistogramData | any>({});
  // eslint-disable-next-line
  const [idQuizPost, setIdQuizPost] = useState<string>();
  const [quizIndicationsByDay, setQuizIndicationsByDay] = useState<CompositionIndication[]>([]);
  const [quizPlaysByDay, setQuizPlaysByDay] = useState<CompositionPlay[]>([]);

  const [activeWhoMatchesTrack, setActiveWhoMatchesTrack] = useState<undefined | Track>(undefined);
  const [whoMatchesPlayList, setWhoMatchesPlayList] = useState<Track[]>([]);
  const [isGettingWhoMatchesPlayList, setIsGettingWhoMatchesPlayList] = useState<boolean>(false);

  const { token } = useContext(AuthContext);

  const { setActiveArtistTrack } = useContext(ArtistDashContext);
  const { setActivePollsTrack } = useContext(DashContext);
  const { setActiveCompositionsTrack } = useContext(ComposerDashContext);

  useEffect(() => {
    setActiveArtistTrack(undefined);
    setActivePollsTrack(undefined);
    setActiveCompositionsTrack(undefined);
  }, []);

  useEffect(() => {
    (async () => {
      if (quizes?.length) {
        await getWhoMatchesPlayList(token);
      }
    })();
  }, [quizes]);

  const _getWhoMatchesTrackById = async (id: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/polls/${id}`, withAuth(defaultToken || token));
      return response.data.data.post;
    } catch (error) {
      console.error('Error on getting compositions play list: ', error);
      return undefined;
    }
  };

  const getWhoMatchesPlayList = async (defaultToken?: string) => {
    quizes?.forEach(async (item, idx) => {
      const track = await _getWhoMatchesTrackById(item._id, defaultToken);
      setWhoMatchesPlayList((prev) => [...prev, track]);
      if (idx === quizes.length - 1) {
        setIsGettingWhoMatchesPlayList(false);
      }
    });
  };

  const fetchQuizesByToken = async (defaultToken?: string) => {
    if (!isGettingQuizInfos) setIsGettingQuizInfos(true);

    try {
      const { data } = await Axios(process.env.REACT_APP_NODE_ENV).get('/v1/data/dashboard/search/quiz/', withAuth(defaultToken || token));

      if (data.length) {
        await _getQuizPostInfos(data[0]._id, defaultToken);
        await _getLocationIndications(data[0]._id);
        await _getQuizIndicationByMoment(data[0]._id, defaultToken);
        await _getQuizIndicationsByDay(data[0]._id, defaultToken);
        await _getQuizPlaysByDay(data[0]._id, defaultToken);
        await _getQuizTopArtists(data[0]._id, defaultToken);

        setActiveQuiz(data.length ? data[0] : undefined);
      }
      const sorted: Quiz[] = data;
      setQuizes(sorted?.sort((a, b) => b.plays - a.plays));
      setIsGettingQuizInfos(false);
      return data;
    } catch (error) {
      console.error('Error on fetching quizes in WhoMatchesProvider', error);
      setIsGettingQuizInfos(false);
      return undefined;
    }
  };

  const handleQuizSelect = async (id: string, isPlay?: boolean) => {
    if (!isGettingQuizInfos) setIsGettingQuizInfos(true);
    try {
      const quiz = quizes?.find((x) => x._id === id);
      setActiveQuiz(quiz || undefined);

      if (id && isPlay) {
        setIsGettingWhoMatchesPlayList(true);
        const track = await _getWhoMatchesTrackById(id);
        setActiveWhoMatchesTrack(track);
        setIsGettingWhoMatchesPlayList(false);
      }

      if (quiz) {
        await _getQuizPostInfos(quiz?._id, token);
        await _getLocationIndications(quiz?._id);
        await _getQuizIndicationByMoment(quiz?._id, token);
        await _getQuizIndicationsByDay(quiz?._id, token);
        await _getQuizPlaysByDay(quiz?._id, token);
        await _getQuizTopArtists(quiz?._id, token);
      }
      setIsGettingQuizInfos(false);
    } catch (error) {
      console.error('error selecting quiz in whoMatches provider: ', error);
      setActiveQuiz(undefined);
      setIsGettingQuizInfos(false);
    }
  };

  const _getQuizTopArtists = async (id: string | undefined, defaultToken: string | undefined) => {
    try {
      const topArtistsResponse = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/polls/${id}/topartists/`, withAuth(defaultToken || token));
      setSelectedQuizTopArtists(topArtistsResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  const _getQuizPostInfos = async (id?: string, defaultToken?: string) => {
    try {
      const getComposersName = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/polls/${id}`, withAuth(defaultToken || token));
      setActiveQuizPost(getComposersName.data.data);
      setIdQuizPost(getComposersName.data.data.post._id);
      setComposers(getComposersName.data.data.post.composers);
      return getComposersName.data.data;
    } catch (error) {
      console.error('Error on fetching composers names in who matches provider:', error);
      setComposers(undefined);
      return undefined;
    }
  };

  const _getLocationIndications = async (id?: string, defaultToken?: string) => {
    try {
      const getLocationIndications = await Axios(process.env.REACT_APP_NODE_ENV).get(
        `v1/data/polls/${id}/location_indications/`,
        withAuth(defaultToken || token)
      );
      setQuizLocationIndications(getLocationIndications.data);
    } catch (error) {
      console.error(error);
    }
  };

  const _getQuizPlaysByDay = async (id: string | undefined, defaultToken: string | undefined) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/polls/${id}/plays_by_day/`, withAuth(defaultToken || token));
      setQuizPlaysByDay(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const _getQuizIndicationsByDay = async (id?: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/polls/${id}/indications_by_day/`, withAuth(defaultToken || token));
      setQuizIndicationsByDay(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const _getQuizIndicationByMoment = async (id: string | undefined, defaultToken: string | undefined) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/polls/${id}/moment_indications/`, withAuth(defaultToken || token));
      setQuizIndicationByMoment(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <WhoMatchesContext.Provider
      value={{
        quizes,
        selectedQuizTopArtists,
        activeQuiz,
        quizLocationIndications,
        composers,
        activeQuizPost,
        quizIndicationByMoment,
        quizIndicationsByDay,
        quizPlaysByDay,
        isGettingQuizInfos,
        activeWhoMatchesTrack,
        isGettingWhoMatchesPlayList,
        whoMatchesPlayList,
        setActiveWhoMatchesTrack,
        fetchQuizesByToken,
        handleQuizSelect
      }}
    >
      {children}
    </WhoMatchesContext.Provider>
  );
};
