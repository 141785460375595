import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Input from '../../../../../components/Input/Input';
import { Modal } from '../../../../../components/Modal';
import RectButton from '../../../../../components/RectButton/RectButton';

import reloadIcon from '../../../../../images/mpf-reload-white.svg';

import { Project } from '../../../../../interfaces';

import { useWindowDimensions } from '../../../../../utils/useWindowsDimension/useWindowsDimension';

type Props = {
  projectData?: Project | any;
  closeFunction: () => void;
  showSuccess: () => void;
  isOpen?: boolean;
  deleteFunction: (id: string) => any | void;
};

export const DeleteModal: React.FC<Props> = ({ projectData, closeFunction, isOpen, deleteFunction, showSuccess }) => {
  const [inputText, setInputText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [requisitionErrorMessage, setRequisitionErrorMessage] = useState('');

  const { width: ScreenWidth } = useWindowDimensions();

  const intl = useIntl();

  const handleDelete = async () => {
    setLoading(true);

    if (!inputText) {
      setErrorMessage(
        intl.formatMessage({ id: 'dashboard.my-projects.modal.v1.input.errors.empty-field', defaultMessage: 'Digite o nome do projeto' })
      );
      setLoading(false);
      return;
    }

    if (inputText.toLowerCase().trim() !== projectData.name.toLowerCase().trim()) {
      setErrorMessage(
        intl.formatMessage({ id: 'dashboard.my-projects.modal.v1.input.errors.wrong-name', defaultMessage: 'O nome do projeto está errado' })
      );
      setLoading(false);
      return;
    }

    const res = await deleteFunction(projectData._id);

    if (res === false) {
      setLoading(false);
      setInputText('');
      setRequisitionErrorMessage(
        intl.formatMessage({
          id: 'dashboard.my-projects.modal.v1.error',
          defaultMessage: 'Um erro aconteceu ao excluir esse projeto. tente novamente mais tarde.'
        })
      );
      return;
    }

    showSuccess();
    closeFunction();
  };

  useEffect(() => {
    setInputText('');
    setLoading(false);
    setErrorMessage('');
    setRequisitionErrorMessage('');
  }, [closeFunction]);

  return (
    <Modal
      closeFunc={closeFunction}
      isOpen={isOpen}
      title={
        <FormattedMessage
          id="dashboard.my-projects.modal.v1.main-title"
          defaultMessage="Quer mesmo deletar este projeto? Cuidado! Esta ação não poderá ser desfeita."
        />
      }
    >
      <span className="py-4">
        <FormattedMessage
          id="dashboard.my-projects.modal.v1.description"
          defaultMessage="Ao excluir este projeto você estará excluindo permanentemente todos os dados e informações associados a ele. Digite o nome do projeto no campo abaixo para confirmar a exclusão permanente."
        />
      </span>
      <b>
        <FormattedMessage id="dashboard.my-projects.modal.v1.project-name" defaultMessage="Nome do projeto: " />
        <span className="font-medium">{projectData.name}</span>
      </b>
      <Input
        className="my-3"
        style={{ color: '#EF233C' }}
        autoFocus
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInputText(e.target.value);
          if (errorMessage) setErrorMessage('');
          if (requisitionErrorMessage) setRequisitionErrorMessage('');
        }}
        value={inputText}
        errorMessage={errorMessage}
        placeholder={intl.formatMessage({ id: 'dashboard.my-projects.modal.v1.input-label', defaultMessage: 'Nome do projeto' })}
      />
      {requisitionErrorMessage && (
        <div className="w-full my-2 transition-opacity p-2 bg-red-500 text-sm font-semibold text-white rounded-md shadow-md">
          {requisitionErrorMessage}
        </div>
      )}
      <div className="w-full flex items-center justify-center mt-6 flex-col sm:flex-row">
        <RectButton
          theme="outline"
          style={{ border: '1px solid black', height: 45, width: ScreenWidth > 640 ? 129 : '100%' }}
          onClick={closeFunction}
        >
          <FormattedMessage id="dashboard.my-projects.modal.v1.button.cancel" defaultMessage="Cancelar" />
        </RectButton>
        <RectButton
          theme="danger"
          disabled={isLoading}
          className="mt-2 ml-0 w-full sm:w-auto sm:ml-2 sm:mt-0 flex items-center justify-center"
          onClick={handleDelete}
        >
          {isLoading ? (
            <>
              <img className="animate-spin mr-2 filter-grayscale" src={reloadIcon} alt="icon spin" />
              <FormattedMessage id="dashboard.my-projects.modal.v1.button.loading" defaultMessage="Carregando..." />
            </>
          ) : (
            <FormattedMessage id="dashboard.my-projects.modal.v1.button.delete" defaultMessage="Excluir Projeto" />
          )}
        </RectButton>
      </div>
    </Modal>
  );
};
