import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import ReactBreakpoints from 'react-breakpoints';
import { Elements } from '@stripe/react-stripe-js';

import Router from './Router';
import GlobalStyle from './globalStyle';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { DashProvider } from './context/dash/Provider';
import { AuthProvider, CheckoutProvider, ComposerDashProvider, ArtistDashProvider, WhoMatchesProvider, ProducerDashProvider } from './context';
import { PollsProvider } from './context/dash/polls';
import { KanbanProvider } from './context/dash/producer/kanban';

const stripePromise = loadStripe(
  String(process.env.REACT_APP_NODE_ENV === 'prod' ? process.env.REACT_APP_STRIPE_SECRET_KEY : process.env.REACT_APP_STRIPE_PUBLISH_KEY)
);

const App: React.FC = () => {
  const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280
  };

  return (
    <Elements stripe={stripePromise}>
      <ReactBreakpoints breakpoints={breakpoints}>
        <AuthProvider>
          <CheckoutProvider>
            <ComposerDashProvider>
              <WhoMatchesProvider>
                <KanbanProvider>
                  <ProducerDashProvider>
                    <ArtistDashProvider>
                      <PollsProvider>
                        <DashProvider>
                          <GlobalStyle />
                          <Router />
                        </DashProvider>
                      </PollsProvider>
                    </ArtistDashProvider>
                  </ProducerDashProvider>
                </KanbanProvider>
              </WhoMatchesProvider>
            </ComposerDashProvider>
          </CheckoutProvider>
        </AuthProvider>
      </ReactBreakpoints>
    </Elements>
  );
};

export default App;
