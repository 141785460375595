import React, { useState, useContext } from 'react';
import { Container, MainContainer } from './styles';
import background_nav from '../images/background-nav.jpg';
import defaultUser from '../images/default_avatar.png';
import useUserData from '../utils/customHooks/useUserData';
import './styles.css';
import MobileSidebar from '../components/MobileSidebar/MobileSidebar';
import DesktopSidebar from '../components/DesktopSidebar/DesktopSidebar';
import Header from '../components/Header/Header';
import { CheckoutContext } from '../context';
import { getActiveProduct } from '../utils/checkoutHelpers';

type Props = {
  children: React.ReactNode;
  title: string;
} & React.HTMLAttributes<HTMLDivElement>;

const AccountManagementContainer = React.memo(({ children, title }: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const { userSubscriptions, prices, isGettingPrices, isGettingUserSubscriptions } = useContext(CheckoutContext);

  const handleMenuButtonClick = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const userActivePlan = getActiveProduct(userSubscriptions, prices);

  const user = useUserData();

  return (
    <MainContainer>
      <div id="page-wrap">
        <Container className="w-full flex flex-col h-screen overflow-hidden">
          <div className="relative">
            <MobileSidebar
              activePrice={userActivePlan}
              isMenuOpen={isMenuOpen}
              handleMenuButtonClick={handleMenuButtonClick}
              isAccountManagement
            />
            <Header
              activePrice={userActivePlan}
              isGettingInfos={isGettingPrices || isGettingUserSubscriptions}
              dashRoute="/dash/compositions"
            />
          </div>
          <div className="w-full overflow-hidden flex flex-1">
            <DesktopSidebar activePrice={userActivePlan} isAccountManagement />
            <div className="w-full  h-full overflow-auto flex flex-col">
              <div style={{ background: '#EF233C' }} className="w-full relative">
                <img
                  style={{ height: '8.125rem' }}
                  className="w-full bg-nav object-cover hidden sm:block"
                  src={background_nav}
                  alt=""
                />
                <div className="flex flex-col text-center sm:text-left sm:flex-row relative sm:absolute w-full picture--title--container p-6 sm:p-0 items-center sm:items-stretch">
                  <picture className="picture--container">
                    <img
                      className="rounded-full border-white border-2 shadow-sm ml-0 sm:ml-8"
                      style={{ width: '7rem', height: '7rem' }}
                      src={user?.coverUrl || defaultUser}
                      alt="user-avatar"
                    />
                  </picture>
                  <div className="texts--container">
                    <span className="font-semibold leading-9 text-black sm:text-white text-3xl">{title}</span>
                    <p className="text-sm leading-6 text-black sm:text-white">
                      Olá {user?.name}. Aqui você encontra todos os dados da sua conta
                    </p>
                  </div>
                </div>
              </div>
              <section
                style={{
                  background: '#F5F5F5'
                }}
                className="w-full flex flex-1 pt-8 sm:pt-16 pr-6 pb-6 pl-6"
              >
                {' '}
                {children}
              </section>
            </div>
          </div>
        </Container>
      </div>
    </MainContainer>
  );
});

export default AccountManagementContainer;
