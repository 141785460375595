import React, { useMemo } from 'react';
import DataTable, { IDataTableColumn, IDataTableStyles } from 'react-data-table-component';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { IoIosArrowForward } from 'react-icons/io';

import { useHistory } from 'react-router-dom';
import { CustomizedTableWrapper, MaterialButton } from './styles';

import { NoDataComponent } from '../NoDataComponent';
import Spinner from '../../../../../components/Spinner/Spinner';
import { Audition } from '../../../../../interfaces';

type Props = {
  data?: Audition[];
  isListingActives?: boolean;
  isLoading?: boolean;
  paginationTotalRows?: number;
  handleChangePage?: (page: number, totalRows: number) => any | void;
  handleChangeRowsPerPage?: (currentRowsPerPage: number, currentPage: number) => any | void;
};

const customStyles: IDataTableStyles = {
  headRow: {
    style: {
      border: 'none',
      borderRadius: '8px 8px 0px 0px',
      backgroundColor: '#504D4D',
      padding: '0 45px'
    }
  },
  headCells: {
    style: {
      color: '#CFCFCF',
      fontSize: '14px',
      padding: 0
    },
    activeSortStyle: {
      color: '#CFCFCF'
    }
  },
  rows: {
    style: {
      margin: '4px 10px 4px 10px',
      padding: '18px 0px',
      border: '1px solid #E4E5E6',
      borderRadius: 4,
      minHeight: 76,

      width: '98%',
      userSelect: 'none'
    },
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(13,101,255,0.1)'
    }
  },
  pagination: {
    style: {
      border: 'none'
    }
  }
};

export const DataTableComponent: React.FC<Props> = ({
  data,
  isLoading,
  paginationTotalRows,
  handleChangePage,
  isListingActives,
  handleChangeRowsPerPage
}) => {
  const intl = useIntl();
  const history = useHistory();

  const columns: IDataTableColumn<any>[] = useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: 'dashboard.audition.list-open.data-table.audition-title',
          defaultMessage: 'Título da audição'
        }),
        selector: 'title',
        cell: (row: Audition) => (
          <div className="flex items-center">
            <div
              style={{
                width: 10,
                height: 10,
                marginRight: 10,
                boxShadow: `0px 0px 18px ${isListingActives ? 'rgba(29, 185, 84, 0.7)' : 'rgba(242, 76, 96, 0.7)'}`,
                borderRadius: '50%',
                backgroundColor: `${isListingActives ? '#1DB954' : '#F24C60'}`
              }}
            ></div>{' '}
            {row.title}
          </div>
        ),
        sortable: true,
        style: {
          color: '#6E6969',
          fontSize: '14px',
          fontWeight: 500
        }
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.audition.list-open.data-table.related-artists',
          defaultMessage: 'Artistas relacionados'
        }),
        selector: 'related_artists',
        cell: (row: Audition) =>
          row.related_artists.length ? (
            <div className="flex flex-wrap">
              {row?.related_artists.map((artist: any, index: number) => (
                <div style={{ margin: '0 2px' }} key={index}>
                  {index === row?.related_artists.length - 1 ? artist.name : artist.name + ', '}
                </div>
              ))}
            </div>
          ) : (
            <span>
              {intl.formatMessage({
                id: 'dashboard.audition.list-open.data-table.fallback-no-related-artists',
                defaultMessage: 'Nenhum artista relacionado'
              })}
            </span>
          ),
        sortable: true,
        style: {
          color: '#6E6969',
          fontSize: '14px',
          fontWeight: 500
        }
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.audition.list-open.data-table.opened-in',
          defaultMessage: 'Aberta em'
        }),
        selector: 'datetime_created',
        sortable: true,
        cell: (row: Audition) => <div>{moment(row.datetime_created).format('DD/MM/yyyy')}</div>,
        style: {
          color: '#6E6969',
          fontSize: '14px',
          fontWeight: 500
        }
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.my-projects.list-component.table-header.actions',
          defaultMessage: 'Ações'
        }),
        selector: 'actions',
        cell: (row: any) => (
          <div className="flex items-center" style={{ marginRight: 65 }}>
            <MaterialButton
              data-tip={intl.formatMessage({
                id: 'dashboard.audition.list-open.data-table.action-tooltip',
                defaultMessage: 'Entrar'
              })}
              onClick={() => history.push({ pathname: `/dash/auditions/${row._id}`, state: row })}
            >
              <IoIosArrowForward size={18} />
            </MaterialButton>
          </div>
        ),
        allowOverflow: true,
        button: true,
        width: '56px'
      }
    ],
    // eslint-disable-next-line
    [history, intl]
  );

  const paginationComponentOptions: any = {
    paginationRowsPerPageOptions: [5, 10, 25, 50, 100],
    rowsPerPageText: intl.formatMessage({
      id: 'dashboard.my-projects.list-component.rows-per-page',
      defaultMessage: 'Linhas por página:'
    }),
    rangeSeparatorText: intl.formatMessage({
      id: 'dashboard.my-projects.list-component.of',
      defaultMessage: 'de'
    }),
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: intl.formatMessage({
      id: 'dashboard.my-projects.list-component.all',
      defaultMessage: 'Todas'
    })
  };

  return (
    <>
      <CustomizedTableWrapper id="table" className="shadow-lg">
        <DataTable
          data={data as Audition[]}
          columns={columns}
          progressPending={isLoading}
          noHeader
          pagination
          paginationServer
          paginationComponentOptions={paginationComponentOptions}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onRowClicked={(row) => history.push({ pathname: `/dash/auditions/${row._id}`, state: row })}
          fixedHeaderScrollHeight="450px"
          noDataComponent={<NoDataComponent />}
          progressComponent={
            <div className="py-8">
              <Spinner isLoading />
            </div>
          }
          customStyles={customStyles}
          paginationTotalRows={paginationTotalRows}
          highlightOnHover
          pointerOnHover
        />
      </CustomizedTableWrapper>
    </>
  );
};
