import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import Input from '../Input/Input';

import { Container } from './styles';

type Props = {
  errorMessage?: string;
  hasSuccess?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

function InputPassword({ hasSuccess, errorMessage, ...rest }: Props) {
  const [hidden, setHidden] = useState(true);

  return (
    <Container>
      <Input hasSuccess={hasSuccess} type={hidden ? 'password' : 'text'} {...rest} />
      {hidden ? (
        <AiOutlineEye size={20} color="#8F8F8F" onClick={() => setHidden(false)} />
      ) : (
        <AiOutlineEyeInvisible size={20} color="#8F8F8F" onClick={() => setHidden(true)} />
      )}
      {errorMessage && (
        <div className="align--errormessage">
          <span className="error text-xs">{errorMessage}</span>
        </div>
      )}
    </Container>
  );
}

export default InputPassword;
