import React from 'react';

import { DefaultButton, PrimaryButton, SecondaryButton, TertiaryButton, QuaternaryButton, DisabledButton } from './styles';

type Props = {
  children: React.ReactNode;
  theme?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'disabled' | 'default' | undefined | null;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const returnTypeOfButton = (type: unknown) => {
  if (type === 'primary') {
    return PrimaryButton;
  }

  if (type === 'secondary') {
    return SecondaryButton;
  }

  if (type === 'tertiary') {
    return TertiaryButton;
  }

  if (type === 'quaternary') {
    return QuaternaryButton;
  }

  if (type === 'disabled') {
    return DisabledButton;
  }

  if (type === 'default') {
    return DefaultButton;
  }

  return DefaultButton;
};

function Button({ children, theme, ...props }: Props) {
  const ButtonType = returnTypeOfButton(theme);

  return (
    <ButtonType type="button" {...props}>
      {children}
    </ButtonType>
  );
}

export default Button;
