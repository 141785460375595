import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';

import { Container } from './styles';

type Props = {
  isOpen?: boolean;
  closeFunc?: () => void;
  children?: React.ReactNode;
  title?: string | React.Component | Element | any;
};

export const Modal = ({ isOpen, children, closeFunc, title }: Props) => {
  return (
    <>
      {isOpen && (
        <Container>
          <div className="content-container">
            <button type="button" onClick={closeFunc} className="close">
              <span className="close-text">
                <FormattedMessage id="dashboard.my-projects.modal.close-text" defaultMessage="FECHAR" />
              </span>
              <AiOutlineCloseCircle color="#CFCFCF" size={18} />
            </button>
            <span className="text-2xl font-semibold">{title}</span>
            {children}
          </div>
        </Container>
      )}
    </>
  );
};
