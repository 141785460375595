/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import SecondCheckoutContainer from '../secondCheckoutContainer/SecondCheckoutContainer';
import 'moment/locale/pt-br';
import { Container } from './styles';
import { FormattedMessage } from 'react-intl';
import Button from '../../components/Button/Button';
import reloadIcon from '../../images/mpf-reload-white.svg';
import { CheckoutConsumer, CheckoutContext } from '../../context/checkout';
import { Link, useRouteMatch, Redirect } from 'react-router-dom';
import { Match } from '../../interfaces';
import Spinner from '../../components/Spinner/Spinner';
import reaisConverter from '../../utils/simpleReaisConverter/reaisConverter';
import { getCurrentSubscription, getCurrentInvoice, getActiveProduct, canTrial } from '../../utils/checkoutHelpers';
import { AuthContext } from '../../context';

const ChangePlan: React.FC = () => {
  const {
    getUserSubscriptions,
    getUserInvoices,
    getPrices,
    getUserUpcomingInvoice,
    userInvoices,
    userSubscriptions,
    prices,
    customer,
    userUpcomingInvoice
  } = useContext(CheckoutContext);

  const { params }: Match = useRouteMatch();
  const { priceId } = params;

  const { token } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (!prices) await getPrices();
      if (token) {
        if (!userSubscriptions?.length) await getUserSubscriptions(token);
        if (!userInvoices?.length) await getUserInvoices(token);
        if (!userUpcomingInvoice) await getUserUpcomingInvoice(token);
      }
    })();
  }, [token]);

  const activePrice = getActiveProduct(userSubscriptions, prices);

  const planType = activePrice?.name;

  const currentSubscription = userSubscriptions && getCurrentSubscription(userSubscriptions);

  const currentInvoice = userInvoices && getCurrentInvoice(userInvoices);

  const planCaptalized = planType ? planType?.charAt(0).toUpperCase() + planType?.slice(1) : undefined;

  const userCanTrial = canTrial(customer, activePrice);

  return (
    <CheckoutConsumer>
      {({
        isGettingUserSubscriptions,
        isCancelingSubscription,
        canceledSubscription,
        isGettingUserInvoices,
        isGettingPrices,
        userUpcomingInvoice,
        isGettingUserUpcomingInvoice,
        handleCancelSubscription
      }) =>
        canceledSubscription === 1 ? (
          <Redirect to={`/checkout/changed-plan/${priceId}`} />
        ) : (
          <SecondCheckoutContainer hasGoBack goBackRoute={`/checkout/invoicing/${priceId}`} title="change-plan">
            <Container className="max-w-md xl:max-w-none w-full pb-4 flex flex-col h-full">
              {isGettingUserSubscriptions ||
              isGettingPrices ||
              isGettingUserInvoices ||
              isGettingUserUpcomingInvoice ? (
                <div className="w-full mt-8 flex justify-center items-center">
                  <Spinner
                    isLoading={
                      isGettingUserSubscriptions ||
                      isGettingPrices ||
                      isGettingUserInvoices ||
                      isGettingUserUpcomingInvoice
                    }
                  />
                </div>
              ) : userInvoices?.length && prices?.length && userSubscriptions?.length ? (
                <div style={{ maxWidth: '600px' }} className="w-full flex flex-col">
                  <span className="font-normal text-xl lg:leading-10 lg:text-3xl " style={{ color: '#202020' }}>
                    <FormattedMessage
                      id="checkout.change-plan.plan-type"
                      values={{
                        productName: activePrice?.name
                      }}
                    />
                  </span>
                  <div className="w-full flex flex-col justify-between mb-8 mt-2">
                    <span style={{ color: '#202020' }} className="text-xl font-semibold leading-7">
                      {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                        <FormattedMessage
                          id="checkout.change-plan.plan-price"
                          values={{
                            productPrice: reaisConverter((currentInvoice?.total as number) / 100)
                          }}
                        />
                      ) : (
                        // <FormattedMessage
                        //   id="checkout.change-plan.plan-price"
                        //   values={{
                        //     productPrice: reaisConverter(((userCanTrial ? currentInvoice?.total : userUpcomingInvoice?.total) as number) / 100)
                        //   }}
                        // />
                        <FormattedMessage
                          id="checkout.change-plan.plan-price"
                          values={{
                            productPrice: reaisConverter((currentInvoice?.total as number) / 100)
                          }}
                        />
                      )}
                    </span>
                    <p style={{ color: '#817B7B' }} className="text-sm leading-5 w-full lg:text-base lg:leading-6">
                      {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                        <FormattedMessage
                          id="checkout.change-plan.description-invoicing-requested-cancel"
                          values={{
                            cancelAt: moment(
                              (currentSubscription?.stripe_subscription?.cancel_at as number) * 1000
                            ).format('LL')
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id="checkout.change-plan.description-invoicing"
                          values={{
                            upcomingInvoiceDate:
                              currentSubscription?.stripe_subscription?.status === 'trialing'
                                ? moment((currentSubscription.stripe_subscription.trial_end as number) * 1000).format(
                                    'LL'
                                  )
                                : moment(
                                    (userCanTrial
                                      ? (currentInvoice?.period_end as number)
                                      : (userUpcomingInvoice?.period_end as number)) * 1000
                                  ).format('LL')
                          }}
                        />
                      )}
                    </p>
                    <p style={{ color: '#817B7B' }} className="mt-6 text-sm leading-5 w-full lg:text-base lg:leading-6">
                      <FormattedMessage id="checkout.change-plan.if-change-idea" />
                    </p>
                  </div>
                  <div className="w-full max-w-md lg:max-w-none flex items-center justify-between mb-6">
                    {currentSubscription?.stripe_subscription?.cancel_at_period_end ? (
                      <Link to={`/checkout/reactivate-subscription/${currentSubscription._id}`} className="w-full mr-5">
                        <Button
                          style={{
                            margin: '0 20px 0 0',
                            borderRadius: '4px',
                            padding: '17px 10px',
                            maxWidth: '285px'
                          }}
                          className="w-full flex items-center font-semibold justify-center text-sm sm:text-base py-4"
                          theme="secondary"
                        >
                          <FormattedMessage id="checkout.change-plan.want-reactivate-subscription" />
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        style={{
                          margin: '0 20px 0 0',
                          borderRadius: '4px',
                          padding: '13px 0',
                          maxWidth: '285px'
                        }}
                        className="w-full  flex items-center font-semibold justify-center text-sm sm:text-base py-4"
                        theme="quaternary"
                        onClick={() => handleCancelSubscription(currentSubscription?._id)}
                      >
                        {!isCancelingSubscription && canceledSubscription === 0 && (
                          <FormattedMessage id="checkout.change-plan.change-my-plan" />
                        )}
                        {isCancelingSubscription && canceledSubscription === 0 && (
                          <>
                            <FormattedMessage id="checkout.change-plan.changing-plan" />
                            <img className="spin" src={reloadIcon} alt="spinner-icon" />
                          </>
                        )}
                        {!isCancelingSubscription && canceledSubscription === -1 && (
                          <FormattedMessage id="checkout.change-plan.change-my-plan" />
                        )}
                      </Button>
                    )}
                    <Link to="/checkout" className="w-full">
                      <Button
                        style={{
                          margin: '0',
                          borderRadius: '4px',
                          padding: '13px 0',
                          border: '1px solid #202020',
                          maxWidth: '285px'
                        }}
                        className="w-full text-sm sm:text-base py-4 font-semibold"
                        theme="default"
                      >
                        <FormattedMessage id="checkout.change-plan.goback" />
                      </Button>
                    </Link>
                  </div>
                  {!isCancelingSubscription && canceledSubscription === -1 && (
                    <span className="text-red-500 text-xs mb-4">
                      <FormattedMessage id="checkout.change-plan.change-my-plan-error" />
                    </span>
                  )}
                  {currentSubscription?.stripe_subscription?.status !== 'canceled' && (
                    <p style={{ color: '#817B7B' }} className="w-full font-normal text-xs leading-5">
                      <FormattedMessage id="checkout.change-plan.terms-initial" />
                      <a href="https://static.musicplayce.com/assets/terms_of_use.pdf" className="text-blue-600">
                        <FormattedMessage id="checkout.change-plan.terms.initial-2" />
                      </a>
                      <FormattedMessage id="checkout.change-plan.terms.initial-3" />
                      <a
                        className="text-blue-600"
                        href="https://static.musicplayce.com/assets/privacy_policy.pdf"
                        target="blank"
                      >
                        <FormattedMessage id="checkout.change-plan.terms.initial-4" />
                      </a>
                      <FormattedMessage id="checkout.change-plan.terms.initial-5" />
                    </p>
                  )}
                </div>
              ) : (
                <>
                  <span className="font-normal text-xl lg:leading-10 lg:text-3xl " style={{ color: '#202020' }}>
                    <FormattedMessage
                      id="checkout.change-plan.plan-type"
                      values={{
                        productName: planCaptalized
                      }}
                    />
                  </span>
                  <span>
                    <FormattedMessage id="checkout.change-plan.subscription-not-found" />
                  </span>
                </>
              )}
            </Container>
          </SecondCheckoutContainer>
        )
      }
    </CheckoutConsumer>
  );
};

export default ChangePlan;
