import React from 'react';

import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Container } from './styles';
import AuthContainer from '../AuthContainer';
import Button from '../../components/Button/Button';
import { AuthConsumer } from '../../context/Auth';

import reloadIcon from '../../images/mpf-reload-black.svg';
import ResetPasswordValidation from '../validations/resetPasswordValidation';
import InputPassword from '../../components/InputPassword/InputPassword';

type FuncType = () => void;

interface Match {
  params: {
    token: string;
  };
}

const ResetPassword: React.FC = () => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, func: FuncType) => {
    func();
  };

  // const { params }: Match = useRouteMatch();
  // const urlToken = params.token;

  return (
    <AuthConsumer>
      {({ isResetingPassword, resetedPassword, handleResetPassword, errorMessage, setErrorMessage }) => (
        <AuthContainer theme="tertiary" hasGoBack goBackRoute="/auth/email">
          <Container className="h-full flex flex-col items-center">
            <span
              data-cy="main-title"
              style={{ color: '#202020' }}
              className=" max-w-xs text-center w-5/6 text-2xl font-normal leading-8 mt-20 sm:mt-40 sm:mb-32 mb-12"
            >
              <FormattedMessage id="auth.reset-password.main-title" />
            </span>
            <Formik
              initialValues={{
                newPassword: '',
                confirmNewPassword: ''
              }}
              validationSchema={ResetPasswordValidation}
              onSubmit={(values) => {
                // handleResetPassword(values.confirmNewPassword, urlToken);
              }}
            >
              {({ errors, values, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
                <>
                  <div className="text-left w-5/6 max-w-xs sm:max-w-sm text-xs sm:text-sm mb-12">
                    <FormattedMessage id="auth.reset-password.placeholder-new-password">
                      {(placeholder: string) => (
                        <InputPassword
                          autoComplete="off"
                          className="my-1 "
                          name="newPassword"
                          id="newPassword"
                          placeholder={placeholder}
                          value={values.newPassword}
                          onChange={handleChange('newPassword')}
                          onBlur={handleBlur('newPassword')}
                          hasSuccess={!(!values.newPassword || (errors.newPassword && touched.newPassword))}
                          errorMessage={errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                        />
                      )}
                    </FormattedMessage>
                    <FormattedMessage id="auth.reset-password.placeholder-confirm-new-password">
                      {(placeholder: string) => (
                        <InputPassword
                          autoComplete="off"
                          className="my-1 "
                          name="confirmNewPassword"
                          id="confirmNewPassword"
                          placeholder={placeholder}
                          value={values.confirmNewPassword}
                          onChange={handleChange('confirmNewPassword')}
                          onBlur={handleBlur('confirmNewPassword')}
                          hasSuccess={
                            !(!values.confirmNewPassword || (errors.confirmNewPassword && touched.confirmNewPassword))
                          }
                          errorMessage={
                            errors.confirmNewPassword && touched.confirmNewPassword ? errors.confirmNewPassword : ''
                          }
                        />
                      )}
                    </FormattedMessage>
                    {values.confirmNewPassword === '' && setErrorMessage('')}
                    {errorMessage !== '' && values.confirmNewPassword !== '' && (
                      <span style={{ color: 'red' }} className="mt-1">
                        {errorMessage}
                      </span>
                    )}
                    {resetedPassword && (
                      <>
                        <span style={{ color: 'green' }} className="mt-1">
                          Senha redefinida com sucesso!{' '}
                          <Link style={{ color: 'black' }} to="/auth/email" className="underline">
                            Realizar login
                          </Link>
                        </span>
                      </>
                    )}
                  </div>
                  <Button
                    style={{ margin: '8px 0' }}
                    className="outline-none flex font-normal items-center justify-center w-5/6 max-w-xs sm:max-w-sm text-sm sm:text-base "
                    disabled={!isValid}
                    theme={isValid ? 'default' : 'disabled'}
                    onClick={(e) => handleClick(e, handleSubmit)}
                  >
                    {isResetingPassword && (
                      <span className="text-sm sm:text-base">
                        <FormattedMessage id="auth.reset-password.reseting-password" />
                      </span>
                    )}
                    {isResetingPassword && <img src={reloadIcon} alt="spinner-icon" />}
                    {!isResetingPassword && <FormattedMessage id="auth.reset-password.reset-password" />}
                  </Button>
                </>
              )}
            </Formik>
            <p
              style={{ color: '#8F8F8F' }}
              className="terms sm:hidden sm:max-w-lg mt-10 mb-12 sm:mb-10 leading-4 sm:leading-6 items-center text-xs sm:text-sm w-full max-w-72 sm:w-78"
            >
              <FormattedMessage id="auth.terms-initial" />
              <a className="link--terms" href="https://static.musicplayce.com/assets/terms_of_use.pdf" target="blank">
                <FormattedMessage id="auth.terms" />
              </a>{' '}
              <FormattedMessage id="auth.terms-initial-2" />
              <a className="link--terms" href="https://static.musicplayce.com/assets/privacy_policy.pdf" target="blank">
                <FormattedMessage id="auth.politics" />
              </a>
              <FormattedMessage id="auth.terms-initial-3" />
            </p>
          </Container>
        </AuthContainer>
      )}
    </AuthConsumer>
  );
};

export default ResetPassword;
