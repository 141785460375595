import React, { useContext, useEffect } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import MySignature from './mySignature/MySignature';
import Receipts from './receipts/Receipts';
import { CheckoutContext } from '../context/checkout';
import { AuthContext } from '../context';

const AccountManagementRouter = () => {
  const { path } = useRouteMatch();
  // É preciso usar o "priceObjectId" para ter uma forma de associoar as informações do price salvas
  // no banco (benefits, etc..),  com as informações do price salvas no stripe

  const { getActualPlan } = useContext(CheckoutContext);

  const { token } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (getActualPlan) await getActualPlan(token);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Switch>
      {/* <Route exact path={path}>
        <OverviewAccount userActivePlan={userActivePlan} />
      </Route> */}
      <Route exact path={`${path}/signature`}>
        <MySignature />
      </Route>
      {/* <Route exact path={`${path}/delete-account`}>
        <DeleteAccount />
      </Route> */}
      <Route exact path={`${path}/receipts`}>
        <Receipts />
      </Route>
    </Switch>
  );
};

export default AccountManagementRouter;
