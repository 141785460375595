import { CompositionPlay } from '../../interfaces';
import * as firebase from '../../utils/firebase/firebaseUtils';

export async function getCompositionPlaysByCompositionId(compositionId: string): Promise<CompositionPlay[]> {
  const playsAggr: any[] = [];
  return new Promise((resolve, reject) => {
    firebase.firestore
      .collection('compositions')
      .doc(compositionId)
      .collection('playsCountAggregation')
      .orderBy('createdAt', 'desc')
      .limit(5)
      .get()
      .then(
        (res) => {
          res.docs.map((play: any) => {
            playsAggr.push(play.data() as CompositionPlay);
          });

          firebase.firestore
            .collection('compositions')
            .doc(compositionId)
            .get()
            .then((res) => {
              const data = res.data();
              const playsTotalCount = data?.playsCount;

              playsAggr.map((p: CompositionPlay) => {
                p.percentage = (p.count / playsTotalCount) * 100;
              });

              return resolve(playsAggr);
            });
        },
        (error) => {
          return reject(error);
        }
      );
  });
}
