/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { FeatureCollection } from 'geojson';
import React, { useContext, useEffect, useState } from 'react';
import { HistogramData } from '../../components/IndicationTimeGraph/IndicationTimeGraph';
import { VotesByTime, PollObj, VoteByDay, Composer, Poll, Track, CompositionPlay } from '../../interfaces';
import { Axios, withAuth } from '../../utils/axios';
import { AuthContext } from '../Auth';
import { ArtistDashContext } from './artist';
import { ComposerDashContext } from './composer';
import { DashContext } from './Context';
import { WhoMatchesContext } from './whomatches';

interface Props {
  children: React.ReactNode;
}

export const DashProvider: React.FC<Props> = ({ children }) => {
  const [showTotalsPostsInfos, setShowTotalsPostsInfos] = useState<boolean>(false);

  const [activePoll, setActivePoll] = useState<Poll | undefined>(undefined);
  const [polls, setPolls] = useState<Poll[] | undefined>(undefined);
  const [isGettingPollInfos, setIsGettingPollInfos] = useState<boolean>(false);
  const [activePollPost, setActivePollPost] = useState<PollObj>();
  const [pollPlaysByDay, setPollPlaysByDay] = useState<CompositionPlay[]>([]);
  const [votesLocations, setVotesLocations] = useState<FeatureCollection>();
  const [votesByTime, setVotesByTime] = useState<VotesByTime[]>([]);
  const [pollVotesByMoment, setPollVotesByMoment] = useState<HistogramData | any>({});
  const [votesByDay, setVotesByDay] = useState<VoteByDay[]>([]);
  const [composersNameList, setComposersNameList] = useState<Composer[] | undefined>(undefined);
  const [activePollsTrack, setActivePollsTrack] = useState<undefined | Track>(undefined);
  const [pollsPlayList, setPollsPlayList] = useState<Track[]>([]);
  const [isGettingPollsPlayList, setIsGettingPollsPlayList] = useState<boolean>(false);

  const [playingNowId, setPlayingNowId] = useState<undefined | string>(undefined);
  const [playingNowTrack, setPlayingNowTrack] = useState<undefined | any>(undefined);
  const [playerHeight, setPlayerHeight] = useState<number>(0);
  const [activePlaylist, setActivePlaylist] = useState<Track[]>([]);
  const [activePublishedList, setActivePlublishedList] = useState<any[] | undefined>(undefined);

  const [globalAudioRef, setGlobalAudioRef] = useState<any | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  const { token } = useContext(AuthContext);

  const { setActiveArtistTrack, activeArtistTrack, artistPlayList, compositions: artistPosts } = useContext(ArtistDashContext);
  const { setActiveCompositionsTrack, activeCompositionsTrack, compositionsPlayList, compositions } = useContext(ComposerDashContext);
  const { setActiveWhoMatchesTrack, activeWhoMatchesTrack, whoMatchesPlayList, quizes } = useContext(WhoMatchesContext);

  useEffect(() => {
    setActiveArtistTrack(undefined);
    setActiveCompositionsTrack(undefined);
    setActiveWhoMatchesTrack(undefined);
  }, []);

  useEffect(() => {
    if (activeCompositionsTrack) {
      setActivePlaylist(compositionsPlayList);
      setActivePlublishedList(compositions);
    } else if (activeArtistTrack) {
      setActivePlaylist(artistPlayList);
      setActivePlublishedList(artistPosts);
    } else if (activePollsTrack) {
      setActivePlaylist(pollsPlayList);
      setActivePlublishedList(polls);
    } else if (activeWhoMatchesTrack) {
      setActivePlaylist(whoMatchesPlayList);
      setActivePlublishedList(quizes);
    }
  }, [activeCompositionsTrack, activeArtistTrack, activePollsTrack, activeWhoMatchesTrack]);

  const globalTogglePlayPause = () => {
    const audioPlayer = globalAudioRef?.current;

    if (audioPlayer) {
      if (!audioPlayer.paused) {
        audioPlayer.pause();
        setIsPlaying(false);
      } else if (audioPlayer.paused) {
        audioPlayer.play();
        setIsPlaying(true);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (polls?.length) {
        await getPollsPlayList(token);
      }
    })();
  }, [polls]);

  const _getPollsTrackById = async (id: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/polls/${id}`, withAuth(defaultToken || token));
      return response.data.data.post;
    } catch (error) {
      console.error('Error on getting compositions play list: ', error);
      return undefined;
    }
  };

  const getPollsPlayList = async (defaultToken?: string) => {
    polls?.forEach(async (item, idx) => {
      const track = await _getPollsTrackById(item._id, defaultToken);
      setPollsPlayList((prev) => [...prev, track]);
      if (idx === polls.length - 1) {
        setIsGettingPollsPlayList(false);
      }
    });
  };

  const _getActivePollPost = async (id?: string, defaultToken?: string) => {
    try {
      const response = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/polls/${id}`, withAuth(defaultToken || token));
      setActivePollPost(response.data.data);
      setComposersNameList(response.data.data.post.composers);
      return response.data.data;
    } catch (error) {
      console.error('Error On Getting ActivePollPost: ', error);
      return undefined;
    }
  };

  const _getPollsByToken = async (defaultToken?: string) => {
    try {
      const resp = await Axios(process.env.REACT_APP_NODE_ENV).get('/v1/data/dashboard/search/poll/', withAuth(defaultToken || token));
      const sorted: Poll[] = resp.data;
      setPolls(sorted?.sort((a, b) => b.plays - a.plays));
      return resp.data;
    } catch (error) {
      console.error('Error on Fetchinf Polls in Dash Provider: ', error);
      return undefined;
    }
  };

  const loadPollsInfos = async (id?: string, defaultToken?: string, isFirstReq?: boolean, isPlay?: boolean) => {
    setIsGettingPollInfos(true);

    if (polls?.length) {
      const active = polls?.find((item) => item._id === id);
      setActivePoll(active);
    }

    if (id && !isFirstReq && isPlay) {
      setIsGettingPollsPlayList(true);
      const track = await _getPollsTrackById(id, defaultToken);
      setActivePollsTrack(track);
      setIsGettingPollsPlayList(false);
    }

    try {
      const response = await _getActivePollPost(id, defaultToken);
      if (response?.post?._id) {
        await Axios(process.env.REACT_APP_NODE_ENV)
          .get(`/v1/data/polls/${id}/plays_by_day/`, withAuth(defaultToken || token))
          .then((resp) => {
            setPollPlaysByDay(resp.data);
          })
          .catch((error) => {
            console.error('ErrorPollPlaysByDay: ', error.response);
            setPollPlaysByDay([]);
          });
      } else {
        setPollPlaysByDay([]);
      }

      await Axios(process.env.REACT_APP_NODE_ENV)
        .get(`/v1/data/polls/${id}/votes_by_day/`, withAuth(defaultToken || token))
        .then((resp) => setVotesByDay(resp.data))
        .catch((error) => {
          console.error('ErrorVotesByDay: ', error.response);
          setVotesByDay([]);
        });

      await Axios(process.env.REACT_APP_NODE_ENV)
        .get(`/v1/data/polls/${id}/location_votes/`, withAuth(defaultToken || token))
        .then((resp) => setVotesLocations(resp.data))
        .catch((error) => {
          console.error(error);
          setVotesLocations(undefined);
        });

      const votesByTime = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/polls/${id}/votes_by_time/`, withAuth(defaultToken || token));

      setVotesByTime(votesByTime.data);
      const votesMoments = await Axios(process.env.REACT_APP_NODE_ENV).get(`/v1/data/polls/${id}/moment_votes/`, withAuth(defaultToken || token));
      setPollVotesByMoment(votesMoments.data);

      setIsGettingPollInfos(false);
    } catch (error) {
      console.error(error.response);
      setIsGettingPollInfos(false);
    }
  };

  const handleSelectSearchItem = async (
    id?: string,
    defaultToken?: string,
    typeReq?: 'compositions' | 'artist' | 'whomatches' | 'poll' | 'allGuides',
    isPlay?: boolean
  ) => {
    if (typeReq === 'poll') {
      await loadPollsInfos(id, defaultToken || token, false, isPlay);
    }
  };

  const removeTrackToPlayer = () => {
    setPlayingNowId(undefined);
    setPlayingNowTrack(undefined);
    setPlayerHeight(0);
  };

  return (
    <DashContext.Provider
      value={{
        votesByTime,
        activePoll,
        isGettingPollInfos,
        activePollPost,
        pollPlaysByDay,
        votesLocations,
        showTotalsPostsInfos,
        pollVotesByMoment,
        votesByDay,
        composersNameList,
        polls,
        playingNowId,
        playingNowTrack,
        playerHeight,
        pollsPlayList,
        isGettingPollsPlayList,
        activePollsTrack,
        activePlaylist,
        activePublishedList,
        globalAudioRef,
        isPlaying,
        globalTogglePlayPause,
        setIsPlaying,
        setGlobalAudioRef,
        setActivePlaylist,
        setActivePollsTrack,
        removeTrackToPlayer,
        setPlayingNowId,
        setPlayingNowTrack,
        setPlayerHeight,
        _getPollsByToken,
        _getActivePollPost,
        loadPollsInfos,
        setActivePoll,
        setActivePollPost,
        setComposersNameList,
        setShowTotalsPostsInfos,
        handleSelectSearchItem
      }}
    >
      {children}
    </DashContext.Provider>
  );
};
