import styled, { css } from 'styled-components';

type SuccessProp = {
  hasSuccess?: boolean;
};

export const Container = styled.div<SuccessProp>`
  div.align--errormessage {
    display: flex;
  }

  .floating--label,
  input:focus {
    border-color: #cfcfcf;
  }

  input {
    border-color: #cfcfcf;
  }

  .floating--label input:focus + span,
  .floating--label textarea:focus + span,
  .floating--label.floating span {
    border-color: #cfcfcf;
    outline: none;
    font-size: 1em;
    padding: 0;
  }

  span.error {
    font-size: '12px';
    color: #ef233c;
    font-family: 'Inter';
    animation: appearSoft 0.6s ease-out;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  @keyframes appearSoft {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  ${(props) =>
    props.hasSuccess
      ? css`
          color: #1db954;
          .floating--label input,
          .floating--label textarea {
            border-bottom: 1px solid #cfcfcf !important;
          }
        `
      : css`
          color: #202020;
          .floating--label input,
          .floating--label textarea {
            border-bottom: 1px solid #cfcfcf !important;
          }
        `};
`;
