import styled, { css } from 'styled-components';

import bg_image_01 from '../images/background-login-01.jpg';
import bg_image_02 from '../images/background-login-02.png';
import bg_image_03 from '../images/background-login-03.png';
import bg_image_04 from '../images/background-login-04.png';
import background_login_sm from '../images/background-login-sm.png';

export const Container = styled.div`
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  button {
    img {
      animation: spinner infinite 1s linear;
      margin-left: 20px;
    }
  }

  @media (max-width: 640px) {
    background-size: cover;
    background-image: url(${background_login_sm});
    background-repeat: no-repeat;
    width: 100%;
  }

  a.goback--arrow--container {
    display: flex;
    position: absolute;
    align-items: center;
    left: 45px;
    top: 45px;
    cursor: pointer;
    text-decoration: none;
    font-size: 28px;

    span {
      font-size: 14px;
      margin-left: 8px;
      color: #8f8f8f;
    }
  }

  @media (max-width: 640px) {
    a.goback--arrow--container {
      top: 30px;
      left: 16px;
    }
  }

  header {
    display: none;
    ${({ theme }) =>
      theme === 'primary'
        ? css`
            display: flex;
          `
        : ''}
  }

  .container--section2--text {
    margin-left: 182px;
    color: #fafafa;
    margin-bottom: 180px;
  }

  .container--section2--header {
    width: 100%;
    max-width: 636px;
    font-size: 56px;
    font-weight: normal;
    line-height: 56px;
    margin-bottom: 72px;
  }

  .container--section2--paragraph {
    width: 100%;
    max-width: 605px;
    font-size: 24px;
    line-height: 33px;
  }

  .bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    ${({ theme }) =>
      theme === 'primary'
        ? css`
            background-image: url(${bg_image_01});
          `
        : ''}
    ${({ theme }) =>
      theme === 'secondary'
        ? css`
            background-image: url(${bg_image_02});
          `
        : ''}
      ${({ theme }) =>
      theme === 'tertiary'
        ? css`
            background-image: url(${bg_image_03});
          `
        : ''}
      ${({ theme }) =>
      theme === 'quaternary'
        ? css`
            background-image: url(${bg_image_04});
          `
        : ''}
  }

  .container--section2--overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.8;
    width: 100%;
  }

  .container--section2--footer {
    position: fixed;
    padding: 2rem;
    bottom: 0;
    right: 0;
  }
`;
