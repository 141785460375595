import styled from 'styled-components';

export const ChipsContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  input {
    width: 100%;
    height: auto;
    background-color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c3c3c4;
    flex-wrap: wrap;
  }

  .loading-content{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
  }
`;

export const ChipsInput = styled.div`
  min-width: 50px;
  width: 100%;
  float: left;
  font-size: 14px;
  margin-top: 10px;
  display: flex;

  #main_artist::placeholder {
    color: #8f8f8f;
  }
`;

export const ChipsSuggestion = styled.div`
  background: #fff;
  min-width: 150px;
  padding: 10px;
  position: absolute;
  box-shadow: 0px 6px 8px 0px #e4e5e6;
  z-index: 999;
`;

export const ChipsItems = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: fit-content;
    padding: 2px 7px;
    border-radius: 4px;
    border: 1px solid #E4E5E6;
    color: #8F8F8F;
    font-weight: 700;
    font-size: 12px;
    margin: 1px 2px;
    display: flex;
    flex-direction: row;
  }
`;
