import styled from 'styled-components';
import yellowBackground from '../../../../../images/img-background-yellow.png';

type Props = {
  hasLetterCount?: boolean;
};

export const Container = styled.div`
  position: absolute;
  z-index: 99999;

  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;

  div.content-container {
    position: relative;
    flex-direction: column;
    width: 80%;
    max-width: 800px;
    background: url(${() => yellowBackground});
    border-radius: 8px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: softAppear 0.2s;

    @keyframes softAppear {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    button.close {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -25px;
      display: flex;
      align-items: center;
      font-size: 0.8rem;

      span.close-text {
        color: #cfcfcf;
        text-transform: uppercase;
        margin-right: 5px;
      }
    }

    span.characters-count {
      position: absolute;
      right: 0;
      margin-bottom: 5px;
      color: #a3a3a3;
      font-size: 0.8rem;
    }

    label {
      color: #373737;
      font-size: 0.9rem;
      margin-bottom: 10px;
    }
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  font-weight: 600;
  color: #8f8f8f;
  border-bottom: 1px solid #cfcfcf;
  padding: 0 ${(props: Props) => (props.hasLetterCount ? '48px' : '0')} 5px 0;

  &::placeholder {
    color: #8f8f8f;
    font-style: normal;
    font-weight: 600;
  }
`;

export const ErrorMessage = styled.span`
  color: #ef233c;
  font-size: 0.8rem;
  animation: softAppear 0.2s;

  @keyframes softAppear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;
