import styled from 'styled-components';

type SuccessProp = {
  hasSuccess?: boolean;
};

export const Container = styled.div<SuccessProp>`
  position: relative;

  input {
    padding-right: 30px;
  }

  svg {
    position: absolute;
    top: 30%;
    right: 0;
  }

  div.align--errormessage {
    display: flex;
  }

  span.error {
    font-size: '12px';
    color: #ef233c;
    font-family: 'Inter';
    animation: appearSoft 0.6s ease-out;
    margin-bottom: 5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  @keyframes appearSoft {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;
