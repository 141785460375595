import styled from 'styled-components';
import fallbackImg from '../../../../../images/fallbackpoll.png';

type MusicBoxProps = {
  imgUrl?: string;
};

export const MusicBox = styled.button`
  width: 96px;
  height: 96px;
  min-height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-image: url(${(props: MusicBoxProps) => (props.imgUrl === undefined ? fallbackImg : props.imgUrl)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 30px 0 20px 0;

  img {
    filter: brightness(0) invert(1);
    margin: 0 auto;
    width: 40%;
  }
`;
