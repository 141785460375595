import { FeatureCollection } from 'geojson';
import React from 'react';
import { HistogramData } from '../../components/IndicationTimeGraph/IndicationTimeGraph';
import { VotesByTime, Poll, PollObj, VoteByDay, Composer, Track, CompositionPlay } from '../../interfaces';

interface IDashContext {
  handleSelectSearchItem: (
    id?: string,
    defaultToken?: string,
    typeReq?: 'compositions' | 'artist' | 'whomatches' | 'poll' | 'allGuides',
    isPlay?: boolean
  ) => Promise<void>;
  setActivePoll: (activePoll: Poll) => void;
  setActivePollPost: (activePollPost: PollObj) => void;
  loadPollsInfos: (id?: string, defaultToken?: string, isFirstReq?: boolean, isPlay?: boolean) => Promise<void>;
  setComposersNameList: (composersNameList?: Composer[]) => void;
  _getActivePollPost: (id?: string, defaultToken?: string) => Promise<PollObj | undefined>;
  _getPollsByToken: (defaultToken?: string) => Promise<Poll[] | undefined>;
  setActivePlaylist: (activePlaylist: Track[]) => void;
  activePlaylist: Track[];
  composersNameList?: Composer[];
  votesByTime?: VotesByTime[];
  activePoll?: Poll | any;
  isGettingPollInfos?: boolean;
  activePollPost?: PollObj;
  pollPlaysByDay?: CompositionPlay[];
  votesLocations?: FeatureCollection;
  showTotalsPostsInfos: boolean;
  pollVotesByMoment?: HistogramData | any;
  votesByDay?: VoteByDay[];
  polls?: Poll[];
  playingNowId?: string;
  playingNowTrack?: any;
  playerHeight: number;
  pollsPlayList: Track[];
  isGettingPollsPlayList: boolean;
  activePollsTrack?: Track;
  activePublishedList?: any[];
  globalAudioRef: any | null;
  isPlaying: boolean;
  globalTogglePlayPause: () => void;
  setIsPlaying: (isPlaying: boolean) => void;
  setGlobalAudioRef: (globalAudioRef: any | null) => void;
  setActivePollsTrack: (activePollsTrack?: Track) => void;
  removeTrackToPlayer: () => void;
  setPlayingNowId: (playingNowId?: string) => void;
  setPlayingNowTrack: (playingNowTrack?: string) => void;
  setPlayerHeight: (playerHeight: number) => void;
  setShowTotalsPostsInfos: (showTotalsPostsInfos: boolean) => void;
}

const initialState: IDashContext = {
  handleSelectSearchItem: async () => {},
  setActivePoll: () => {},
  setActivePollPost: () => {},
  loadPollsInfos: async () => {},
  setComposersNameList: () => {},
  _getActivePollPost: async () => undefined,
  _getPollsByToken: async () => undefined,
  setPlayingNowId: () => {},
  setPlayerHeight: () => {},
  setPlayingNowTrack: () => {},
  removeTrackToPlayer: () => {},
  setActivePollsTrack: () => {},
  setActivePlaylist: () => {},
  setGlobalAudioRef: () => {},
  globalTogglePlayPause: () => {},
  globalAudioRef: null,
  isPlaying: true,
  setIsPlaying: () => {},
  activePlaylist: [],
  pollsPlayList: [],
  isGettingPollsPlayList: false,
  polls: undefined,
  activePoll: undefined,
  isGettingPollInfos: false,
  pollPlaysByDay: [],
  showTotalsPostsInfos: false,
  playerHeight: 0,
  setShowTotalsPostsInfos: () => {}
};

const DashContext = React.createContext<IDashContext>(initialState);

const DashConsumer = DashContext.Consumer;

export { DashContext, DashConsumer };

export type { IDashContext };
