import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import './tailwind.output.css';
import './style.css';
import App from './app';
import { userLanguage, userLocale } from './utils/identifyUserLocale';

ReactDOM.render(
  <IntlProvider locale={userLocale} messages={userLanguage}>
    <App />
  </IntlProvider>,
  document.getElementById('root')
);
