/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Media } from 'react-breakpoints';

import moment from 'moment';
import 'moment/locale/pt-br';

import DashboardContainer from '../DashboardContainer';

import IndicationTimeGraph from '../../components/IndicationTimeGraph/IndicationTimeGraph';
import MapComponent from '../../components/MapComponent/MapComponent';
import InfoCard from '../../components/InfoCard/InfoCard';
import PublishedPostsSearch from '../../components/PublishedPostsSearch/PublishedPostsSearch';

import { MediaOptions, PriceObj } from '../../interfaces';

import MPClock from '../../images/mpf-clock.svg';
import SliderCards from '../../components/SliderCards/SliderCards';
// eslint-disable-next-line import/no-named-as-default-member
import DashCard from '../../components/DashCard/DashCard';
import { ArtistDashContext, AuthContext, ComposerDashContext, WhoMatchesContext } from '../../context';
import { DashContext } from '../../context/dash/Context';

type Props = {
  activePlan?: PriceObj;
};

const Poll: React.FC<Props> = ({ activePlan }) => {
  const [inputValue, setInputValue] = useState('');
  const [showResults, setShowResults] = useState<boolean>(false);

  const { token } = useContext(AuthContext);

  const {
    polls,
    setActivePoll,
    activePoll,
    loadPollsInfos,
    _getPollsByToken,
    isGettingPollInfos,
    pollPlaysByDay,
    votesLocations,
    composersNameList,
    pollVotesByMoment,
    votesByDay
  } = useContext(DashContext);

  const { setActiveCompositionsTrack } = useContext(ComposerDashContext);
  const { setActiveArtistTrack } = useContext(ArtistDashContext);
  const { setActiveWhoMatchesTrack } = useContext(WhoMatchesContext);

  useEffect(() => {
    setActiveCompositionsTrack(undefined);
    setActiveArtistTrack(undefined);
    setActiveWhoMatchesTrack(undefined);
  }, []);

  useEffect(() => {
    setShowResults(false);

    (async () => {
      if (!polls) {
        await _getPollsByToken(token);
      }
      if (polls?.length && !activePoll) {
        setActivePoll(polls[0]);
        await loadPollsInfos(polls[0]?._id, token, true);
      }

      setShowResults(true);
    })();
  }, [token, activePoll, polls]);

  const ordenatedPolls = polls?.sort((a, b) => {
    const x = a.datetime_created.toLowerCase();
    const y = b.datetime_created.toLowerCase();
    if (x > y) {
      return -1;
    }
    if (x < y) {
      return 1;
    }
    return 0;
  });

  const composersName = composersNameList?.map((item) => item.name);

  return (
    <DashboardContainer activePlan={activePlan}>
      <div className="w-full flex flex-col" id="page-top-div">
        <div className="w-full hidden sm:block">
          <span className="font-semibold text-2xl">
            <FormattedMessage id="dashboard.poll.page-main-title" defaultMessage="Enquetes" />
          </span>
          {!isGettingPollInfos && polls?.length ? (
            <span className="text-gray-700 flex items-center">
              <img src={MPClock} alt="icon-clock" />
              <span className="ml-1">
                {ordenatedPolls && (
                  <FormattedMessage
                    id="dashboard.poll.last-published-post"
                    values={{
                      date: moment(ordenatedPolls[0]?.datetime_created).format('DD/MM/YYYY'),
                      hourDate: moment(ordenatedPolls[0]?.datetime_created).format('LT')
                    }}
                    defaultMessage="Última enquete publicada em: {date}"
                  />
                )}
              </span>
            </span>
          ) : null}
        </div>
        <div className="flex flex-col lg:flex-row mt-0 sm:mt-8">
          <div className="flex h-full justify-center ">
            <PublishedPostsSearch
              changeInput={setInputValue}
              inputValue={inputValue}
              pollData={polls?.sort((a, b) => b.plays - a.plays)}
              isLoading={isGettingPollInfos && !activePoll}
              isPoll
              className="rounded-none sm:rounded-lg"
              style={{ width: '100%' }}
            />
          </div>
          <hr className="block sm:hidden" />
          <div className="flex flex-col pt-0 sm:pt-8 md:pt-8 md:pl-0 lg:pt-0 lg:pl-8 w-full">
            {isGettingPollInfos ? (
              <div className="pb-8 hidden sm:block">
                <InfoCard guideName={activePoll?.post_name || ''} composersName={composersName} isLoading={isGettingPollInfos} />
              </div>
            ) : polls?.length ? (
              <div className="pb-8 hidden sm:block">
                <InfoCard guideName={activePoll?.post_name || ''} composersName={composersName} isLoading={isGettingPollInfos} />
              </div>
            ) : null}

            <SliderCards>
              <DashCard
                title="Plays"
                width="w-full"
                isPoll
                isPlays
                showResults={showResults}
                totalValue={activePoll?.plays}
                playsByDay={activePoll && activePoll.plays > 0 ? pollPlaysByDay : []}
                isLoading={isGettingPollInfos}
              />
              <DashCard
                title="N° de votos"
                width="w-full"
                isVotesNumber
                isLoading={isGettingPollInfos}
                totalValue={votesByDay?.length ? votesByDay[0]?.total : 0}
                showResults={showResults}
                votesByDay={activePoll?.votes > 0 ? votesByDay : []}
              />
              <DashCard
                title="Resultado da enquete"
                isLoading={isGettingPollInfos}
                showResults={showResults}
                width="w-full"
                isPollResult
                selectedPoll={activePoll}
              />
            </SliderCards>

            <Media>
              {({ breakpoints, currentBreakpoint }: MediaOptions) =>
                breakpoints[currentBreakpoint] > breakpoints.sm ? (
                  <div className="my-8">
                    <MapComponent isPoll data={votesLocations} />
                    <div className="w-full flex flex-col">
                      <IndicationTimeGraph
                        isPoll
                        data={pollVotesByMoment?.data?.length ? pollVotesByMoment : undefined}
                        isLoading={isGettingPollInfos}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex flex-col">
                    <IndicationTimeGraph
                      isPoll
                      data={pollVotesByMoment?.data?.length ? pollVotesByMoment : undefined}
                      isLoading={isGettingPollInfos}
                    />
                    <hr />
                  </div>
                )
              }
            </Media>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Poll;
