import styled from 'styled-components';

export const TagsContainer = styled.div`
    width: 100%;
    height: auto;
    background-color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    padding: 8.5px 0;
    flex-wrap: wrap;
    
    .selected{
      background: #8F8F8F;
      color: #fff;
    }
  
    .not-selected{
      background: #F5F5F5;
    }
`;

export const TagItem = styled.div`
  width: fit-content;
  height: 30px;
  border: 1px solid #E4E5E6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  user-select: none;
`;