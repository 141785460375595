import { FeatureCollection } from 'geojson';
import React from 'react';
import { HistogramData } from '../../../components/IndicationTimeGraph/IndicationTimeGraph';
import {
  AuditionPost,
  Composer,
  Composition,
  CompositionIndication,
  CompositionPlay,
  CompositionTopArtists,
  TopShare,
  Track
} from '../../../interfaces';

interface ComposerDashState {
  compositions: Composition[];
  isGettingPosts: boolean;
  isGettingPlays: boolean;
  isGettingIndications: boolean;
  isGettingTopArtists: boolean;
  // gettedPosts: boolean;
  isGettingTotals: boolean;
  isGettingCompositions: boolean;
  totalPlaysByDay: number;
  totalIndicationsByDay: number;
  totalTopArtists: number;
  totalTopShares: TopShare[];
  activeComposition?: Composition | null | undefined;
  playsByDay?: CompositionPlay[];
  composersNameList?: Composer[];
  indicationsByDay?: CompositionIndication[];
  topArtists?: CompositionTopArtists[];
  topShares?: TopShare[];
  indicationTimes?: HistogramData;
  indicationLocations?: FeatureCollection;
  showTotalsPostsInfos?: boolean;
  showTotalCompositionInfos?: boolean;
  activeCompositionsTrack?: Track;
  compositionsPlayList: Track[];
  isGettingCompPlayList: boolean;
  auditionPosts: AuditionPost[];
  setActiveComposition: (activeComposition?: Composition) => void;
  setActiveCompositionsTrack: (activeCompositionsTrack?: Track) => void;
  handleCompositionData: (compositionId: string) => Promise<any>;
  handlePostSelect: (id: string, defaultToken?: string, isPlay?: boolean) => Promise<any>;
  setComposersNameList: (composersNameList?: Composer[]) => void;
  fetchCompositionsByUserProfileId: () => void;
  setTotalPlaysByDay: (total: number) => void;
  setTotalIndicationsByDay: (total: number) => void;
  setTotalTopArtists: (total: number) => void;
}

const initialState: ComposerDashState = {
  compositions: [],
  isGettingPosts: false,
  // gettedPosts: false,
  isGettingPlays: false,
  isGettingIndications: false,
  isGettingTopArtists: false,
  isGettingTotals: false,
  isGettingCompositions: false,
  totalPlaysByDay: 0,
  totalIndicationsByDay: 0,
  totalTopArtists: 0,
  totalTopShares: [],
  activeComposition: undefined,
  playsByDay: undefined,
  compositionsPlayList: [],
  isGettingCompPlayList: false,
  auditionPosts: [],
  setActiveComposition: () => {},
  setActiveCompositionsTrack: () => {},
  handleCompositionData: async () => {},
  handlePostSelect: async () => {},
  setComposersNameList: () => {},
  fetchCompositionsByUserProfileId: async () => {},
  setTotalPlaysByDay: () => {},
  setTotalIndicationsByDay: () => {},
  setTotalTopArtists: () => {}
};

const ComposerDashContext = React.createContext<ComposerDashState>(initialState);

const ComposerDashConsumer = ComposerDashContext.Consumer;

export { ComposerDashContext, ComposerDashConsumer };

export type { ComposerDashState };
