import React, { useMemo } from 'react';
import DataTable, { IDataTableColumn, IDataTablePaginationOptions, IDataTableStyles } from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { RiPencilFill } from 'react-icons/ri';
import { FaRegTrashAlt } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';

import { useHistory } from 'react-router-dom';
import { CustomizedTableWrapper, MaterialButton } from './styles';

import { NoDataComponent } from '../NoDataComponent';
import Spinner from '../../../../../components/Spinner/Spinner';
import { Project } from '../../../../../interfaces';

import ReactTooltip from 'react-tooltip';

type Props = {
  setActualProject: (row: any) => void;
  setOpenModal: (state: boolean) => void;
  data?: Project[];
  isLoading?: boolean;
  paginationTotalRows?: number;
  handleChangePage?: (page: number, totalRows: number) => any | void;
  handleChangeRowsPerPage?: (currentRowsPerPage: number, currentPage: number) => any | void;
};

const customStyles: IDataTableStyles = {
  headRow: {
    style: {
      border: 'none',
      borderRadius: '8px 8px 0px 0px',
      backgroundColor: '#504D4D',
      padding: '10px 16px'
    }
  },
  headCells: {
    style: {
      color: '#CFCFCF',
      fontSize: '0.9rem',
      fontWeight: 400,
      padding: 0
    },
    activeSortStyle: {
      color: '#CFCFCF'
    }
  },
  rows: {
    style: {
      border: '1px solid #E4E5E6',
      borderRadius: 4,
      minHeight: 76,
      userSelect: 'none',
      width: '100%',
      margin: '4px 0',
      padding: '0 16px'
    },
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(13,101,255,0.1)',
      borderColor: '#fff'
    }
  },
  pagination: {
    style: {
      border: 'none'
    }
  }
};

const rowCellStyle = {
  color: '#6E6969',
  fontSize: '1rem',
  fontWeight: 600,
  padding: '0'
};

export const DataTableComponent: React.FC<Props> = ({
  setActualProject,
  setOpenModal,
  data,
  isLoading,
  paginationTotalRows,
  handleChangePage,
  handleChangeRowsPerPage
}) => {
  const intl = useIntl();
  const history = useHistory();

  const columns: IDataTableColumn<any>[] = useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: 'dashboard.my-projects.list-component.table-header.project_name',
          defaultMessage: 'Nome do projeto'
        }),
        selector: 'name',
        cell: (row: any) => (
          <div
            onClick={() => {
              if (row._id) return history.push({ pathname: `/dash/producer/project/${row._id}`, state: row });
            }}
          >
            {row?.name}
          </div>
        ),
        sortable: true,
        style: {
          ...rowCellStyle
        }
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.my-projects.list-component.table-header.main-artist',
          defaultMessage: 'Artista principal'
        }),
        selector: 'target_artists',
        cell: (row: any) => (
          <div
            onClick={() => {
              if (row._id) return history.push({ pathname: `/dash/producer/project/${row._id}`, state: row });
            }}
          >
            {row?.target_artists[0]?.name}
          </div>
        ),
        sortable: true,
        style: {
          ...rowCellStyle
        }
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.my-projects.list-component.table-header.guides',
          defaultMessage: 'Guias'
        }),
        selector: 'guides',
        cell: (row: any) => (
          <div
            onClick={() => {
              if (row._id) return history.push({ pathname: `/dash/producer/project/${row._id}`, state: row });
            }}
          >
            {row.members.length}
          </div>
        ),
        sortable: true,
        style: {
          ...rowCellStyle
        }
      },
      {
        name: intl.formatMessage({
          id: 'dashboard.my-projects.list-component.table-header.actions',
          defaultMessage: 'Ações'
        }),
        selector: 'actions',
        cell: (row: Project) => (
          <div className="flex items-center max-content min-content">
            <MaterialButton data-tip data-for="edit" onClick={() => history.push(`/dash/producer/edit-project/${row._id}`)}>
              <RiPencilFill size={20} color={rowCellStyle.color} />
            </MaterialButton>
            <ReactTooltip id="edit" place="top" effect="solid">
              {intl.formatMessage({
                id: 'dashboard.my-projects.list-component.actions.edit',
                defaultMessage: 'Editar'
              })}
            </ReactTooltip>
            <MaterialButton
              data-tip
              data-for="delete"
              onClick={() => {
                setActualProject(row);
                setOpenModal(true);
              }}
            >
              <FaRegTrashAlt size={20} color={rowCellStyle.color} />
            </MaterialButton>
            <ReactTooltip id="delete" place="top" effect="solid">
              {intl.formatMessage({
                id: 'dashboard.my-projects.list-component.actions.delete',
                defaultMessage: 'Excluir'
              })}
            </ReactTooltip>
            <MaterialButton
              data-tip
              data-for="open"
              onClick={() => {
                if (row._id) return history.push({ pathname: `/dash/producer/project/${row._id}`, state: row });
              }}
            >
              <IoIosArrowForward size={20} color={rowCellStyle.color} />
            </MaterialButton>
            <ReactTooltip id="open" place="top" effect="solid">
              <span>
                {intl.formatMessage({
                  id: 'dashboard.my-projects.list-component.actions.open',
                  defaultMessage: 'Gestão de guias'
                })}
              </span>
            </ReactTooltip>
          </div>
        ),
        allowOverflow: true,
        button: true
      }
    ],
    [history, intl, setActualProject, setOpenModal]
  );

  const paginationComponentOptions: IDataTablePaginationOptions = {
    rowsPerPageText: intl.formatMessage({
      id: 'dashboard.my-projects.list-component.rows-per-page',
      defaultMessage: 'Linhas por página:'
    }),
    rangeSeparatorText: intl.formatMessage({
      id: 'dashboard.my-projects.list-component.of',
      defaultMessage: 'de'
    }),
    noRowsPerPage: true,
    selectAllRowsItem: false,
    selectAllRowsItemText: intl.formatMessage({
      id: 'dashboard.my-projects.list-component.all',
      defaultMessage: 'Todas'
    })
  };

  return (
    <>
      <CustomizedTableWrapper className="shadow-lg">
        <DataTable
          data={data as Project[]}
          columns={columns}
          progressPending={isLoading}
          noHeader
          pagination
          paginationServer
          paginationComponentOptions={paginationComponentOptions}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onRowClicked={(row) => {
            if (row._id) return history.push({ pathname: `/dash/producer/project/${row._id}`, state: row });
          }}
          fixedHeaderScrollHeight="450px"
          noDataComponent={<NoDataComponent />}
          progressComponent={
            <div className="py-8">
              <Spinner isLoading />
            </div>
          }
          customStyles={customStyles}
          paginationTotalRows={paginationTotalRows}
          highlightOnHover
          pointerOnHover
        />
      </CustomizedTableWrapper>
    </>
  );
};
