/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect } from 'react';
import { Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FormattedMessage } from 'react-intl';
import SecondCheckoutContainer from '../secondCheckoutContainer/SecondCheckoutContainer';
import { Container } from './styles';
import checkedIcon from '../../images/checked-green.svg';
import visaCard from '../../images/visa-card.svg';
import masterCard from '../../images/mc-card.svg';
import unknownCard from '../../images/unknown-card.svg';
import { CheckoutConsumer, CheckoutContext } from '../../context/checkout';
import reloadIcon from '../../images/mpf-reload-white.svg';
import checkedIconWhite from '../../images/mpf-checked-white.svg';
import reaisConverter from '../../utils/simpleReaisConverter/reaisConverter';
import Button from '../../components/Button/Button';
import { Match } from '../../interfaces';
import { canTrial } from '../../utils/checkoutHelpers';

const ConfirmPlan: React.FC = () => {
  const { selectedPrice, customer, setErrorOnConfirmSubscription } = useContext(CheckoutContext);

  useEffect(() => {
    setErrorOnConfirmSubscription('');
  }, []);

  const { params }: Match = useRouteMatch();
  const history = useHistory();
  const { priceId } = params;

  const userCanTrial = canTrial(customer, selectedPrice);

  const trialEnd = moment()
    .add((selectedPrice?.trial_days as number) / 30, 'M')
    .format('LL');

  return (
    <CheckoutConsumer>
      {({
        isChangingPlan,
        isChangedPlan,
        selectedPrice,
        discountedPrice,
        userPaymentMethod,
        coupon,
        handleConfirmSubscription,
        errorOnConfirmSubscription,
        setIsChangingPaymentMethod
      }) =>
        isChangedPlan ? (
          <Redirect to={`/checkout/invoicing/${selectedPrice?._id}`} />
        ) : !userPaymentMethod ? (
          <Redirect to={`/checkout/make-payment/${priceId || selectedPrice?._id}`} />
        ) : (
          <SecondCheckoutContainer
            hasGoBack
            goBackRoute={`/checkout/make-payment/${selectedPrice?._id}`}
            title="confirm-new-plan"
          >
            <Container className="w-full max-w-md bg-white pb-4 flex flex-col h-full">
              <span className="font-normal text-xl lg:leading-10 lg:text-3xl " style={{ color: '#202020' }}>
                <FormattedMessage id="checkout.confirm-plan.plan-type" values={{ productName: selectedPrice?.name }} />
              </span>
              <div className="w-full flex items-center justify-between mb-4 pt-2">
                <p style={{ color: '#817B7B' }} className="text-xs leading-5 w-68 lg:text-base lg:leading-6">
                  {!userCanTrial ? (
                    <FormattedMessage id="checkout.confirm-plan.description-payment-has-tried" />
                  ) : selectedPrice?.has_trial ? (
                    <FormattedMessage
                      id="checkout.confirm-plan.description-payment"
                      values={{
                        firstBillingDate: trialEnd
                      }}
                    />
                  ) : (
                    <FormattedMessage id="checkout.confirm-plan.description-payment-has-tried" />
                  )}
                </p>
                <span style={{ color: '#202020' }} className="text-right text-base md:text-xl font-semibold leading-7">
                  <FormattedMessage
                    id="checkout.confirm-plan.plan-price"
                    values={{
                      productPrice: reaisConverter(discountedPrice || selectedPrice?.price)
                    }}
                  />
                </span>
              </div>
              <div className="w-56 text-base leading-6 font-semibold h-8 flex">
                {userPaymentMethod?.card.brand === 'visa' && <img className="mr-4" src={visaCard} alt="brand-card" />}

                {userPaymentMethod?.card.brand === 'mastercard' && (
                  <img className="mr-4" src={masterCard} alt="brand-card" />
                )}

                {!userPaymentMethod?.card.brand && <img className="mr-4" src={unknownCard} alt="brand-card" />}
                <span>**** **** **** {userPaymentMethod?.card.last4 ? userPaymentMethod?.card.last4 : 'XXXX'}</span>
              </div>
              <div style={{ background: '#cfcfcf' }} className="mt-6 mb-8 w-full h-px" />
              <div className="w-full flex justify-between">
                <span style={{ color: '#817B7B' }} className="text-sm font-normal leading-6">
                  <FormattedMessage id="checkout.confirm-plan.payment-period" />
                </span>
                <span style={{ color: '#202020' }} className="text-sm font-normal leading-6">
                  <FormattedMessage id="checkout.confirm-plan.month" />
                </span>
              </div>
              <div style={{ background: '#cfcfcf' }} className="mt-4 mb-4 w-full h-px" />
              {selectedPrice?.has_trial && userCanTrial && (
                <>
                  <div className="w-full flex justify-between">
                    <span style={{ color: '#817B7B' }} className="text-sm font-normal leading-6">
                      <FormattedMessage id="checkout.confirm-plan.free-demo" />
                    </span>
                    <span style={{ color: '#202020' }} className="text-sm font-normal leading-6">
                      {selectedPrice.trial_days &&
                        (selectedPrice.trial_days <= 30 ? (
                          <FormattedMessage id="checkout.confirm-plan.one-month" />
                        ) : (
                          <FormattedMessage
                            id="checkout.confirm-plan.more-than-one-months"
                            values={{
                              monthsNumber: selectedPrice.trial_days / 30
                            }}
                          />
                        ))}
                    </span>
                  </div>
                  <div style={{ background: '#cfcfcf' }} className="mt-4 mb-4 w-full h-px" />
                  <div className="w-full flex justify-between">
                    <span style={{ color: '#817B7B' }} className="text-sm font-normal leading-6">
                      <FormattedMessage
                        id="checkout.confirm-plan.after-xtrial-days"
                        values={{
                          trial_days: selectedPrice.trial_days
                        }}
                      />
                    </span>
                    <span style={{ color: '#202020' }} className="text-sm font-normal leading-6">
                      <FormattedMessage
                        id="checkout.confirm-plan.plan-price"
                        values={{
                          productPrice: reaisConverter(discountedPrice || selectedPrice?.price)
                        }}
                      />
                    </span>
                  </div>
                  <div style={{ background: '#cfcfcf' }} className="mt-4 mb-4 w-full h-px" />
                </>
              )}

              <div className="w-full flex justify-between">
                <span style={{ color: '#817B7B' }} className="text-sm font-normal leading-6">
                  <FormattedMessage id="checkout.confirm-plan.has-coupon" />
                </span>
                <div className="flex items-center">
                  {discountedPrice ? (
                    <>
                      <img className="w-5 h-5" src={checkedIcon} alt="checked-icon" />
                      <span style={{ color: '#817B7B' }} className="ml-2 mr-2 sm:mr-4 text-xs">
                        <FormattedMessage id="checkout.confirm-plan.coupon.applied" />
                      </span>
                      <span style={{ color: '#202020' }} className="text-right text-base font-semibold leading-6">
                        <FormattedMessage
                          id="checkout.confirm-plan.coupon.dicount"
                          values={{
                            discountValue: reaisConverter(
                              selectedPrice?.price && selectedPrice?.price - discountedPrice
                            )
                          }}
                        />
                      </span>
                    </>
                  ) : (
                    <span style={{ color: '#202020' }} className="text-right text-base font-normal leading-6">
                      Não
                    </span>
                  )}
                </div>
              </div>
              <div style={{ background: '#cfcfcf' }} className="mt-4 mb-4 w-full h-px" />
              <div style={{ marginBottom: '55px' }} className="w-full flex justify-between">
                <span style={{ color: '#202020' }} className="text-base font-normal leading-6">
                  <FormattedMessage id="checkout.confirm-plan.today-total" />
                </span>
                <span style={{ color: '#202020' }} className="text-base font-semibold leading-6">
                  <FormattedMessage
                    id="checkout.confirm-plan.today-total-price"
                    values={{
                      todayTotal:
                        selectedPrice?.has_trial && userCanTrial
                          ? reaisConverter(selectedPrice.trial_price)
                          : reaisConverter(discountedPrice || selectedPrice?.price)
                    }}
                  />
                </span>
              </div>
              {errorOnConfirmSubscription !== '' ? (
                <span className="text-xs text-red-500 mb-4">{errorOnConfirmSubscription}</span>
              ) : null}

              <Button
                style={{
                  margin: '0 0 24px',
                  borderRadius: '4px',
                  padding: '17px 0'
                }}
                className="w-full flex items-center justify-center text-base py-4"
                theme="secondary"
                onClick={() => handleConfirmSubscription(selectedPrice?._id, coupon?.id)}
              >
                {!isChangingPlan && !isChangedPlan && <FormattedMessage id="checkout.confirm-plan.confirm-change" />}
                {isChangingPlan && !isChangedPlan && (
                  <>
                    <FormattedMessage id="checkout.confirm-plan.confirming-change" />
                    <img className="spin" src={reloadIcon} alt="spinner-icon" />
                  </>
                )}
                {!isChangingPlan && isChangedPlan && (
                  <>
                    <FormattedMessage id="checkout.confirm-plan.confirmed-change-success" />
                    <img className="ml-6" src={checkedIconWhite} alt="checked-icon" />
                  </>
                )}
              </Button>
              {/* <Link to={`/checkout/make-payment/${selectedPrice?._id}`} className="w-full"> */}
              <Button
                onClick={() => {
                  setIsChangingPaymentMethod(true);
                  history.push(`/checkout/make-payment/${selectedPrice?._id}`);
                }}
                style={{
                  margin: '0 0 20px',
                  borderRadius: '4px',
                  padding: '17px 0',
                  border: '1px solid #202020'
                }}
                className="w-full text-base py-4 font-semibold"
                theme="default"
              >
                <FormattedMessage id="checkout.confirm-plan.change-card" />
              </Button>
              {/* </Link> */}
              <p style={{ color: '#817B7B' }} className="w-full font-normal text-xs leading-5 mb-4">
                <FormattedMessage id="checkout.confirm-plan.terms-initial" />"
                <b>
                  <FormattedMessage id="checkout.confirm-plan.terms.initial-2" />
                </b>
                ", <FormattedMessage id="checkout.confirm-plan.terms.initial-3" />
                <a
                  className="text-blue-600"
                  href="https://static.musicplayce.com/assets/terms_of_use.pdf"
                  target="blank"
                >
                  <FormattedMessage id="checkout.confirm-plan.terms.initial-4" />
                </a>
                .
              </p>
            </Container>
          </SecondCheckoutContainer>
        )
      }
    </CheckoutConsumer>
  );
};

export default ConfirmPlan;
