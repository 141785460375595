import { Composition } from '../../interfaces';
import * as firebase from '../../utils/firebase/firebaseUtils';

export async function getCompositionsByUserProfileId(uid: string): Promise<Composition[]> {     
  return new Promise((resolve, reject) => {
    firebase.firestore
      .collection('compositions')
      .where('userProfileId', '==', uid)
      .where('deleted', '==', false)
      .orderBy('createdAt', 'desc')
      .get()
      .then((data) => {
        const compositions: Composition[] = [];
        let composition: Composition;
        data.docs.map((c: any) => {
          composition = c.data() as Composition;
          composition._id = c.id;
          compositions.push(composition);
        });
        return resolve(compositions);
      },
      (error) => { return reject(error)});
  });
}
