import styled from 'styled-components';

export const ChipsContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  input {
    width: 100%;
    height: auto;
    background-color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    padding: 8.5px 0;
    border-bottom: 1px solid #c3c3c4;
    flex-wrap: wrap;
  }
`;

export const ChipsInput = styled.div`
  min-width: 50px;
  width: 100%;
  float: left;

  input {
    width: 100%;
  }
`;

export const ChipsSuggestion = styled.div`
  background: #fff;
  width: 45%;
  padding: 10px;
  position: absolute;
  box-shadow: 0px 6px 8px 0px #e4e5e6;
  z-index: 999;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  margin-top: -16px;
  div{
    padding: 10px 0;
    cursor: pointer;
  }

  @media screen and (max-width: 640px) {
    width: 84%;
  }
`;

export const ChipsSuggestionItems = styled.div`
  padding: 10px 0;
  cursor: pointer;
`;

export const ChipsItems = styled.div`
  margin-top: 4px;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: fit-content;
    padding: 5px;
    background-color: #e2e2e2;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 12px;
    margin: 1px 2px;
    display: flex;
    flex-direction: row;
  }
`;
