import styled, { css } from 'styled-components';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

type Props = {
  isSucceed: boolean;
  isFailed: boolean;
};

export const CustomDropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  padding: 20px;
  height: 280px;
  border-radius: 4px;
  border: 2px dashed ${(props: any) => getColor(props)};
  box-sizing: border-box;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const UploadBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${(props: Props) => {
    if (props.isSucceed)
      return css`
        background-color: rgb(29, 185, 84, 0.2);
      `;
    if (props.isFailed)
      return css`
        background-color: rgb(204, 28, 53, 0.2);
      `;
  }};
  align-items: center;
  border: 1px solid #e4e5e6;
  border-radius: 4px;
  margin-top: 5px;
  padding: 10px;

  div.upload--infos--container {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 28px;
    }
  }

  div.upload--infos {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    span.upload--name {
      ${(props: Props) => {
        if (props.isSucceed)
          return css`
            color: #1db954;
          `;
        if (props.isFailed)
          return css`
            color: #cc1c35;
          `;
      }};
      font-weight: 600;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 18rem;
    }

    span.upload--size {
      ${(props: Props) => {
        if (props.isSucceed)
          return css`
            color: #1db954;
          `;
        if (props.isFailed)
          return css`
            color: #cc1c35;
          `;
      }};
      font-weight: normal;
      font-size: 10px;
    }
  }
`;

export const UploadsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 160px;
  overflow-x: auto;
`;
